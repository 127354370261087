import { RECEIVING_INSPECTION_HEADER_STYLE } from "./header"

const RECEIVING_INSPECTION_HEADER_CONFIG = [
    {id: 'id', label: 'ID LIST', cellPosition: 'A5', mergedCells: 'A5:B5'},
    {id: 'date', label: 'DATE', cellPosition: 'A6', mergedCells: 'A6:B6'},
    {id: 'co_purchase_order', label: 'CUSTOMER PO#', cellPosition: 'A7', mergedCells: 'A7:B7'},
    {id: 'co_purchase_item', label: 'CUSTOMER ITEM#', cellPosition: 'A8', mergedCells: 'A8:B8'},
    {id: 'num_sales_order', label: 'SALES PO#', cellPosition: 'A9', mergedCells: 'A9:B9'},
    {id: 'num_sales_item', label: 'SALES ITEM#', cellPosition: 'A10', mergedCells: 'A10:B10'},
    {id: 'erp_ref', label: 'ERP REFERENCE', cellPosition: 'A11', mergedCells: 'A11:B11'},
    {id: 'dsc_material', label: 'MATERIAL', cellPosition: 'A12', mergedCells: 'A12:B12'},
    {id: 'end_user', label: 'END USER', cellPosition: 'A13', mergedCells: 'A13:B13'},
]

const RECEIVING_INSPECTION_HEADER_CONTENT_CONFIG = [
    {id: 'id', accessor: 'id', cellPosition: 'C5', mergedCells: 'C5:D5'},
    {id: 'date', accessor: 'date', cellPosition: 'C6', mergedCells: 'C6:D6'},
    {id: 'co_purchase_order', accessor: 'co_purchase_order', cellPosition: 'C7', mergedCells: 'C7:D7'},
    {id: 'co_purchase_item', accessor: 'co_purchase_item', cellPosition: 'C8', mergedCells: 'C8:D8'},
    {id: 'num_sales_order', accessor: 'num_sales_order', cellPosition: 'C9', mergedCells: 'C9:D9'},
    {id: 'num_sales_item', accessor: 'num_sales_item', cellPosition: 'C10', mergedCells: 'C10:D10'},
    {id: 'erp_ref', accessor: 'erp_ref', cellPosition: 'C11', mergedCells: 'C11:D11'},
    {id: 'dsc_material', accessor: 'dsc_material', cellPosition: 'C12', mergedCells: 'C12:D12'},
    {id: 'end_user', accessor: 'end_user', cellPosition: 'C13', mergedCells: 'C13:D13'},
]

const RECEIVING_INSPECTION_SECOND_HEADER_CONFIG = [
    {id: 'total_inspected', label: 'Inspection Summary', cellPosition: 'K10', mergedCells: 'K10:N10'},
    {id: 'total_inspected', label: 'Inspected', cellPosition: 'K11', mergedCells: 'K11:K12'},
    {id: 'total_accepted', label: 'Accepted', cellPosition: 'L11', mergedCells: 'L11:L12'},
    {id: 'total_rejected', label: 'Rejected', cellPosition: 'M11', mergedCells: 'M11:M12'},
    {id: 'total_repair', label: 'Repair', cellPosition: 'N11', mergedCells: 'N11:N12'},
]

const RECEIVING_INSPECTION_SECOND_HEADER_CONTENT_CONFIG = [
    {id: 'total_inspected', accessor: 'total_inspected', cellPosition: 'K13', mergedCells: 'K13:K13'},
    {id: 'total_accepted', accessor: 'total_accepted', cellPosition: 'L13', mergedCells: 'L13:L13'},
    {id: 'total_rejected', accessor: 'total_rejected', cellPosition: 'M13', mergedCells: 'M13:M13'},
    {id: 'total_repair', accessor: 'total_repair', cellPosition: 'N13', mergedCells: 'N13:N13'},
]

const RECEIVING_INSPECTION_DATA_HEADER_CONFIG = [
    {id: 'item_no', label: 'NO', cellPosition: 'A15', mergedCells: 'A15:A16'},
    {id: 'val_id', label: 'VALID', cellPosition: 'B15', mergedCells: 'B15:B16'},
    {id: 'mark_legibility_report', label: 'MARKING LEGIBILITY', cellPosition: 'C15', mergedCells: 'C15:C16'},
    {id: 'coating_varnish_report', label: 'COATING / VARNISH CONDITION', cellPosition: 'D15', mergedCells: 'D15:D16'},
    {id: 'thread_prot_report', label: 'THREAD PROTECTORS', cellPosition: 'E15', mergedCells: 'E15:E16'},
    {id: 'thread', label: 'THREAD', cellPosition: 'F15', mergedCells: 'F15:G15'},
    {id: 'pin_report', label: 'PIN', cellPosition: 'F16', mergedCells: 'F16:F16'},
    {id: 'box_report', label: 'BOX', cellPosition: 'G16', mergedCells: 'G16:G16'},
    {id: 'pipe_body', label: 'PIPE BODY', cellPosition: 'H15', mergedCells: 'H15:I15'},
    {id: 'pipe_int_report', label: 'INTERNAL', cellPosition: 'H16', mergedCells: 'H16:H16'},
    {id: 'pipe_ext_report', label: 'EXTERNAL', cellPosition: 'I16', mergedCells: 'I16:I16'},
    {id: 'drift_report', label: 'DRIFT', cellPosition: 'J15', mergedCells: 'J15:J16'},
    {id: 'final_report', label: 'FINAL STATUS', cellPosition: 'K15', mergedCells: 'K15:K16'},
    {id: 'root_cause', label: 'ROOT CAUSE', cellPosition: 'L15', mergedCells: 'L15:L16'},
    {id: 'pic_ref', label: 'PICTURE REFERENCE', cellPosition: 'M15', mergedCells: 'M15:M16'},
    {id: 'inspector', label: 'INSPECTOR', cellPosition: 'N15', mergedCells: 'N15:N16'},
    {id: 'equipment', label: 'EQUIPMENT', cellPosition: 'O15', mergedCells: 'O15:O16'},
    {id: 'remarks', label: 'REMARKS', cellPosition: 'P15', mergedCells: 'P15:P16'},
]

const RECEIVING_INSPECTION_DATA_CONTENT_CONFIG = [
    {id: 'item_no', accessor: 'item_no', cellPosition: 'A17'},
    {id: 'val_id', accessor: 'val_id', cellPosition: 'B17'},
    {id: 'mark_legibility_report', accessor: 'mark_legibility_report', cellPosition: 'C17'},
    {id: 'coating_varnish_report', accessor: 'coating_varnish_report', cellPosition: 'D17'},
    {id: 'thread_prot_report', accessor: 'thread_prot_report', cellPosition: 'E17'},
    {id: 'pin_report', accessor: 'pin_report', cellPosition: 'F17', mergedCells: 'F17:F17'},
    {id: 'box_report', accessor: 'box_report', cellPosition: 'G17', mergedCells: 'G17:G17'},
    {id: 'pipe_int_report', accessor: 'pipe_int_report', cellPosition: 'H17', mergedCells: 'H17:H17'},
    {id: 'pipe_ext_report', accessor: 'pipe_ext_report', cellPosition: 'I17', mergedCells: 'I17:I17'},
    {id: 'drift_report', accessor: 'drift_report', cellPosition: 'J17'},
    {id: 'final_report', accessor: 'final_report', cellPosition: 'K17'},
    {id: 'root_cause', accessor: 'root_cause', cellPosition: 'L17'},
    {id: 'pic_ref', accessor: 'pic_ref', cellPosition: 'M17'},
    {id: 'inspector', accessor: 'inspector', cellPosition: 'N17'},
    {id: 'equipment', accessor: 'equipment', cellPosition: 'O17'},
    {id: 'remarks', accessor: 'remarks', cellPosition: 'P17'},
]

const RECEIVING_INSPECTION_IMAGE_SHEET_CONFIG = [
    {id: 'valid', label: 'VALID', cellPosition: 'A1', mergedCells: 'A1:A1'},
    {id: 'picture_reference', label: 'PICTURE REFERENCE', cellPosition: 'B1', mergedCells: 'B1:B1'},
    {id: 'picture', label: 'PICTURE', cellPosition: 'C1', mergedCells: 'C1:C1'}
]

const RECEIVING_INSPECTION_IMAGE_SHEET_CONTENT_CONFIG = [
    {id: 'valid', accessor: 'valid'},
    {id: 'picture_reference', accessor: 'picture_reference'},
    {id: 'picture', accessor: 'picture'}
]

const RECEIVING_INSPECTION_LOGO_POSITION = 'K1:N9'
const RECEIVING_INSPECTION_DATA_CONTENT_ROW = 17
const RECEIVING_INSPECTION_IMAGE_CONTENT_ROW = 2 

export const RECEIVING_INSPECTION_CONFIG = {
    RECEIVING_INSPECTION: {
        headerLabelConfig: RECEIVING_INSPECTION_HEADER_CONFIG,
        headerContentConfig: RECEIVING_INSPECTION_HEADER_CONTENT_CONFIG,
        secondHeaderLabelConfig: RECEIVING_INSPECTION_SECOND_HEADER_CONFIG,
        secondHeaderContentConfig: RECEIVING_INSPECTION_SECOND_HEADER_CONTENT_CONFIG,
        secondHeaderLabelStyle: RECEIVING_INSPECTION_HEADER_STYLE.RI_LABEL,
        secondHeaderContentStyle: RECEIVING_INSPECTION_HEADER_STYLE.RI_CONTENT,
        dataLabelConfig: RECEIVING_INSPECTION_DATA_HEADER_CONFIG,
        dataContentConfig: RECEIVING_INSPECTION_DATA_CONTENT_CONFIG,
        dataContentRow: RECEIVING_INSPECTION_DATA_CONTENT_ROW,
        dataLogoRow: RECEIVING_INSPECTION_LOGO_POSITION,
        headerImageSheetConfig: RECEIVING_INSPECTION_IMAGE_SHEET_CONFIG,
        dataImageContentConfig: RECEIVING_INSPECTION_IMAGE_SHEET_CONTENT_CONFIG,
        dataImageContentRow: RECEIVING_INSPECTION_IMAGE_CONTENT_ROW
    }
}