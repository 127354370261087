const DEFAULT_NUM_FMT = "0.000"
const INT_NUM_FMT = '0'

const FONT_NAME_BOLD = "Arial Black"
const FONT_NAME_NORMAL = "Arial"

const GREY_COLOR = "D9D9D9"
const YELLOW_COLOR = "FFF2CC"
const ALT_YELLOW_COLOR = "FFC000"
const WHITE_COLOR = "FFFFFF"
const DEFAULT_BORDER_COLOR = "c9c9c9"

const GREEN_FONT_COLOR = { argb: "0000B050" }
const RED_FONT_COLOR = { argb: "00FF0000" }
const YELLOW_FONT_COLOR = { argb: "00BE9505" }

const getSolidFill = (color) => ({
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: color },
})

const YELLOW_FILL = getSolidFill(YELLOW_COLOR)
const ALT_YELLOW_FILL = getSolidFill(ALT_YELLOW_COLOR)
const GREY_FILL = getSolidFill(GREY_COLOR)
const WHITE_FILL = getSolidFill(WHITE_COLOR)

const BOTTOM_LEFT_ALIGNMENT = {
    vertical: "bottom",
    horizontal: "left",
    wrapText: true,
}
const MIDDLE_CENTER_ALIGNMENT = {
    vertical: "middle",
    horizontal: "center",
    wrapText: true,
}

const THIN_FULL_BORDER = {
    top: { style: "thin" },
    left: { style: "thin" },
    bottom: { style: "thin" },
    right: { style: "thin" },
}

// const MEDIUM_FULL_BORDER = {
//     top: { style: 'medium', },
//     left: { style: 'medium' },
//     bottom: { style: 'medium' },
//     right: { style: 'medium' }
// }

const DEFAULT_BORDER = {
    top: { style: "thin", color: { argb: DEFAULT_BORDER_COLOR } },
    left: { style: "thin", color: { argb: DEFAULT_BORDER_COLOR } },
    bottom: { style: "thin", color: { argb: DEFAULT_BORDER_COLOR } },
    right: { style: "thin", color: { argb: DEFAULT_BORDER_COLOR } },
}

const CLEAR_STYLE = {
    alignment: BOTTOM_LEFT_ALIGNMENT,
    border: DEFAULT_BORDER,
    fill: WHITE_FILL,
    height: 15,
    font: {
        name: FONT_NAME_NORMAL,
        size: 11,
        bold: false,
    },
}

const DEFAULT_COLUMNS_WIDTH = [
    { key: "A", width: 10 },
    { key: "B", width: 30 },
    { key: "C", width: 20 },
    { key: "D", width: 25 },
    { key: "E", width: 20 },
    { key: "F", width: 20 },
    { key: "G", width: 70 },
    { key: "H", width: 20 },
    { key: "I", width: 40 },
    { key: "J", width: 40 },
    { key: "K", width: 20 },
    { key: "L", width: 20 },
]

const DELIVERY_NOTES_WITH_MEASURE_COLUMNS_WIDTH = [
    { key: "A", width: 30 },
    { key: "B", width: 30 },
    { key: "C", width: 30 },
    { key: "D", width: 30 },
    { key: "E", width: 30 },
    { key: "F", width: 50 },
    { key: "G", width: 30 },
    { key: "H", width: 40 },
    { key: "I", width: 30 }
]

const MATERIAL_REQUISITION_WITH_MEASURE_COLUMNS_WIDTH = [
    { key: "A", width: 18 },
    { key: "B", width: 30 },
    { key: "C", width: 30 },
    { key: "D", width: 30 },
    { key: "E", width: 20 },
    { key: "F", width: 30 },
    { key: "G", width: 100 },
    { key: "H", width: 20 },
    { key: "I", width: 15 },
    { key: "J", width: 15 },
    { key: "K", width: 15 },
    { key: "L", width: 15 },
    { key: "M", width: 30 },
    { key: "N", width: 30 },
    { key: "O", width: 20 },
    { key: "P", width: 45 },
    { key: "Q", width: 25 },
    { key: "R", width: 25 },
    { key: "S", width: 20 },
    { key: "T", width: 30 },
    { key: "U", width: 25 },
    { key: "V", width: 30 },
    { key: "W", width: 30 },
    { key: "X", width: 30 },
    { key: "Y", width: 20 },
    { key: "Z", width: 25 },
    { key: "AA", width: 40 },
    { key: "AB", width: 40 },
]

const RECEIPT_NOTES_COLUMNS_WIDTH = [
    { key: "A", width: 15 },
    { key: "B", width: 30 },
    { key: "C", width: 50 },
    { key: "D", width: 25 },
    { key: "E", width: 30 },
    { key: "F", width: 40 },
    { key: "G", width: 50 },
    { key: "H", width: 20 }
]

const DEFAULT_COLUMNS_IMAGE_SHEET_WIDTH = [
    { key: "A", width: 60 },
    { key: "B", width: 60 },
    { key: "C", width: 60 },
    { key: "D", width: 60 },
    { key: "E", width: 60 },
    { key: "F", width: 60 },
    { key: "G", width: 60 }
]

const MATERIAL_REQUISITION_IMAGE_SHEET_COLUMNS_WIDTH = [
    { key: "A", width: 60 },
    { key: "B", width: 60 },
    { key: "C", width: 60 }
]

const RECEIVING_INSPECTION_COLUMNS_WIDTH = [
    { key: "A", width: 18 },
    { key: "B", width: 30 },
    { key: "C", width: 30 },
    { key: "D", width: 60 },
    { key: "E", width: 30 },
    { key: "F", width: 15 },
    { key: "G", width: 15 },
    { key: "H", width: 15 },
    { key: "I", width: 17 },
    { key: "J", width: 15 },
    { key: "K", width: 20 },
    { key: "L", width: 40 },
    { key: "M", width: 40 },
    { key: "N", width: 40 },
    { key: "O", width: 30 },
    { key: "P", width: 30 },
]

const BACKLOAD_MANIFEST_COLUMNS_WIDTH = [
    { key: 'A', width: 18 },
    { key: 'B', width: 30 },
    { key: 'C', width: 30 },
    { key: 'D', width: 30 },
    { key: 'E', width: 30 },
    { key: 'F', width: 100 },
    { key: 'G', width: 30 },
    { key: 'H', width: 30 },
    { key: 'I', width: 30 }
]

const PERIODICAL_INSPECTION_COLUMNS_WIDTH = [
    { key: "A", width: 18 },
    { key: "B", width: 20 },
    { key: "C", width: 20 },
    { key: "D", width: 20 },
    { key: "E", width: 20 },
    { key: "F", width: 15 },
    { key: "G", width: 15 },
    { key: "H", width: 15 },
    { key: "I", width: 17 },
    { key: "J", width: 15 },
    { key: "K", width: 17 },
    { key: "L", width: 40 },
    { key: "M", width: 30 },
    { key: "N", width: 30 },
    { key: "O", width: 30 },
    { key: 'P', width: 30 },
]

const MODIFIED_PRODUCT_COLUMNS_WIDTH = [
    { key: "A", width: 25 },
    { key: "B", width: 35 },
    { key: "C", width: 100 },
    { key: "D", width: 20 },
    { key: "E", width: 20 },
    { key: "F", width: 15 },
    { key: "G", width: 15 },
    { key: "H", width: 15 },
    { key: "I", width: 15 },
    { key: "J", width: 22 },
    { key: "K", width: 22 },
    { key: "L", width: 27 },
    { key: "M", width: 25 },
    { key: "N", width: 25 },
    { key: "O", width: 25 },
    { key: "P", width: 35 },
    { key: "Q", width: 25 },
    { key: "R", width: 30 },
    { key: "S", width: 35 },
    { key: "T", width: 35 },
    { key: "U", width: 30 },
    { key: "V", width: 28 }
]

const PIPE_AGE_COLUMNS_WIDTH = [
    {key: 'A', width: 15},
    {key: 'B', width: 30},
    {key: 'C', width: 30},
    {key: 'D', width: 30},
    {key: 'E', width: 30},
    {key: 'F', width: 30},
    {key: 'G', width: 100},
    {key: 'H', width: 30},
    {key: 'I', width: 30}, 
    {key: 'J', width: 30}, 
    {key: 'K', width: 30}, 
    {key: 'L', width: 30},
    {key: 'M', width: 30}
]

const RIG_RETURN_COLUMNS_WIDTH = [
    { key: "A", width: 22 },
    { key: "B", width: 40 },
    { key: "C", width: 25 },
    { key: "D", width: 30 },
    { key: "E", width: 22 },
    { key: "F", width: 25 },
    { key: "G", width: 100 },
    { key: "H", width: 20 },
    { key: "I", width: 25 },
    { key: "J", width: 15 },
    { key: "K", width: 15 },
    { key: "L", width: 15 },
    { key: "M", width: 20 },
    { key: "N", width: 20 },
    { key: "O", width: 20 },
    { key: "P", width: 30 },
    { key: "Q", width: 25 },
    { key: "R", width: 25 },
    { key: "S", width: 25 },
    { key: "T", width: 30 },
    { key: "U", width: 25 }
]

const columnsWidth = {
    DEFAULT: DEFAULT_COLUMNS_WIDTH,
    DELIVERY_NOTES_DELIVERY_THIRD_PARTY: DELIVERY_NOTES_WITH_MEASURE_COLUMNS_WIDTH,
    DELIVERY_NOTES_MAT_MODIFICATION: DELIVERY_NOTES_WITH_MEASURE_COLUMNS_WIDTH,
    DELIVERY_NOTES_MAT_PHYSICAL_TRANSFER: DELIVERY_NOTES_WITH_MEASURE_COLUMNS_WIDTH,
    DELIVERY_NOTES_RIG_PREP: DELIVERY_NOTES_WITH_MEASURE_COLUMNS_WIDTH,
    MATERIAL_REQUISITION_RIG_PREP: MATERIAL_REQUISITION_WITH_MEASURE_COLUMNS_WIDTH,
    MATERIAL_REQUISITION_MAT_MODIFICATION: MATERIAL_REQUISITION_WITH_MEASURE_COLUMNS_WIDTH,
    MATERIAL_REQUISITION_DELIVERY_THIRD_PARTY: MATERIAL_REQUISITION_WITH_MEASURE_COLUMNS_WIDTH,
    MATERIAL_REQUISITION_MAT_PHYSICAL_TRANSFER: MATERIAL_REQUISITION_WITH_MEASURE_COLUMNS_WIDTH,
    RECEIPT_NOTES: RECEIPT_NOTES_COLUMNS_WIDTH,
    RIG_RETURN: RIG_RETURN_COLUMNS_WIDTH,
    RECEIVING_INSPECTION: RECEIVING_INSPECTION_COLUMNS_WIDTH,
    BACKLOAD_MANIFEST: BACKLOAD_MANIFEST_COLUMNS_WIDTH,
    PERIODICAL_INSPECTION: PERIODICAL_INSPECTION_COLUMNS_WIDTH,
    MODIFIED_PRODUCTS: MODIFIED_PRODUCT_COLUMNS_WIDTH, 
    PIPE_AGE: PIPE_AGE_COLUMNS_WIDTH
}

const columnsWidthImageSheet = {
    DEFAULT: DEFAULT_COLUMNS_IMAGE_SHEET_WIDTH,
    DELIVERY_NOTES_DELIVERY_THIRD_PARTY: DEFAULT_COLUMNS_IMAGE_SHEET_WIDTH,
    DELIVERY_NOTES_MAT_MODIFICATION: DEFAULT_COLUMNS_IMAGE_SHEET_WIDTH,
    DELIVERY_NOTES_MAT_PHYSICAL_TRANSFER: DEFAULT_COLUMNS_IMAGE_SHEET_WIDTH,
    DELIVERY_NOTES_RIG_PREP: DEFAULT_COLUMNS_IMAGE_SHEET_WIDTH,
    MATERIAL_REQUISITION_RIG_PREP: MATERIAL_REQUISITION_IMAGE_SHEET_COLUMNS_WIDTH,
    MATERIAL_REQUISITION_MAT_MODIFICATION: MATERIAL_REQUISITION_IMAGE_SHEET_COLUMNS_WIDTH,
    MATERIAL_REQUISITION_DELIVERY_THIRD_PARTY: MATERIAL_REQUISITION_IMAGE_SHEET_COLUMNS_WIDTH,
    MATERIAL_REQUISITION_MAT_PHYSICAL_TRANSFER: MATERIAL_REQUISITION_IMAGE_SHEET_COLUMNS_WIDTH,
    RECEIPT_NOTES: DEFAULT_COLUMNS_IMAGE_SHEET_WIDTH,
    RIG_RETURN: MATERIAL_REQUISITION_IMAGE_SHEET_COLUMNS_WIDTH,
    RECEIVING_INSPECTION: MATERIAL_REQUISITION_IMAGE_SHEET_COLUMNS_WIDTH,
    BACKLOAD_MANIFEST: MATERIAL_REQUISITION_IMAGE_SHEET_COLUMNS_WIDTH,
    PERIODICAL_INSPECTION: MATERIAL_REQUISITION_IMAGE_SHEET_COLUMNS_WIDTH,
    MODIFIED_PRODUCTS: MATERIAL_REQUISITION_IMAGE_SHEET_COLUMNS_WIDTH, 
    PIPE_AGE: DEFAULT_COLUMNS_IMAGE_SHEET_WIDTH
}

const zoomScaleMap = {
    DEFAULT: 55,
    DELIVERY_NOTES: 55,
    MATERIAL_REQUISITION: 55,
    RECEIPT_NOTES: 70,
    RIG_RETURN_INSP: 55,
    RECEIVING_INSPECTION: 60,
    BACKLOAD_MANIFEST: 65,
    PERIODICAL_INSPECTION: 58,
    MODIFIED_PRODUCTS: 55, 
    PIPE_AGE: 60
}

const getColumnsWidth = (reportType = "DEFAULT") => columnsWidth[reportType]

const getImageSheetColumnsWidth = (reportType = "DEFAULT") => columnsWidthImageSheet[reportType]

const getZoomScale = (reportType = "DEFAULT") => zoomScaleMap[reportType]

export {
    DEFAULT_NUM_FMT,
    INT_NUM_FMT,
    FONT_NAME_BOLD,
    FONT_NAME_NORMAL,
    GREY_COLOR,
    YELLOW_COLOR,
    WHITE_COLOR,
    GREEN_FONT_COLOR,
    RED_FONT_COLOR,
    YELLOW_FONT_COLOR,
    DEFAULT_BORDER_COLOR,
    YELLOW_FILL,
    GREY_FILL,
    WHITE_FILL,
    ALT_YELLOW_FILL,
    BOTTOM_LEFT_ALIGNMENT,
    MIDDLE_CENTER_ALIGNMENT,
    THIN_FULL_BORDER,
    DEFAULT_BORDER,
    CLEAR_STYLE,
    getColumnsWidth,
    getImageSheetColumnsWidth,
    getZoomScale
}
