import axios from 'axios'
import { decompressResponse } from '../utils/decompress'
import { addLogger } from 'utils/logger'
import Cookies from 'js-cookie'

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  headers: {
    'Content-Type': 'application/json', 
    'VLR-Authorization': btoa(JSON.stringify({ "id": process.env.REACT_APP_ID }))
  }
})

addLogger(instance, true)

// const formatAuthorization = idToken => idToken + '.' + btoa(JSON.stringify({ 'id': process.env.REACT_APP_ID }))

export default {

  getKpis: (uri, params = {}, idToken = '') => new Promise((resolve, reject) => {  // { kpiType: constants.KPI_TYPE.KPI, customerId: this.endUser }
    instance.post(
      `/kpis/${uri}`,
      params,
      {
        headers: {
          Authorization: Cookies.get("_ssoToken")
        }
      }
    ).then(response => {
      if (response.status === 200) {
        resolve(decompressResponse(response.data))
      }
      else {
        reject(new Error('Couldn\'t retrieve data'))
      }
    })
      .catch(error => {
        reject(error)
      })
  }), 
  upInsertKpis: (uri, params = {}, idToken = '') => new Promise((resolve, reject) => {  // { type: kpi.type, value: kpi.value, period: date, customerId: this.endUser, kpi_id: kpi.id }
    instance.post(
      `/kpis/${uri}`,
      params,
      {
        headers: {
          Authorization: Cookies.get("_ssoToken")
        }
      }
    ).then(response => {
      if (response.status === 200) {
        resolve(decompressResponse(response.data))
      }
      else {
        reject(new Error('Couldn\'t retrieve data'))
      }
    })
    .catch(error => {
      reject(error)
    })
  }),  
  getKpiHistory: (uri, params = {}, idToken = '') => new Promise((resolve, reject) => { // { kpiType: kpi.type, kpiId: kpi.id, customerId: this.endUser };
  instance.post(
    `/kpis/${uri}`,
    params,
    {
      headers: {
        Authorization: Cookies.get("_ssoToken")
      }
    }
  ).then(response => {
    if (response.status === 200) {
      resolve(decompressResponse(response.data))
    }
    else {
      reject(new Error('Couldn\'t retrieve data'))
    }
  })
    .catch(error => {
      reject(error)
    })
  }), 
  getKpisFromDate: (uri, params = {}, idToken = '') => new Promise((resolve, reject) => { // { "kpiType": this.type, "customerId": this.endUser, "initDate": this.initialDate, "endDate": this.endDate}
  instance.post(
    `/kpis/${uri}`,
    params,
    {
      headers: {
        Authorization: Cookies.get("_ssoToken")
      }
    }
  ).then(response => {
    if (response.status === 200) {
      resolve(decompressResponse(response.data))
    }
    else {
      reject(new Error('Couldn\'t retrieve data'))
    }
  })
    .catch(error => {
      reject(error)
    })
  }), 
  upInsertAverageYear: (uri, params = {}, idToken = '') => new Promise((resolve, reject) => {  
  instance.post(
      `/kpis/${uri}`,
      params,
      {
        headers: {
          Authorization: Cookies.get("_ssoToken")
        }
      }
    ).then(response => {
      if (response.status === 200) {
        resolve(decompressResponse(response.data))
      }
      else {
        reject(new Error('Couldn\'t retrieve data'))
      }
    })
    .catch(error => {
      reject(error)
    })
  })
}
