export default {
  development: {
    rig_return_report_data: 'datasets_bra_smart_inventory_qas_dashboard_rig_return_report_data',
    modified_products_report_data: 'datasets_bra_smart_inventory_qas_dashboard_modified_product_report_data',
    receive_inspection_data: 'datasets_bra_smart_inventory_qas_dashboard_receiving_inspection_report_data',
    periodical_inspection_data: 'datasets_bra_smart_inventory_qas_dashboard_periodical_inspection_report_data',
    mill_receipt: 'datasets_bra_smart_inventory_qas_dashboard_mill_receipt_report_data',
    material_requisition_report_data: 'datasets_bra_smart_inventory_qas_dashboard_material_requisition_rigprep_inspection_report_data',
    material_requisition_delivery_third_party_data: 'datasets_bra_smart_inventory_qas_dashboard_material_requisition_delivery_third_party_data',
    material_requisition_mat_modification_data: 'datasets_bra_smart_inventory_qas_dashboard_material_requisition_mat_modification_data',
    material_requisition_mat_physical_transfer_data: 'datasets_bra_smart_inventory_qas_dashboard_material_requisition_mat_physical_transfer_data',
    slow_moving_pipes_report_data:'datasets_bra_smart_inventory_qas_dashboard_slow_moving_pipes_report_data',
    material_physical_transfer_report_data:'datasets_bra_smart_inventory_qas_dashboard_physical_transfer_report_data',
    title_transfer_report_data:'datasets_bra_smart_inventory_qas_dashboard_title_transfer_report_data',
    pipe_data: 'pipe_data_dev',
    nominal_data: 'datasets_bra_smart_inventory_general_pipe_data_nominal_data', 
  },
  qas: {
    rig_return_report_data: 'datasets_bra_smart_inventory_qas_dashboard_rig_return_report_data',
    modified_products_report_data: 'datasets_bra_smart_inventory_qas_dashboard_modified_product_report_data',
    receive_inspection_data: 'datasets_bra_smart_inventory_qas_dashboard_receiving_inspection_report_data',
    periodical_inspection_data: 'datasets_bra_smart_inventory_qas_dashboard_periodical_inspection_report_data',
    mill_receipt: 'datasets_bra_smart_inventory_qas_dashboard_mill_receipt_report_data',
    material_requisition_report_data: 'datasets_bra_smart_inventory_qas_dashboard_material_requisition_rigprep_inspection_report_data',
    material_requisition_delivery_third_party_data: 'datasets_bra_smart_inventory_qas_dashboard_material_requisition_delivery_third_party_data',
    material_requisition_mat_modification_data: 'datasets_bra_smart_inventory_qas_dashboard_material_requisition_mat_modification_data',
    material_requisition_mat_physical_transfer_data: 'datasets_bra_smart_inventory_qas_dashboard_material_requisition_mat_physical_transfer_data',
    nominal_data: 'datasets_bra_smart_inventory_general_pipe_data_nominal_data', 
    slow_moving_pipes_report_data:'datasets_bra_smart_inventory_qas_dashboard_slow_moving_pipes_report_data',
    material_physical_transfer_report_data:'datasets_bra_smart_inventory_qas_dashboard_physical_transfer_report_data',
    title_transfer_report_data:'datasets_bra_smart_inventory_qas_dashboard_title_transfer_report_data',
    pipe_data: 'pipe_data_dev',
  },
  staging: {
    rig_return_report_data: 'datasets_bra_smart_inventory_preprod_dashboard_rig_return_report_data',
    receive_inspection_data: 'datasets_bra_smart_inventory_preprod_dashboard_receiving_inspection_report_data',
    periodical_inspection_data: 'datasets_bra_smart_inventory_preprod_dashboard_periodical_inspection_report_data',
    modified_products_report_data: 'datasets_bra_smart_inventory_preprod_dashboard_modified_product_report_data',
    mill_receipt: 'datasets_bra_smart_inventory_preprod_dashboard_mill_receipt_report_data',
    material_requisition_report_data: 'datasets_bra_smart_inventory_preprod_dashboard_material_requisition_rigprep_inspection_report_data',
    material_requisition_delivery_third_party_data: 'datasets_bra_smart_inventory_preprod_dashboard_material_requisition_delivery_third_party_data',
    material_requisition_mat_modification_data: 'datasets_bra_smart_inventory_preprod_dashboard_material_requisition_mat_modification_data',
    material_requisition_mat_physical_transfer_data: 'datasets_bra_smart_inventory_preprod_dashboard_material_requisition_mat_physical_transfer_data',
    slow_moving_pipes_report_data:'datasets_bra_smart_inventory_preprod_dashboard_slow_moving_pipes_report_data',
    material_physical_transfer_report_data:'datasets_bra_smart_inventory_preprod_dashboard_physical_transfer_report_data',
    title_transfer_report_data:'datasets_bra_smart_inventory_preprod_dashboard_title_transfer_report_data',
    pipe_data: 'pipe_data_dev',
    nominal_data: 'datasets_bra_smart_inventory_general_pipe_data_nominal_data', 
  },
  production: {
    rig_return_report_data: 'datasets_bra_smart_inventory_production_smartengo_prod_vtsa_dashboard_rig_return_report_data',
    modified_products_report_data: 'datasets_bra_smart_inventory_production_smartengo_prod_vtsa_dashboard_modified_product_report_data',
    receive_inspection_data: 'datasets_bra_smart_inventory_production_smartengo_prod_vtsa_dashboard_receiving_inspection_report_data',
    periodical_inspection_data: 'datasets_bra_smart_inventory_production_smartengo_prod_vtsa_dashboard_periodical_inspection_report_data',
    mill_receipt: 'datasets_bra_smart_inventory_production_smartengo_prod_vtsa_dashboard_mill_receipt_report_data',
    material_requisition_report_data: 'datasets_bra_smart_inventory_production_smartengo_prod_vtsa_dashboard_material_requisition_rigprep_inspection_report_data',
    material_requisition_delivery_third_party_data: 'datasets_bra_smart_inventory_production_smartengo_prod_vtsa_dashboard_material_requisition_delivery_third_party_data',
    material_requisition_mat_physical_transfer_data: 'datasets_bra_smart_inventory_qas_dashboard_material_requisition_mat_physical_transfer_data',
    material_requisition_mat_modification_data: 'datasets_bra_smart_inventory_production_smartengo_prod_vtsa_dashboard_material_requisition_mat_modification_data',
    slow_moving_pipes_report_data:'datasets_bra_smart_inventory_production_smartengo_prod_vtsa_dashboard_slow_moving_pipes_report_data',
    material_physical_transfer_report_data:'datasets_bra_smart_inventory_production_smartengo_prod_vtsa_dashboard_physical_transfer_report_data',
    title_transfer_report_data:'datasets_bra_smart_inventory_production_smartengo_prod_vtsa_dashboard_title_transfer_report_data',
    pipe_data: 'pipe_data_dev',
    nominal_data: 'datasets_bra_smart_inventory_general_pipe_data_nominal_data'
  },
}