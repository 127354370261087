import React, { useContext, useMemo, useEffect, useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import _ from 'lodash'
import moment from 'moment'

import documentManagement from 'api/document-management'
import modifiedProduct from 'api/modified-product'

import { LoadingOverlay, FlexView, Icon, Card } from 'components/common'
import FilterableTable from 'components/common/FilterableTable'

import StockTabs from 'containers/layout/StockTabs'

import { AppContext } from 'stores/AppStore'
import { UserContext } from 'stores/UserStore'
import { WarehouseContext } from 'stores/WarehouseStore'

import { filenames, S3_TYPE_SUBFOLDER } from 'utils/constants'
import { checkIfCanSearchImages, getFileName } from 'utils/helpers'

import { templateType } from 'provider/types'
import { generateSpreadsheet } from 'provider/excelGeneration'
import FilterDisplay from 'containers/common/FilterDisplay'
import FiltersModal from 'containers/common/FiltersModal'
import { FilterContext } from 'stores/FilterStore'

import apiDataReport from 'api/report-data'

const formatReports = reports => _.orderBy(reports, 'closure_date', 'desc')

const ModifiedProductsInspectionReports = ({ stockKey }) => {

  const { t } = useTranslation()
  
  const { setPageSubtitle, datasets, formatNumberByCulture 
        } = useContext(AppContext)
  const { endUsers, idToken } = useContext(UserContext)
  const { warehouses } = useContext(WarehouseContext)

  const { filteredData: {filteredTableData }, data, setData } = useContext(FilterContext)

  const stock = useMemo(() => _.find(warehouses, { key: stockKey }), [warehouses, stockKey])

  const [loading, setLoading] = useState(true)
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    if (stock) {
      setPageSubtitle(stock?.title + ' - ' + t('Modified Products Inspection Reports'))
    }
  }, [stock, setPageSubtitle, t])

  const downloadReport = useCallback(async report => {
    setLoading(true)
    try {
      const reportData = await modifiedProduct.getModifiedProductData({reportId: report.mplid}, idToken)
      const filename = getFileName(filenames['MODIFIED_PRODUCTS'], report.mplid)
      let reportImagesContent = []
      if(checkIfCanSearchImages(reportData)){
        reportImagesContent = await apiDataReport.getReportImages({imageRows: reportData.data.imageRows, type: S3_TYPE_SUBFOLDER.MODIFIED_PRODUCTS }, idToken)
      }
      const logoBase64 = await documentManagement.getStockLogo({ wid: stock.wid }, idToken)
      generateSpreadsheet(filename, reportData, templateType.MODIFIED_PRODUCTS, logoBase64, reportImagesContent)
      toast.info(t('documents.popupInfo'))
    }
    catch (error) {
      toast.error(error.message)
    }
    finally {
      setLoading(false)
    }
  }, [idToken, stock, t])

  const onDownloadClick = useCallback(report => () => {
    downloadReport(report)
  }, [downloadReport])

  useEffect(() => {
    async function fetchData() {
      setLoading(true)
      try {
        const data = await modifiedProduct.getModifiedProductList({ wid: stock.wid }, idToken)
        setData(formatReports(data))
      } catch (error) {
        toast.error(error.message)
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  }, [endUsers, datasets, idToken, stock, setData])

  const columns = useMemo(() => [
    {
      accessor: 'mplid',
      Header: t('ID'),
      label: t('ID'),
      customHeaderProps: {
        style: {
          minWidth: '60px'
        }
      }
    },
    {
      accessor: 'name',
      Header: t('Name'),
      label: t('Name'),
      customHeaderProps: {
        style: {
          minWidth: '150px'
        }
      }
    },
    {
      accessor: 'closure_date',
      label: t('Entrance Date'),
      Header: t('Entrance Date'),
      Cell: (props) => {
          const { closure_date } = props.row.original
          return  closure_date ? moment(closure_date).format('MMM D, YYYY, h:mm:ss A') : ''
      },
      customHeaderProps: {
        style: {
          minWidth: '150px'
        }
      }
    },
    {
      label: t('MP Reference'),
      accessor: 'er_ref',
      Header: t('MP Reference'),
      customHeaderProps: {
        style: {
          minWidth: '150px'
        }
      }
    },
    {
      label: t('Owner'),
      accessor: 'owner',
      Header: t('Owner'),
      customHeaderProps: {
        style: {
          minWidth: '150px'
        }
      }
    },
    {
      label: t('Inspected'),
      accessor: 'pipe_count',
      Cell: (props) => {
        const { pipe_count } = props.row.original
        return `${formatNumberByCulture(pipe_count, 0)}`
      },
      Header: t('Inspected'),
      customHeaderProps: {
        style: {
          minWidth: '70px'
        }
      }
    },
    {
      label: t('Accepted'),
      accessor: 'accept_count',
      Cell: (props) => {
        const { accept_count } = props.row.original
        return `${formatNumberByCulture(accept_count, 0)}`
      },
      Header: t('Accepted'),
      customHeaderProps: {
        style: {
          minWidth: '70px'
        }
      }
    },
    {
      label: t('Rejected'),
      accessor: 'reject_count',
      Cell: (props) => {
        const { reject_count } = props.row.original
        return `${formatNumberByCulture(reject_count, 0)}`
      },
      Header: t('Rejected'),
      customHeaderProps: {
        style: {
          minWidth: '70px'
        }
      }
    },
    {
      label: t('To Repair'),
      accessor: 'repair_count',
      Cell: (props) => {
        const { repair_count } = props.row.original
        return `${formatNumberByCulture(repair_count, 0)}`
      },
      Header: t('To Repair'),
      customHeaderProps: {
        style: {
          minWidth: '75px'
        }
      }
    },
    {
      id: 'download',
      Header: t('Download'),
      disableFilters: true,
      customHeaderProps: {
        style: {
          minWidth: '32px'
        }
      },
      Cell: ({ cell: { row } }) => <FlexView alignItems="center" justifyContent="center" width="100%">
        <Icon name="download" width="24px" height="24px" onClick={onDownloadClick(row.original)} />
      </FlexView>
    }
  ], [t, onDownloadClick, formatNumberByCulture])

  const toggleModal = () => setShowModal(currentState => !currentState)

  return <FlexView flex="1" position="relative" alignSelf="stretch">
    <StockTabs stock={stock} />
    <FlexView margin="16px 16px 8px" flexDirection="row" justifyContent="space-between" alignSelf="stretch">
      <FilterDisplay options={columns} onTagClick={toggleModal} />
      <FlexView flexDirection="row" alignItems="center" justifyContent="flex-end" flex="1">
        <Icon name="filter" width="28px" height="28px" margin="0px 8px 0px 0px" tooltip={t('Filter')} onClick={toggleModal}/>
      </FlexView>
    </FlexView>
    <Card alignSelf="stretch" padding="0px" margin="16px">
      <FilterableTable columns={columns} data={filteredTableData} />
    </Card>
    <FiltersModal isOpen={showModal} onOutsideClick={toggleModal} options={columns} data={data}/>
    <LoadingOverlay visible={loading} />
  </FlexView>
}

export default ModifiedProductsInspectionReports