import {
    THIN_FULL_BORDER,
    FONT_NAME_BOLD,
    BOTTOM_LEFT_ALIGNMENT,
    FONT_NAME_NORMAL,
    GREY_FILL,
    DEFAULT_NUM_FMT,
    YELLOW_FILL,
    MIDDLE_CENTER_ALIGNMENT,
    INT_NUM_FMT,
} from "../styles"

const DEFAULT_DATA_LABEL_STYLE = {
    alignment: MIDDLE_CENTER_ALIGNMENT,
    border: THIN_FULL_BORDER,
    fill: GREY_FILL,
    height: 40,
    font: {
        name: FONT_NAME_BOLD,
        size: 11,
        bold: true,
    },
}

const DEFAULT_DATA_CONTENT_STYLE = {
    numFmt: DEFAULT_NUM_FMT,
    alignment: BOTTOM_LEFT_ALIGNMENT,
    border: THIN_FULL_BORDER,
    fill: YELLOW_FILL,
    height: 15,
    font: {
        name: FONT_NAME_NORMAL,
        size: 10,
        bold: false,
    },
}

const DEFAULT_IMAGE_DATA_CONTENT_STYLE = {
    numFmt: DEFAULT_NUM_FMT,
    alignment: MIDDLE_CENTER_ALIGNMENT,
    border: THIN_FULL_BORDER,
    fill: YELLOW_FILL,
    height: 15,
    font: {
        name: FONT_NAME_NORMAL,
        size: 22,
        bold: false,
    },
}

const MATERIAL_REQUISITION_DATA_LABEL_STYLE = {
    ...DEFAULT_DATA_LABEL_STYLE
}

const MATERIAL_REQUISITION_DATA_CONTENT_STYLE = {
    ...DEFAULT_DATA_CONTENT_STYLE,
    alignment: MIDDLE_CENTER_ALIGNMENT,
}

const RECEIPT_NOTES_DATA_CONTENT_STYLE = {
    ...DEFAULT_DATA_CONTENT_STYLE,
    alignment: MIDDLE_CENTER_ALIGNMENT,
}

const RIG_RETURN_DATA_CONTENT_STYLE = {
    ...DEFAULT_DATA_CONTENT_STYLE,
    alignment: MIDDLE_CENTER_ALIGNMENT,
}
const RECEIVING_INSPECTION_CONTENT_STYLE = {
    ...DEFAULT_DATA_CONTENT_STYLE,
    alignment: MIDDLE_CENTER_ALIGNMENT
}

const BACKLOAD_MANIFEST_DATA_CONTENT_STYLE = {
    ...DEFAULT_DATA_CONTENT_STYLE,
    alignment: MIDDLE_CENTER_ALIGNMENT
}


const PERIODICAL_INSPECTION_DATA_CONTENT_STYLE = {
    ...DEFAULT_DATA_CONTENT_STYLE,
    alignment: MIDDLE_CENTER_ALIGNMENT
}

const MODIFIED_PRODUCTS_DATA_CONTENT_STYLE = {
    ...DEFAULT_DATA_CONTENT_STYLE,
    alignment: MIDDLE_CENTER_ALIGNMENT
}

const PIPE_AGE_DATA_CONTENT_STYLE = {
    ...DEFAULT_DATA_CONTENT_STYLE,
    alignment: MIDDLE_CENTER_ALIGNMENT, 
    numFmt: INT_NUM_FMT, 
    font: { size: 18 }
}

export const DATA_STYLES = {
    DEFAULT_LABEL: DEFAULT_DATA_LABEL_STYLE,
    DEFAULT_CONTENT: DEFAULT_DATA_CONTENT_STYLE,
    DEFAULT_IMAGE_CONTENT: DEFAULT_IMAGE_DATA_CONTENT_STYLE
}

const MATERIAL_REQUISITION_DATA_STYLE = {
    LABEL: MATERIAL_REQUISITION_DATA_LABEL_STYLE,
    CONTENT: MATERIAL_REQUISITION_DATA_CONTENT_STYLE,
}

const dataStylesMap = {
    DEFAULT: {
        label: DATA_STYLES.DEFAULT_LABEL,
        content: DATA_STYLES.DEFAULT_CONTENT,
        imageContent: DATA_STYLES.DEFAULT_IMAGE_CONTENT
    },
    DELIVERY_NOTES_DELIVERY_THIRD_PARTY: {
        label: DATA_STYLES.DEFAULT_LABEL,
        content: DATA_STYLES.DEFAULT_CONTENT,
        imageContent: DATA_STYLES.DEFAULT_IMAGE_CONTENT
    },
    DELIVERY_NOTES_MAT_MODIFICATION: {
        label: DATA_STYLES.DEFAULT_LABEL,
        content: DATA_STYLES.DEFAULT_CONTENT,
        imageContent: DATA_STYLES.DEFAULT_IMAGE_CONTENT
    },
    DELIVERY_NOTES_MAT_PHYSICAL_TRANSFER: {
        label: DATA_STYLES.DEFAULT_LABEL,
        content: DATA_STYLES.DEFAULT_CONTENT,
        imageContent: DATA_STYLES.DEFAULT_IMAGE_CONTENT
    },
    DELIVERY_NOTES_RIG_PREP: {
        label: DATA_STYLES.DEFAULT_LABEL,
        content: DATA_STYLES.DEFAULT_CONTENT,
        imageContent: DATA_STYLES.DEFAULT_IMAGE_CONTENT
    },
    MATERIAL_REQUISITION_RIG_PREP: {
        label: MATERIAL_REQUISITION_DATA_STYLE.LABEL,
        content: MATERIAL_REQUISITION_DATA_STYLE.CONTENT,
        imageContent: DATA_STYLES.DEFAULT_IMAGE_CONTENT
    },
    MATERIAL_REQUISITION_MAT_MODIFICATION: {
        label: MATERIAL_REQUISITION_DATA_STYLE.LABEL,
        content: MATERIAL_REQUISITION_DATA_STYLE.CONTENT,
        imageContent: DATA_STYLES.DEFAULT_IMAGE_CONTENT
    },
    MATERIAL_REQUISITION_DELIVERY_THIRD_PARTY: {
        label: MATERIAL_REQUISITION_DATA_STYLE.LABEL,
        content: MATERIAL_REQUISITION_DATA_STYLE.CONTENT,
        imageContent: DATA_STYLES.DEFAULT_IMAGE_CONTENT
    },
    MATERIAL_REQUISITION_MAT_PHYSICAL_TRANSFER: { 
        label: MATERIAL_REQUISITION_DATA_STYLE.LABEL,
        content: MATERIAL_REQUISITION_DATA_STYLE.CONTENT,
        imageContent: DATA_STYLES.DEFAULT_IMAGE_CONTENT
    },
    RECEIPT_NOTES: { 
        label: DEFAULT_DATA_LABEL_STYLE,
        content: RECEIPT_NOTES_DATA_CONTENT_STYLE,
        imageContent: DATA_STYLES.DEFAULT_IMAGE_CONTENT
    },
    RIG_RETURN: { 
        label: DEFAULT_DATA_LABEL_STYLE,
        content: RIG_RETURN_DATA_CONTENT_STYLE,
        imageContent: DATA_STYLES.DEFAULT_IMAGE_CONTENT
    },
    RECEIVING_INSPECTION: { 
        label: DEFAULT_DATA_LABEL_STYLE,
        content: RECEIVING_INSPECTION_CONTENT_STYLE,
        imageContent: DATA_STYLES.DEFAULT_IMAGE_CONTENT
    },
    BACKLOAD_MANIFEST: { 
        label: DEFAULT_DATA_LABEL_STYLE,
        content: BACKLOAD_MANIFEST_DATA_CONTENT_STYLE,
        imageContent: DATA_STYLES.DEFAULT_IMAGE_CONTENT
    },
    PERIODICAL_INSPECTION: {
        label: DEFAULT_DATA_LABEL_STYLE,
        content: PERIODICAL_INSPECTION_DATA_CONTENT_STYLE,
        imageContent: DATA_STYLES.DEFAULT_IMAGE_CONTENT
    },
    MODIFIED_PRODUCTS: {
        label: DEFAULT_DATA_LABEL_STYLE,
        content: MODIFIED_PRODUCTS_DATA_CONTENT_STYLE,
        imageContent: DATA_STYLES.DEFAULT_IMAGE_CONTENT
    },
    PIPE_AGE: { 
        label: DEFAULT_DATA_LABEL_STYLE,
        content: PIPE_AGE_DATA_CONTENT_STYLE,
        imageContent: DATA_STYLES.DEFAULT_IMAGE_CONTENT
    }
}

export const getDataStyle = (reportType = "DEFAULT") => {
    return {
        labelStyle: dataStylesMap[reportType].label,
        contentStyle: dataStylesMap[reportType].content,
        imageContentStyle: dataStylesMap[reportType].imageContent,
    }
}

const DEFAULT_DATA_LABEL_CONFIG = {
    row: 16,
}

const DEFAULT_DATA_CONTENT_CONFIG = {
    row: 17,
}

export const DATA_CONFIG = {
    DEFAULT_LABEL: DEFAULT_DATA_LABEL_CONFIG,
    DEFAULT_CONTENT: DEFAULT_DATA_CONTENT_CONFIG,
}

