import {
    FONT_NAME_BOLD,
} from "../styles"

const DEFAULT_TITLE_CONFIG = {
    cellPosition: 'A2',
    height: 35,
    mergedCells: 'A2:G2',
    font: {
        name: FONT_NAME_BOLD,
        size: 22,
        bold: true
    }
}

const RECEIPT_NOTES_TITLE_CONFIG = {
    cellPosition: 'A2',
    height: 35,
    mergedCells: 'A2:D2',
    font: {
        name: FONT_NAME_BOLD,
        size: 22,
        bold: true
    }
}

const BACKLOAD_MANIFEST_TITLE_CONFIG = {
    cellPosition: 'A2',
    height: 35,
    mergedCells: 'A2:D2',
    font: {
        name: FONT_NAME_BOLD,
        size: 22,
        bold: true
    }
}

const RECEIVING_INSPECTION_TITLE_CONFIG = {
    cellPosition: 'A2',
    height: 35,
    mergedCells: 'A2:D2',
    font: {
        name: FONT_NAME_BOLD,
        size: 22,
        bold: true
    }
}

const PIPE_AGE_TITLE_CONFIG = {
    cellPosition: 'A2',
    height: 35,
    mergedCells: 'A2:D2',
    font: {
        name: FONT_NAME_BOLD,
        size: 22,
        bold: true
    }
}

const RIG_RETURN_TITLE_CONFIG = {
    cellPosition: 'A2',
    height: 35,
    mergedCells: 'A2:D2',
    font: {
        name: FONT_NAME_BOLD,
        size: 22,
        bold: true
    }
}

export const getTitleConfig = (reportType) => reportTitleConfigMap[reportType]

const reportTitleConfigMap = {
    'RECEIPT_NOTES': RECEIPT_NOTES_TITLE_CONFIG,
    'RIG_RETURN': RIG_RETURN_TITLE_CONFIG,
    'MODIFIED_PRODUCTS': RECEIVING_INSPECTION_TITLE_CONFIG,
    'MATERIAL_REQUISITION_RIG_PREP': DEFAULT_TITLE_CONFIG,
    'MATERIAL_REQUISITION_MAT_MODIFICATION': DEFAULT_TITLE_CONFIG,
    'MATERIAL_REQUISITION_MAT_PHYSICAL_TRANSFER': DEFAULT_TITLE_CONFIG,
    'MATERIAL_REQUISITION_DELIVERY_THIRD_PARTY': DEFAULT_TITLE_CONFIG,
    'PERIODICAL_INSPECTION': DEFAULT_TITLE_CONFIG,
    'ARCHIVED_REPORTS': DEFAULT_TITLE_CONFIG,
    'RECEIVING_INSPECTION': RECEIVING_INSPECTION_TITLE_CONFIG, 
    'DELIVERY_NOTES_DELIVERY_THIRD_PARTY': DEFAULT_TITLE_CONFIG,
    'DELIVERY_NOTES_MAT_MODIFICATION': DEFAULT_TITLE_CONFIG,
    'DELIVERY_NOTES_MAT_PHYSICAL_TRANSFER': DEFAULT_TITLE_CONFIG,
    'DELIVERY_NOTES_RIG_PREP': DEFAULT_TITLE_CONFIG,
    'BACKLOAD_MANIFEST': BACKLOAD_MANIFEST_TITLE_CONFIG, 
    'PIPE_AGE': PIPE_AGE_TITLE_CONFIG
}

const reportTitleMap = {
    'RECEIPT_NOTES': 'Receipt Notes',
    'RIG_RETURN': 'Rig Return',
    'MODIFIED_PRODUCTS': 'Modified Products',
    'MATERIAL_REQUISITION_RIG_PREP': 'Material Requisition Rig Prep',
    'MATERIAL_REQUISITION_MAT_MODIFICATION': 'MATERIAL MODIFICATION INSPECTION REPORT',
    'MATERIAL_REQUISITION_MAT_PHYSICAL_TRANSFER': 'PHYSICAL TRANSFER INSPECTION REPORT',
    'MATERIAL_REQUISITION_DELIVERY_THIRD_PARTY': 'DELIVERY TO THIRD PARTY INSPECTION REPORT',
    'PERIODICAL_INSPECTION': 'Periodical Inspection',
    'ARCHIVED_REPORTS': 'Archived Reports',
    'RECEIVING_INSPECTION': 'Receiving Inspection', 
    'DELIVERY_NOTES_DELIVERY_THIRD_PARTY': 'Delivery to Third Party Workshop DELIVERY NOTE',
    'DELIVERY_NOTES_MAT_MODIFICATION': 'Material Modification DELIVERY NOTE',
    'DELIVERY_NOTES_MAT_PHYSICAL_TRANSFER': '18-Months Threshold - PHYSICAL TRANSFER ITEMS DELIVERY NOTES',
    'DELIVERY_NOTES_RIG_PREP': 'RIG PREPARATION DELIVERY NOTE',
    'BACKLOAD_MANIFEST': 'BACKLOAD MANIFEST', 
    'PIPE_AGE': 'Months Threshold'
}

export const getReportTitle = (reportType) => reportTitleMap[reportType]
