import axios from 'axios'
import { decompressResponse } from '../utils/decompress'
import { addLogger } from 'utils/logger'
import Cookies from 'js-cookie'

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  headers: {
    'Content-Type': 'application/json', 
    'VLR-Authorization': btoa(JSON.stringify({ "id": process.env.REACT_APP_ID }))
  }
})

addLogger(instance, true)

// const formatAuthorization = idToken => idToken + '.' + btoa(JSON.stringify({ 'id': process.env.REACT_APP_ID }))

export default {
  getMaterialRequisitionRigPrepReportData: (params = {}, idToken ='') => new Promise((resolve, reject) => {
    instance.post(
      '/material-requisition/mr-rig-prep',
      params,
      {
        headers: {
          Authorization: Cookies.get("_ssoToken")
        }
      }
    ).then(response => {
      if (response.status === 200) {
        resolve(decompressResponse(response.data))
      }
      else {
        reject(new Error('Couldn\'t retrieve data'))
      }
    })
      .catch(error => {
        reject(error)
      })
  }),
  getMaterialRequisitionMatModifReportData: (params = {}, idToken ='') => new Promise((resolve, reject) => {
    instance.post(
      '/material-requisition/mr-mat-modification',
      params,
      {
        headers: {
          Authorization: Cookies.get("_ssoToken")
        }
      }
    ).then(response => {
      if (response.status === 200) {
        resolve(decompressResponse(response.data))
      }
      else {
        reject(new Error('Couldn\'t retrieve data'))
      }
    })
      .catch(error => {
        reject(error)
      })
  }),
  getMaterialRequisitionThirdPartyReportData: (params = {}, idToken ='') => new Promise((resolve, reject) => {
    instance.post(
      '/material-requisition/mr-third-party',
      params,
      {
        headers: {
          Authorization: Cookies.get("_ssoToken")
        }
      }
    ).then(response => {
      if (response.status === 200) {
        resolve(decompressResponse(response.data))
      }
      else {
        reject(new Error('Couldn\'t retrieve data'))
      }
    })
      .catch(error => {
        reject(error)
      })
  }),
  getMaterialRequisitionMatPhysicalTransferReportData: (params = {}, idToken ='') => new Promise((resolve, reject) => {
    instance.post(
      '/material-requisition/mr-mat-physical-transfer',
      params,
      {
        headers: {
          Authorization: Cookies.get("_ssoToken")
        }
      }
    ).then(response => {
      if (response.status === 200) {
        resolve(decompressResponse(response.data))
      }
      else {
        reject(new Error('Couldn\'t retrieve data'))
      }
    })
      .catch(error => {
        reject(error)
      })
  }),
  getDeliveryNotesRigPrepReportData: (params = {}, idToken = '') => new Promise((resolve, reject) => {
    instance.post(
      '/delivery-notes/rig-prep',
      params,
      {
        headers: {
          Authorization: Cookies.get("_ssoToken")
        }
      }
    ).then(response => {
      if (response.status === 200) {
        resolve(decompressResponse(response.data))
      }
      else {
        reject(new Error('Couldn\'t retrieve data'))
      }
    })
      .catch(error => {
        reject(error)
      })
  }),
  getDeliveryNotesMatPhysicalTransferReportData: (params = {}, idToken = '') => new Promise((resolve, reject) => {
    instance.post(
      '/delivery-notes/mat-physical-transfer',
      params,
      {
        headers: {
          Authorization: Cookies.get("_ssoToken")
        }
      }
    ).then(response => {
      if (response.status === 200) {
        resolve(decompressResponse(response.data))
      }
      else {
        reject(new Error('Couldn\'t retrieve data'))
      }
    })
      .catch(error => {
        reject(error)
      })
  }),
  getDeliveryNotesMatModificationReportData: (params = {}, idToken = '') => new Promise((resolve, reject) => {
    instance.post(
      '/delivery-notes/mat-modification',
      params,
      {
        headers: {
          Authorization: Cookies.get("_ssoToken")
        }
      }
    ).then(response => {
      if (response.status === 200) {
        resolve(decompressResponse(response.data))
      }
      else {
        reject(new Error('Couldn\'t retrieve data'))
      }
    })
      .catch(error => {
        reject(error)
      })
  }),
  getDeliveryNotesDeliveryThirdPartyReportData: (params = {}, idToken = '') => new Promise((resolve, reject) => {
    instance.post(
      '/delivery-notes/delivery-third-party',
      params,
      {
        headers: {
          Authorization: Cookies.get("_ssoToken")
        }
      }
    ).then(response => {
      if (response.status === 200) {
        resolve(decompressResponse(response.data))
      }
      else {
        reject(new Error('Couldn\'t retrieve data'))
      }
    })
      .catch(error => {
        reject(error)
      })
  }),
  getReportImages: (params = {}, idToken = '') => new Promise((resolve, reject) => {
    instance.post(
      '/document-management/get-report-images',
      params,
      {
        headers: {
          Authorization: Cookies.get("_ssoToken")
        }
      }
    ).then(response => {
      if (response.status === 200) {
        resolve(decompressResponse(response.data))
      }
      else {
        reject(new Error('Couldn\'t retrieve data'))
      }
    })
      .catch(error => {
        reject(error)
      })
  }),
}