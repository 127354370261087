import { DATA_CONFIG } from "./data"
import { HEADER_CONFIG } from "./header"
import { DELIVERY_NOTES_CONFIG } from "./deliveryNotes"
import { MATERIAL_REQUISITION_CONFIG } from "./materialRequisition"
import { RECEIPT_NOTES_CONFIG } from './receiptNotes'
import { RIG_RETURN_CONFIG } from './rigReturn'
import { RECEIVING_INSPECTION_CONFIG } from "./receivingInspection"
import { BACKLOAD_MANIFEST_CONFIG } from "./backloadManifest"
import { PERIODICAL_INSPECTION_CONFIG } from './periodicalInspection'
import { MODIFIED_PRODUCTS_CONFIG } from './modifiedProduct'
import { PIPE_AGE_CONFIG } from "./pipeAge"

const FILE_EXTENSION = {
    xlsx: ".xlsx",
}

export const getFileExtension = (fileExtension) => FILE_EXTENSION[fileExtension]

const signatureBoolMap = {
    DEFAULT: false,
    DELIVERY_NOTES_DELIVERY_THIRD_PARTY: true,
    DELIVERY_NOTES_MAT_MODIFICATION: true,
    DELIVERY_NOTES_MAT_PHYSICAL_TRANSFER : true,
    DELIVERY_NOTES_RIG_PREP: true,
    MATERIAL_REQUISITION_RIG_PREP: false,
    MATERIAL_REQUISITION_MAT_MODIFICATION: false,
    MATERIAL_REQUISITION_DELIVERY_THIRD_PARTY: false, 
    MATERIAL_REQUISITION_MAT_PHYSICAL_TRANSFER: false, 
    RECEIPT_NOTES: false,
    RIG_RETURN: false,
    RECEIVING_INSPECTION: false,
    BACKLOAD_MANIFEST: false,
    PERIODICAL_INSPECTION: false,
    MODIFIED_PRODUCTS: false, 
    PIPE_AGE: false
}

export const hasSignature = (reportType = "DEFAULT") => signatureBoolMap[reportType]

const secondHeaderBoolMap = {
    DEFAULT: false,
    DELIVERY_NOTES_DELIVERY_THIRD_PARTY: false,
    DELIVERY_NOTES_MAT_MODIFICATION: false,
    DELIVERY_NOTES_MAT_PHYSICAL_TRANSFER : false,
    DELIVERY_NOTES_RIG_PREP: false,
    MATERIAL_REQUISITION_RIG_PREP: true, 
    MATERIAL_REQUISITION_MAT_MODIFICATION: true,
    MATERIAL_REQUISITION_DELIVERY_THIRD_PARTY: true, 
    MATERIAL_REQUISITION_MAT_PHYSICAL_TRANSFER: true, 
    RECEIPT_NOTES: false,
    RIG_RETURN: true,
    RECEIVING_INSPECTION: true,
    BACKLOAD_MANIFEST: false,
    PERIODICAL_INSPECTION: true,
    MODIFIED_PRODUCTS: true, 
    PIPE_AGE: false
}

const imageHeaderBoolMap = {
    DEFAULT: false,
    DELIVERY_NOTES_DELIVERY_THIRD_PARTY: false,
    DELIVERY_NOTES_MAT_MODIFICATION: false,
    DELIVERY_NOTES_MAT_PHYSICAL_TRANSFER : false,
    DELIVERY_NOTES_RIG_PREP: false,
    // TODO: CHANGE MATERIAL REQUISITION TO TRUE when have images in base64 on aws bucket
    MATERIAL_REQUISITION_RIG_PREP: false, 
    MATERIAL_REQUISITION_MAT_MODIFICATION: false,
    MATERIAL_REQUISITION_DELIVERY_THIRD_PARTY: false, 
    MATERIAL_REQUISITION_MAT_PHYSICAL_TRANSFER: false, 
    RECEIPT_NOTES: false,
    RIG_RETURN: true,
    RECEIVING_INSPECTION: true,
    BACKLOAD_MANIFEST: false,
    PERIODICAL_INSPECTION: true,
    MODIFIED_PRODUCTS: true, 
    PIPE_AGE: false
}

export const hasSecondHeader = (reportType = "DEFAULT") => secondHeaderBoolMap[reportType]
export const hasImageHeader = (reportType = "DEFAULT") => imageHeaderBoolMap[reportType]

const reportConfigMap = {
    // Pendente a criação do restante da estrutura
    DEFAULT: {
        headerLabelConfig: HEADER_CONFIG.DEFAULT_LABEL,
        headerContentConfig: HEADER_CONFIG.DEFAULT_SUBTITLE,
        dataLabelConfig: DATA_CONFIG.DEFAULT_LABEL,
        dataContentConfig: DATA_CONFIG.DEFAULT_CONTENT,
    },
    ...DELIVERY_NOTES_CONFIG,
    ...MATERIAL_REQUISITION_CONFIG,
    ...RECEIPT_NOTES_CONFIG,
    ...RIG_RETURN_CONFIG,
    ...RECEIVING_INSPECTION_CONFIG,
    ...BACKLOAD_MANIFEST_CONFIG,
    ...PERIODICAL_INSPECTION_CONFIG,
    ...MODIFIED_PRODUCTS_CONFIG,
    ...PIPE_AGE_CONFIG
}

export const getReportHeaderConfig = (reportType = "DEFAULT") => {
    return {
        headerLabelConfig: reportConfigMap[reportType].headerLabelConfig,
        headerContentConfig: reportConfigMap[reportType].headerContentConfig,
        headerImageSheetConfig: reportConfigMap[reportType].headerImageSheetConfig
    }
}

export const getReportSecondHeaderConfig = (reportType = "DEFAULT") => {
    return {
        secondHeaderLabelConfig: reportConfigMap[reportType].secondHeaderLabelConfig,
        secondHeaderContentConfig: reportConfigMap[reportType].secondHeaderContentConfig,
        secondHeaderLabelStyle: reportConfigMap[reportType].secondHeaderLabelStyle,
        secondHeaderContentStyle:  reportConfigMap[reportType].secondHeaderContentStyle
    }
}

export const getReportDataConfig = (reportType = "DEFAULT") => {
    return {
        dataLabelConfig: reportConfigMap[reportType].dataLabelConfig,
        dataContentConfig: reportConfigMap[reportType].dataContentConfig,
        dataContentRow: reportConfigMap[reportType].dataContentRow, 
        dataLogoRow: reportConfigMap[reportType].dataLogoRow, 
        dataImageContentRow: reportConfigMap[reportType].dataImageContentRow, 
        dataImageContentConfig: reportConfigMap[reportType].dataImageContentConfig,
    }
}

export const getReportSignatureConfig = (reportType = "DEFAULT") => {
    return {
        signatureShipper: reportConfigMap[reportType].signatureShipper,
        signatureReceiver: reportConfigMap[reportType].signatureReceiver,
        signatureGap: reportConfigMap[reportType].signatureGap
    }
}
