import React, { useState, useEffect, useContext, useCallback, useMemo } from 'react'
import { toast } from 'react-toastify'
import moment from 'moment'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'

import apiStock from 'api/stock'

import { UserContext } from 'stores/UserStore'

import { storageType, conditions, reports_uri } from 'utils/constants'
import { exportAsExcelFile } from 'utils/excel'
import { exportAsTablePdfFile } from 'utils/pdf'

export const PipeAgeContext = React.createContext({})

export const PipeAgeProvider = ({ children }) => {
 
  const { t } = useTranslation()
  const { idToken } = useContext(UserContext)
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])
  const [filteredData, setFilteredData] = useState([])
  const [exportData, setExportData] = useState([])
  const [chartData, setChartData] = useState([])
  const [filters, setFilters] = useState({})
  
  const [isToFilter, setIsToFilter] = useState(false) 
  const [paramFilter, setParamFilter] = useState('') 
  const [condition, setCondition] = useState(null) 
  const [ stock, setStock ] = useState([])

  const QTD_PC = 'qtd_pc'

  const equalStorageType = useCallback((storageType) => {
    return storageType.toUpperCase()  === paramFilter.toUpperCase() 
  }, [paramFilter])

  const countSumOfPipes = (data, qtdProp) => data
  .map(item => item[qtdProp])
  .reduce((res, item) => res + item, 0)

  const percentOfPipes = (countOfPipes, total) => total === 0 ?  0 : Math.round((countOfPipes*100)/total)
  const getPercentageOfPipes = useCallback((pipes, condition, qtdProp, total) => {
    return percentOfPipes(countSumOfPipes(pipes.filter(item => condition(item.months_in_stock)), qtdProp), total)
  }, [])

  const loadChartData = useCallback((pipeAgeGlobalPipes) => {
    const closedEndPipes = pipeAgeGlobalPipes.filter(item => item.storage_type === storageType.closedEnd)
    const openEndPipes = pipeAgeGlobalPipes.filter(item => item.storage_type === storageType.openEnd)
    const naPipes = pipeAgeGlobalPipes.filter(item => item.storage_type === storageType.na)

    const chartData = [{
        'id': 'closed_end',
        'name': 'Closed End',
        'less_than_six_months': getPercentageOfPipes(closedEndPipes, conditions.lessThanSixMonths.condition, QTD_PC, countSumOfPipes(closedEndPipes, QTD_PC)),
        'between_six_and_eight_months': getPercentageOfPipes(closedEndPipes, conditions.betweenSixAndEightMonths.condition, QTD_PC, countSumOfPipes(closedEndPipes, QTD_PC)),
        'between_eight_and_twelve_months': getPercentageOfPipes(closedEndPipes, conditions.betweenEightAndTwelveMonths.condition, QTD_PC, countSumOfPipes(closedEndPipes, QTD_PC)),
        'between_twelve_and_eighteen_months': getPercentageOfPipes(closedEndPipes, conditions.betweenTwelveAndEighteenMonths.condition, QTD_PC, countSumOfPipes(closedEndPipes, QTD_PC)),
        'more_than_eighteen_months': getPercentageOfPipes(closedEndPipes, conditions.moreThanEighteen.condition, QTD_PC, countSumOfPipes(closedEndPipes, QTD_PC)),
      },
      {
        'id': 'open_end',
        'name': 'Open End',
        'less_than_six_months': getPercentageOfPipes(openEndPipes, conditions.lessThanSixMonths.condition, QTD_PC, countSumOfPipes(openEndPipes, QTD_PC)),
        'between_six_and_eight_months': getPercentageOfPipes(openEndPipes, conditions.betweenSixAndEightMonths.condition, QTD_PC, countSumOfPipes(openEndPipes, QTD_PC)),
        'between_eight_and_twelve_months': getPercentageOfPipes(openEndPipes, conditions.betweenEightAndTwelveMonths.condition, QTD_PC, countSumOfPipes(openEndPipes, QTD_PC)),
        'between_twelve_and_eighteen_months': getPercentageOfPipes(openEndPipes, conditions.betweenTwelveAndEighteenMonths.condition, QTD_PC, countSumOfPipes(openEndPipes, QTD_PC)),
        'more_than_eighteen_months': getPercentageOfPipes(openEndPipes, conditions.moreThanEighteen.condition, QTD_PC, countSumOfPipes(openEndPipes, QTD_PC)),
      },
      {
        'id': 'na',
        'name': 'NA',
        'less_than_six_months': getPercentageOfPipes(naPipes, conditions.lessThanSixMonths.condition, QTD_PC, countSumOfPipes(naPipes, QTD_PC)),
        'between_six_and_eight_months': getPercentageOfPipes(naPipes, conditions.betweenSixAndEightMonths.condition, QTD_PC, countSumOfPipes(naPipes, QTD_PC)),
        'between_eight_and_twelve_months': getPercentageOfPipes(naPipes, conditions.betweenEightAndTwelveMonths.condition, QTD_PC, countSumOfPipes(naPipes, QTD_PC)),
        'between_twelve_and_eighteen_months': getPercentageOfPipes(naPipes, conditions.betweenTwelveAndEighteenMonths.condition, QTD_PC, countSumOfPipes(naPipes, QTD_PC)),
        'more_than_eighteen_months': getPercentageOfPipes(naPipes, conditions.moreThanEighteen.condition, QTD_PC, countSumOfPipes(naPipes, QTD_PC)),
      },
      {
        'id': 'global',
        'name': 'Global',
        'less_than_six_months': getPercentageOfPipes(pipeAgeGlobalPipes, conditions.lessThanSixMonths.condition, QTD_PC, countSumOfPipes(pipeAgeGlobalPipes, QTD_PC)),
        'between_six_and_eight_months': getPercentageOfPipes(pipeAgeGlobalPipes, conditions.betweenSixAndEightMonths.condition, QTD_PC, countSumOfPipes(pipeAgeGlobalPipes, QTD_PC)),
        'between_eight_and_twelve_months': getPercentageOfPipes(pipeAgeGlobalPipes, conditions.betweenEightAndTwelveMonths.condition, QTD_PC, countSumOfPipes(pipeAgeGlobalPipes, QTD_PC)),
        'between_twelve_and_eighteen_months': getPercentageOfPipes(pipeAgeGlobalPipes, conditions.betweenTwelveAndEighteenMonths.condition, QTD_PC, countSumOfPipes(pipeAgeGlobalPipes, QTD_PC)),
        'more_than_eighteen_months': getPercentageOfPipes(pipeAgeGlobalPipes, conditions.moreThanEighteen.condition, QTD_PC, countSumOfPipes(pipeAgeGlobalPipes, QTD_PC)),
      }
    ]
     
    setChartData(chartData)
  }, [getPercentageOfPipes])

  useEffect(() => {
    setLoading(true)
    if (stock.wid > 0) {
      apiStock.getPipeAgeList(reports_uri.GET_PIPE_AGE, { wid: stock.wid }, idToken).then(pipeAgeGlobalPipes => {
        setData(pipeAgeGlobalPipes)
        setFilteredData(pipeAgeGlobalPipes)
        setExportData(pipeAgeGlobalPipes)      
        loadChartData(pipeAgeGlobalPipes)
      }).catch(error => {
        console.log(error)
        toast.error(error.message)
      }).finally(() => {
        setLoading(false)
      })
    }
  }, [idToken, loadChartData, stock.wid])

  const updateFilteredData = useCallback((data) => {
    loadChartData(data)
    setExportData(data)
  }, [loadChartData])

  const filteredPipeAgeTable = useMemo(() => {
    const filteredTableData = _.filter(filteredData, item => {
      let match = true
      _.chain(filters)
      .pickBy(filter => filter.length > 0)
      .each((filter, key) => {
        const itemValue = _.get(item, key)
        match = match && _.includes(filter, itemValue)
      })
      .value()
      return match
    })

    loadChartData(filteredTableData)
    setExportData(filteredTableData)
    return {
      ...filteredData,
      filteredTableData
    }
  }, [filters, filteredData, loadChartData])

  useEffect(() => {
    if(isToFilter){ 
      if(paramFilter.toUpperCase() !== storageType.global){
        setFilteredData(data => data.filter(p=> equalStorageType(p.storage_type)))
      }
      if(condition === conditions.lessThanSixMonths.id)
        setFilteredData(data => data.filter(p=> conditions.lessThanSixMonths.condition(p.months_in_stock)))
      if(condition === conditions.betweenSixAndEightMonths.id)
        setFilteredData(data => data.filter(p=> conditions.betweenSixAndEightMonths.condition(p.months_in_stock)))
      if(condition === conditions.betweenEightAndTwelveMonths.id)
        setFilteredData(data => data.filter(p=> conditions.betweenEightAndTwelveMonths.condition(p.months_in_stock)))
      if(condition === conditions.betweenTwelveAndEighteenMonths.id)
        setFilteredData(data => data.filter(p=> conditions.betweenTwelveAndEighteenMonths.condition(p.months_in_stock)))
      if(condition === conditions.moreThanEighteen.id)
        setFilteredData(data => data.filter(p=> conditions.moreThanEighteen.condition(p.months_in_stock)))       
    }
     
  }, [isToFilter, paramFilter, condition, equalStorageType])
  
  const exportToExcel = useCallback((columnsConfig, stockKey) => {
    const excelData = _.map(exportData, row => (
      _.reduce(columnsConfig, (acc, { accessor, label, formatter }) => {
        const value = _.get(row, accessor, '')
        return {
          ...acc,
          [label]: formatter ? formatter(value) : value
        }
      }, {})
    ))
    exportAsExcelFile(excelData, `${t('Pipe Age Management')} ${moment().format('YYYY-MM-DD HH-mm-ss')}`)
  }, [exportData, t])

  const exportToPdf = useCallback((columnsConfig, stockKey) => {
    const header = {
        title: `${t('Pipe Age Management')} ${moment().format('YYYY-MM-DD HH-mm-ss')}`,
        subTitle: ' ',
        logo: 'Smart'
    }
    const headers = _.map(columnsConfig, 'label')
    const body = _.map(exportData, row => (
      _.reduce(columnsConfig, (acc, { accessor, formatter }) => {
        const value = _.get(row, accessor)
        return [
          ...acc,
          value ? (formatter ? formatter(value) : value) : '-'
        ]
      }, [])
    ))
    const reportData = [headers, ...body]
    const options = {
        pageOrientation: 'landscape',
        pageSize: 'A3',
        watermark: false,
        zebraStyle: true
    }
    exportAsTablePdfFile(header, reportData, options)
  }, [exportData, t])

  

  const renderStore =
  <PipeAgeContext.Provider value={{
    data, setData, 
    filteredData, setFilteredData, 
    exportData, setExportData,
    filteredPipeAgeTable,
    chartData, setChartData, 
    loading, setLoading, 
    filters, setFilters, 
    isToFilter, setIsToFilter, 
    paramFilter, setParamFilter,
    condition, setCondition,
    exportToExcel, 
    exportToPdf,
    updateFilteredData, 
    stock, setStock
  }}>
    {children}
  </PipeAgeContext.Provider>

  return renderStore
}