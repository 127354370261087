import { FONT_NAME_NORMAL } from "provider/styles"

const DEFAULT_REPORT_REFERENCE_CONFIG = {
    cellPosition: 'H2',
    height: 35,
    mergedCells: "H2:H2",
    font: {
        name: FONT_NAME_NORMAL,
        size: 12,
        bold: false
    }
}

const RECEIPT_NOTES_REPORT_REFERENCE_CONFIG = {
    cellPosition: 'E2',
    height: 35,
    mergedCells: "E2:E2",
    font: {
        name: FONT_NAME_NORMAL,
        size: 12,
        bold: false
    }
}

const PIPE_AGE_REPORT_REFERENCE_CONFIG = {
    cellPosition: 'G4',
    height: 35,
    mergedCells: "G4:G4",
    font: {
        name: FONT_NAME_NORMAL,
        size: 12,
        bold: false
    }
}

const reportReferenceMap = {
    DEFAULT: DEFAULT_REPORT_REFERENCE_CONFIG,
    DELIVERY_NOTES_DELIVERY_THIRD_PARTY: DEFAULT_REPORT_REFERENCE_CONFIG,
    DELIVERY_NOTES_MAT_MODIFICATION: DEFAULT_REPORT_REFERENCE_CONFIG,
    DELIVERY_NOTES_MAT_PHYSICAL_TRANSFER : DEFAULT_REPORT_REFERENCE_CONFIG,
    DELIVERY_NOTES_RIG_PREP: DEFAULT_REPORT_REFERENCE_CONFIG,
    MATERIAL_REQUISITION_RIG_PREP: DEFAULT_REPORT_REFERENCE_CONFIG,
    MATERIAL_REQUISITION_MAT_MODIFICATION: DEFAULT_REPORT_REFERENCE_CONFIG,
    MATERIAL_REQUISITION_DELIVERY_THIRD_PARTY: DEFAULT_REPORT_REFERENCE_CONFIG, 
    MATERIAL_REQUISITION_MAT_PHYSICAL_TRANSFER: DEFAULT_REPORT_REFERENCE_CONFIG, 
    RECEIPT_NOTES: RECEIPT_NOTES_REPORT_REFERENCE_CONFIG,
    RIG_RETURN: RECEIPT_NOTES_REPORT_REFERENCE_CONFIG,
    RECEIVING_INSPECTION: RECEIPT_NOTES_REPORT_REFERENCE_CONFIG,
    BACKLOAD_MANIFEST: DEFAULT_REPORT_REFERENCE_CONFIG,
    PERIODICAL_INSPECTION: DEFAULT_REPORT_REFERENCE_CONFIG,
    MODIFIED_PRODUCTS: RECEIPT_NOTES_REPORT_REFERENCE_CONFIG, 
    PIPE_AGE: PIPE_AGE_REPORT_REFERENCE_CONFIG
}

export const getReportReferenceConfig = (reportType) => reportReferenceMap[reportType]

export const getReportReference = (reportReference) => reportReference || ''
