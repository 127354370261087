import React, { useContext, useMemo, useEffect, useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import moment from 'moment'
import _ from 'lodash'

import documentManagement from 'api/document-management'
import apiPipeAge from 'api/pipe-age'

import { LoadingOverlay, FlexView, Icon, Card } from 'components/common'
import FilterableTable from 'components/common/FilterableTable'

import StockTabs from 'containers/layout/StockTabs'

import { AppContext } from 'stores/AppStore'
import { UserContext } from 'stores/UserStore'
import { WarehouseContext } from 'stores/WarehouseStore'

import { filenames } from 'utils/constants'
import { getFileName } from 'utils/helpers'

import { generateSpreadsheet } from 'provider/excelGeneration'
import { templateType } from 'provider/types'
import FiltersModal from 'containers/common/FiltersModal'
import { FilterContext } from 'stores/FilterStore'
import FilterDisplay from 'containers/common/FilterDisplay'

const formatReports = reports => reports.map(report => {
    const formattedDate = report.trigger_date ? moment(report.trigger_date).format('DD/MM/YYYY') : 'Undated'
    return {
      id: report.id,
      trigger_date: report.trigger_date,
      name: `Pipe_Age_Reports_List_${formattedDate}`,
      type: report.type
    }
  })

const PipeAgeReports = ({ stockKey }) => {
  const { t } = useTranslation()
  const { setPageSubtitle, datasets } = useContext(AppContext)
  const { idToken } = useContext(UserContext)
  const { warehouses } = useContext(WarehouseContext)

  const stock = useMemo(() => _.find(warehouses, { key: stockKey }), [warehouses, stockKey])
  
  const [loading, setLoading] = useState(true)
  const [showModal, setShowModal] = useState(false)

  const { filteredData: {filteredTableData }, data, setData } = useContext(FilterContext)

  useEffect(() => {
    if (stock) {
      setPageSubtitle(stock?.title + ' - ' + t('Pipe Age Reports'))
    }
  }, [stock, setPageSubtitle, t])

  const downloadReport = useCallback(async reportId => {
    setLoading(true)
    try {
      const reportData = await apiPipeAge.getPipeAgeData({ reportId: reportId }, idToken)
      const fileName = getFileName(filenames['PIPE_AGE'], reportId)
      let reportImagesContent = []
      const logoBase64 = await documentManagement.getStockLogo({ wid: stock.wid }, idToken)
      generateSpreadsheet(fileName, reportData, templateType.PIPE_AGE, logoBase64, reportImagesContent)
      toast.info(t('documents.popupInfo'))
    }
    catch (error) {
      toast.error(error.message)
    }
    finally {
      setLoading(false)
    }
  }, [idToken, stock, t])

  const onDownloadClick = useCallback(id => () => {
    downloadReport(id)
  }, [downloadReport])

  useEffect(() => {
    setLoading(true)
    apiPipeAge.getPipeAgeList({ wid: stock.wid}, idToken).then(data => {
      setData(formatReports(data))
    }).catch(error => {
      toast.error(error.message)
    }).finally(() => {
      setLoading(false)
    })
  }, [datasets, idToken, setData, stock])

  const columns = useMemo(() => [
    {
      accessor: 'id',
      Header: t('ID'), 
      label: t('ID')
    },
    {
      accessor: 'name',
      Header: t('Name'),
      label: t('Name'),
      customHeaderProps: {
        style: {
          minWidth: '150px'
        }
      }
    },
    {
      accessor: 'type',
      Header: t('Filtering Type'),
      label: t('Filtering Type'),
      customHeaderProps: {
        style: {
          minWidth: '150px'
        }
      }
    },
    {
      Header: t('Extraction Date'),
      label: t('Extraction Date'),
      accessor: 'trigger_date',
      Cell: (props) => {
        const { trigger_date } = props.row.original
        return trigger_date ? moment(trigger_date).format('DD/MM/YYYY') : ''
      },
      sortType: 'date',
      customHeaderProps: {
        style: {
          minWidth: '150px'
        }
      }
    },
    {
      id: 'download',
      accessor: 'id',
      Header: t('Download'),
      disableFilters: true,
      customHeaderProps: {
        style: {
          minWidth: '32px'
        }
      },
      Cell: ({ cell: { value } }) => <FlexView alignItems="center" justifyContent="center" width="100%">
        <Icon name="download" width="24px" height="24px" onClick={onDownloadClick(value)} />
      </FlexView>
    }
  ], [t, onDownloadClick])

  const toggleModal = () => setShowModal(currentState => !currentState)

  return <FlexView flex="1" position="relative" alignSelf="stretch">
    <StockTabs stock={stock} />
    <FlexView margin="16px 16px 8px" flexDirection="row" justifyContent="space-between" alignSelf="stretch">
      <FilterDisplay options={columns} onTagClick={toggleModal} />
      <FlexView flexDirection="row" alignItems="center" justifyContent="flex-end" flex="1">
        <Icon name="filter" width="28px" height="28px" margin="0px 8px 0px 0px" tooltip={t('Filter')} onClick={toggleModal}/>
      </FlexView>
    </FlexView>
    <Card alignSelf="stretch" padding="0px" margin="16px">
      <FilterableTable columns={columns} data={filteredTableData} />
    </Card>
    <FiltersModal isOpen={showModal} onOutsideClick={toggleModal} options={columns} data={data}/>
    <LoadingOverlay visible={loading} />
  </FlexView>
}

export default PipeAgeReports