import React, { useState, useContext, useCallback, useMemo } from 'react'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

import _, { range } from 'lodash'
import moment from 'moment'

import pipeDataApi from 'api/pipe-data'
import stock from 'api/stock'

import { UserContext } from './UserStore'

import { exportAsTablePdfFile } from 'utils/pdf'
import { exportAsExcelFile } from 'utils/excel'
import { PIPE_DATA_SLUGS } from 'utils/constants'

export const StockContext = React.createContext({})

export const StockProvider = ({ children }) => {
  const { t } = useTranslation()
  const { idToken } = useContext(UserContext)
  const [stockData, setStockData] = useState({
    tableData: [],
    monthlyData: {
      in: [],
      out: [],
      res: []
    }
  })
  const [pipeData, setPipeData] = useState([])
  const [pipeNominalData, setPipeNominalData] = useState({})
  const [loading, setLoading] = useState({
    pipeData: false,
    pipeNominalData: false,
  })
  const [filters, setFilters] = useState({})

  const MAX_DATA_LENGTH = 2000

  const getStockData = useCallback((stockRoute, wid = null) => new Promise((resolve, reject) => {
    setStockData({
      tableData: [],
      monthlyData: {
        in: [],
        out: [],
        res: []
      }
    })
    setFilters({})
    stock.getStockData(stockRoute, wid, idToken).then(data => {
      setStockData({
        ...data,
        tableData: _.map(data.tableData, item => ({
          ...item,
          entranceDateString: moment(item.entranceDate).format('DD/MM/YYYY')
        }))
      })
      resolve()
    }).catch(e => {
      reject(e)
    })
  }), [idToken])

  const getPipeNominalData = useCallback((sapReference, endUserId) => {
    pipeDataApi.getPipeDataInfo({ params : { endUser: endUserId, erpRef: sapReference }, 
                                  slug: PIPE_DATA_SLUGS.GET_NOMINAL_DATA  }, idToken)
    .then(response => {
      setPipeNominalData(response)
    }).catch(error => {
      setPipeNominalData({})
      toast.error(error.message)
    }).finally(() => {
      setLoading(currentState => ({
        ...currentState,
        pipeNominalData: false
      }))
    })
  }, [idToken])

  const getActualDataFromFiltersChuncks = (timesToSearch, params, idToken) => range(timesToSearch).map(execNo =>
    pipeDataApi.getPipeDataInfo({ params : { filters: params, offset: execNo * MAX_DATA_LENGTH, limit: MAX_DATA_LENGTH }, 
                                  slug: PIPE_DATA_SLUGS.GET_PIPE_LIST  }, idToken))

  const handleResult = useCallback((data) => {
    if (data) {
      if (data.length === 0) {
        toast.warn(t('Not Found'))
        return []
      } else {
        return data
      }
    } else {
      toast.error(t('hasn\'t data'))
      return []
    }
  }, [t])
    
  const executeManyCallsToGetDataFromFilters = useCallback(async (params, dataCount) => {
    const timesToSearch = Math.ceil(dataCount / MAX_DATA_LENGTH)
    const promises = getActualDataFromFiltersChuncks(timesToSearch, params, idToken)
    const data = []

    const results = await Promise.all(promises)
    if (results) {
      results.forEach(item => { data.push(...item) })
      return handleResult(data)
    }
  }, [handleResult, idToken])
  
  const getPipeData = useCallback(async (valids, sapReference, wid) => {
    try {
      setLoading(currentState => ({
        ...currentState,
        pipeData: true
      }))
      const params = { val_id: valids, erp_ref: sapReference }
      const dataCount = await pipeDataApi.getPipeDataInfo({ params, slug: PIPE_DATA_SLUGS.GET_ACTUAL_DATA_COUNT_FROM_FILTERS  }, idToken)
      let pipeDataList = []
      if (dataCount) {
        pipeDataList = await executeManyCallsToGetDataFromFilters(params, dataCount)
      }

      const racksFromPipes = await stock.getRackFromPipes({ valids: valids, wid: wid }, idToken)

      if(!pipeDataList || pipeDataList.length <= 0  || !racksFromPipes || racksFromPipes.length <= 0) {
        toast.error(t("No Pipe Data Found"))
      }

      const pipeWithRacks = pipeDataList?.map(pipe => ({
        ...pipe,
        rack: racksFromPipes.find(rackObj => rackObj?.valid === pipe?.val_id)?.rack || ''
      }))

      setPipeData(pipeWithRacks)
    }
    catch (error) {
      setPipeData([])
      toast.error(error.message)
    }
    finally {
      setLoading(currentState => ({
        ...currentState,
        pipeData: false
      }))
    }
  }, [executeManyCallsToGetDataFromFilters, idToken, t])

  const downloadCertificate = useCallback(path => new Promise((resolve, reject) => {
    pipeDataApi.getPipeDataInfo({ params : { path: path }, slug: PIPE_DATA_SLUGS.GET_QUALITY_CERTIFICATE  }, idToken)
    .then(response => {
      const { url } = response
      const link = document.createElement('a')
      link.href = url
      link.target = '_blank'
      link.click()
    }).catch(e => {
      reject(e)
    })
  }), [idToken])

  const filteredStockData = useMemo(() => {
    const filteredTableData = _.filter(stockData.tableData, item => {
      let match = true
      _.chain(filters)
        .pickBy(filter => filter.length > 0)
        .each((filter, key) => {
          const itemValue = _.get(item, key)
          match = match && _.includes(filter, itemValue)
        })
        .value()
      return match
    })
    return {
      ...stockData,
      tableData: filteredTableData
    }
  }, [filters, stockData])

  const removeCommasFromLengthAndWeight = (data) => {
    const formattedItems = data.map((item) => {
      return {
        ...item,
        'Total Length [m]': Number(item['Total Length [m]'].replace(',', '')),
        'Total Weight [kg]': Number(item['Total Weight [kg]'].replace(',', ''))
      }
    })

    return formattedItems
  }
  
  const exportToExcel = useCallback((columnsConfig, stockKey) => {
    const excelData = _.map(filteredStockData.tableData, row => (
      _.reduce(columnsConfig, (acc, { accessor, label, formatter }) => {
        const value = _.get(row, accessor, '')
        return {
          ...acc,
          [label]: formatter ? formatter(value) : value
        }
      }, {})
    ))
    exportAsExcelFile(removeCommasFromLengthAndWeight(excelData), `${stockKey} ${moment().format('YYYY-MM-DD HH-mm-ss')}`)
  }, [filteredStockData])

  const exportToPdf = useCallback((columnsConfig, stockKey) => {
    const header = {
      title: `${stockKey} ${moment().format('YYYY-MM-DD HH-mm-ss')}`,
      subTitle: ' ',
      logo: 'Smart'
    }
    const headers = _.map(columnsConfig, 'label')
    const body = _.map(filteredStockData.tableData, row => (
      _.reduce(columnsConfig, (acc, { accessor, formatter }) => {
        const value = _.get(row, accessor)
        return [
          ...acc,
          value ? (formatter ? formatter(value) : value) : '-'
        ]
      }, [])
    ))
    const reportData = [headers, ...body]
    const options = {
      pageOrientation: 'landscape',
      pageSize: 'A3',
      watermark: false,
      zebraStyle: true
    }
    exportAsTablePdfFile(header, reportData, options)
  }, [filteredStockData])

  return <StockContext.Provider value={{
    loading,
    stockData,
    filteredStockData,
    exportToExcel,
    exportToPdf,
    pipeData,
    pipeNominalData,
    filters,
    setFilters,
    downloadCertificate,
    getStockData,
    getPipeNominalData,
    getPipeData
  }}>
    {children}
  </StockContext.Provider>
}