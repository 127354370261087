import React, { useState, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import moment from 'moment'

import DATASETS from 'utils/datasets'
import DATAPORT_TEMPLATES from 'utils/dataport-templates'
import Cookies from 'js-cookie'

const initialState = {
  name: process.env.REACT_APP_NAME,
  subtitle: '',
  version: process.env.REACT_APP_VERSION, 
  redirectUrl: '', 
  isHeaderVisible: true, 
  lengthUnit: 'm', 
  weightUnit: 'kg', 
  pageLoading: false, 
  endUser: null, 
  sideMenuOptions: []
}

const datasets = DATASETS[process.env.REACT_APP_DATASETS_ENV]
const dataportTemplates = DATAPORT_TEMPLATES[process.env.REACT_APP_DATAPORT_TEMPLATES_ENV]

const nullValue = value => value === null || value === undefined || value === ''

export const AppContext = React.createContext(initialState)

export const AppProvider = ({ children }) => {

  const { i18n } = useTranslation()
  moment.locale(i18n.language)

  const [redirectUrl, setRedirectUrl] = useState(initialState.redirectUrl)
  const [pageTitle, setPageTitle] = useState(initialState.name)
  const [pageSubtitle, setPageSubtitle] = useState(initialState.subtitle)
  const [isHeaderVisible, setHeaderVisibility] = useState(initialState.isHeaderVisible)
  const [lengthUnit, setLengthUnit] = useState(initialState.lengthUnit)
  const [weightUnit, setWeightUnit] = useState(initialState.weightUnit)
  const [ pageLoading, setPageLoading ] = useState(initialState.pageLoading)
  const [ endUser, setEndUser ] = useState(initialState.endUser)
  
  const formatNumberByCulture = useCallback((value, digits) => {
    return new Intl.NumberFormat(i18n.language, { minimumFractionDigits: digits } ).format( value )
  }, [i18n.language])

  const convertToCurrentLengthUnit = useCallback((value_m,value_ft, unit) => {
    const nullMetricValue = nullValue(value_m)
    const nullImperialValue = nullValue(value_ft)
    const selectedValueNull = ((nullMetricValue && lengthUnit === 'm') || (nullImperialValue && lengthUnit === 'ft'))

    if (nullMetricValue && nullImperialValue) {
      return 0
    }

    if (selectedValueNull) {
      return 0
    }

    if (unit === 'm') {
      return formatNumberByCulture(value_m, 3)
    }  
    
    if (lengthUnit === 'ft') {
      return formatNumberByCulture(value_ft, 3)
    }
    return value_m
  }, [lengthUnit, formatNumberByCulture])

  const convertToCurrentLengthUnitWithoutCulture = useCallback((value_m,value_ft, unit) => {
    const nullMetricValue = nullValue(value_m)
    const nullImperialValue = nullValue(value_ft)
    const selectedValueNull = ((nullMetricValue && lengthUnit === 'm') || (nullImperialValue && lengthUnit === 'ft'))

    if (nullMetricValue && nullImperialValue) {
      return 0
    }
    if (selectedValueNull) {
      return 0
    }
    if (unit === 'm') {
      return Number((value_m).toFixed(3)) 
    }  
    if (lengthUnit === 'ft') {
      return Number((value_ft).toFixed(3))
    }
    return  Number((value_m).toFixed(3)) 
  }, [lengthUnit])

  const convertToCurrentWeightUnit = useCallback((value, unit) => {
    if (value === null || value === undefined || value === '') {
      return 0
    }
    if (weightUnit === 'lb') {
      return formatNumberByCulture(value*2.20462, 3)
    }
    if (weightUnit === 'kg') {
      return formatNumberByCulture(value, 3)
    }
    if (unit === weightUnit) {
      return formatNumberByCulture(value, 3)
    }
    return formatNumberByCulture(value/2.20462, 3)
  }, [weightUnit, formatNumberByCulture])

  const convertToCurrentWeightUnitWithoutCulture = useCallback((value, unit) => {
    if (value === null || value === undefined || value === '') {
      return 0
    }
    if (weightUnit === 'lb') {
      return Number((value*2.20462).toFixed(3))
    }
    if (weightUnit === 'kg') {
      return formatNumberByCulture(value, 3)
    }
    if (unit === weightUnit) {
      return Number((value).toFixed(3))
    }
    return Number((value/2.20462).toFixed(3))
  }, [formatNumberByCulture, weightUnit])

  useEffect(() => {
    const wid = Cookies.get('_currentWarehouse')
    const length = Cookies.get(`_lengthUnit${wid}`) || initialState.lengthUnit
    const weight = Cookies.get(`_weightUnit${wid}`) || initialState.weightUnit

    setLengthUnit(length)
    setWeightUnit(weight)
  }, [lengthUnit, weightUnit])

  return <AppContext.Provider value={{
    ...initialState,
    redirectUrl,
    pageTitle,
    pageSubtitle,
    isHeaderVisible,
    datasets,
    dataportTemplates,
    lengthUnit,
    weightUnit,
    convertToCurrentLengthUnit,
    convertToCurrentWeightUnit,
    setLengthUnit,
    setWeightUnit,
    setRedirectUrl,
    setPageTitle,
    setPageSubtitle,
    setHeaderVisibility, 
    formatNumberByCulture, 
    pageLoading, setPageLoading,
    convertToCurrentLengthUnitWithoutCulture, 
    convertToCurrentWeightUnitWithoutCulture, 
    endUser, setEndUser
  }}>
    {children}
  </AppContext.Provider>
}

