import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import _ from 'lodash'
import moment from 'moment'

import apiDataReport from 'api/report-data'
import apiReports from 'api/reports'
import documentManagement from 'api/document-management'

import { Card, FlexView, Icon, LoadingOverlay } from 'components/common'
import FilterableTable from 'components/common/FilterableTable'

import StockTabs from 'containers/layout/StockTabs'

import { generateSpreadsheet } from 'provider/excelGeneration'

import { AppContext } from 'stores/AppStore'
import { UserContext } from 'stores/UserStore'
import { WarehouseContext } from 'stores/WarehouseStore'

import { ITEM_STATUS, REPORT_STATUS } from '../provider/enum'
import { templateType } from '../provider/types'

import {
  getMaterialRequisitionReportDataFromDayQuery,
  getMatRequisitionDeliveryThirdPartyReportDataFromDayQuery,
  getMatRequisitionMatModificationReportDataFromDayQuery,
  getMatRequisitionMatPhysicalTransferReportDataFromDayQuery
} from 'utils/dataport-query'
import {
  getMaterialRequisitionMatPhysicalTransferReportDownloadQuery, getMaterialRequisitionReportDownloadQuery, getMatRequisitionDeliveryThirdPartyReportDownloadQuery, getMatRequisitionMatModificationReportDownloadQuery
} from 'utils/template-dataport-query'
import { dispatchTypeId, filenames, reports_uri } from 'utils/constants'
import { getFileName, getPictureRef, getRejectReason } from 'utils/helpers'

import FilterDisplay from 'containers/common/FilterDisplay'
import { FilterContext } from 'stores/FilterStore'
import FiltersModal from 'containers/common/FiltersModal'



const nameReport = (dispatchTypeName, closure_date) => `${dispatchTypeName}_${closure_date ? moment(closure_date).format('DD_MM_YY') : 'Undated'}`

const materialRequisitionTypeMap = {
  [dispatchTypeId.Delivery3rdParty]: {
    dataQueryFunction: (report, revision, datasets) => getMatRequisitionDeliveryThirdPartyReportDataFromDayQuery(report, revision, datasets),
    downloadQueryFunction: (jobId, datasets, dataportTemplates, id) => getMatRequisitionDeliveryThirdPartyReportDownloadQuery(jobId, datasets, dataportTemplates, id),
    nameFunction: (closure_date) => nameReport('Delivery_to_3d_Party_workshop_Dispatch_Inspection_Report', closure_date)
  },
  [dispatchTypeId.MaterialModification]: {
    dataQueryFunction: (report, revision, datasets) => getMatRequisitionMatModificationReportDataFromDayQuery(report, revision, datasets),
    downloadQueryFunction: (jobId, datasets, dataportTemplates, id) => getMatRequisitionMatModificationReportDownloadQuery(jobId, datasets, dataportTemplates, id),
    nameFunction: (closure_date) => nameReport('Material_Modification_Dispatch_Inspection_Report', closure_date)
  },
  [dispatchTypeId.RigPreparation]: {
    dataQueryFunction: (report, revision, datasets) => getMaterialRequisitionReportDataFromDayQuery(report, revision, datasets),
    downloadQueryFunction: (jobId, datasets, dataportTemplates, id) => getMaterialRequisitionReportDownloadQuery(jobId, datasets, dataportTemplates, id),
    nameFunction: (closure_date) => nameReport('Rig_prep_Dispatch_Inspection_Report', closure_date)
  },
  [dispatchTypeId.MaterialPhysicalTransfer]: {
    dataQueryFunction: (report, revision, datasets) => getMatRequisitionMatPhysicalTransferReportDataFromDayQuery(report, revision, datasets),
    downloadQueryFunction: (jobId, datasets, dataportTemplates, id) => getMaterialRequisitionMatPhysicalTransferReportDownloadQuery(jobId, datasets, dataportTemplates, id),
    nameFunction: (closure_date) => nameReport('Material_Physical_Transfer_Inspection_Report', closure_date)
  }
}

const formatReports = (reports) => _.chain(reports)
  .map((report, index, filteredReports) => ({
    ...report,
    name: materialRequisitionTypeMap[report.dispatch_type_id].nameFunction(report.closure_date),
    rplc_ids: _.chain(filteredReports)
      .filter(({ id }) => id === report.id)
      .map(({ id }) => id)
      .value()
  }))
  .orderBy('closure_date', 'desc')
  .value()

const MaterialRequisitionInspectionReportsPage = ({ stockKey }) => {
  
  const { t } = useTranslation()

  const { setPageSubtitle } = useContext(AppContext)
  const { idToken } = useContext(UserContext)
  const { warehouses } = useContext(WarehouseContext)

  const { filteredData: {filteredTableData }, data, setData } = useContext(FilterContext)

  const stock = useMemo(() => _.find(warehouses, { key: stockKey }), [stockKey, warehouses])

  const [loading, setLoading] = useState(true)
  const [showModal, setShowModal] = useState(false)


  useEffect(() => {
    if (stock) {
      setPageSubtitle(stock?.title + ' - ' + t('Material Requisition Inspection Reports'))
    }
  }, [stock, setPageSubtitle, t])

  const materialRequisitionReportDataMap = {
    [dispatchTypeId.Delivery3rdParty]: {
      reportDataFunction: (report) => apiDataReport.getMaterialRequisitionThirdPartyReportData({
        reportId: report.id,
        revision: report.revision
      }, idToken),
      prepareReportDataFunction: (reportData) => ({
        ...reportData,
        data: {
          ...reportData.data,
          header: {
            ...reportData.data.header,
          },
          secondHeader: {
            ...reportData.data.inspectionHeaderData,
            total_effective_length: reportData.data.inspectionHeaderData.total_effective_length
          },
          rows: reportData.data.rows.map(row => ({
            ...row,
            mark_legibility_report: ITEM_STATUS.literal[row.mark_legibility_report],
            pin_report: ITEM_STATUS.literal[row.pin_report],
            box_report: ITEM_STATUS.literal[row.box_report],
            int_body_report: ITEM_STATUS.literal[row.int_body_report],
            ext_body_report: ITEM_STATUS.literal[row.ext_body_report],
            drift_report: ITEM_STATUS.literal[row.drift_report],
            final_report: row.final_report === ITEM_STATUS.code.GOOD ? 'ACCEPT' : 'REJECT',
            measure_status: REPORT_STATUS.literal[row.measure_status],
            root_cause: getRejectReason([row.mark_defect, row.pin_defect, row.box_defect, row.int_body_defect, row.ext_body_defect, row.drift_defect]),
            instruments: row.instruments !== null ? row.instruments : '',
            pic_ref: getPictureRef(row.mark_legibility_photo, row.pin_photo, row.box_photo, row.int_body_photo, row.ext_body_photo, row.drift_photo)
          }))
        }
      }),
      filename: (reportId) => getFileName(filenames['MATERIAL_REQUISITION_DELIVERY_TO_THIRD_PARTY'], reportId),
    },
    [dispatchTypeId.MaterialModification]: {
      reportDataFunction: (report) => apiDataReport.getMaterialRequisitionMatModifReportData({
        reportId: report.id,
        revision: report.revision
      }, idToken),
      prepareReportDataFunction: (reportData) => ({
        ...reportData,
        data: {
          ...reportData.data,
          header: {
            ...reportData.data.header,
          },
          secondHeader: {
            ...reportData.data.inspectionHeaderData,
            total_effective_length: reportData.data.inspectionHeaderData.total_effective_length
          },
          rows: reportData.data.rows.map(row => ({
            ...row,
            mark_legibility_report: ITEM_STATUS.literal[row.mark_legibility_report],
            pin_report: ITEM_STATUS.literal[row.pin_report],
            box_report: ITEM_STATUS.literal[row.box_report],
            int_body_report: ITEM_STATUS.literal[row.int_body_report],
            ext_body_report: ITEM_STATUS.literal[row.ext_body_report],
            drift_report: ITEM_STATUS.literal[row.drift_report],
            final_report: row.final_report === ITEM_STATUS.code.GOOD ? 'ACCEPT' : 'REJECT',
            measure_status: REPORT_STATUS.literal[row.measure_status],
            root_cause: getRejectReason([row.mark_defect, row.pin_defect, row.box_defect, row.int_body_defect, row.ext_body_defect, row.drift_defect]),
            instruments: row.instruments !== null ? row.instruments : '',
            pic_ref: getPictureRef(row.mark_legibility_photo, row.pin_photo, row.box_photo, row.int_body_photo, row.ext_body_photo, row.drift_photo)
          }))
        }
      }),
      filename: (reportId) => getFileName(filenames['MATERIAL_REQUISITION_MATERIAL_MODIFICATION'], reportId),
    }, 
    [dispatchTypeId.RigPreparation]: {
      reportDataFunction: (report) => apiDataReport.getMaterialRequisitionRigPrepReportData({
        reportId: report.id
      }, idToken),
      prepareReportDataFunction: (reportData) => ({
        ...reportData,
        data: {
          ...reportData.data,
          header: {
            ...reportData.data.header,
          },
          secondHeader: {
            ...reportData.data.inspectionHeaderData,
            total_effective_length: reportData.data.inspectionHeaderData.total_effective_length
          },
          rows: reportData.data.rows.map(row => ({
            ...row,
            mark_legibility_report: ITEM_STATUS.literal[row.mark_legibility_report],
            pin_report: ITEM_STATUS.literal[row.pin_report],
            box_report: ITEM_STATUS.literal[row.box_report],
            int_body_report: ITEM_STATUS.literal[row.int_body_report],
            ext_body_report: ITEM_STATUS.literal[row.ext_body_report],
            drift_report: ITEM_STATUS.literal[row.drift_report],
            final_report: row.final_report === ITEM_STATUS.code.GOOD ? 'ACCEPT' : 'REJECT',
            measure_status: REPORT_STATUS.literal[row.measure_status],
             // TO DO AT BACK END
            root_cause: getRejectReason([row.mark_defect, row.pin_defect, row.box_defect, row.int_body_defect, row.ext_body_defect, row.drift_defect]),
            instruments: row.instruments !== null ? row.instruments : '',
            pic_ref: getPictureRef(row.mark_legibility_photo, row.pin_photo, row.box_photo, row.int_body_photo, row.ext_body_photo, row.drift_photo)
          }))
        }
      }),
      filename: (reportId) => getFileName(filenames['MATERIAL_REQUISITION_RIG_PREP'], reportId),
    },
    [dispatchTypeId.MaterialPhysicalTransfer]: {
      reportDataFunction: (report) => apiDataReport.getMaterialRequisitionMatPhysicalTransferReportData({
        reportId: report.id,
        revision: report.revision
      }, idToken),
      prepareReportDataFunction: (reportData) => ({
        ...reportData,
        data: {
          ...reportData.data,
          header: {
            ...reportData.data.header,
          },
          secondHeader: {
            ...reportData.data.inspectionHeaderData,
            total_effective_length: reportData.data.inspectionHeaderData.total_effective_length
          },
          rows: reportData.data.rows.map(row => ({
            ...row,
            mark_legibility_report: ITEM_STATUS.literal[row.mark_legibility_report],
            pin_report: ITEM_STATUS.literal[row.pin_report],
            box_report: ITEM_STATUS.literal[row.box_report],
            int_body_report: ITEM_STATUS.literal[row.int_body_report],
            ext_body_report: ITEM_STATUS.literal[row.ext_body_report],
            drift_report: ITEM_STATUS.literal[row.drift_report],
            final_report: row.final_report === ITEM_STATUS.code.GOOD ? 'ACCEPT' : 'REJECT',
            measure_status: REPORT_STATUS.literal[row.measure_status],
            root_cause: getRejectReason([row.mark_defect, row.pin_defect, row.box_defect, row.int_body_defect, row.ext_body_defect, row.drift_defect]),
            instruments: row.instruments !== null ? row.instruments : '',
            pic_ref: getPictureRef(row.mark_legibility_photo, row.pin_photo, row.box_photo, row.int_body_photo, row.ext_body_photo, row.drift_photo)
          }))
        }
      }),
      filename: (reportId) => getFileName(filenames['MATERIAL_REQUISITION_MATERIAL_PHYSICAL_TRANSFER'], reportId),
    }
  }

  const downloadRigPrepReport = useCallback(async report => {
    setLoading(true)
    try {
      const fetchDataFunction = materialRequisitionReportDataMap[report.dispatch_type_id].reportDataFunction
      const data = await fetchDataFunction(report)
      const prepareDataFunction = materialRequisitionReportDataMap[report.dispatch_type_id].prepareReportDataFunction
      const preparedData = prepareDataFunction(data)
      const reportFileName = materialRequisitionReportDataMap[report.dispatch_type_id].filename(report.id)
      const logoBase64 = await documentManagement.getStockLogo({ wid: stock.wid }, idToken)
      generateSpreadsheet(reportFileName, preparedData, templateType.MATERIAL_REQUISITION, logoBase64, [])
      toast.info(t('documents.popupInfo'))
    } catch (error) {
      console.log(error)
      toast.error(error.message)
    } finally {
      setLoading(false)
    }
  }, [idToken, materialRequisitionReportDataMap, stock, t])

  const onDownloadClick = useCallback(report => () => {
      downloadRigPrepReport(report)
  }, [downloadRigPrepReport])

  useEffect(() => {
    setLoading(true)
    apiReports.getMaterialRequisitionList(reports_uri.GET_MATERIAL_REQUISITION, { wid: stock.wid}, idToken).then(data => {
      setData(formatReports(data))
    }).catch(error => {
      console.log(error)
      toast.error(error.message)
    }).finally(() => {
      setLoading(false)
    })
  }, [idToken, setData, stock])

  const columns = useMemo(() => [
    {
      accessor: 'id',
      Header: t('ID'),
      label: t('ID'),
      customHeaderProps: {
        style: {
          minWidth: '60px'
        }
      }
    },
    {
      accessor: 'name',
      Header: t('Name'),
      label: t('Name'),
      customHeaderProps: {
        style: {
          minWidth: '250px'
        }
      }
    },
    {
      Header: t('Material Requisition Date'),
      label: t('Material Requisition Date'),
      accessor: 'closure_date',
      Cell: (props) => {
        const { closure_date } = props.row.original
        return closure_date ? moment(closure_date).format('DD/MM/YYYY') : ''
      },
      sortType: 'date',
      customHeaderProps: {
        style: {
          minWidth: '150px'
        }
      }
    },
    {
      accessor: 'mr_ref',
      Header: t('MR Reference'),
      label: t('MR Reference'),
      customHeaderProps: {
        style: {
          minWidth: '200px'
        }
      }
    },
    {
      id: 'download',
      Header: t('Download'),
      disableFilters: true,
      customHeaderProps: {
        style: {
          minWidth: '32px'
        }
      },
      Cell: ({ cell: { row } }) => <FlexView alignItems="center" justifyContent="center" width="100%">
        <Icon name="download" width="24px" height="24px" onClick={onDownloadClick(row.original)} />
      </FlexView>
    }
  ], [t, onDownloadClick])

  const toggleModal = () => setShowModal(currentState => !currentState)

  return <FlexView flex="1" position="relative" alignSelf="stretch">
    <StockTabs stock={stock} />
    <FlexView margin="16px 16px 8px" flexDirection="row" justifyContent="space-between" alignSelf="stretch">
      <FilterDisplay options={columns} onTagClick={toggleModal} />
      <FlexView flexDirection="row" alignItems="center" justifyContent="flex-end" flex="1">
        <Icon name="filter" width="28px" height="28px" margin="0px 8px 0px 0px" tooltip={t('Filter')} onClick={toggleModal}/>
      </FlexView>
    </FlexView>
    <Card alignSelf="stretch" padding="0px" margin="16px">
      <FilterableTable columns={columns} data={filteredTableData} />
    </Card>
    <FiltersModal isOpen={showModal} onOutsideClick={toggleModal} options={columns} data={data}/>
    <LoadingOverlay visible={loading} />
  </FlexView>
}

export default MaterialRequisitionInspectionReportsPage