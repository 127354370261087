import React, { useState, useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import _ from 'lodash'
import styled from 'styled-components'

import { FlexView } from '../components/common'

import StockCard from 'containers/home/StockCard'
import InfoModal from 'components/home/InfoModal'

import { AppContext } from 'stores/AppStore'
import { UserContext } from 'stores/UserStore'
import { WarehouseContext } from 'stores/WarehouseStore'
import { getFunctionalityRoute } from 'utils/warehouse-config/helpers'

const Logo = styled.div`
  font-family: Roboto;
  span {
    font-weight: 100;
  }
  span:first-child {
    color: ${({ theme }) => theme.colors.primary};
    margin-right: 16px;
    font-weight: bold;
  }
  font-size: 80px;
  margin-bottom: 32px;

  @media screen and (min-height: 800px) {
    margin-bottom: 32px;
  }

  @media screen and (max-width: 1280px) {
    font-size: 64px;
  }

  @media screen and (max-width: 800px) {
    font-size: 56px;
  }

  @media screen and (max-width: 680px) {
    font-size: 48px;
  }
`

const Grid = styled(FlexView)`
  display: grid;
  grid-template-columns: repeat(4, auto);

  @media screen and (max-width: 1280px) {
    grid-template-columns: repeat(3, auto);
  }

  @media screen and (max-width: 920px) {
    grid-template-columns: repeat(2, auto);
  }

  @media screen and (max-width: 600px) {
    grid-template-columns: repeat(1, auto);
  }
`

const Home = () => {
  const [selectedStockKey, setSelectedStockKey] = useState(null)
  const { setPageSubtitle } = useContext(AppContext)
  const { currentUser } = useContext(UserContext)
  const { warehouses } = useContext(WarehouseContext)

  const history = useHistory()

  const clearKey = () => setSelectedStockKey(null)

  const onInfoClick = key => setSelectedStockKey(key)

  useEffect(() => {
    setPageSubtitle('')
  }, [setPageSubtitle])

  useEffect(() => {
    if (warehouses?.length === 1) {
      history.push(getFunctionalityRoute({ name: warehouses[0]?.title }, warehouses[0]?.functionalities[0]))
    }
  }, [currentUser, history, warehouses])

  return <FlexView flex="1" alignSelf="stretch" alignItems="center" backgroundColor="background" padding="40px">
    <Logo>
      <span>Smartengo</span>
      <span>INVENTORY</span>
    </Logo>
    <Grid width="100%" backgroundColor="transparent" alignItems="stretch" justifyContent="center">
      {
        _.chain(warehouses)
          .map(stock => <StockCard key={stock.key} stock={stock} onCardInfoClick={onInfoClick} />)
          .value()
      }
    </Grid>
    <InfoModal isOpen={selectedStockKey} onOutsideClick={clearKey} />
  </FlexView>
}

export default Home