import React, { useContext, useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import _ from 'lodash'
import styled from 'styled-components'

import stockApi from 'api/stock'

import LogoImg from '../../assets/images/logo-small.svg'

import Header from '../../components/layout/Header'

import { UserContext } from '../../stores/UserStore'
import { AppContext } from '../../stores/AppStore'
import { WarehouseContext } from 'stores/WarehouseStore'

// import { userGuide } from 'utils/constants'
import { getFunctionalityRoute } from 'utils/warehouse-config/helpers'

const Logo = styled.div`
  align-self: center;
  text-align: center;
  font-family: Roboto;
  span {
    font-weight: 100;
  }
  span:first-child {
    color: ${({ theme }) => theme.colors.primary};
    margin-right: 4px;
    font-weight: bold;
  }
  font-size: 24px;
  margin: 32px 0px 16px;
`


const AppHeader = () => {
  const { t } = useTranslation()
  const { isHeaderVisible, pageTitle, pageSubtitle } = useContext(AppContext)

  const { authState, signOut, currentUser, settings, idToken } = useContext(UserContext)
  const { warehouses } = useContext(WarehouseContext)
  const history = useHistory()

  const redirect = useCallback(path => () => {
    history.push(path)
  }, [history])

  const [userSettings, setUserSettings] = useState({})

  const roles = _.get(currentUser, 'roles.' + process.env.REACT_APP_ID, {})

  const downloadUserGuide = useCallback((key) => new Promise((resolve, reject) => {
    stockApi.getUserGuideDownloadUrl(key, process.env.REACT_APP_VERSION, idToken).then(url => {
      const link = document.createElement('a')
      link.href = url
      link.target = '_blank'
      link.click()
    }).catch(e => {
      reject(e)
    })
  }), [idToken])


  useEffect(() => {
    const options = [
      { key: 'version', label: 'v' + process.env.REACT_APP_VERSION, onClick: () => { } },
      { key: 'sign-out', label: t('Sign Out'), onClick: signOut, divider: true }
    ]
    // _.map(settings, function (item, i) {
    //   if (item.settings.find(p => p.name === userGuide)) {
    //     options.splice(options.length - 1, 0,
    //       { key: item.name, label: t('User Guide') + ' - ' + item.description, divider: true, onClick: () => downloadUserGuide(item.name) }
    //     )
    //   }
    // })
    setUserSettings({ username: currentUser ? currentUser.firstName : currentUser, options: options })

  }, [currentUser, signOut, t, setUserSettings, settings, downloadUserGuide])

  const warehouseConfigSideMenuItem = {
    label: 'Management',
    key: 'management',
    items: [{
      label: 'Warehouse Config',
      key: 'warehouseConfig',
      onClick: redirect('/warehouse-config'),
      icon: 'gear-3', 
      id: 'WAREHOUSE_MANAGEMENT'
    }]
  }

  const sideMenuSettings = {
    appLogoComponent: <Logo>
      <span>Smartengo</span>
      <span>INVENTORY</span>
    </Logo>,
    itemGroups: [
      ...warehouses?.map(stock => ({
        ...stock,
        label: t(stock.description),
        key: stock.key.toString(),
        items: _.orderBy(stock.functionalities.filter(item => item.id !== 'PIPE_DATA')
        .map(item => ({
          ...item,
          label: t(item.label),
          key: stock.id + item.id.toString(),
          id: item.id,
          onClick: redirect(getFunctionalityRoute({ name: stock?.title }, item))
        })), 'label', 'asc'),
      })),
    ]
  }

  const sideMenuSettingsAlt = {
    appLogoComponent: <Logo>
      <span>Smartengo</span>
      <span>INVENTORY</span>
    </Logo>,
    itemGroups: [
      ...warehouses?.map(stock => ({
        ...stock,
        label: t(stock.description),
        key: stock.key.toString(),
        items: _.orderBy(stock.functionalities.filter(item => item.id !== 'PIPE_DATA')
        .map(item => ({
          ...item,
          label: t(item.label),
          key: stock.id + item.id.toString(),
          id: item.id,
          onClick: redirect(getFunctionalityRoute({ name: stock?.title }, item))
        })), 'label', 'asc'),
      })),
      warehouseConfigSideMenuItem
    ]
  }

  // const apps = currentUser && currentUser.applications.map(app => {
  //   return {
  //     key: app.id,
  //     label: app.label,
  //     iconSrc: app.icon,
  //     onClick: () => window.location.href = app.link
  //   }
  // })

  const home = {
    onClick: () => window.location.href = process.env.REACT_APP_HOME_URL
  }

  return isHeaderVisible && authState === 'SIGNED_IN' ?
    <Header
      title={pageTitle}
      homeIcon={home}
      subtitle={pageSubtitle}
      appIconSrc={LogoImg}
      onAppIconClick={redirect('/')}
      userSettings={userSettings}
      sideMenuSettings={roles?.hasOwnProperty('WAREHOUSE_MANAGEMENT') ? sideMenuSettingsAlt : sideMenuSettings} 
      // apps={apps}
      />
    : null
}

export default AppHeader