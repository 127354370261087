import axios from 'axios'
import { addLogger } from 'utils/logger'
import { getDefaultHeader, manageError, manageResponse } from './api-util'

const SHOULD_DECOMPRESS = false

const instance = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}`,
    headers: {
        'Content-Type': 'application/json', 
        'VLR-Authorization': btoa(JSON.stringify({ "id": process.env.REACT_APP_ID }))
    }
})

addLogger(instance, false)


export default {
    getRigReturnList: async (params, idToken) => {
        try {
            const result = await instance.post('/rig-return/get-rig-return-list', params, getDefaultHeader())
            return manageResponse(result, SHOULD_DECOMPRESS)
        } catch (error) {
            manageError(error)
        }
    },
    getRigReturnData: async (params, idToken) => {
        try {
            const result = await instance.post('/rig-return/get-rig-return-inspection-data', params, getDefaultHeader())
            return manageResponse(result, SHOULD_DECOMPRESS)
        } catch (error) {
            manageError(error)
        }
    },
    getRigReturnBackloadList: async (params, idToken) => {
        try {
            const result = await instance.post('/rig-return/get-backload-list', params, getDefaultHeader())
            return manageResponse(result, SHOULD_DECOMPRESS)
        } catch (error) {
            manageError(error)
        }
    },
    getBackloadManifestData: async (params, idToken) => {
        try {
            const response = await instance.post('/rig-return/get-rig-return-backload-data', params, getDefaultHeader())
            return manageResponse(response, SHOULD_DECOMPRESS)
        } catch (error) {
            manageError(error)
        }
    }
}