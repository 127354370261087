import { RIG_RETURN_SECOND_HEADER_STYLES } from "./header"

const RIG_RETURN_HEADER_CONFIG = [
    {id: 'id', label: 'ID List', cellPosition: 'A5', mergedCells: 'A5:A5'},
    {id: 'date', label: 'Date', cellPosition: 'A6', mergedCells: 'A6:A6'},
    {id: 'rr_ref', label: 'RR Number', cellPosition: 'A7', mergedCells: 'A7:A7'},
    {id: 'field_name', label: 'Field Name', cellPosition: 'A8', mergedCells: 'A8:A8'},
    {id: 'rig_name', label: 'Rig Name', cellPosition: 'A9', mergedCells: 'A9:A9'},
    {id: 'well_name', label: 'Well Name', cellPosition: 'A10', mergedCells: 'A10:A10'},
    {id: 'end_user', label: 'End User', cellPosition: 'A11', mergedCells: 'A11:A11'}
]

const RIG_RETURN_HEADER_CONTENT_CONFIG = [
    {id: 'id', accessor: 'id', cellPosition: 'B5', mergedCells: 'B5:C5'},
    {id: 'date', accessor: 'date', cellPosition: 'B6', mergedCells: 'B6:C6'},
    {id: 'rr_ref', accessor: 'rr_ref', cellPosition: 'B7', mergedCells: 'B7:C7'},
    {id: 'field_name', accessor: 'field_name', cellPosition: 'B8', mergedCells: 'B8:C8'},
    {id: 'rig_name', accessor: 'rig_name', cellPosition: 'B9', mergedCells: 'B9:C9'},
    {id: 'well_name', accessor: 'well_name', cellPosition: 'B10', mergedCells: 'B10:C10'},
    {id: 'end_user', accessor: 'end_user', cellPosition: 'B11', mergedCells: 'B11:C11'}
]

const RIG_RETURN_SECOND_HEADER_CONFIG = [
    {id: 'non_traceable_pipe', label: 'Non Traceable Pipe', cellPosition: 'H9', mergedCells: 'H9:I10'},
    {id: 'nt_quantity', label: 'Qty Inspected', cellPosition: 'J9', mergedCells: 'J9:M9'},
    {id: 'total_inspected', label: 'Inspected', cellPosition: 'J10', mergedCells: 'J10:J10'},
    {id: 'total_accepted', label: 'Accepted', cellPosition: 'K10', mergedCells: 'K10:K10'},
    {id: 'total_rejected', label: 'Rejected', cellPosition: 'L10', mergedCells: 'L10:L10'},
    {id: 'total_repair', label: 'To Repair', cellPosition: 'M10', mergedCells: 'M10:M10'}
]

const RIG_RETURN_SECOND_CONTENT_CONFIG = [
    {id: 'non_traceable_pipe', accessor: 'nt_quantity', cellPosition: 'H11', mergedCells: 'H11:I11'},
    {id: 'total_inspected', accessor: 'total_inspected', cellPosition: 'J11', mergedCells: 'J11:J11'},
    {id: 'total_accepted', accessor: 'total_accepted', cellPosition: 'K11', mergedCells: 'K11:K11'},
    {id: 'total_rejected', accessor: 'total_rejected', cellPosition: 'L11', mergedCells: 'L11:L11'},
    {id: 'total_repair', accessor: 'total_repair', cellPosition: 'M11', mergedCells: 'M11:M11'}
]

const RIG_RETURN_DATA_LABEL_CONFIG = [
    {id: 'item_no', label: 'ITEM NO', cellPosition: 'A13', mergedCells: 'A13:A14'},
    {id: 'co_purchase_order', label: 'CUSTOMER ORDER#', cellPosition: 'B13', mergedCells: 'B13:B14'},
    {id: 'co_purchase_item', label: 'CUSTOMER ITEM#', cellPosition: 'C13', mergedCells: 'C13:C14'},
    {id: 'num_sales_order', label: 'SALES PO#', cellPosition: 'D13', mergedCells: 'D13:D14'},
    {id: 'num_sales_item', label: 'SALES ITEM#', cellPosition: 'E13', mergedCells: 'E13:E14'},
    {id: 'erp_ref', label: 'ERP REFERENCE', cellPosition: 'F13', mergedCells: 'F13:F14'},
    {id: 'dsc_material', label: 'MATERIAL', cellPosition: 'G13', mergedCells: 'G13:G14'},
    {id: 'val_id', label: 'VALID', cellPosition: 'H13', mergedCells: 'H13:H14'},
    {id: 'ippn', label: 'IPPN#', cellPosition: 'I13', mergedCells: 'I13:I14'},
    {id: 'mark_legibility_report', label: 'MARKING LEGIBILITY', cellPosition: 'J13', mergedCells: 'J13:J14'},
    {id: 'thread', label: 'THREAD', cellPosition: 'K13', mergedCells: 'K13:L13'},
    {id: 'thread_pin', label: 'PIN', cellPosition: 'K14', mergedCells: 'K14:K14'},
    {id: 'thread_box', label: 'BOX', cellPosition: 'L14', mergedCells: 'L14:L14'},
    {id: 'pipe_body', label: 'PIPE BODY', cellPosition: 'M13', mergedCells: 'M13:N13'},
    {id: 'body_int', label: 'INTERNAL', cellPosition: 'M14', mergedCells: 'M14:M14'},
    {id: 'body_ext', label: 'EXTERNAL', cellPosition: 'N14', mergedCells: 'N14:N14'},
    {id: 'drift', label: 'DRIFT', cellPosition: 'O13', mergedCells: 'O13:O14'},
    {id: 'final_report', label: 'ACCEPT / REJECT', cellPosition: 'P13', mergedCells: 'P13:P14'},
    {id: 'root_cause', label: 'ROOT CAUSE', cellPosition: 'Q13', mergedCells: 'Q13:Q14'},
    {id: 'pic_ref', label: 'PICTURE REFERENCE', cellPosition: 'R13', mergedCells: 'R13:R14'},
    {id: 'inspector', label: 'INSPECTOR', cellPosition: 'S13', mergedCells: 'S13:S14'},
    {id: 'equipment', label: 'EQUIPMENT', cellPosition: 'T13', mergedCells: 'T13:T14'},
    {id: 'remarks', label: 'REMARKS', cellPosition: 'U13', mergedCells: 'U13:U14'}
]

const RIG_RETURN_DATA_CONTENT_CONFIG = [
    {id: 'item_no', accessor: 'item_no', cellPosition: 'A15', mergedCells: 'A15:A15'},
    {id: 'co_purchase_order', accessor: 'co_purchase_order', cellPosition: 'B15', mergedCells: 'B15:B15'},
    {id: 'co_purchase_item', accessor: 'co_purchase_item', cellPosition: 'C15', mergedCells: 'C15:C15'},
    {id: 'num_sales_order', accessor: 'num_sales_order', cellPosition: 'D15', mergedCells: 'D15:D15'},
    {id: 'num_sales_item', accessor: 'num_sales_item', cellPosition: 'E15', mergedCells: 'E15:E15'},
    {id: 'erp_ref', accessor: 'erp_ref', cellPosition: 'F15', mergedCells: 'F15:F15'},
    {id: 'dsc_material', accessor: 'dsc_material', cellPosition: 'G15', mergedCells: 'G15:G15'},
    {id: 'val_id', accessor: 'val_id', cellPosition: 'H15', mergedCells: 'H15:H15'},
    {id: 'ippn', accessor: 'ippn', cellPosition: 'I15'},
    {id: 'mark_legibility_report', accessor: 'mark_legibility_report',cellPosition: 'J15', mergedCells: 'J15:J15'},
    
    {id: 'thread_pin', accessor: 'pin_report', cellPosition: 'K15', mergedCells: 'K15:K15'},
    {id: 'thread_box', accessor: 'box_report', cellPosition: 'L15', mergedCells: 'L15:L15'},
    {id: 'body_int', accessor: 'int_body_report', cellPosition: 'M15', mergedCells: 'M15:M15'},
    {id: 'body_ext', accessor: 'ext_body_report', cellPosition: 'N15', mergedCells: 'N15:N15'},
    {id: 'drift', accessor: 'drift_report', cellPosition: 'O15', mergedCells: 'O15:O15'},

    {id: 'final_report', accessor: 'final_report', cellPosition: 'P15', mergedCells: 'P15:P15'},
    {id: 'root_cause', accessor: 'root_cause', cellPosition: 'Q15', mergedCells: 'Q15:Q15'},
    {id: 'pic_ref', accessor: 'pic_ref', cellPosition: 'R15', mergedCells: 'R15:R15'},
    {id: 'inspector', accessor: 'inspector', cellPosition: 'S15', mergedCells: 'S15:S15'},
    {id: 'equipment', accessor: 'equipment', cellPosition: 'T15', mergedCells: 'T15:T15'},
    {id: 'remarks', accessor: 'remarks', cellPosition: 'U15', mergedCells: 'U15:U15'}
]

const RIG_RETURN_LOGO_POSITION = 'H1:M8'
const RIG_RETURN_DATA_CONTENT_ROW = 15

const RIG_RETURN_IMAGE_SHEET_LABEL_CONFIG = [
    {
        id: "valid",
        label: "VALID",
        cellPosition: "A1",
        mergedCells: "A1:A1",
    },
    {
        id: "picture_reference",
        label: "PICTURE REFERENCE",
        cellPosition: "B1",
        mergedCells: "B1:B1",
    },
    {
        id: "picture",
        label: "PICTURE",
        cellPosition: "C1",
        mergedCells: "C1:C1",
    },
]

const RIG_RETURN_IMAGE_SHEET_CONTENT_CONFIG = [
    { id: "valid", accessor: "valid" }, 
    { id: "picture_reference", accessor: "picture_reference" }, 
    { id: "picture", accessor: "picture" }
]

const RIG_RETURN_IMAGE_DATA_CONTENT_ROW = 2

export const RIG_RETURN_CONFIG = {
    RIG_RETURN: {
        headerLabelConfig: RIG_RETURN_HEADER_CONFIG,
        headerContentConfig: RIG_RETURN_HEADER_CONTENT_CONFIG,
        secondHeaderLabelConfig: RIG_RETURN_SECOND_HEADER_CONFIG,
        secondHeaderContentConfig: RIG_RETURN_SECOND_CONTENT_CONFIG,
        secondHeaderLabelStyle: RIG_RETURN_SECOND_HEADER_STYLES.RR_LABEL, 
        secondHeaderContentStyle: RIG_RETURN_SECOND_HEADER_STYLES.RR_CONTENT,
        dataLabelConfig: RIG_RETURN_DATA_LABEL_CONFIG,
        dataContentConfig: RIG_RETURN_DATA_CONTENT_CONFIG,
        dataContentRow: RIG_RETURN_DATA_CONTENT_ROW,
        dataLogoRow: RIG_RETURN_LOGO_POSITION, 
        headerImageSheetConfig: RIG_RETURN_IMAGE_SHEET_LABEL_CONFIG,
        dataImageContentConfig: RIG_RETURN_IMAGE_SHEET_CONTENT_CONFIG,
        dataImageContentRow: RIG_RETURN_IMAGE_DATA_CONTENT_ROW,

    }
}