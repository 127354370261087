
/**
 *  File to keep all the dataport queries to avoid duplicates and make easier the visualization
 */

export const getMillReceiptReportQuery = (beid, rev, datasets) => {
  return {
    datasets: [datasets.mill_receipt],
    logic_op: 'AND',
    select: [
      'beid',
      'co_purchase_item',
      'co_purchase_order',
      'vtsa_order_item',
      'vtsa_sales_order',
      'cat_id',
      'owner',
      'entrance_date',
      'dsc_material',
      'pipe_count',
      'pipe_length',
      'pipe_weight',
      'val_id',
      'report_reference',
    ],
    columns: [
      { slug: 'beid', operator: 'equal', value: beid },
      { slug: 'revision', operator: 'equal', value: rev }
    ]
  }
}


export const getMaterialRequisitionReportDataFromDayQuery = (report, revision, datasets) => {
  return {
    datasets: [datasets.material_requisition_report_data],
    logic_op: "AND",
    select: ["id",
      "wid",
      "rpl_name",
      "rplc_id",
      "field",
      "rig",
      "well",
      "mr_ref",
      "ltpa",
      "expected_date",
      "quantity",
      "status",
      "valid",
      "it_status",
      "dsc_material",
      "insp_status",
      "mark_legibility_report",
      "pin_report",
      "box_report",
      "int_body_report",
      "ext_body_report",
      "drift_report",
      "final_report",
      "insp_responsible",
      "measure_status",
      "measured_lenght",
      "makeup_loss",
      "effective_lenght",
      "measure_responsible",
      "co_purchase_order",
      "co_purchase_item",
      "num_sales_order",
      "num_sales_item",
      "cat_id",
      "mark_defect",
      "pin_defect",
      "box_defect",
      "int_body_defect",
      "ext_body_defect",
      "drift_defect",
      "remarks",
      "mark_legibility_photo",
      "pin_photo",
      "box_photo",
      "int_body_photo",
      "ext_body_photo",
      "drift_photo", 
      "int_diameter", 
      "out_diameter", 
      "instruments", 
      "end_user"
      ],
      columns: [{
        slug: 'id',
        operator: 'equal',
        value: report.id
      }, 
      { slug: 'revision', operator: 'equal', value: revision }]
  }
}

export const getMatRequisitionDeliveryThirdPartyReportDataFromDayQuery = (report, revision, datasets) => {
  return {
    datasets: [datasets.material_requisition_delivery_third_party_data],
    logic_op: "AND",
    select: ["id",
      "wid",
      "twl_name",
      "twlc_id",
      "tparty_name",
      "tparty_loc",
      "mr_ref",
      "ltpa",
      "closure_date",
      "quantity",
      "status",
      "co_purchase_order",
      "co_purchase_item",
      "num_sales_order",
      "num_sales_item",
      "cat_id",
      "valid",
      "it_status",
      "dsc_material",
      "insp_status",
      "mark_legibility_report",
      "pin_report",
      "box_report",
      "int_body_report",
      "ext_body_report",
      "drift_report",
      "final_report",
      "insp_responsible",
      "measure_status",
      "measured_lenght",
      "makeup_loss",
      "effective_lenght",
      "measure_responsible",
      "mark_defect",
      "pin_defect",
      "box_defect",
      "int_body_defect",
      "ext_body_defect",
      "drift_defect",
      "remarks",
      "mark_legibility_photo",
      "pin_photo",
      "box_photo",
      "int_body_photo",
      "ext_body_photo",
      "drift_photo", 
      'int_diameter',
      'out_diameter', 
      'instruments', 
      'end_user'
    ],
    columns: [{
      slug: 'id',
      operator: 'equal',
      value: report.id
    }, 
    { slug: 'revision', operator: 'equal', value: revision }]
  }
}

export const getMatRequisitionMatModificationReportDataFromDayQuery = (report, revision, datasets) => {
  return {
    datasets: [datasets.material_requisition_mat_modification_data],
    logic_op: "AND",
    select: ["id",
      "wid",
      "mml_name",
      "mmlc_id",
      "tparty_name",
      "tparty_loc",
      "mr_ref",
      "ltpa",
      "closure_date",
      "quantity",
      "status",
      "co_purchase_order",
      "co_purchase_item",
      "num_sales_order",
      "num_sales_item",
      "cat_id",
      "valid",
      "it_status",
      "dsc_material",
      "insp_status",
      "mark_legibility_report",
      "pin_report",
      "box_report",
      "int_body_report",
      "ext_body_report",
      "drift_report",
      "final_report",
      "insp_responsible",
      "measure_status",
      "measured_lenght",
      "makeup_loss",
      "effective_lenght",
      "measure_responsible",
      "mark_defect",
      "pin_defect",
      "box_defect",
      "int_body_defect",
      "ext_body_defect",
      "drift_defect",
      "remarks",
      "mark_legibility_photo",
      "pin_photo",
      "box_photo",
      "int_body_photo",
      "ext_body_photo",
      "drift_photo", 
      "int_diameter",
      "out_diameter", 
      "instruments", 
      "end_user"
      ],
      columns: [{
        slug: 'id',
        operator: 'equal',
        value: report.id
      },       
      { slug: 'revision', operator: 'equal', value: revision }]
  }
}

export const getMatRequisitionMatPhysicalTransferReportDataFromDayQuery = (report, revision, datasets) => {
  return {
    datasets: [datasets.material_requisition_mat_physical_transfer_data],
    logic_op: "AND",
    select: [
      'id', 'wid', 'ptl_name', 'ptlc_id', 'tparty_name', 'tparty_loc', 'mr_ref', 'ltpa', 'closure_date', 'quantity', 'status', 'co_purchase_order', 'co_purchase_item', 'num_sales_order', 'num_sales_item', 'cat_id', 'valid', 'it_status', 'dsc_material',
      'insp_status', 'mark_legibility_report', 'pin_report', 'box_report', 'int_body_report', 'ext_body_report', 'drift_report', 'final_report', 'insp_responsible', 'measure_status', 'measured_lenght', 'makeup_loss', 'effective_lenght', 'measure_responsible', 'mark_defect', 
      'pin_defect', 'box_defect', 'int_body_defect', 'ext_body_defect', 'drift_defect', 'remarks', 'mark_legibility_photo', 'pin_photo', 'box_photo', 'int_body_photo', 'ext_body_photo', 'drift_photo', 'revision', 'int_diameter', 'out_diameter', 'instruments',  'end_user'],
      columns: [
        { slug: 'id', operator: 'equal', value: report.id },       
        { slug: 'revision', operator: 'equal', value: revision }
      ]
  }
}


/**
 * Gets pipe data from
 * @param valids: array of Valids to query
 */
export const getPipeDataQuery = (valids, datasets) => {
  return {
    datasets: [datasets.pipe_data],
    logic_op: 'OR',
    select: [
      'val_id',
      'cat_id',
      'num_sales_order',
      'num_sales_item',
      'co_purchase_order',
      'co_purchase_item',
      'heat',
      'id_quality_certificate',
      'pipe_length',
      'pipe_weight',
      'id_plant',
      'certificate_path'
    ],
    // todo: find out how to filter passing an array of valids
    columns: valids.map(valid => ({ slug: 'val_id', operator: 'equal', value: valid }))
  }
}

/**
 * Gets the nominal material information of the argument catId
 * @param sapReference: sapReference of the material
 */
export const getNominalDataQuery = (sapReference, datasets) => {
  return {
    datasets: [datasets.nominal_data],
    logic_op: 'AND',
    select: ['makeup_loss', 'revision', 'standard', 'drift', 'weight_nominal', 'material_type', 'dth_reg_update', 'dth_reg_criation', 'length_nominal', 'type_material', 'connection', 'grade', 'wt_nominal', 'od_nominal', 'dsc_material', 'material', 'id_plant', 'storage_type', 'serial_number'],
    columns: [{ slug: 'material', operator: 'equal', value: sapReference }]
  }
}

export const getReceivingInspectionDataQuery = (rlid, dscMaterial, rev, datasets) => {
  return {
    datasets: [datasets.receive_inspection_data],
    logic_op: 'AND',
    select: [
      'id',
      'closure_date',
      'customer_po',
      'customer_item',
      'num_sales_order',
      'num_sales_item',
      'dsc_material',
      'valid',
      'mark_legibility_report',
      'coating_varnish_report',
      'thread_prot_report',
      'pin_report',
      'box_report',
      'int_body_report',
      'ext_body_report',
      'drift_report',
      'final_report',
      'responsible',
      'mark_defect',
      'coat_varn_defect',
      'prot_defect',
      'pin_defect',
      'box_defect',
      'int_body_defect',
      'ext_body_defect',
      'drift_defect',
      'remarks',
      'mark_legibility_photo',
      'coating_varnish_photo',
      'thread_prot_photo',
      'pin_photo',
      'box_photo',
      'int_body_photo',
      'ext_body_photo',
      'drift_photo',
      'cat_id', 
      'instruments', 
      'end_user',
      'report_reference',
    ],
    columns: [
      { slug: 'id', operator: 'equal', value: rlid },
      { slug: 'dsc_material', operator: 'equal', value: dscMaterial },
      { slug: 'revision', operator: 'equal', value: rev }
    ]
  }
}

export const getReceivingInspectionArchivedDataQuery = (rlid, rev, datasets) => {
  return {
    datasets: [datasets.receive_inspection_data],
    logic_op: 'AND',
    select: [
      'id',
      'closure_date',
      'customer_po',
      'customer_item',
      'num_sales_order',
      'num_sales_item',
      'dsc_material',
      'valid',
      'mark_legibility_report',
      'coating_varnish_report',
      'thread_prot_report',
      'pin_report',
      'box_report',
      'int_body_report',
      'ext_body_report',
      'drift_report',
      'final_report',
      'responsible',
      'mark_defect',
      'coat_varn_defect',
      'prot_defect',
      'pin_defect',
      'box_defect',
      'int_body_defect',
      'ext_body_defect',
      'drift_defect',
      'remarks',
      'mark_legibility_photo',
      'coating_varnish_photo',
      'thread_prot_photo',
      'pin_photo',
      'box_photo',
      'int_body_photo',
      'ext_body_photo',
      'drift_photo',
      'cat_id', 
      'instruments', 
      'end_user',
      'report_reference',
    ],
    columns: [
      { slug: 'id', operator: 'equal', value: rlid },
      { slug: 'revision', operator: 'equal', value: rev }
    ]
  }
}


export const getPeriodicalInspectionDataQuery = (pilid, dscMaterial, rev, datasets) => {
  return {
    datasets: [datasets.periodical_inspection_data],
    logic_op: 'AND',
    select: [
      'id',
      'closure_date',
      'customer_po',
      'customer_item',
      'sales_order',
      'sales_item',
      'dsc_material',
      'valid',
      'mark_legibility_report',
      'coating_varnish_report',
      'thread_prot_report',
      'pin_report',
      'box_report',
      'int_body_report',
      'ext_body_report',
      'drift_report',
      'final_report',
      'responsible',
      'mark_defect',
      'coat_varn_defect',
      'prot_defect',
      'pin_defect',
      'box_defect',
      'int_body_defect',
      'ext_body_defect',
      'drift_defect',
      'remarks',
      'mark_legibility_photo',
      'coating_varnish_photo',
      'thread_prot_photo',
      'pin_photo',
      'box_photo',
      'int_body_photo',
      'ext_body_photo',
      'drift_photo',
      'cat_id',
      'entrance_id', 
      'instruments', 
      'end_user',
      'report_reference',
    ],
    columns: [
      { slug: 'id', operator: 'equal', value: pilid },
      { slug: 'dsc_material', operator: 'equal', value: dscMaterial },
      { slug: 'revision', operator: 'equal', value: rev }
    ]
  }
}

export const getPeriodicalInspectionArchivedDataQuery = (pilid, rev, datasets) => {
  return {
    datasets: [datasets.periodical_inspection_data],
    logic_op: 'AND',
    select: [
      'id',
      'closure_date',
      'customer_po',
      'customer_item',
      'sales_order',
      'sales_item',
      'dsc_material',
      'valid',
      'mark_legibility_report',
      'coating_varnish_report',
      'thread_prot_report',
      'pin_report',
      'box_report',
      'int_body_report',
      'ext_body_report',
      'drift_report',
      'final_report',
      'responsible',
      'mark_defect',
      'coat_varn_defect',
      'prot_defect',
      'pin_defect',
      'box_defect',
      'int_body_defect',
      'ext_body_defect',
      'drift_defect',
      'remarks',
      'mark_legibility_photo',
      'coating_varnish_photo',
      'thread_prot_photo',
      'pin_photo',
      'box_photo',
      'int_body_photo',
      'ext_body_photo',
      'drift_photo',
      'cat_id',
      'entrance_id', 
      'instruments',
      'report_reference',
    ],
    columns: [
      { slug: 'id', operator: 'equal', value: pilid },
      { slug: 'revision', operator: 'equal', value: rev }
    ]
  }
}

export const getRigReturnReportDataQuery = (rrlid, rev, datasets) => {
  return {
    datasets: [datasets.rig_return_report_data],
    logic_op: 'AND',
    select: [
      'closure_date',
      'field',
      'rig',
      'well',
      'rr_ref',
      'end_user',
      'nt_quantity',
      'co_purchase_order',
      'co_purchase_item',
      'num_sales_order',
      'num_sales_item',
      'dsc_material',
      'valid',
      'mark_legibility_report',
      'pin_report',
      'box_report',
      'int_body_report',
      'ext_body_report',
      'drift_report',
      'final_report',
      'responsible',
      'id',
      'cat_id',
      'mark_defect',
      'coat_varn_defect',
      'prot_defect',
      'pin_defect',
      'box_defect',
      'int_body_defect',
      'ext_body_defect',
      'drift_defect',
      'remarks',
      'mark_legibility_photo',
      'coating_varnish_photo',
      'thread_prot_photo',
      'pin_photo',
      'box_photo',
      'int_body_photo',
      'ext_body_photo',
      'drift_photo', 
      'instruments',
      'report_reference',
      'ippn',
    ],
    columns: [
      { slug: 'id', operator: 'equal', value: rrlid },
      { slug: 'revision', operator: 'equal', value: rev }
    ]
  }
}

export const getModifiedProductsDataQuery = (mplid, rev, datasets) => {
  return {
    datasets: [datasets.modified_products_report_data],
    logic_op: 'AND',
    select: [
      'id',
      'wid',
      'mpl_name',
      'mplc_id',
      'er_ref',
      'closure_date',
      'status',
      'cat_id',
      'valid',
      'it_status',
      'dsc_material',
      'insp_status', 
      'mark_legibility_report',
      'pin_report',
      'box_report',
      'int_body_report',
      'ext_body_report',
      'drift_report',
      'final_report',
      'insp_responsible',
      'measure_status', 
      'measured_lenght', 
      'makeup_loss',
      'effective_lenght',
      'measure_responsible',
      'mark_defect',
      'pin_defect',
      'box_defect',
      'int_body_defect',
      'ext_body_defect',
      'drift_defect',
      'mark_legibility_photo',
      'coating_varnish_photo',
      'thread_prot_photo',
      'pin_photo',
      'box_photo',
      'int_body_photo',
      'ext_body_photo',
      'drift_photo',
      'remarks',
      'int_diameter', 
      'out_diameter', 
      'instruments', 
      'owner',
      'report_reference',
    ],
    columns: [
      { slug: 'id', operator: 'equal', value: mplid },
      { slug: 'revision', operator: 'equal', value: rev }
    ]
  }
}

export const getSlowMovingDataQuery = (id, datasets) => {
  return {
    datasets: [datasets.slow_moving_pipes_report_data],
    logic_op: 'AND',
    select: [
      'id', 'trigger_date', 'ltpa', 'co_purchase_order', 'co_purchase_item', 'num_sales_item', 'num_sales_order', 'cat_id', 'dsc_material', 
    'ownership', 'entrance_date', 'months_in_stock', 'pipe_count', 'pipes_length', 'pipes_weight', 'storage_type', 'end_user', 'report_reference',
    ],
    columns: [ { slug: 'id', operator: 'equal', value: id }]
  }
}

export const getTitleTransferDataQuery = (id, datasets) => {
  return {
    datasets: [datasets.title_transfer_report_data],
    logic_op: 'AND',
    select: [
      'id', 'trigger_date', 'ltpa', 'co_purchase_order', 'co_purchase_item', 'num_sales_item', 'num_sales_order', 'cat_id', 'dsc_material', 
    'ownership', 'entrance_date', 'months_in_stock', 'pipe_count', 'pipes_length', 'pipes_weight', 'storage_type', 'end_user', 'report_reference',
    ],
    columns: [ { slug: 'id', operator: 'equal', value: id }]
  }
}

export const getMaterialPhysicalTransferDataQuery = (id, datasets) => {
  return {
    datasets: [datasets.material_physical_transfer_report_data],
    logic_op: 'AND',
    select: [
      'id', 'trigger_date', 'ltpa', 'co_purchase_order', 'co_purchase_item', 'num_sales_item', 'num_sales_order', 'cat_id', 'dsc_material', 
    'ownership', 'entrance_date', 'months_in_stock', 'pipe_count', 'pipes_length', 'pipes_weight', 'storage_type', 'end_user', 'report_reference'
    ],
    columns: [ { slug: 'id', operator: 'equal', value: id }]
  }
}