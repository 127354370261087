import React, { useContext, useState } from 'react'
import { toast } from 'react-toastify'

import _ from 'lodash'
import moment from 'moment'

import stock from 'api/stock'
import reportsApi from 'api/reports'
import receivingApi from 'api/receiving-inspection'
import receiptNotesApi from 'api/receipt-notes'
import rigReturnApi from 'api/rig-return'

import { reports_uri, internalReportsType, filenames } from 'utils/constants'
import { exportAsExcelFileMultiSheet, exportAsExcelFile } from '../utils/excel'
import { exportAsTablePdfFile } from 'utils/pdf'
import { useTranslation } from 'react-i18next'

import { UserContext } from './UserStore'
import { AppContext } from './AppStore'

const initialState = {
    isToExport: false,
    isPdf: false,
    initDate: undefined,
    endDate: undefined,
    value: undefined,
    modalLoading: false
}

export const ExportContext = React.createContext(initialState)

export const ExportProvider = ({ children }) => {

    const { idToken } = useContext(UserContext)

    const [modalLoading, setModalLoading] = useState(false)
    const [value, setValue] = useState(undefined)
    const [initDate, setInitDate] = useState(undefined)
    const [endDate, setEndDate] = useState(undefined)
    const [isPdf, setIsPdf] = useState(null)
    const [multiValue, setMultiValue] = useState([])
    const { t } = useTranslation()
    const { lengthUnit, weightUnit, convertToCurrentWeightUnitWithoutCulture, convertToCurrentLengthUnitWithoutCulture } = useContext(AppContext)

    const formatExcelExport = (data, columns) => {
        const columnsName = columns.map(item => item.name)
        let translate = []
        columnsName.forEach((column) => {
            translate.push(translate[column] = column)
        })
        const arrayToExcel = []
        data.forEach((row) => {
            const objRow = {}
            columns.forEach(column => {
                objRow[translate[column.name]] = row[column.prop]
            })
            arrayToExcel.push(objRow)
        })
        return arrayToExcel
    }

    const getKeyLabel = (key) => key.includes('length_ft') ? t(key) + '[ft]' :  key.includes('length_m') ? t(key) + '[m]' : key.includes('totalFootage') ? t(key) + ' [' + lengthUnit + ']' : key.includes('length') ? t(key) + '[' + lengthUnit + ']' : key.includes('weight') ? t(key) + '[' + weightUnit + ']' : t(key)
    const formatExcelValues = (data) => {
        const keys = Object.keys(data)
        if (keys && keys.length > 0) {
            return keys.map(key => ({ name: getKeyLabel(key), prop: key }))
        }
        return []
    }

    const formatPDFValues = (data) => {
        const keys = Object.keys(data)
        if (keys && keys.length > 0) {
            return keys.map(key => ({ accessor: key, label: getKeyLabel(key) }))
        }
        return []
    }

    const formatExcelMultiSheets = (stockData, receivingInspectionData, deliveryNotesData, rigReturnData, materialRequisitionData, batchEntranceData, rackData, pipeAgeData) => {
        let sheets = []
        if (stockData.tableData.length > 0) {
            const columnsValue = formatExcelValues(stockData.tableData[0])
            sheets.push({ name: 'Stock Level Report', data: formatExcelExport(stockData.tableData, columnsValue) })
        }
        if (rackData.length > 0) {
            const columnsValue = formatExcelValues(rackData[0])
            sheets.push({ name: 'Rack Distribution', data: formatExcelExport(rackData, columnsValue) })
        }
        if (receivingInspectionData && receivingInspectionData.length > 0) {
            const columnsValue = formatExcelValues(receivingInspectionData[0])
            sheets.push({ name: 'Receiving Inspection', data: formatExcelExport(receivingInspectionData, columnsValue) })
        }
        if (rigReturnData && rigReturnData.length > 0) {
            const columnsValue = formatExcelValues(rigReturnData[0])
            sheets.push({ name: 'Rig Return List', data: formatExcelExport(rigReturnData, columnsValue) })
        }
        if (materialRequisitionData && materialRequisitionData.length > 0) {
            const columnsValue = formatExcelValues(materialRequisitionData[0])
            sheets.push({ name: 'Material Req. Inspection List', data: formatExcelExport(materialRequisitionData, columnsValue) })
        }
        if (batchEntranceData && batchEntranceData.length > 0) {
            const columnsValue = formatExcelValues(batchEntranceData[0])
            sheets.push({ name: 'Batch Entrance List', data: formatExcelExport(batchEntranceData, columnsValue) })
        }
        if (deliveryNotesData && deliveryNotesData.length > 0) {
            const columnsValue = formatExcelValues(deliveryNotesData[0])
            sheets.push({ name: 'Delivery Notes', data: formatExcelExport(deliveryNotesData, columnsValue) })
        }
        if (pipeAgeData && pipeAgeData.length > 0) {
            const columnsValue = formatExcelValues(pipeAgeData[0])
            sheets.push({ name: 'Pipe Age List', data: formatExcelExport(pipeAgeData, columnsValue) })
        }
        return sheets
    }

    const getReceivingInspectionList = async (wid) => { //Receiving Inspection
        try {
            const params = { hasClosureDate: false, wid: wid }
            const response = await receivingApi.getReceivingInspectionList(params, idToken)
            const formattedData = formatReceivingInspections(response)
            return formattedData
        } catch (error) {
            throw new Error(error)
        }
    }

    const getStockData = async (stck) => {
        try {
            // TODO: remove this hardcoded service stock url
            const data = await stock.getStockData('service-stock-adnoc', stck.wid, idToken)
            const formattedData = formatStockData(data)
            formattedData.tableData.forEach(data => {
                delete data.totalFootage_ft
                delete data.totalFootage_m
            })
            return formattedData
        } catch (error) {
            throw new Error(error)
        }
    }

    const getHistoricalStockData = async (stck, stockDate) => {
        try {
            const stockDateString = stockDate.format('YYYY-MM-DD')
            const data = await stock.getDailyServiceStock({ stock: stck.key, date: stockDateString, wid: stck.wid }, idToken)
            return data
        } catch (error) {
            throw new Error(error)
        }
    }

    const formatStockData = stockData => ({
        ...stockData,
        tableData: _.map(stockData.tableData, item => ({
            ...item,
            valids: item.valids.join(', '),
            totalFootage: (convertToCurrentLengthUnitWithoutCulture(item.totalFootage_m, item.totalFootage_ft, lengthUnit)),
            total_weight: (convertToCurrentWeightUnitWithoutCulture(Number(item.total_weight), weightUnit))
        }))
    })

    const getRackData = async (wid) => {
        try {
            const data = await stock.getRackData(wid, idToken)
            const formattedData = formatRackData(data)
            return formattedData
        } catch (error) {
            throw new Error(error)
        }
    }

    const formatRackData = data => data.rackData.map(item => ({ ...item, valids: item.valids.join(', ') })) || []

    const formatReceivingInspections = receivingInspections => _.chain(receivingInspections) //Receiving Inspection
        .orderBy('inspection_date', 'desc')
        .value()

    const getDeliveryNotesData = async (wid) => { // DeliveryNotes
        try {
            const params = { hasClosureDate: false, wid: wid }
            const response = await reportsApi.getDeliveryNotesList(reports_uri.GET_DELIVERY_NOTES, params, idToken)
            const formattedData = formatDeliveryNotes(response)
            return formattedData
        } catch (error) {
            throw new Error(error)
        }
    }

    const formatDeliveryNotes = (deliveryNotes) => _.chain(deliveryNotes) // Delivery Notes
        .orderBy('closure_date', 'desc')
        .value()

    const getRigReturnReportList = async (wid) => { // Rig Return
        try {
            const params = { hasClosureDate: false, wid: wid }
            const response = await rigReturnApi.getRigReturnList(params, idToken)
            const formattedData = formatRigReturnReportList(response)
            return formattedData
        } catch (error) {
            throw new Error(error)
        }
    }

    const formatRigReturnReportList = reports => _.orderBy(reports, 'closure_date', 'desc')

    const getMaterialRequisitionReportList = async (wid) => {
        try {
            const params = { hasClosureDate: false, wid: wid }
            const response = await reportsApi.getMaterialRequisitionList(reports_uri.GET_MATERIAL_REQUISITION, params, idToken)
            const formattedData = formatMaterialRequisitionList(response)
            return formattedData
        } catch (error) {
            throw new Error(error)
        }
    }

    const formatMaterialRequisitionList = reports => _.chain(reports)
        .orderBy('closure_date', 'desc')
        .value()

    const getBatchEntranceReportList = async (wid) => {
        try {
            const params = { hasClosureDate: false, wid: wid }
            const response = await receiptNotesApi.getReceiptNotesList(params, idToken)
            const formattedData = formatBatchEntranceReportList(response)
            return formattedData
        } catch (error) {
            throw new Error(error)
        }
    }

    const formatBatchEntranceReportList = reports => _.chain(reports)
        .orderBy('entrance_date', 'desc')
        .value()

    const getPipeAgeList = async (wid) => {
        try {
            const response = await stock.getPipeAgeList(reports_uri.GET_PIPE_AGE, { wid: wid }, idToken)
            const formattedData = formatPipeAgeList(response.tableData)
            return formattedData
        } catch (error) {
            throw new Error(error)
        }
    }

    const formatPipeAgeList = reports => _.chain(reports)
        .orderBy('entrance_date', 'desc')
        .value()

    const downloadBackup = async (stock) => {
        try {
            const [stockData, receivingInspectionData, deliveryNotesData, rigReturnData, rigPrepData, batchEntranceData, rackData, pipeAgeData] =
                await Promise.all([getStockData(stock), getReceivingInspectionList(stock.wid), getDeliveryNotesData(stock.wid), getRigReturnReportList(stock.wid), getMaterialRequisitionReportList(stock.wid), getBatchEntranceReportList(stock.wid), getRackData(stock.wid), getPipeAgeList(stock.wid)])

            const allReportsList = [stockData, receivingInspectionData, deliveryNotesData, rigReturnData, rigPrepData, batchEntranceData, rackData, pipeAgeData]
            const listsWithData = allReportsList.filter(item => item?.length > 0) || []
            if(listsWithData?.length === 0) {
                throw new Error('No Data available')
            }


            const workbook = formatExcelMultiSheets(...allReportsList)
            if (workbook && workbook.length > 0) {
                exportAsExcelFileMultiSheet(workbook, filenames.INVENTORY_BACKUP + moment(new Date()).format('DD_MM_YYYY_HH_mm_ss'))
            }
        }
        catch (error) {
            console.log(error)
            toast.error(error.message)
        }
    }


    const exportToPdf = async (name, data, title = null) => {
        const header = {
            title: title ? title : name + '_' + moment(new Date()).format('DD_MM_YYYY_HH_mm_ss'),
            subTitle: ' ',
            logo: 'Smart'
        }
        const columnsConfig = formatPDFValues(data[0])
        const headers = await _.map(columnsConfig, 'label')
        const body = _.map(data, row => (
            _.reduce(columnsConfig, (acc, { accessor, formatter }) => {
                const value = _.get(row, accessor)
                return [
                    ...acc,
                    value ? (formatter ? formatter(value) : value) : '-'
                ]
            }, [])
        ))

        const reportData = [headers, ...body]
        const options = {
            pageOrientation: 'landscape',
            pageSize: 'A3',
            watermark: false,
            zebraStyle: true
        }

        exportAsTablePdfFile(header, reportData, options)
    }

    const getStockLevelInternalReportsData = async (type, stock) => {
        try {
            const response = await getHistoricalStockData(stock, initDate)
            //  reportsApi.getStockLevelInternalReportsData(reports_uri.GET_STOCK_LEVEL_REPORTS, { initDate: moment(new Date(initDate)).format('YYYY-MM-DD'), endDate: moment(new Date(endDate)).format('YYYY-MM-DD'), type: value }, idToken)
            if (response && response.length > 0) {
                const formattedData = formatStockReports(response)
                const fileName = filenames.STOCK_LEVEL_INTERNAL_REPORT + '_' + moment(new Date()).format('DD_MM_YYYY_HH_mm_ss') + '_FROM_' + moment(initDate).format('DD_MM_YYYY')
                if (isPdf) {    
                    const newFormattedData = formattedData.map(item => {
                        return _.omit(item, ['total_length_ft', 'total_length_m'])
                    })       
                    await exportToPdf(type, newFormattedData, fileName)
                }
                else{
                    const newFormattedData = formattedData.map(item => {
                        return _.omit(item, ['total_length_ft', 'total_length_m'])
                    })
                    exportAsExcelFile(formatExcelExport(newFormattedData, formatExcelValues(formattedData[0])), fileName)
                }
            }
            else {
                toast.warn(t('There isn\'t data for the selected period'))
            }

        } catch (error) {
          toast.warn(t('There isn\'t data for the selected period'))
        }
    }

    const getConsumptionInternalReportsData = async (type) => {
        try {
            const response = await reportsApi.getConsumptionInternalReportsData(reports_uri.GET_CONSUMPTION_INTERNAL_REPORTS, { initDate: moment(new Date(initDate)).format('YYYY-MM-DD'), endDate: moment(new Date(endDate)).format('YYYY-MM-DD'), type: multiValue }, idToken)
            if (response && response.length > 0) {
                const formattedData = await formatInternalReports(response)
                if (isPdf){
                    const newFormattedData = formattedData.map(item => {
                        return _.omit(item, ['total_length_ft', 'total_length_m'])
                    })   
                    await exportToPdf(type + '_' + multiValue, newFormattedData)
                }
                else{
                    const newFormattedData = formattedData.map(item => {
                        return _.omit(item, ['total_length_ft', 'total_length_m'])
                    })   
                    exportAsExcelFile(formatExcelExport(newFormattedData, formatExcelValues(newFormattedData[0])), filenames.CONSUMPTION + multiValue + '_' + moment(new Date()).format('DD_MM_YYYY_HH_mm_ss'))
                }
            }
            else {
                toast.warn(t('There isn\'t data for the selected period'))
            }

        } catch (error) {
            throw new Error(error)
        }
    }

    const getReceiptInternalReportsData = async (type) => {
        try {
            const response = await reportsApi.getReceiptInternalReportsData(reports_uri.GET_RECEIPT_INTERNAL_REPORTS, { initDate: moment(new Date(initDate)).format('YYYY-MM-DD'), endDate: moment(new Date(endDate)).format('YYYY-MM-DD') }, idToken)
            if (response && response.length > 0) {
                const formattedData = formatInternalReports(response)
                if (isPdf){
                    const newFormattedData = formattedData.map(item => {
                        return _.omit(item, ['total_length_ft', 'total_length_m'])
                    })   
                  
                    await exportToPdf(type, newFormattedData)
                }
                else{
                    const newFormattedData = formattedData.map(item => {
                        return _.omit(item, ['total_length_ft', 'total_length_m'])
                    })   
                    exportAsExcelFile(formatExcelExport(newFormattedData, formatExcelValues(newFormattedData[0])), filenames.RECEIPT_NOTES + '_' + moment(new Date()).format('DD_MM_YYYY_HH_mm_ss'))
                }
            }
            else {
                toast.warn(t('There isn\'t data for the selected period'))
            }
        } catch (error) {
            throw new Error(error)
        }
    }

    const formatStockReports = reports => _.chain(reports)
        .map((item) => ({
          sapReference: item.sapReference,
          productType: item.productType,
          nominalOD: item.nominalOd,
          nominalWeight: item.nominalWeight,
          grade: item.grade,
          connection: item.connection,
          couplingOption: item.couplingOption,
          drift: item.drift,
          nominalLength: item.nominalLength,
          modifiedProductDesc: item.modifiedProductDesc,
          qtdPC: item.qtdPc ? Math.round(item.qtdPc, 0) : 
                 item.qtdPC ? Math.round(item.qtdPC, 0) : '',
          dscStatusMaterial: item.dscStatusMaterial,
          rackLocation: item.rackLocation,
          totalFootage: (convertToCurrentLengthUnitWithoutCulture(item.totalFootage_m, item.totalFootage_ft, lengthUnit)),
          total_weight: item.totalWeight ? (convertToCurrentWeightUnitWithoutCulture(Number(item.totalWeight), weightUnit)) :
                        item.total_weight ? (convertToCurrentWeightUnitWithoutCulture(Number(item.total_weight), weightUnit)) : '', 
          endUser: item.endUser,
          owner_name: item.ownerName,
          dscMaterial: item.dscMaterial,
          makeupLoss: item.makeupLoss,
          nominalWT: item.nominalWt,
          nominalODExt: item.nominalOdExt,
          nominalWeightExt: item.nominalWeightExt,
          gradeExt: item.gradeExt,
          connectionExt: item.connectionExt,
          couplingOptionExt: item.couplingOptionExt,
          makeupLossExt: item.makeupLossExt,
          nominalWTExt: item.nominalWtExt,
          standard: item.standard,
          revision: item.revision,
        }))
        .orderBy('entrance_date', 'desc')
        .value()

    const formatInternalReports = reports => _.chain(reports)
        .map((item) => ({
            ...item,
            pipe_count: Math.round(item.pipe_count, 0),
            total_weight: convertToCurrentWeightUnitWithoutCulture(Number(item.total_weight), weightUnit),
            total_length: convertToCurrentLengthUnitWithoutCulture(item.total_length_m, item.total_length_ft, lengthUnit)
        }))
        .orderBy('entrance_date', 'desc')
        .value()

    const exportInternalReports = async (type, stock) => {
        try {
            type === internalReportsType.STOCK_LEVEL ? await getStockLevelInternalReportsData(type, stock) :
                type === internalReportsType.CONSUMPTION ? await getConsumptionInternalReportsData(type) :
                    await getReceiptInternalReportsData(type)
        }
        catch (error) {
            console.log(error)
            toast.error(error.message)
        }
    }

    const renderStore =
        <ExportContext.Provider value={{
            downloadBackup,
            exportInternalReports,
            modalLoading, setModalLoading,
            value, setValue,
            initDate, setInitDate,
            endDate, setEndDate,
            isPdf, setIsPdf,
            multiValue, setMultiValue
        }}>
            {children}
        </ExportContext.Provider>

    return renderStore
}
