import axios from 'axios'
import { decompressResponse } from '../utils/decompress'
import { addLogger } from 'utils/logger'
import Cookies from 'js-cookie'

const shouldDecompress = false

const instance = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}`,
    headers: {
        'Content-Type': 'application/json', 
        'VLR-Authorization': btoa(JSON.stringify({ "id": process.env.REACT_APP_ID }))
    }
})

addLogger(instance, shouldDecompress)
const isSuccessfulResponse = (response) => response && response.status === 200

const manageResponse = (response, shouldDecompress) => {
    if (isSuccessfulResponse(response)) {
        if (shouldDecompress) {
            return decompressResponse(response.data)
        }
        return response.data.body ? response?.data?.body : response.data
    } else {
        throw new Error('Couldn\'t retrieve data')
    }
}

export default {
    getPipeDataInfo: (params = {}) => new Promise((resolve, reject) => {
        instance.post(
            `/pipe-data/get-info`,
            params, {
            headers: {
                Authorization: Cookies.get("_ssoToken")
            }
        }
        ).then(response => {
            resolve(manageResponse(response, shouldDecompress))
        })
            .catch(error => {
                reject(error)
            })
    })
}