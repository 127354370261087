import axios from 'axios'
import { addLogger } from 'utils/logger'
import { getDefaultHeader, manageError, manageResponse } from './api-util'

const SHOULD_DECOMPRESS = false

const instance = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}`,
    headers: {
        'Content-Type': 'application/json', 
        'VLR-Authorization': btoa(JSON.stringify({ "id": process.env.REACT_APP_ID }))
    }
})

addLogger(instance, false)

export default {
    getPeriodicalInspectionList: async (params, idToken) => {
        try {
            const data = await instance.post('/periodical-inspection/get-periodical-inspection-list', params, getDefaultHeader())
            return manageResponse(data, SHOULD_DECOMPRESS)
        } catch (error) {
            manageError(error)
        }
    },
    getPeriodicalInspectionData: async (params, idToken) => {
        try {
            const data = await instance.post('/periodical-inspection/get-periodical-inspection-data', params, getDefaultHeader())
            return manageResponse(data, SHOULD_DECOMPRESS)
        } catch (error) {
            manageError(error)
        }
    }
}