const RECEIPT_NOTES_HEADER_LABEL_CONFIG = [
    { id: "id", label: "RECEIPT NOTE ID", cellPosition: "A5", mergedCells: "A5:B5" },
    { id: "date", label: "ENTRANCE DATE", cellPosition: "A6", mergedCells: "A6:B6" },
    { id: "num_purchase_order", label: "SALES PO#", cellPosition: "A7", mergedCells: "A7:B7" },
    { id: "num_purchase_item", label: "SALES ITEM#", cellPosition: "A8", mergedCells: "A8:B8" },
    { id: "co_sales_order", label: "CUSTOMER PO#", cellPosition: "A9", mergedCells: "A9:B9" },
    { id: "co_sales_item", label: "CUSTOMER ITEM#", cellPosition: "A10", mergedCells: "A10:B10" },
    { id: "end_user", label: "END USER", cellPosition: "A11", mergedCells: "A11:B11" },
    { id: "quantity_prep", label: "Total Joints", cellPosition: "A12", mergedCells: "A12:B12" },
    { id: "total_length", label: "Total Measured Length [ft]", cellPosition: "A13", mergedCells: "A13:B13" },
    { id: "total_weight", label: "Total Weight [kg]", cellPosition: "A14", mergedCells: "A14:B14" },
]

const RECEIPT_NOTES_HEADER_CONTENT_CONFIG = [
    { id: "id", accessor: "id", cellPosition: "C5", mergedCells: "C5:C5" },
    { id: "date", accessor: "date", cellPosition: "C6", mergedCells: "C6:C6" },
    { id: "num_sales_order", accessor: "num_sales_order", cellPosition: "C7", mergedCells: "C7:C7" },
    { id: "num_sales_item", accessor: "num_sales_item", cellPosition: "C8", mergedCells: "C8:C8" },
    { id: "co_purchase_order", accessor: "co_purchase_order", cellPosition: "C9", mergedCells: "C9:C9" },
    { id: "co_purchase_item", accessor: "co_purchase_item", cellPosition: "C10", mergedCells: "C10:C10" },
    { id: "end_user", accessor: "end_user", cellPosition: "C11", mergedCells: "C11:C11" },
    { id: "quantity_prep", accessor: "quantity_prep", cellPosition: "C12", mergedCells: "C12:C12" },
    { id: "total_length", accessor: "total_length", cellPosition: "C13", mergedCells: "C13:C13" },
    { id: "total_weight", accessor: "total_weight", cellPosition: "C14", mergedCells: "C14:C14" }
]

const RECEIPT_NOTES_DATA_LABEL_CONFIG = [
    { id: "item_no", label: "ITEM NO", cellPosition: "A16", mergedCells: "A16:A17" },
    { id: "erp_ref", label: "ERP REFERENCE", cellPosition: "B16", mergedCells: "B16:B17" },
    { id: "dsc_material", label: "MATERIAL", cellPosition: "C16", mergedCells: "C16:C17" },
    { id: "val_id", label: "VALID", cellPosition: "D16", mergedCells: "D16:D17" },
    { id: "ippn", label: "IPPN", cellPosition: "E16", mergedCells: "E16:E17" },
    { id: "pipe_length", label: "MEASURED LENGTH [ft]", cellPosition: "F16", mergedCells: "F16:F17" },
    { id: "pipe_weight", label: "WEIGHT [kg]", cellPosition: "G16", mergedCells: "G16:G17" },
]

const RECEIPT_NOTES_DATA_CONTENT_CONFIG = [
    { id: "item_no", accessor: "item_no"},
    { id: "erp_ref", accessor: "erp_ref"},
    { id: "dsc_material", accessor: "dsc_material"},
    { id: "val_id", accessor: "val_id"},
    { id: "ippn", accessor: "ippn" },
    { id: "pipe_length", accessor: "pipe_length"},
    { id: "pipe_weight", accessor: "pipe_weight"},
]

const RECEIPT_NOTES_LOGO_POSITION = 'F2:G8'
const RECEIPT_NOTES_DATA_CONTENT_ROW = 18

export const RECEIPT_NOTES_CONFIG = {
    RECEIPT_NOTES: {
        headerLabelConfig: RECEIPT_NOTES_HEADER_LABEL_CONFIG,
        headerContentConfig: RECEIPT_NOTES_HEADER_CONTENT_CONFIG,
        dataLabelConfig: RECEIPT_NOTES_DATA_LABEL_CONFIG,
        dataContentConfig: RECEIPT_NOTES_DATA_CONTENT_CONFIG,
        dataContentRow: RECEIPT_NOTES_DATA_CONTENT_ROW,
        dataLogoRow: RECEIPT_NOTES_LOGO_POSITION
    }
}