import _ from 'lodash'
import React, { useCallback, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'

const initialState = {
  filteredData: [], 
  filters: {}
}

export const FilterContext = React.createContext(initialState)

export const FilterProvider = ({ children }) => {
  const history = useHistory()

  const [ filters, setFilters ] = useState({})
  const [ data, setData ] = useState({})

  history.listen((location, action) => {
    init()
  })

  const init = useCallback(()=> {
   
  }, [])  

  const filteredData = useMemo(() => {
    const filteredTableData = _.filter(data, item => {
      let match = true
      _.chain(filters)
      .pickBy(filter => filter.length > 0)
      .each((filter, key) => {
        const itemValue = _.get(item, key)
        match = match && _.includes(filter, itemValue)
      })
      .value()
      return match
    })

    return {
      ...data,
      filteredTableData
    }
  }, [data, filters])
  
  return <FilterContext.Provider value={{
    ...initialState,
    data, setData,
    filteredData,
    filters, setFilters
  }}>
    {children}
  </FilterContext.Provider>
}