export default {
    development: {
        rig_return_report_data: 'RigReturnInspectionReport-V12',
        modified_products_report_data: 'ModifiedProductInspectionReport-V7',
        receive_inspection_data: 'ReceivingInspectionReport-V15',
        periodical_inspection_data: 'PeriodicInspectionReport-V9',
        mill_receipt: 'millReceipt_template_v15',
        material_requisition_report: 'material_request_rig_prep_inspection_report_V6',
        material_requisition_delivery_third_party_report: 'material_request_delivery_third_party_inspection_report_V7',
        material_requisition_mat_modification_report: 'material_request_material_modification_inspection_report_V6',
        material_requisition_mat_physical_transfer_report: 'material_requisition_material_physical_transfer_report-V6',
        slow_moving_report_data: 'SlowMoving-V5', 
        title_transfer_report_data: 'TitleTransfer-V5', 
        material_physical_transfer_report_data: 'PhysicalTransfer-V5'
    },
    qas: {
        rig_return_report_data: 'RigReturnInspectionReport-V12',
        modified_products_report_data: 'ModifiedProductInspectionReport-V7',
        receive_inspection_data: 'ReceivingInspectionReport-V15',
        periodical_inspection_data: 'PeriodicInspectionReport-V9',
        mill_receipt: 'millReceipt_template_v15',
        material_requisition_report: 'material_request_rig_prep_inspection_report_V6',
        material_requisition_delivery_third_party_report: 'material_request_delivery_third_party_inspection_report_V7',
        material_requisition_mat_modification_report: 'material_request_material_modification_inspection_report_V6',
        material_requisition_mat_physical_transfer_report: 'material_requisition_material_physical_transfer_report-V6',
        slow_moving_report_data: 'SlowMoving-V5', 
        title_transfer_report_data: 'TitleTransfer-V5', 
        material_physical_transfer_report_data: 'PhysicalTransfer-V5'
    },
    staging: {
        rig_return_report_data: 'RigReturnInspectionReport-V12',
        modified_products_report_data: 'ModifiedProductInspectionReport-V7',
        receive_inspection_data: 'ReceivingInspectionReport-V15',
        periodical_inspection_data: 'PeriodicInspectionReport-V9',
        mill_receipt: 'millReceipt_template_v15',
        material_requisition_report: 'material_request_rig_prep_inspection_report_V6',
        material_requisition_delivery_third_party_report: 'material_request_delivery_third_party_inspection_report_V7',
        material_requisition_mat_modification_report: 'material_request_material_modification_inspection_report_V6',
        material_requisition_mat_physical_transfer_report: 'material_requisition_material_physical_transfer_report-V6',
        slow_moving_report_data: 'SlowMoving-V5', 
        title_transfer_report_data: 'TitleTransfer-V5', 
        material_physical_transfer_report_data: 'PhysicalTransfer-V5'
    },
    production: {
        rig_return_report_data: 'RigReturnInspectionReport-V12',
        modified_products_report_data: 'ModifiedProductInspectionReport-V7',
        receive_inspection_data: 'ReceivingInspectionReport-V15',
        periodical_inspection_data: 'PeriodicInspectionReport-V9',
        mill_receipt: 'millReceipt_template_v15',
        material_requisition_report: 'material_request_rig_prep_inspection_report_V6',
        material_requisition_delivery_third_party_report: 'material_request_delivery_third_party_inspection_report_V7',
        material_requisition_mat_modification_report: 'material_request_material_modification_inspection_report_V6',
        material_requisition_mat_physical_transfer_report: 'material_requisition_material_physical_transfer_report-V6',
        slow_moving_report_data: 'SlowMoving-V5', 
        title_transfer_report_data: 'TitleTransfer-V5', 
        material_physical_transfer_report_data: 'PhysicalTransfer-V5'
    }
}