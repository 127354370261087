import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { toast } from 'react-toastify'

import styled from 'styled-components'
import _ from 'lodash'

import schema from 'utils/validator/schemas/plannedStockInput'

import { FlexView } from '../common'

import { AppContext } from 'stores/AppStore'
import { UserContext } from 'stores/UserStore'
import { exportErrorsToSheet, xlsxValidator } from 'utils/validator/xlsxSchemaValidation'
import { useTranslation } from 'react-i18next'
import { BlockedStockContext } from 'stores/BlockedStockStore'
import { DialogModal } from 'components/form'

const getColor = (props) => {
  if (props.isDragAccept) {
    return props.theme.colors.success
  }
  if (props.isDragReject) {
    return props.theme.colors.error
  }
  if (props.isDragActive) {
    return props.theme.colors.primary
  }
  return props.theme.colors.lightGray
}

const Container = styled(FlexView)`
  font-family: 'Roboto';
  flex-wrap: nowrap;
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  justify-content: center;
  margin: 16px 16px 16px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${props => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  min-width: 20rem;
  transition: border .24s ease-in-out;

  &:hover {
    border-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.primary};
  }
`
const UploadBox = ({ text, fileTypes, height, setFile, hasSaveButton, fontSize = 3 }) => {
  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ accept: fileTypes, multiple: false, noDragEventsBubbling: true })
  // '.xls, .xlsx, .xltx, .xlsm, .xltm, .xlam, .xlsb, .csv'
  const { setPageLoading } = useContext(AppContext)
  const { idToken } = useContext(UserContext)
  const { setSchemaErrors, setUploadData, saveUploadedData, file } = useContext(BlockedStockContext)
  const { t } = useTranslation()
  const [isOpen, setIsOpen ] = useState(false)

  useEffect(() => {
    async function receiveFile() {
      try {
        if (!_.isEmpty(acceptedFiles) && _.isEmpty(file)) {
          if (acceptedFiles.length && acceptedFiles[0].size <= 314572800) {
              setPageLoading(true)
              setFile(acceptedFiles[0])
              // const type = { "schemaType": uploadType }
              // const schema = await api.getValidationSchema(type, idToken)
              if(schema){
                  xlsxValidator(acceptedFiles[0], (result) => {
                    if (result.errors.length === 0) {
                      setUploadData(result.data)
                      setSchemaErrors([])
                      setPageLoading(false)
                      if(!hasSaveButton){
                        toggleModal()
                      }
                    }
                    else {
                      if(hasSaveButton){
                        setSchemaErrors(result.errors)
                      }
                      else{
                        exportErrorsToSheet(result.errors)
                      } 
                      acceptedFiles.length = 0
                      setFile([])
                      setUploadData([])
                      setPageLoading(false)
                      toast.error(t('Upload Error Message'))
                    }
                  }, schema)
              }
          }
        }
      }
      catch (error) {
        toast.error(error.message)
        setPageLoading(false)
      }
    }
    receiveFile()
  }, [acceptedFiles, file, hasSaveButton, idToken, setFile, setPageLoading, setSchemaErrors, setUploadData, t])
       
  const toggleModal = () => setIsOpen(currentState => !currentState)

  const onCancel = useCallback(async (e) => {
    setIsOpen(false)
    acceptedFiles.length = 0
    setFile([])
  }, [acceptedFiles, setFile])

  const onConfirm = useCallback(async (e) => {
    setIsOpen(false)
    acceptedFiles.length = 0
    await saveUploadedData()
    setFile([])
  }, [acceptedFiles, saveUploadedData, setFile])

  return <>
          <Container {...getRootProps({ isDragActive, isDragAccept, isDragReject, onClick: event => console.log(event) })} style={ { paddingTop: `${height/2}px`, paddingBottom:  `${height/2}px` }} >
                    <input {...getInputProps()} />
                    {<font size={fontSize} > <p> <b> {text} </b>  </p></font>}
                    {/* {isDragReject && t("Sorry, this file type is not permitted!")} */}
          </Container>
          <DialogModal isOpen={ isOpen } title={t('Confirm')} onConfirm={onConfirm} text={t('confirmUpload')} onCancel={ onCancel } />
        </>
}

export default UploadBox