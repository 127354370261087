import React, { useContext, useMemo, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import _ from 'lodash'

import api from 'api/api'
import kpiApi from 'api/kpi'

import { Card, FlexView, LoadingOverlay, Spinner } from 'components/common'

import AdminGrid from 'containers/kpis/AdminGrid'
import CustomerGrid from 'containers/kpis/CustomerGrid'
import KpiHeader from 'containers/kpis/KpiHeader'
import StockTabs from 'containers/layout/StockTabs'

import { AppContext } from 'stores/AppStore'
import { KpiContext } from 'stores/KpiStore'
import { UserContext } from 'stores/UserStore'
import { WarehouseContext } from 'stores/WarehouseStore'

import { kpi_uri } from '../utils/constants'

const KpisGlobalView = ({ stockKey }) => {
  const { setPageSubtitle, setPageLoading, pageLoading } = useContext(AppContext)
  const { warehouses } = useContext(WarehouseContext)
  const { kpis, adminKpis, isAdmin, endUsers, 
          setEndUser, endUser, inputEndUsers, 
          wid, kpiType, includeFieldsAdmKpiArray, 
          setTotalAndMaxScore, includeFieldsKpiArray, 
          setEndUsers, setWid 
        } = useContext(KpiContext)
  const { idToken } = useContext(UserContext)
  
  const stock = useMemo(() => _.find(warehouses, { key: stockKey }), [stockKey, warehouses])

  const { t } = useTranslation()

  useEffect(() => {
    if (stock) { 
      setWid(stock.wid)
      setPageSubtitle(stock?.title + ' - ' + t('KPI Global View'))
    }
  }, [stock, setPageSubtitle, t, setWid])

  useEffect(() => {
    async function fetchData(){
        try {
            if(stock){
                setPageLoading(true)
                const response = await api.getAvailableEndUsers({ "wid": stock.wid }, idToken)
                if(response){
                    setEndUsers(response.endUsers)
                    setPageLoading(false)
                }
            }  
        }
        catch(error){
            setPageLoading(false)
            toast.error(error.message)
        }
    }
    fetchData() 
  }, [stock]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if(endUsers  && endUsers.length > 0){
      setEndUser(endUsers[0].id_global)
    }
  }, [endUsers, setEndUser])

  useEffect(() => {
    if(inputEndUsers  && inputEndUsers.length > 0){
      if(!endUser)
        setEndUser(inputEndUsers[0].value)
    }
  }, [inputEndUsers, endUser, setEndUser, wid])

  useEffect(() => {
    async function fetchData(){
        try {
            if(stock){
                setPageLoading(true)
                const response = await api.getAvailableEndUsers({ "wid": stock.wid }, idToken)
                if(response){
                    setEndUsers(response.endUsers)
                    setPageLoading(false)
                }
            }  
        }
        catch(error){
            setPageLoading(false)
            toast.error(error.message)
        }
    }
    fetchData() 
  }, [stock]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if(endUsers  && endUsers.length > 0){
      setEndUser(endUsers[0].id_global)
    }
  }, [endUsers, setEndUser])

  useEffect(() => {
    if(inputEndUsers  && inputEndUsers.length > 0){
      if(!endUser)
        setEndUser(inputEndUsers[0].value)
    }
  }, [inputEndUsers, endUser, setEndUser, wid])

  const getData = useCallback(async () => {
    try {
      if(stock && endUser && kpiType){
        setPageLoading(true)
        const responseKpis = await kpiApi.getKpis(kpi_uri.GET_CURRENT_KPIS, { kpiType: kpiType, customerId: endUser, wid: stock.wid  }, idToken)
        setTotalAndMaxScore(responseKpis.kpis)
        includeFieldsKpiArray(responseKpis.kpis.sort((a, b) => a.idOrder - b.idOrder))
        const responseAdminKpis = await kpiApi.getKpis(kpi_uri.GET_ADMIN_KPIS, { kpiType: kpiType, customerId: endUser, wid: stock.wid }, idToken)
        includeFieldsAdmKpiArray(responseAdminKpis.kpis.sort((a, b) => a.idOrder - b.idOrder), responseKpis.kpis)
        setPageLoading(false)
      }
    }
    catch (error) {
      setPageLoading(false)
      throw new Error(error)
    }
    finally {
      setPageLoading(false)
    }
  }, [endUser, idToken, kpiType, stock ]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if(endUser)
      getData()
  }, [endUser, getData])
    
  return ( 
  <FlexView alignSelf="stretch" data-cy="flex-page">
    <StockTabs stock={stock} data-cy="menu-tab" />
    <Card margin="16px" padding="16px" alignSelf="stretch" data-cy="menu-tab">
      <KpiHeader isAdmin={isAdmin} hasAdmin={true} data-cy="kpi-header" ></KpiHeader>
      <LoadingOverlay visible={pageLoading} />
      {pageLoading && <FlexView width="100%" padding="32px 0px" alignItems="center">
        <Spinner size="32px" />
      
      </FlexView>}
      { !pageLoading && (isAdmin
        ? <AdminGrid adminKpis={adminKpis || null}/>
        : <CustomerGrid kpis={kpis || null} />
      )}
    </Card>
  </FlexView>)
}
export default KpisGlobalView