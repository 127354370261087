import React, { useContext, useMemo, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import _ from 'lodash'

import { LoadingOverlay, FlexView, Icon, Button } from 'components/common'
import StockTable from 'containers/stock/StockTable'

import UnitSelect from 'containers/common/UnitSelect'
import ExportModal from 'containers/common/ExportModal'
import StockTabs from 'containers/layout/StockTabs'
import ChartsCard from 'containers/stock/ChartsCard'
import FilterDisplay from 'containers/stock/FilterDisplay'
import FiltersModal from 'containers/stock/FiltersModal'

import { AppContext } from 'stores/AppStore'
import { ExportContext } from 'stores/ExportStore'
import { StockContext } from 'stores/StockStore'
import { WarehouseContext } from 'stores/WarehouseStore'

import { internalReportsType, STOCK_ROUTES } from 'utils/constants'
import Cookies from 'js-cookie'
import { useCallback } from 'react'
import { functionalities } from 'utils/warehouse-config/functionalities'
import moment from 'moment'
import { useHistory, useRouteMatch } from 'react-router-dom'

const StockPage = ({ stockKey }) => {
  const { t } = useTranslation()

  const { setPageSubtitle, lengthUnit, convertToCurrentLengthUnit, 
          convertToCurrentWeightUnit, weightUnit } = useContext(AppContext)
  const { getStockData, exportToExcel, exportToPdf, getPipeNominalData, getPipeData, 
        filteredStockData: { tableData } } = useContext(StockContext)

  const { downloadBackup } = useContext(ExportContext)
  const { warehouses } = useContext(WarehouseContext)

  const { url } = useRouteMatch()
  const history = useHistory()

  const stock = useMemo(() => _.find(warehouses, { key: stockKey }), [stockKey, warehouses])
  const [loading, setLoading] = useState(true)
  const [showModal, setShowModal] = useState(false)
  const [showExportModal, setShowExportModal] = useState(false)
  // const filterOptions = useStockFilters(stock.legacyKey|| 'DEFAULT')
  const [isBackupDownloading, setIsBackupDownloading] = useState(false)

  const toggleModal = () => setShowModal(currentState => !currentState)
  const toggleExportModal = () => setShowExportModal(currentState => !currentState)
  const downloadExcelBackup  = () => setIsBackupDownloading(true)

  const { setIsPdf } = useContext(ExportContext)

  const hasPipeDataFunctionality = useMemo(() => stock?.functionalities.filter(func => func.id === functionalities.PIPE_DATA.id).length > 0, [stock])

  const getRoute = () => stock.isLegacy 
                         ? STOCK_ROUTES[stock.legacyKey]                    
                         : 'service-stock-adnoc' 
                         
  const maxOwnershipLevel = useMemo(() => Math.max(...tableData.map(data => data.maxOwnershipLevel)), [tableData])

  

  useEffect(() => {
    if (stock) {
      Cookies.set('_currentWarehouse', stockKey)
      setPageSubtitle(stock?.title + ' - ' + t('Service Stock'))
    }
  }, [stock, setPageSubtitle, t, stockKey])

  const data = useMemo(() => (
    _.chain(tableData)
      .groupBy((item)=>`${item.sapReference}--${item.endUser}--${item.dscMaterial}`)
      .map((value, key) => ({
        sapReference: value[0].sapReference,
        dscMaterial: value[0].dscMaterial,
        qtdPC: _.sumBy(value, ({ qtdPC }) => Number(qtdPC)),
        totalFootage: _.sumBy(value, ({totalFootage}) => Number(totalFootage)),
        totalFootage_m: _.sumBy(value, ({ totalFootage_m }) => Number(totalFootage_m)),
        totalFootage_ft: _.sumBy(value, ({ totalFootage_ft }) => Number(totalFootage_ft)),
        total_weight: _.sumBy(value, ({total_weight}) => Number(total_weight)),
        endUser: value[0].endUser,
        children: value || []
      }))
    .value()
  ), [tableData])

  useEffect(() => {
    setLoading(true)
    getStockData(getRoute(), stock.wid).catch(e => {
      console.log(e)
    }).finally(() => setLoading(false))
  }, [stock, getStockData ]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isBackupDownloading) {
      setLoading(true)
      downloadBackup(stock).catch(e => {
        console.log(e)
      }).finally(() => {
        setLoading(false)
        setIsBackupDownloading(false)
      })
    }
  }, [isBackupDownloading, downloadBackup, stock])

  const onExcelExportClick = () => {
    if(stock.isLegacy){
      exportToExcel(columns, stock.key)
    }
    else {
      setShowExportModal(prev => !prev)
    }
  }

  const onPdfExportClick = () => {
    if(stock.isLegacy){
      exportToPdf(columns, stock.key)
    }
    else {
      setIsPdf(true)
      setShowExportModal(prev => !prev)
    }
      
  }

  
  const onDetailClick = useCallback(({ valids, sapReference, endUserId }) => () => {
    const baseRoute = url.split('/')[1]
    getPipeNominalData(sapReference, endUserId)
    getPipeData(valids, sapReference, stock.wid)
    history.push(`/${baseRoute}/pipe-data`)
  }, [url, getPipeNominalData, getPipeData, history]) // eslint-disable-line react-hooks/exhaustive-deps

  const columns = useMemo(() => [
    {
      Header: t('SAP Reference'),
      accessor: 'sapReference',
      label: t('SAP Reference'),
      Cell: ({ cell: { value, row } }) => (
        <FlexView
          flexDirection="row"
          alignItems="center"
          justifyContent="flex-start"
          fontSize="13px"
          padding="16px 0px"
          {...row.getToggleRowExpandedProps({
            style: {
              paddingLeft: `${row.depth * 32}px`,
              cursor: row.canExpand ? 'pointer' : 'inherit'
            },
            title: ''
          })}
        >
          {row.canExpand && <Icon name={row.isExpanded ? 'chevron-up' : 'chevron-down'} width="12px" height="12px" margin="0px 4px 0px 8px" />}
          {value === 'null' ? t('Not Found') : value}
          {!row.canExpand &&
          <Button fontSize="12px" padding="4px 8px" margin="0px 0px 0px 8px" color="secondary" onClick={onDetailClick(row.original)} disabled={!hasPipeDataFunctionality}>{t('Details')}</Button>}
        </FlexView>
      ),
      customCellProps: {
        style: {
          padding: '0px'
        }
      }, 
      show: true
    },
    {
      Header: t('End User'),
      label: t('End User'),
      accessor: 'endUser',
      customHeaderProps: {
        style: {
          minWidth: 50
        }
      }, 
      show: true
    },
    {
      Header: t('Material Description'),
      label: t('Material Description'),
      accessor: 'dscMaterial',
      customHeaderProps: {
        style: {
          minWidth: 350
        }
      }, 
      show: true
    },
    // {
    //   Header: t('Ownership'),
    //   accessor: 'owner_name',
    //   customCellProps: {
    //     style: {
    //       textAlign: 'center'
    //     }
    //   },
    //   disableFilters: true
    // },
    {
      Header: t('Ownership Level 1'),
      accessor: 'ownerLevel1',
      label: t('Ownership Level 1'),
      customCellProps: {
        style: {
          textAlign: 'center'
        }
      },
      disableFilters: true,
      show: maxOwnershipLevel >= 1 ? true : false
    },
    {
      Header: t('Ownership Level 2'),
      label: t('Ownership Level 2'),
      accessor: 'ownerLevel2',
      customCellProps: {
        style: {
          textAlign: 'center'
        }
      },
      disableFilters: true,
      show: maxOwnershipLevel >= 2 ? true : false
    },
    {
      Header: t('Ownership Level 3'),
      label: t('Ownership Level 3'),
      accessor: 'ownerLevel3',
      customCellProps: {
        style: {
          textAlign: 'center'
        }
      },
      disableFilters: true,
      show: maxOwnershipLevel >= 3 ? true : false
    },
    {
      Header: t('Ownership Level 4'),
      label: t('Ownership Level 4'),
      accessor: 'ownerLevel4',
      customCellProps: {
        style: {
          textAlign: 'center'
        }
      },
      disableFilters: true,
      show: maxOwnershipLevel >= 4 ? true : false
    },
    {
      Header: t('Ownership Level 5'),
      label: t('Ownership Level 5'),
      accessor: 'ownerLevel5',
      customCellProps: {
        style: {
          textAlign: 'center'
        }
      },
      disableFilters: true,
      show: maxOwnershipLevel >= 5 ? true : false
    },
    {
      Header: t('Ownership Level 6'),
      label: t('Ownership Level 6'),
      accessor: 'ownerLevel6',
      customCellProps: {
        style: {
          textAlign: 'center'
        }
      },
      disableFilters: true,
      show: maxOwnershipLevel >= 6 ? true : false
    },
    {
      Header: t('Ownership Level 7'),
      label: t('Ownership Level 7'),
      accessor: 'ownerLevel7',
      customCellProps: {
        style: {
          textAlign: 'center'
        }
      },
      disableFilters: true,
      show: maxOwnershipLevel >= 7 ? true : false
    },
    {
      Header: t('Ownership Level 8'),
      label: t('Ownership Level 8'),
      accessor: 'ownerLevel8',
      customCellProps: {
        style: {
          textAlign: 'center'
        }
      },
      disableFilters: true,
      show: maxOwnershipLevel >= 8 ? true : false
    },
    {
      Header: t('Ownership Level 9'),
      label: t('Ownership Level 9'),
      accessor: 'ownerLevel9',
      customCellProps: {
        style: {
          textAlign: 'center'
        }
      },
      disableFilters: true,
      show: maxOwnershipLevel >= 9 ? true : false
    },
    {
      Header: t('Ownership Level 10'),
      label: t('Ownership Level 10'),
      accessor: 'ownerLevel10',
      customCellProps: {
        style: {
          textAlign: 'center'
        }
      },
      disableFilters: true,
      show: maxOwnershipLevel >= 10 ? true : false
    },
    {
      Header: t('Entrance Date'),
      // label: t('Entrance Date'),
      accessor: ({ entranceDate }) => entranceDate ? moment(entranceDate).format('MMM D, YYYY') : '',
      // Cell: ({ cell: { value } }) => value ? moment(value).format('MMM D, YYYY') : '',
      customCellProps: {
        style: {
          textAlign: 'center'
        }
      },
      sortType: 'date',
      disableFilters: true, 
      show: true
    },
    {
      Header: t('Pipe Age') + ' (' + t('Months') + ')',
      accessor: 'pipeAge',
      label: t('Pipe Age') + ' (' + t('Months') + ')',
      customCellProps: {
        style: {
          textAlign: 'center'
        }
      },
      disableFilters: true, 
      show: true
    },
    {
      Header: t('Material Status'),
      id: 'dscStatusMaterial',
      accessor: ({ dscStatusMaterial, feature }) => dscStatusMaterial && t(`stock.materialStatus.${dscStatusMaterial}`).toUpperCase() + (feature ?  `${' ' + t('for').toUpperCase() + ' ' + t(feature).toUpperCase()}` : ''),
      customCellProps: {
        style: {
          textAlign: 'center'
        }
      },
      disableFilters: true, 
      show: true
    },
    {
      Header: t('Pieces'),
      accessor: 'qtdPC',
      label: t('Pieces'),
      customCellProps: {
        style: {
          textAlign: 'center'
        }
      }, 
      show: true
    },
    {
      Header: t('Total Length') + ` (${lengthUnit})`,
      accessor: ({ totalFootage_m, totalFootage_ft }) => `${convertToCurrentLengthUnit(totalFootage_m, totalFootage_ft, lengthUnit)}`,
      customCellProps: {
        style: {
          textAlign: 'center'
        }
      }, 
      show: true
    },
    {
      Header: `${t('Total Weight') + ` (${weightUnit})`}`,
      accessor: ({ total_weight }) => `${convertToCurrentWeightUnit(total_weight, 'kg')}`,
      customCellProps: {
        style: {
          textAlign: 'center'
        }
      }, 
      show: true
    }
  ]
  .filter(column => column.show === true)
  , [ t, lengthUnit, weightUnit, hasPipeDataFunctionality, onDetailClick, convertToCurrentLengthUnit, 
      convertToCurrentWeightUnit, maxOwnershipLevel ])

  return <FlexView flex="1" position="relative" alignSelf="stretch">
    <StockTabs stock={stock} />
    <FlexView margin="16px 16px 8px" flexDirection="row" justifyContent="space-between" alignSelf="stretch">
      <FilterDisplay options={columns} onTagClick={toggleModal} />
      <FlexView flexDirection="row" alignItems="center" justifyContent="flex-end" flex="1">
        <UnitSelect />
        {stock.hasExcelBackup
          ? <Icon name="download" width="28px" height="28px" margin="0px 8px 0px 0px" tooltip={t('Download Backup Information')} onClick={downloadExcelBackup} />
          : null
        }
        <Icon name="filter" width="28px" height="28px" margin="0px 8px 0px 0px" tooltip={t('Filter')} onClick={toggleModal} />
        <Icon name="xls" width="28px" height="28px" margin="0px 8px 0px 0px" tooltip={t('Export to Excel')} onClick={onExcelExportClick} />
        <Icon name="pdf" width="28px" height="28px" margin="0px 0px 0px 0px" tooltip={t('Export to PDF')} onClick={onPdfExportClick} />
      </FlexView>
    </FlexView>
    <ChartsCard />
    <StockTable columns={columns} data={data} />
    <FiltersModal isOpen={showModal} onOutsideClick={toggleModal} options={columns} data={tableData}/>
    <ExportModal title={t('Export Service Stock Level')} label={t('Choose the extraction date')+':'}  
                 type={ internalReportsType.STOCK_LEVEL } isOpen={showExportModal} 
    stock={stock} onOutsideClick={toggleExportModal} />
    <LoadingOverlay visible={loading} />
  </FlexView>
}

export default StockPage