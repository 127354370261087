export const ITEM_STATUS = {
    literal: [
        'OK',
        'REJECT',
        'GOOD',
        '',
        'REPAIR',
        'SCRAP',
        'NOT APPLICABLE'
    ],
    code: {
        UNSET: -1,
        OK: 0,
        REJECT: 1,
        GOOD: 2,
        REPAIR: 4,
        SCRAP: 5,
        NA: 6
    }
}

export const REPORT_STATUS = {
    literal: [
        'NOT_STARTED',
        'RUNNING',
        'STAND_BY',
        'DONE'
    ],
    code: {
        NOT_STARTED:0,
        RUNNING: 1,
        STAND_BY: 2,
        DONE: 3
    }
}