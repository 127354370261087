const PIPE_AGE_HEADER_LABEL_CONFIG = [
    { id: "id", label: "Report ID", cellPosition: "A5", mergedCells: "A5:B5" },
    { id: "date", label: "Trigger Date", cellPosition: "A6", mergedCells: "A6:B6" },
    { id: "ltpa", label: "LTPA Number", cellPosition: "A7", mergedCells: "A7:B7" }
]

const PIPE_AGE_HEADER_CONTENT_CONFIG = [
    { id: "id", accessor: "id", cellPosition: "C5", mergedCells: "C5:C5" },
    { id: "date", accessor: "trigger_date", cellPosition: "C6", mergedCells: "C6:C6" },
    { id: "ltpa", accessor: "ltpa", cellPosition: "C7", mergedCells: "C7:C7" }
]

const PIPE_AGE_DATA_LABEL_CONFIG = [
    { id: "item_no", label: "ITEM NO", cellPosition: "A9", mergedCells: "A9:A10" },

    { id: "co_purchase_order", label: "CUSTOMER PO#", cellPosition: "B9", mergedCells: "B9:B10" },
    { id: "co_purchase_item", label: "CUSTOMER ITEM#", cellPosition: "C9", mergedCells: "C9:C10" },
    { id: "num_sales_order", label: "SALES PO#", cellPosition: "D9", mergedCells: "D9:D10" },
    { id: "num_sales_item", label: "SALES ITEM#", cellPosition: "E9", mergedCells: "E9:E10" },

    { id: "cat_id", label: "ERP REFERENCE", cellPosition: "F9", mergedCells: "F9:F10" },
    { id: "dsc_material", label: "MATERIAL", cellPosition: "G9", mergedCells: "G9:G10" },
    { id: "consignment_type", label: "CONSIGNMENT TYPE", cellPosition: "H9", mergedCells: "H9:H10" }, 

    { id: "entrance_date", label: "ENTRANCE DATE", cellPosition: "I9", mergedCells: "I9:I10"},
    { id: "pipe_age", label: "PIPE AGE (Months)", cellPosition: "J9", mergedCells: "J9:J10"},
    { id: "total_joints", label: "TOTAL JOINTS", cellPosition: "K9", mergedCells: "K9:K10"},
    { id: "total_length", label: "TOTAL LENGTH [ft]", cellPosition: "L9", mergedCells: "L9:L10"},
    { id: "total_weight", label: "TOTAL WEIGHT [kg]", cellPosition: "M9", mergedCells: "M9:M10"}
]

const PIPE_AGE_DATA_CONTENT_CONFIG = [
    { id: "item_no", accessor: "item_no"},
    { id: "co_purchase_order", accessor: "co_purchase_order"},
    { id: "co_purchase_item", accessor: "co_purchase_item"},
    { id: "num_sales_order", accessor: "num_sales_order"},
    { id: "num_sales_item", accessor: "num_sales_item"},
    { id: "cat_id", accessor: "cat_id"},
    { id: "dsc_material", accessor: "dsc_material"},
    { id: "consignment_type", accessor: "consignment_type"},
    { id: "entrance_date", accessor: "entrance_date"},
    { id: "pipe_age", accessor: "pipe_age"},
    { id: "total_joints", accessor: "total_joints"},
    { id: "total_length", accessor: "total_length"},
    { id: "total_weight", accessor: "total_weight"}
]

const PIPE_AGE_LOGO_POSITION = 'K1:M7'
const PIPE_AGE_DATA_CONTENT_ROW = 11

export const PIPE_AGE_CONFIG = {
    PIPE_AGE: {
        headerLabelConfig: PIPE_AGE_HEADER_LABEL_CONFIG,
        headerContentConfig: PIPE_AGE_HEADER_CONTENT_CONFIG,
        dataLabelConfig: PIPE_AGE_DATA_LABEL_CONFIG,
        dataContentConfig: PIPE_AGE_DATA_CONTENT_CONFIG,
        dataContentRow: PIPE_AGE_DATA_CONTENT_ROW,
        dataLogoRow: PIPE_AGE_LOGO_POSITION
    }
}