import { MODIFIED_PRODUCTS_HEADER_STYLE } from './header'

const MODIFIED_PRODUCTS_HEADER_CONFIG = [
    { id: 'id', label: 'REPORT ID', cellPosition: 'A5', mergedCells: 'A5:A5' },
    { id: 'date', label: 'DATE', cellPosition: 'A6', mergedCells: 'A6:A6' },
    { id: 'rr_number', label: 'RR NUMBER', cellPosition: 'A7', mergedCells: 'A7:A7' },
    { id: 'equipment', label: 'EQUIPMENT', cellPosition: 'A8', mergedCells: 'A8:A8' },
    { id: 'end_user', label: 'END USER', cellPosition: 'A9', mergedCells: 'A9:A9' }
]

const MODIFIED_PRODUCTS_HEADER_CONTENT_CONFIG = [
    { id: 'id', accessor: 'id', cellPosition: 'B5', mergedCells: 'B5:B5' },
    { id: 'date', accessor: 'date', cellPosition: 'B6', mergedCells: 'B6:B6' },
    { id: 'rr_number', accessor: 'rr_number', cellPosition: 'B7', mergedCells: 'B7:B7' },
    { id: 'equipment', accessor: 'equipment', cellPosition: 'B8', mergedCells: 'B8:B8' },
    { id: 'end_user', accessor: 'end_user', cellPosition: 'B9', mergedCells: 'B9:B9' }
]

const MODIFIED_PRODUCTS_SECOND_HEADER_CONFIG = [
    { id: 'qty_inspected', label: 'Qty Inspected', cellPosition: 'F7', mergedCells: 'F7:I7' },
    { id: 'total_inspected', label: 'Inspected', cellPosition: 'F8', mergedCells: 'F8:F8' },
    { id: 'total_accepted', label: 'Accepted', cellPosition: 'G8', mergedCells: 'G8:G8' },
    { id: 'total_rejected', label: 'Rejected', cellPosition: 'H8', mergedCells: 'H8:H8' },
    { id: 'total_repair', label: 'To Repair', cellPosition: 'I8', mergedCells: 'I8:I8' },
    { id: 'qty_measured', label: 'Qty Measured', cellPosition: 'J7', mergedCells: 'J7:M7' },
    { id: 'measured_total_length', label: 'MEASURED TOTAL LENGTH (ft)', cellPosition: 'J8', mergedCells: 'J8:K8' },
    { id: 'effective_total_length', label: 'EFFECTIVE TOTAL LENGTH (ft)', cellPosition: 'L8', mergedCells: 'L8:M8' }
]

const MODIFIED_PRODUCTS_SECOND_HEADER_CONTENT_CONFIG = [
    { id: 'total_inspected', accessor: 'total_inspected', cellPosition: 'F9', mergedCells: 'F9:F9' },
    { id: 'total_accepted', accessor: 'total_accepted', cellPosition: 'G9', mergedCells: 'G9:G9' },
    { id: 'total_rejected', accessor: 'total_rejected', cellPosition: 'H9', mergedCells: 'H9:H9' },
    { id: 'total_repair', accessor: 'total_repair', cellPosition: 'I9', mergedCells: 'I9:I9' },
    { id: 'measured_total_length', accessor: 'measured_total_length', cellPosition: 'J9', mergedCells: 'J9:K9' },
    { id: 'effective_total_length', accessor: 'effective_total_length', cellPosition: 'L9', mergedCells: 'L9:M9' }
]

const MODIFIED_PRODUCTS_DATA_HEADER_CONFIG = [
    { id: 'item_no', label: 'NO', cellPosition: 'A11', mergedCells: 'A11:A12' },
    { id: 'cat_id', label: 'ERP REFERENCE', cellPosition: 'B11', mergedCells: 'B11:B12' },
    { id: 'dsc_material', label: 'MATERIAL', cellPosition: 'C11', mergedCells: 'C11:C12' },
    { id: 'val_id', label: 'VALID', cellPosition: 'D11', mergedCells: 'D11:D12' },
    { id: 'mark_legibility', label: 'MARKING LEGIBILITY', cellPosition: 'E11', mergedCells: 'E11:E12' },
    { id: 'thread', label: 'THREAD', cellPosition: 'F11', mergedCells: 'F11:G11' },
    { id: 'thread_pin', label: 'PIN', cellPosition: 'F12', mergedCells: 'F12:F12' },
    { id: 'thread_box', label: 'BOX', cellPosition: 'G12', mergedCells: 'G12:G12' },
    { id: 'pipe', label: 'PIPE BODY', cellPosition: 'H11', mergedCells: 'H11:I11' },
    { id: 'pipe_int', label: 'INTERNAL', cellPosition: 'H12', mergedCells: 'H12:H12' },
    { id: 'pipe_ext', label: 'EXTERNAL', cellPosition: 'I12', mergedCells: 'I12:I12' },
    { id: 'drift', label: 'DRIFT', cellPosition: 'J11', mergedCells: 'J11:J12' },
    { id: 'final_report', label: 'FINAL STATUS', cellPosition: 'K11', mergedCells: 'K11:K12' },
    { id: 'inspector', label: 'INSPECTOR', cellPosition: 'L11', mergedCells: 'L11:L12' },
    { id: 'root_cause', label: 'ROOT CAUSE', cellPosition: 'M11', mergedCells: 'M11:M12' },
    { id: 'pic_ref', label: 'PICTURE REFERENCE', cellPosition: 'N11', mergedCells: 'N11:N12' },
    { id: 'length', label: 'LENGTH MEASUREMENT', cellPosition: 'O11', mergedCells: 'O11:U11' },
    { id: 'status', label: 'STATUS', cellPosition: 'O12', mergedCells: 'O12:O12' },
    { id: 'measured_length', label: 'MEASURED LENGTH (FT)', cellPosition: 'P12', mergedCells: 'P12:P12' },
    { id: 'makeup_loss', label: 'MAKEUP LOSS (FT)', cellPosition: 'Q12', mergedCells: 'Q12:Q12' },
    { id: 'effective_length', label: 'EFFECTIVE LENGTH (FT)', cellPosition: 'R12', mergedCells: 'R12:R12' },
    { id: 'int_diameter', label: 'INTERNAL DIAMETER [ID]', cellPosition: 'S12', mergedCells: 'S12:S12' },
    { id: 'out_diameter', label: 'EXTERNAL DIAMETER [OD]', cellPosition: 'T12', mergedCells: 'T12:T12' },
    { id: 'responsible', label: 'RESPONSIBLE', cellPosition: 'U12', mergedCells: 'U12:U12' },
    { id: 'remarks', label: 'REMARKS', cellPosition: 'V11', mergedCells: 'V11:V12' },
]

const MODIFIED_PRODUCTS_DATA_HEADER_CONTENT_CONFIG = [
    { id: 'item_no', accessor: 'item_no', cellPosition: 'A13', mergedCells: 'A13:A13' },
    { id: 'cat_id', accessor: 'cat_id', cellPosition: 'B13', mergedCells: 'B13:B13' },
    { id: 'dsc_material', accessor: 'dsc_material', cellPosition: 'C13', mergedCells: 'C13:C13' },
    { id: 'val_id', accessor: 'val_id', cellPosition: 'D13', mergedCells: 'D13:D13' },
    { id: 'mark_legibility', accessor: 'mark_legibility', cellPosition: 'E13', mergedCells: 'E13:E13' },
    { id: 'thread_pin', accessor: 'thread_pin', cellPosition: 'F13', mergedCells: 'F13:F13' },
    { id: 'thread_box', accessor: 'thread_box', cellPosition: 'G13', mergedCells: 'G13:G13' },
    { id: 'pipe_int', accessor: 'pipe_int', cellPosition: 'H13', mergedCells: 'H13:H13' },
    { id: 'pipe_ext', accessor: 'pipe_ext', cellPosition: 'I13', mergedCells: 'I13:I13' },
    { id: 'drift', accessor: 'drift', cellPosition: 'J13', mergedCells: 'J13:J13' },
    { id: 'final_report', accessor: 'final_report', cellPosition: 'K13', mergedCells: 'K13:K13' },
    { id: 'inspector', accessor: 'inspector', cellPosition: 'L13', mergedCells: 'L13:L13' },
    { id: 'root_cause', accessor: 'root_cause', cellPosition: 'M13', mergedCells: 'M13:M13' },
    { id: 'pic_ref', accessor: 'pic_ref', cellPosition: 'N13', mergedCells: 'N13:N13' },
    { id: 'status', accessor: 'status', cellPosition: 'O13', mergedCells: 'O13:O13' },
    { id: 'measured_length', accessor: 'measured_length', cellPosition: 'P13', mergedCells: 'P13:P13' },
    { id: 'makeup_loss', accessor: 'makeup_loss', cellPosition: 'Q13', mergedCells: 'Q13:Q13' },
    { id: 'effective_length', accessor: 'effective_length', cellPosition: 'R13', mergedCells: 'R13:R13' },
    { id: 'int_diameter', accessor: 'int_diameter', cellPosition: 'S13', mergedCells: 'S13:S13' },//CHECK if is better '0' or ''
    { id: 'out_diameter', accessor: 'out_diameter', cellPosition: 'T13', mergedCells: 'T13:T13' },
    { id: 'responsible', accessor: 'responsible', cellPosition: 'U13', mergedCells: 'U13:U13' },
    { id: 'remarks', accessor: 'remarks', cellPosition: 'V13', mergedCells: 'V13:V13' },
]

const MODIFIED_PRODUCTS_IMAGE_SHEET_CONFIG = [
    { id: 'valid', label: 'VALID', cellPosition: 'A1', mergedCells: 'A1:A1' },
    { id: 'picture_reference', label: 'PICTURE REFERENCE', cellPosition: 'B1', mergedCells: 'B1:B1' },
    { id: 'picture', label: 'PICTURE', cellPosition: 'C1', mergedCells: 'C1:C1' }
]

const MODIFIED_PRODUCTS_IMAGE_SHEET_CONTENT_CONFIG = [
    { id: 'valid', accessor: 'valid' },
    { id: 'picture_reference', accessor: 'picture_reference' },
    { id: 'picture', accessor: 'picture' }
]

const MODIFIED_PRODUCTS_LOGO_POSITION = 'H2:M6'
const MODIFIED_PRODUCTS_CONTENT_ROW = 13
const MODIFIED_PRODUCTS_IMAGE_CONTENT_ROW = 2

export const MODIFIED_PRODUCTS_CONFIG = {
    MODIFIED_PRODUCTS: {
        headerLabelConfig: MODIFIED_PRODUCTS_HEADER_CONFIG,
        headerContentConfig: MODIFIED_PRODUCTS_HEADER_CONTENT_CONFIG,
        secondHeaderLabelConfig: MODIFIED_PRODUCTS_SECOND_HEADER_CONFIG,
        secondHeaderContentConfig: MODIFIED_PRODUCTS_SECOND_HEADER_CONTENT_CONFIG,
        secondHeaderLabelStyle: MODIFIED_PRODUCTS_HEADER_STYLE.MP_LABEL,
        secondHeaderContentStyle: MODIFIED_PRODUCTS_HEADER_STYLE.MP_CONTENT,
        dataLabelConfig: MODIFIED_PRODUCTS_DATA_HEADER_CONFIG,
        dataContentConfig: MODIFIED_PRODUCTS_DATA_HEADER_CONTENT_CONFIG,
        dataContentRow: MODIFIED_PRODUCTS_CONTENT_ROW,
        dataLogoRow: MODIFIED_PRODUCTS_LOGO_POSITION,
        headerImageSheetConfig: MODIFIED_PRODUCTS_IMAGE_SHEET_CONFIG,
        dataImageContentConfig: MODIFIED_PRODUCTS_IMAGE_SHEET_CONTENT_CONFIG,
        dataImageContentRow: MODIFIED_PRODUCTS_IMAGE_CONTENT_ROW
    }
}