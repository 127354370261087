import axios from 'axios'

import { addLogger } from 'utils/logger'
import { getDefaultHeader, manageError, manageResponse } from './api-util'

const SHOULD_DECOMPRESS = false

const instance = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}`,
    headers: {
        'Content-Type': 'application/json', 
        'VLR-Authorization': btoa(JSON.stringify({ "id": process.env.REACT_APP_ID }))
    }
})

addLogger(instance, false)

export default {
    getWarehouseConfig: async (params, idToken) => {
        try {
            const response = 
            await instance.post('/warehouses/get', params, getDefaultHeader())
           return manageResponse(response, SHOULD_DECOMPRESS)
        } catch (error) {
            manageError(error)
        }
    },
    getWmsWarehouses: async (params, idToken) => {
        try {
            const response = await instance.post('/warehouses/get-wms', params, getDefaultHeader())
            return manageResponse(response, SHOULD_DECOMPRESS)
        } catch (error) {
            manageError(error)
        }
    },
    getFunctionalities: async (params, idToken) => {
        try {
            const response = await instance.post('/warehouses/get-functionalities', params, getDefaultHeader())
            return manageResponse(response)
        } catch (error) {
            manageError(error)
        }
    },
    createWarehouse: async (params, idToken) => {
        try {
            const response = await instance.post('/warehouses/create', params, getDefaultHeader())
            return manageResponse(response, SHOULD_DECOMPRESS)
        } catch (error) {
            manageError(error)
        }
    },
    updateWarehouse: async (params, idToken) => {
        try {
            const response = await instance.post('/warehouses/update', params, getDefaultHeader())
            return manageResponse(response, SHOULD_DECOMPRESS)
        } catch (error) {
            manageError(error)
        }
    },
    deleteWarehouse: async (params, idToken) => {
        try {
            const response = await instance.post('/warehouses/delete', params, getDefaultHeader())
            return manageResponse(response, SHOULD_DECOMPRESS)
        } catch (error) {
            manageError(error)
        }
    }
}