import axios from 'axios'
import { addLogger } from 'utils/logger'
import { getDefaultHeader, manageError, manageResponse } from './api-util'

const SHOULD_DECOMPRESS = false

const instance = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}`,
    headers: {
        'Content-Type': 'application/json', 
        'VLR-Authorization': btoa(JSON.stringify({ "id": process.env.REACT_APP_ID }))
    }
})

addLogger(instance, false)

export default {
    getArchivedReportList: async (params, idToken) => {
        try {
            const data = await instance.post('/archived-report/get-list', params, getDefaultHeader(idToken))
            return manageResponse(data, SHOULD_DECOMPRESS)
        } catch (error) {
            manageError(error)
        }
    }, 
    hideReport: async (params, idToken) => {
        try {
            const data = await instance.post('/archived-report/hide-report', params, getDefaultHeader(idToken))
            return manageResponse(data, SHOULD_DECOMPRESS)
        } catch (error) {
            manageError(error)
        }
    }, 
    revealReport: async (params, idToken) => {
        try {
            const data = await instance.post('/archived-report/reveal-report', params, getDefaultHeader(idToken))
            return manageResponse(data, SHOULD_DECOMPRESS)
        } catch (error) {
            manageError(error)
        }
    }, 
    getAvailableReports: async (params, idToken) => {
        try {
            const data = await instance.post('/archived-report/get-available-reports', params, getDefaultHeader(idToken))
            return manageResponse(data, SHOULD_DECOMPRESS)
        } catch (error) {
            manageError(error)
        }
    }, 
    getReportInfo: async (params, idToken) => {
        try {
            const data = await instance.post('/archived-report/get-report-info', params, getDefaultHeader(idToken))
            return manageResponse(data, SHOULD_DECOMPRESS)
        } catch (error) {
            manageError(error)
        }
    }
}