import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import apiArchivedReports from 'api/archived-reports'

import _ from 'lodash'
import moment from 'moment'
import styled from 'styled-components'

import { Button, Card, FlexView, Icon, LoadingOverlay } from 'components/common'
import FilterableTable from 'components/common/FilterableTable'
import { Select } from 'components/form'

import ReportVisibilityModal from 'containers/common/ReportVisibilityModal'
import StockTabs from 'containers/layout/StockTabs'

import { AppContext } from 'stores/AppStore'
import { UserContext } from 'stores/UserStore'

import { hiddenReportsStatus } from 'utils/constants'
import api from 'api/api'
import { WarehouseContext } from 'stores/WarehouseStore'
import FilterDisplay from 'containers/common/FilterDisplay'
import { FilterContext } from 'stores/FilterStore'
import FiltersModal from 'containers/common/FiltersModal'
// import { getArchivedReportData } from 'utils/archivedReportsUtil'
// import { getFileName } from 'utils/helpers'
// import documentManagement from 'api/document-management'
// import { generateSpreadsheet } from 'provider/excelGeneration'

const ArchiveButton = styled(Button)`
font-family: 'Roboto';
background: none;
border-radius: 0px;
border: none;
box-shadow: none;
margin-top: 20px;
color: gray; 
font-weight: bold; 
margin: 0px;  

&:active {
  opacity: 0.4;
}
`

const FlexStatus = styled(FlexView)`
    font-family: 'Roboto';
    font-size: 0.9rem;
`

const PageHeader = styled(FlexView)`
   flex-direction: row;
   justify-content: flex-start; 
   align-self: stretch; 
   margin: 10px 0px 0px 15px;
`

const formatReports = reports => _.orderBy(reports, 'date', 'desc')

const getBackgroundColor = value => value === hiddenReportsStatus.VISIBLE ? 'success' : 'warning'
const getActionIcon = value => value === hiddenReportsStatus.VISIBLE ? 'hide' : 'view'

const ArchivedReports = ({ stockKey }) => {
  const { t } = useTranslation()
  const { setPageSubtitle, datasets, pageLoading, setPageLoading } = useContext(AppContext)
  const { idToken } = useContext(UserContext)

  const { warehouses } = useContext(WarehouseContext)

  const stock = useMemo(() => warehouses?.find(warehouse => warehouse?.wid === stockKey), [stockKey, warehouses])
    
  const { filteredData: {filteredTableData }, data, setData } = useContext(FilterContext)
  const [showFilterModal, setShowFilterModal] = useState(false)

  const [ reportId, setReportId] = useState(null)
  const [ reportType, setReportType] = useState(null)
  const [ isOpen, setIsOpen ] = useState(null)
  const [ status, setStatus ] = useState(null)
  const [ endUser, setEndUser ] = useState(null)
  const [ endUsers, setEndUsers ] = useState(null)

  useEffect(() => {
    if (stock) {
      setPageSubtitle(stock?.title + ' - ' +  t('Archive Reports'))
    }
  }, [stock, setPageSubtitle, t])

  useEffect(() => {
    async function fetchData(){
        try {
            setPageLoading(true)
            if(stock){
                const response = await api.getAvailableEndUsers({ "wid": stock.wid }, idToken)
                if(response){
                    setEndUsers(response.endUsers)
                    setPageLoading(false)
                }
            }  
        }
        catch(error){
            setPageLoading(false)
            toast.error(error.message)
        }
    }
    fetchData() 
  }, [stock]) // eslint-disable-line react-hooks/exhaustive-deps

  const inputEndUsers = useMemo(() => _.orderBy(_.uniqBy(endUsers, 'dsc_client'), 'label', 'asc').filter(item => item.wid === stock.wid).map(({ id_global, dsc_client }) => ({
    value: id_global,
    label: dsc_client
  })), [ endUsers, stock ])
  
  useEffect(() => {
    if(inputEndUsers  && inputEndUsers.length > 0){
      if(!endUser)
        setEndUser(inputEndUsers[0].value)
    }
  }, [endUser, inputEndUsers, setEndUser])

  // const onDownloadClick = useCallback(async (report) => {
  //   try {
  //     setPageLoading(true)
  //     const reportData = await getArchivedReportData(report.report_type, report.id_report, idToken)
  //     const fileName = getFileName(filenames[reportData.config.type], report.id_report)
  //     const logoBase64 = await documentManagement.getStockLogo({ wid: currentWID }, idToken)
  //     generateSpreadsheet(fileName, reportData, reportData.config.type, logoBase64, [])
  //     toast.success(t('documents.popupInfo'))
  //   }
  //   catch (error) {
  //     setPageLoading(false)
  //     toast.error(error.message)
  //     throw new Error(error)
  //   } finally {
  //     setPageLoading(false)
  //   }
  // }, [currentWID, idToken, setPageLoading, t])

  const onVisibilityClick = useCallback(async(id, type, status) => {
    setReportId(id)
    setReportType(type)
    setStatus(status)
    setIsOpen(true)
  }, [])

  const loadReports = useCallback(async() => {
    try {
      if(endUser){
        setPageLoading(true)
        const result = await apiArchivedReports.getArchivedReportList({ wid: stock.wid, endUserId: endUser }, idToken)
        if(result){
          setData(formatReports(result))
          setPageLoading(false)
        }
      }
    }
    catch(error){
      console.log(error.message)
      toast.error(error.message)
      setPageLoading(false)
    }    
  }, [endUser, idToken, setData, setPageLoading, stock])

  const toggleModal = useCallback(async(isSaving) => {
    setIsOpen(false)
    if(isSaving)
      loadReports()
    setTimeout(() => {
      setReportType(null)
      setReportId(null)
      setStatus(null)
    }, 500)
  }, [ loadReports ])

  useEffect(() => {
    try {
      loadReports()
    }
    catch(error){
      console.log(error.message)
      toast.error(error.message)
      setPageLoading(false)
    }
  }, [ endUsers, datasets, idToken, setPageLoading, loadReports ])

  const columns = useMemo(() => [
    {
      accessor: 'id_report',
      Header: t('Report ID'), 
      label: t('Report ID'),
    },
    {
      id: 'date',
      Header: t('Date'),
      label: t('Date'),
      accessor: 'date', 
      Cell: (props) => {
        const { date } = props.row.original
        return date ? moment(date).format('MMM D, YYYY, h:mm:ss A') : ''
      },
    },
    {
      accessor: ({ report_type }) => report_type ? t(report_type) : '',
      Header: t('Report Type'),
      label: t('Report Type')
    },
    {
      accessor: 'responsible',
      Header: t('Changed By'), 
      label: t('Changed By')
    },
    {
      accessor: 'justification',
      Header: t('Justification'),
      label: t('Justification')
    },
    {
      accessor: 'status',
      Header: 'Status',
      label: 'Status',
      Cell: ({ cell: { value, row } }) => ( 
        <FlexView alignItems="center" justifyContent="center">
          <FlexStatus  alignItems="center" justifyContent="center" padding="4px 8px" margin="0px 0px 0px 8px" color="white" 
                     backgroundColor={getBackgroundColor(value)} width="10rem" borderRadius="10px" text-center>
               <b> {t(value?.toLowerCase())} </b> 
          </FlexStatus>         
        </FlexView>
      )
    },
    
    {
      id: 'options',
      Header: t('Options'),
      disableFilters: true,
      Cell: ({ cell: { row } }) => (
          <FlexView flexDirection="row" alignItems="flex-start" justifyContent="center" width="100%" >
            {/* <Icon name="download" width="24px" height="24px" onClick={()=> onDownloadClick(row.original)} margin="0px 10px 0px 0px" tooltip={t('Export to Excel')}/> */}
            <Icon name={getActionIcon(row.original.status)} width="24px" height="24px" tooltip={ row.original.status === hiddenReportsStatus.VISIBLE ? t('Archive Tooltip') :  t('Reveal Tooltip') } onClick={()=> onVisibilityClick(row.original.id_report, row.original.report_type, row.original.status )} />
          </FlexView> )
    }
  ], [t, onVisibilityClick])

  const toggleFilterModal = () => setShowFilterModal(currentState => !currentState)

  return <FlexView flex="1" position="relative" alignSelf="stretch">
    <StockTabs stock={stock} />
    <PageHeader>
      <Select value={endUser} options={inputEndUsers} onChange={setEndUser} placeHolder={endUser ? endUser : t('Select an option')} />
      <FlexView margin="0px 16px" flexDirection="row"  alignItems="center" justifyContent="space-between" alignSelf="stretch" flex='1'>
        <FilterDisplay options={columns} onTagClick={toggleFilterModal} />
        <Icon name="filter" width="28px" height="28px" tooltip={t('Filter')} onClick={toggleFilterModal}/>
      </FlexView>
    </PageHeader>
    <Card alignSelf="stretch" padding="0px" margin="16px">
      <ArchiveButton flexDirection="row" onClick={() => setIsOpen(true)} fontSize="subtitle" justifyContent="flex-end" width="99%">
              <Icon name="plus" width="16px" height="16px"  margin="0px 5px 0px 0px"/>
              <div> <font size="4"> { t('Archive New Report') } </font> </div>
      </ArchiveButton>
      <FilterableTable columns={ columns } data={ filteredTableData } />
    </Card>
    <FiltersModal isOpen={showFilterModal} onOutsideClick={toggleFilterModal} options={columns} data={data}/>
    <ReportVisibilityModal isOpen={ isOpen } reportId={ reportId }  type={ reportType } status={ status } isRowClick={ status ? true : false } onOutsideClick={toggleModal} stockKey={ stockKey } endUser={endUser}> </ReportVisibilityModal>
    <LoadingOverlay visible={pageLoading} />
  </FlexView>
}

export default ArchivedReports