import axios from 'axios'
import { decompressResponse } from 'utils/decompress'
import { addLogger } from 'utils/logger'
import Cookies from 'js-cookie'

const SHOULD_DECOMPRESS = false

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  headers: {
    'Content-Type': 'application/json', 
    'VLR-Authorization': btoa(JSON.stringify({ "id": process.env.REACT_APP_ID }))
  }
})

addLogger(instance, SHOULD_DECOMPRESS)

// const formatAuthorization = idToken => idToken + '.' + btoa(JSON.stringify({ 'id': process.env.REACT_APP_ID }))

const getDefaultHeader = () => ({
  headers: { Authorization: Cookies.get("_ssoToken") }
})

const isSuccessfulResponse = (response) => response && response.status === 200
const isUploadError = (response) => response && response.data.isUploadError
const hasMessageError = (response) => response && response.data.messageError

const manageResponse = (response, shouldDecompress) => {
  if (isSuccessfulResponse(response)) {
    if (shouldDecompress) {
      return decompressResponse(response.data)
    }
    return response.data.body ? response?.data?.body : response.data
  } else {
    throw new Error('Couldn\'t retrieve data')
  }
}

const manageError = (error) => {
  console.log(error)
  if (isUploadError(error.response)) {
    throw error.response.data 
  }
  else if(hasMessageError(error.response)){
    throw new Error(error.response.data.messageError)
  }
  else {
    throw new Error(error)
  }
}

const requests = {
  getPipeDataFilters: async (params, idToken) => {
    try {
      const response = await instance.post('/data/get-pipe-data-filters', params, getDefaultHeader())
      return manageResponse(response, SHOULD_DECOMPRESS)
    } catch (error) {
      manageError(error)   
    }
  }, 
  getAvailableEndUsers: async (params, idToken) => {
    try {
      const response = await instance.post('/end-user/get-available', params, getDefaultHeader())
      return manageResponse(response, SHOULD_DECOMPRESS)
    } catch (error) {
      manageError(error)   
    }
  }
}
export default requests