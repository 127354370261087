import axios from 'axios'
import { decompressResponse } from 'utils/decompress'
import { addLogger } from 'utils/logger'
import { getDefaultHeader, manageError, manageResponse } from './api-util'
import Cookies from 'js-cookie'

const SHOULD_DECOMPRESS = false 

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  headers: {
    'Content-Type': 'application/json', 
    'VLR-Authorization': btoa(JSON.stringify({ "id": process.env.REACT_APP_ID }))
  }
})

addLogger(instance, false)

// const formatAuthorization = idToken => idToken + '.' + btoa(JSON.stringify({ 'id': process.env.REACT_APP_ID }))

export default {
  getStockData: (stock, wid, idToken) => new Promise((resolve, reject) => {
    instance.post(
      `/stocks/${stock}`,
      wid ? {
        wid
      } : {},
      {
        headers: {
          Authorization: Cookies.get("_ssoToken")
        }
      }
    ).then(response => {
      if (response.status === 200) {
        resolve(decompressResponse(response.data))
      }
      else {
        reject(new Error('Couldn\'t retrieve data'))
      }
    }).catch(error => {
      reject(error)
    })
  }),
  getRackData: (wid, idToken) => new Promise((resolve, reject) => {
    instance.post(
      `/stocks/rack-distribution-adnoc`,
      { wid: wid },
      {
        headers: {
          Authorization: Cookies.get("_ssoToken")
        }
      }
    ).then(response => {
      if (response.status === 200) {
        resolve(decompressResponse(response.data))
      }
      else {
        reject(new Error('Couldn\'t retrieve data'))
      }
    }).catch(error => {
      reject(error)
    })
  }),
  getCertificateDownloadUrl: (path, idToken) => new Promise((resolve, reject) => {
    instance.post(
      '/document-management/get-certificate',
      {
        path
      },
      {
        headers: {
          Authorization: Cookies.get("_ssoToken")
        }
      }
    ).then(response => {
      if (response.status === 200) {
        const { url } = response.data.body
        resolve(url)
      }
      else {
        reject(new Error('Couldn\'t retrieve data'))
      }
    }).catch(error => {
      reject(error)
    })
  }),
  getUserGuideDownloadUrl: (key, version, idToken) => new Promise((resolve, reject) => {
    instance.post(
      '/document-management/get-user-guide',
      {
        key, version
      },
      {
        headers: {
          Authorization: Cookies.get("_ssoToken")
        }
      }
    ).then(response => {
      if (response.status === 200) {
        const { url } = decompressResponse(response.data)
        resolve(url)
      }
      else {
        reject(new Error('Couldn\'t retrieve data'))
      }
    }).catch(error => {
      reject(error)
    })
  }),
  listTrackingOfGoodsReports: (params, idToken) => new Promise((resolve, reject) => {
    instance.post(
      '/document-management/tracking-of-goods/list',
      params,
      {
        headers: {
          Authorization: Cookies.get("_ssoToken")
        }
      }
    ).then(response => {
      if (response.status === 200) {
        const data = decompressResponse(response.data)
        resolve(data)
      }
      else {
        reject(new Error('Couldn\'t retrieve data'))
      }
    }).catch(error => {
      reject(error)
    })
  }),
  uploadTrackingOfGoodsReport: (fileParams, idToken) => new Promise((resolve, reject) => {
    instance.post(
      '/document-management/tracking-of-goods/upload', 
      fileParams,
      {
        headers: {
          Authorization: Cookies.get("_ssoToken")
        }
      }
    ).then(response => {
      if (response.status === 200) {
        const data = decompressResponse(response.data)
        resolve(data)
      }
      else {
        reject(new Error('Couldn\'t retrieve data'))
      }
    }).catch(error => {
      reject(error)
    })
  }),
  downloadTrackingOfGoodsReport: (id, idToken) => new Promise((resolve, reject) => {
    instance.post(
      '/document-management/tracking-of-goods/download',
      { id },
      {
        headers: {
          Authorization: Cookies.get("_ssoToken")
        }
      }
    ).then(response => {
      if (response.status === 200) {
        const url = decompressResponse(response.data)
        resolve(url)
      }
      else {
        reject(new Error('Couldn\'t retrieve data'))
      }
    }).catch(error => {
      reject(error)
    })
  }),
  deleteTrackingOfGoodsReport: (id, idToken) => new Promise((resolve, reject) => {
    instance.post(
      '/document-management/tracking-of-goods/delete',
      { id },
      {
        headers: {
          Authorization: Cookies.get("_ssoToken")
        }
      }
    ).then(response => {
      if (response.status === 200) {
        const data = decompressResponse(response.data)
        resolve(data)
      }
      else {
        reject(new Error('Couldn\'t retrieve data'))
      }
    }).catch(error => {
      reject(error)
    })
  }),
  listReportsCollaborative: (params, idToken) => new Promise((resolve, reject) => {
    instance.post(
      '/document-management/collaborative-planning/list',
      params,
      {
        headers: {
          Authorization: Cookies.get("_ssoToken")
        }
      }
    ).then(response => {
      if (response.status === 200) {
        const data = decompressResponse(response.data)
        resolve(data)
      }
      else {
        reject(new Error('Couldn\'t retrieve data'))
      }
    }).catch(error => {
      reject(error)
    })
  }),
  uploadReportCollaborative: (fileParams, idToken) => new Promise((resolve, reject) => {
    instance.post(
      '/document-management/collaborative-planning/upload',
      fileParams,
      {
        headers: {
          Authorization: Cookies.get("_ssoToken")
        }
      }
    ).then(response => {
      if (response.status === 200) {
        const data = decompressResponse(response.data)
        resolve(data)
      }
      else {
        reject(new Error('Couldn\'t retrieve data'))
      }
    }).catch(error => {
      reject(error)
    })
  }),
  downloadReportCollaborative: (id, idToken) => new Promise((resolve, reject) => {
    instance.post(
      '/document-management/collaborative-planning/download',
      { id },
      {
        headers: {
          Authorization: Cookies.get("_ssoToken")
        }
      }
    ).then(response => {
      if (response.status === 200) {
        const url = decompressResponse(response.data)
        resolve(url)
      }
      else {
        reject(new Error('Couldn\'t retrieve data'))
      }
    }).catch(error => {
      reject(error)
    })
  }),
  deleteReportCollaborative: (id, idToken) => new Promise((resolve, reject) => {
    instance.post(
      '/document-management/collaborative-planning/delete',
      { id },
      {
        headers: {
          Authorization: Cookies.get("_ssoToken")
        }
      }
    ).then(response => {
      if (response.status === 200) {
        const data = decompressResponse(response.data)
        resolve(data)
      }
      else {
        reject(new Error('Couldn\'t retrieve data'))
      }
    }).catch(error => {
      reject(error)
    })
  }),
  getPipeAgeList: (uri, params = {}, idToken = '') => new Promise((resolve, reject) => {
    instance.post(
      `/${uri}`,
      params,
      {
        headers: {
          Authorization: Cookies.get("_ssoToken")
        }
      }
    ).then(response => {
      if (response.status === 200) {
        resolve(decompressResponse(response.data))
      }
      else {
        reject(new Error('Couldn\'t retrieve data'))
      }
    })
      .catch(error => {
        reject(error)
      })
    }), 
    getDailyServiceStock: async (params = {}, idToken = '') => {
      try {
        const data = await instance.post(`/service-stock/get-daily-stock-data`, params, getDefaultHeader(idToken))
        return manageResponse(data, SHOULD_DECOMPRESS)
      } catch (error) {
        manageError(error)
      }
    }, 
    getRackFromPipes: async (params = {}, idToken = '') => {
      try {
          const result = await instance.post(`/service-stock/get-racks-from-pipes`, params, getDefaultHeader(idToken))
          console.log(result)
          return manageResponse(result, SHOULD_DECOMPRESS)
      } catch (error) {
          manageError(error)
      }
    }
}