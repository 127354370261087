export const workSheetNames = {
    DELIVERY_NOTES: "Dispatch Details",
    MATERIAL_REQUISITION: "Inspection Details",
    RECEIPT_NOTES: "Receipt details",
    RIG_RETURN_INSP: 'Rig Return inspection details',
    RECEIVING_INSPECTION: 'Receiving inspection details',
    BACKLOAD_MANIFEST: 'Backload manifest details',
    PERIODICAL_INSPECTION: 'Periodical inspection details',
    MODIFIED_PRODUCTS: 'Modified product details', 
    PIPE_AGE: 'Pipe Age Details'
}

export const templateType = {
    DELIVERY_NOTES: "DELIVERY_NOTES",
    MATERIAL_REQUISITION: "MATERIAL_REQUISITION",
    RECEIPT_NOTES: "RECEIPT_NOTES",
    RIG_RETURN_INSP: 'RIG_RETURN_INSP',
    RECEIVING_INSPECTION: 'RECEIVING_INSPECTION',
    BACKLOAD_MANIFEST: 'BACKLOAD_MANIFEST',
    PERIODICAL_INSPECTION: 'PERIODICAL_INSPECTION',
    MODIFIED_PRODUCTS: 'MODIFIED_PRODUCTS', 
    PIPE_AGE: 'PIPE_AGE'
}
