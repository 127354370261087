import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'


import { toast } from 'react-toastify'
import * as _ from 'lodash'
import moment from 'moment'

import rigReturn from 'api/rig-return'
import documentManagement from 'api/document-management'

import { AppContext } from 'stores/AppStore'
import { UserContext } from 'stores/UserStore'
import { WarehouseContext } from 'stores/WarehouseStore'

import { getFileName } from 'utils/helpers'
import { filenames } from 'utils/constants'

import { generateSpreadsheet } from 'provider/excelGeneration'
import { templateType } from 'provider/types'

import { Card, FlexView, Icon, LoadingOverlay } from 'components/common'
import FilterableTable from 'components/common/FilterableTable'

import StockTabs from 'containers/layout/StockTabs'
import FilterDisplay from 'containers/common/FilterDisplay'
import FiltersModal from 'containers/common/FiltersModal'
import { FilterContext } from 'stores/FilterStore'

const formatReports = reports => _.chain(reports)
    .orderBy('closure_date', 'desc')
    .value()

const BackloadManifestReportsPage = ({ stockKey }) => {
    const { t } = useTranslation()
    const { setPageSubtitle, datasets } = useContext(AppContext)
    const { endUsers, idToken } = useContext(UserContext)
    const { warehouses } = useContext(WarehouseContext)

    const { filteredData: {filteredTableData }, data, setData } = useContext(FilterContext)

    const [loading, setLoading] = useState(true)
    const [showModal, setShowModal] = useState(false)

    const stock = useMemo(() => warehouses?.find(warehouse => warehouse?.wid === stockKey), [stockKey, warehouses])
    
    useEffect(() => {
        if (stock) {
            setPageSubtitle(stock?.title + ' - ' + t('Backload Manifest Reports'))
        }
    }, [stock, setPageSubtitle, t])

    const downloadReport = useCallback(async report => {
        setLoading(true)
        try {
            const reportData = await rigReturn.getBackloadManifestData({ reportId: report.rrlid }, idToken)
            const fileName = getFileName(filenames['BACKLOAD_MANIFEST'], report.rrlid)
            const logoBase64 = await documentManagement.getStockLogo({ wid: stock.wid }, idToken)
            generateSpreadsheet(fileName, reportData, templateType.BACKLOAD_MANIFEST, logoBase64, [])
            toast.info(t('documents.popupInfo'))
        }
        catch (error) {
            toast.error(error.message)
        }
        finally {
            setLoading(false)
        }
    }, [idToken, stock, t])

    const onDownloadClick = useCallback(report => () => {
        downloadReport(report)
    }, [downloadReport])

    useEffect(() => {
        async function fetchData() {
            setLoading(true)
            try {
                const data = await rigReturn.getRigReturnBackloadList({ wid: stock.wid }, idToken)
                setData(formatReports(data))
            } catch (error) {
                toast.error(error.message || error)
            } finally {
                setLoading(false)
            }
        }
        fetchData()
    }, [endUsers, datasets, idToken, stock, setData])

    const columns = useMemo(() => [
        {
            accessor: 'rrlid',
            Header: t('ID'),
            label: t('ID'),
            customerHeaderProps: {
                style: {
                    minWidth: '10px'
                }
            }
        },
        {
            accessor: 'name',
            Header: t('Name'),
            label: t('Name'),
            customerHeaderProps: {
                style: {
                    minWidth: '150px'
                }
            }
        },
        {
            id: 'closure_date',
            Header: t('Date'),
            label: t('Date'),
            accessor: 'closure_date',
            Cell: (props) => {
                const { closure_date } = props.row.original
                return closure_date ? moment(closure_date).format('MMM D, YYYY, h:mm:ss A') : ''
            },
            customerHeaderProps: {
                style: {
                    minWidth: '150px'
                }
            }
        },
        {
            accessor: 'rr_ref',
            Header: t('MR Reference'),
            label: t('MR Reference'),
            customerHeaderProps: {
                style: {
                    minWidth: '150px'
                }
            }
        },
        {
            accessor: 'nt_quantity',
            Header: t('Non Traceable'),
            label: t('Non Traceable'),
            customerHeaderProps: {
                style: {
                    minWidth: '60px'
                }
            }
        },
        {
            accessor: 'pipe_count',
            Header: t('Inspected'),
            label: t('Inspected'),
            customerHeaderProps: {
                style: {
                    minWidth: '60px'
                }
            }
        },
        {
            accessor: 'download',
            Header: t('Download'),
            customerHeaderProps: {
                style: {
                    minWidth: '32px'
                }
            },
            Cell: ({ cell: { row } }) => <FlexView alignItems='center' justifyContent='center' width='100%'>
                <Icon name='download' width='24px' height='24px' onClick={onDownloadClick(row.original)} />
            </FlexView>
        },
    ], [t, onDownloadClick])

    const toggleModal = () => setShowModal(currentState => !currentState)

    return <FlexView flex='1' position='relative' alignSelf='stretch'>
        <StockTabs stock={stock} />
        <FlexView margin="16px 16px 8px" flexDirection="row" justifyContent="space-between" alignSelf="stretch">
        <FilterDisplay options={columns} onTagClick={toggleModal} />
        <FlexView flexDirection="row" alignItems="center" justifyContent="flex-end" flex="1">
            <Icon name="filter" width="28px" height="28px" margin="0px 8px 0px 0px" tooltip={t('Filter')} onClick={toggleModal}/>
        </FlexView>
    </FlexView>
        <Card alignSelf='stretch' padding='0px' margin='16px'>
            <FilterableTable columns={columns} data={filteredTableData} />
        </Card>
        <FiltersModal isOpen={showModal} onOutsideClick={toggleModal} options={columns} data={data}/>
        <LoadingOverlay visible={loading} />
    </FlexView>
}

export default BackloadManifestReportsPage