import axios from 'axios'

import { addLogger } from 'utils/logger'
import { getDefaultHeader, manageError, manageResponse } from './api-util'

const SHOULD_DECOMPRESS = false

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,  
  headers: {
    'Content-Type': 'application/json', 
    'VLR-Authorization': btoa(JSON.stringify({ "id": process.env.REACT_APP_ID }))
  }
})

addLogger(instance, false)

export default {
    getCompleteStock: async (params, idToken) => {
      try {
        const response = await instance.post('/stock-status/get-complete-stock', params, getDefaultHeader())
        return manageResponse(response, SHOULD_DECOMPRESS)
      } catch (error) {
        manageError(error)   
      }
  }, 
  upsertPlannedStock: async (params, idToken) => {
    try {
      const response = await instance.post('/stock-status/upsert-planned-stock/bulk', params, getDefaultHeader())
      return manageResponse(response, SHOULD_DECOMPRESS)
    } catch (error) {
      manageError(error)   
    }
  }, 
  deletePlannedStock: async (params, idToken) => {
      try {
        const response = await instance.post('/stock-status/delete-planned-stock', params, getDefaultHeader())
        return manageResponse(response, SHOULD_DECOMPRESS)
      } catch (error) {
        manageError(error)   
      }
  }, 
  getPlannedStockTemplate: async (params, idToken) => {
    try {
      const response = await instance.post('stock-status/get-planned-stock-template', params, getDefaultHeader())
      return manageResponse(response, SHOULD_DECOMPRESS)
    } catch (error) {
      manageError(error)   
    }
  }
  
}