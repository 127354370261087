import React, { useContext, useMemo, useEffect, useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import _ from 'lodash'
import moment from 'moment'

import documentManagement from 'api/document-management'
import periodicalInspection from 'api/periodical-inspection'

import { LoadingOverlay, FlexView, Icon, Card } from 'components/common'
import FilterableTable from 'components/common/FilterableTable'

import StockTabs from 'containers/layout/StockTabs'

import { AppContext } from 'stores/AppStore'
import { UserContext } from 'stores/UserStore'
import { WarehouseContext } from 'stores/WarehouseStore'

import { filenames, S3_TYPE_SUBFOLDER } from 'utils/constants'
import { checkIfCanSearchImages, getFileName } from 'utils/helpers'

import { templateType } from 'provider/types'
import { generateSpreadsheet } from 'provider/excelGeneration'
import FilterDisplay from 'containers/common/FilterDisplay'
import FiltersModal from 'containers/common/FiltersModal'
import { FilterContext } from 'stores/FilterStore'

import apiDataReport from 'api/report-data'

const formatReports = reports => _.chain(reports)
  .orderBy('inspection_date', 'desc')
  .value()

const PeriodicalInspectionReports = ({ stockKey }) => {
  const { t } = useTranslation()
  const { setPageSubtitle, datasets, formatNumberByCulture } = useContext(AppContext)
  const { endUsers, idToken } = useContext(UserContext)
  const { warehouses } = useContext(WarehouseContext)

  const stock = useMemo(() => _.find(warehouses, { key: stockKey }), [warehouses, stockKey])

  const { filteredData: {filteredTableData }, data, setData } = useContext(FilterContext)
  const [showModal, setShowModal] = useState(false)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (stock) {
      setPageSubtitle(stock?.title + ' - ' + t('Periodical Inspection Reports'))
    }
  }, [stock, setPageSubtitle, t])

  const downloadReport = useCallback(async report => {
    setLoading(true)
    try {
      const reportData = await periodicalInspection.getPeriodicalInspectionData({ reportId: report.pilid }, idToken)
      const fileName = getFileName(filenames['PERIODICAL_INSPECTION'], report.pilid)//{TODO} check if this is undefined or not
      let reportImagesContent = []
      console.log(checkIfCanSearchImages(reportData))
      console.log(reportData)
      if(checkIfCanSearchImages(reportData)){
        reportImagesContent = await apiDataReport.getReportImages({imageRows: reportData.data.imageRows, type: S3_TYPE_SUBFOLDER.PERIODICAL_INSPECTION }, idToken)
      }
      const logoBase64 = await documentManagement.getStockLogo({ wid: stock.wid }, idToken)
      generateSpreadsheet(fileName, reportData, templateType.PERIODICAL_INSPECTION, logoBase64, reportImagesContent)
      toast.info(t('documents.popupInfo'))
    }
    catch (error) {
      toast.error(error.message)
    }
    finally {
      setLoading(false)
    }
  }, [idToken, stock, t])

  const onDownloadClick = useCallback(report => () => {
    downloadReport(report)
  }, [downloadReport])

  useEffect(() => {
    async function fetchData() {
      setLoading(true)
      try {
        const data = await periodicalInspection.getPeriodicalInspectionList({ wid: stock.wid }, idToken)
        setData(formatReports(data))
      } catch (error) {
        toast.error(error.message)
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  }, [endUsers, datasets, idToken, stock, setData])

  const columns = useMemo(() => [
    {
      accessor: 'pilid',
      Header: t('ID'),
      label: t('ID'),
      customHeaderProps: {
        style: {
          minWidth: '40px'
        }
      }
    },
    {
      Header: t('CUSTOMER PO#'),
      accessor: 'customer_po',
      label: t('CUSTOMER PO#'),
      customHeaderProps: {
        style: {
          minWidth: '60px'
        }
      },
      customCellProps: {
        style: {
          textAlign: 'center'
        }
      }
    },
    {
      Header: t('CUSTOMER ITEM#'),
      accessor: 'customer_item',
      label: t('CUSTOMER ITEM#'),
      customHeaderProps: {
        style: {
          minWidth: '80px'
        }
      },
      customCellProps: {
        style: {
          textAlign: 'center'
        }
      }
    },
    {
      Header: t('SALES PO#'),
      accessor: 'sales_order',
      label: t('SALES PO#'),
      customHeaderProps: {
        style: {
          minWidth: '80px'
        }
      },
      customCellProps: {
        style: {
          textAlign: 'center'
        }
      }
    },
    {
      Header: t('SALES ITEM#'),
      accessor: 'sales_item',
      label: t('SALES ITEM#'),
      customHeaderProps: {
        style: {
          minWidth: '60px'
        }
      },
      customCellProps: {
        style: {
          textAlign: 'center'
        }
      }
    },
    {
      id: 'inspection_date',
      Header: t('Inspection Date'),
      label: t('Inspection Date'),
      accessor: 'inspection_date',
      Cell: (props) => {
        const { inspection_date } = props.row.original
        return inspection_date ? moment(inspection_date).format('MMM D, YYYY, h:mm:ss A') : ''
      },
      customHeaderProps: {
        style: {
          minWidth: '100px'
        }
      }
    },
    {
      Header: t('ERP REFERENCE'),
      label: t('ERP REFERENCE'),
      accessor: 'cat_id',
      customHeaderProps: {
        style: {
          minWidth: '60px'
        }
      },
    },
    {
      Header: t('Material Description'),
      accessor: 'dsc_material',
      label: t('Material Description'),
      customHeaderProps: {
        style: {
          minWidth: '300px'
        }
      },
    },
    {
      Header: `${t('Inspected')}`,
      label: t('Inspected'),
      accessor: 'inspected_pipe_count',
      Cell: (props) => {
        const { inspected_pipe_count } = props.row.original
        return `${formatNumberByCulture(Number(inspected_pipe_count), 0)}`
      },
      customCellProps: {
        style: {
          textAlign: 'center', 
          minWidth: '20px'
        }
      }
    },

    {
      Header: `${t('Accepted')}`,
      label: t('Accepted'),
      accessor: 'accept_count',
      Cell: (props) => {
        const { accept_count } = props.row.original
        return `${formatNumberByCulture(accept_count, 0)}`
      },
      customCellProps: {
        style: {
          textAlign: 'center', 
          minWidth: '20px'
        }
      }
    },
    {
      Header: `${t('Rejected')}`,
      label: t('Rejected'),
      accessor: 'reject_count',
      Cell: (props) => {
        const { reject_count } = props.row.original
        return `${formatNumberByCulture(reject_count, 0)}`
      },
      customCellProps: {
        style: {
          textAlign: 'center', 
          minWidth: '20px'
        }
      }
    },
    {
      Header: `${t('To Repair')}`,
      label: t('To Repair'),
      accessor: 'repair_count',
      Cell: (props) => {
        const { repair_count } = props.row.original
        return `${formatNumberByCulture(repair_count, 0)}`
      },
      customHeaderProps: {
        style: {
          minWidth: '20px'
        }
      },
      customCellProps: {
        style: {
          textAlign: 'center'
        }
      }
    },
    {
      id: 'download',
      Header: t('Download'),
      disableFilters: true,
      customHeaderProps: {
        style: {
          minWidth: '32px'
        }
      },
      Cell: ({ cell: { row } }) => <FlexView alignItems="center" justifyContent="center" width="100%">
        <Icon name="download" width="24px" height="24px" onClick={onDownloadClick(row.original)} />
      </FlexView>
    }
  ], [t, onDownloadClick, formatNumberByCulture])

  const toggleModal = () => setShowModal(currentState => !currentState)

  return <FlexView flex="1" position="relative" alignSelf="stretch">
    <StockTabs stock={stock} />
    <FlexView margin="16px 16px 8px" flexDirection="row" justifyContent="space-between" alignSelf="stretch">
      <FilterDisplay options={columns} onTagClick={toggleModal} />
      <FlexView flexDirection="row" alignItems="center" justifyContent="flex-end" flex="1">
        <Icon name="filter" width="28px" height="28px" margin="0px 8px 0px 0px" tooltip={t('Filter')} onClick={toggleModal}/>
      </FlexView>
    </FlexView>
    <Card alignSelf="stretch" padding="0px" margin="16px">
      <FilterableTable columns={columns} data={filteredTableData} />
    </Card>
    <FiltersModal isOpen={showModal} onOutsideClick={toggleModal} options={columns} data={data}/>
    <LoadingOverlay visible={loading} />
  </FlexView>
}

export default PeriodicalInspectionReports