import Exceljs from "exceljs"
import { saveAs } from "file-saver"

// import * as logo from "../assets/images/logo-vallourec-excel.js"

import {
    getFileExtension,
    getReportDataConfig,
    getReportHeaderConfig,
    getReportSecondHeaderConfig,
    getReportSignatureConfig,
    hasSecondHeader,
    hasImageHeader,
    hasSignature
} from "./report-config"

import { getDataStyle } from "./report-config/data"
import { HEADER_CONFIG, HEADER_STYLES } from "./report-config/header"
import { getReportReferenceConfig } from "./report-config/report-reference.js"
import { getReportTitle, getTitleConfig } from "./report-config/title"
import { ALT_YELLOW_FILL, CLEAR_STYLE, FONT_NAME_NORMAL, getColumnsWidth, getImageSheetColumnsWidth, getZoomScale, MIDDLE_CENTER_ALIGNMENT } from "./styles"
import { templateType, workSheetNames } from "./types"

import { REPORTS } from "utils/constants"

const pictureCell = 'C'

const getRangeMergeRange = (row, columns) =>
    `${columns[0]}${row}:${columns[1]}${row}`

const createAndConfigureWorkbook = (
    workbookCreator = "Smartengo Inventory"
) => {
    const workbook = new Exceljs.Workbook()
    workbook.creator = workbookCreator
    return workbook
}

const createWorksheet = (workbook, sheetName, zoomScale) => {
    // Create worksheets with headers and footers
    const sheet = workbook.addWorksheet(sheetName, {
        headerFooter: {
            firstHeader: "Hello Exceljs",
            firstFooter: "Hello World",
        },
        views: [{ state: "normal", zoomScale: zoomScale }],
    })

    return sheet
}

const charactersRegex = /^\D+/g
const numbersRegex = /[0-9]/g

const getRowFromCellPosition = (cellPosition) =>
    cellPosition.replace(charactersRegex, "")

const getCellFromCellPosition = (cellPosition) =>
    cellPosition.replace(numbersRegex, "")

const getRowAndCellPosition = (cellPosition) => ({
    rowPosition: getRowFromCellPosition(cellPosition),
    cellPosition: getCellFromCellPosition(cellPosition),
})

/**
 *
 * @param {Exceljs.Worksheet} ws
 */
const mergeItemCells = (ws, cellToMerge) => {
    ws.mergeCells(cellToMerge)
}

/* Setters helpers */
const setCellFont = (cell, font) => (cell.font = font)
const setCellFill = (cell, fill) => (cell.fill = fill)
const setCellBorder = (cell, border) => (cell.border = border)
const setCellAlignment = (cell, alignment) => (cell.alignment = alignment)
const setObjectHeight = (object, height) => (object.height = height)

const setObjectStyle = (
    object,
    font,
    fill,
    border,
    alignment,
    height = null
) => {
    setCellFont(object, font)
    setCellFill(object, fill)
    setCellBorder(object, border)
    setCellAlignment(object, alignment)
    setObjectHeight(object, height)
}

const setCellValue = (cell, content) => {
    cell.value = content
}

const setRowHeight = (row, height) => {
    row.height = height
}

/**
 *
 * @param {Exceljs.Worksheet} ws
 * @param {*} widthObj
 */
const setColumnsWidth = (ws, widthObj) => {
    ws.columns = widthObj
}

const setNumFmt = (cell, format) => {
    cell.numFmt = format
}

/**
 *
 * @param {Exceljs.Worksheet} ws
 * @param {string} content
 * @param {*} config
 */

const addSingleItem = (ws, content, config) => {
    mergeItemCells(ws, config.mergedCells)

    const { rowPosition } = getRowAndCellPosition(config.cellPosition)

    const itemRow = ws.getRow(rowPosition)
    const itemCell = ws.getCell(config.cellPosition)

    setRowHeight(itemRow, config.height)

    setCellFont(itemCell, config.font)
    setCellValue(itemCell, content)

    return itemCell
}

/**
 *
 * @param {Exceljs.Worksheet} ws
 * @param {*} rowValues
 * @param {*} offset
 */
const addHeaders = (ws, rowValues, headerConfig, styleConfig) => {
    const headersConfig = headerConfig.map((config) => ({
        ...HEADER_CONFIG.DEFAULT_LABEL,
        ...config,
    }))
    rowValues.forEach((rowVal, index) => {
        const addedItem = addSingleItem(ws, rowVal, headersConfig[index])
        const { numFmt, font, fill, border, alignment } = styleConfig
        setObjectStyle(addedItem, font, fill, border, alignment)
        setNumFmt(addedItem, numFmt)
    })
}

const addSecondHeaders = (ws, rowValues, headerConfig, stylesConfig) => {
    const headersConfig = headerConfig.map((config) => ({
        ...HEADER_CONFIG.DEFAULT_LABEL,
        ...config,
    }))
    rowValues.forEach((rowVal, index) => {
        const addedItem = addSingleItem(ws, rowVal, headersConfig[index])
        const { numFmt, font, fill, border, alignment } = stylesConfig[index].style
        setObjectStyle(addedItem, font, fill, border, alignment)
        setNumFmt(addedItem, numFmt)
    })
}

const getHeaderContentFromData = (config, data) =>
    config.map((item) => data[item.accessor])

// const addDataLabels = (ws, row, labels) => {
//     return ws.insertRow(row, labels)
// }

// const styleDataLabelRow = (row, styleConfig) => {
//     const { font, fill, border, alignment, height } = styleConfig
//     setObjectStyle(row, font, fill, border, alignment, height)
// }

const formatDataContent = (data, config) => {
    return config.map((item) => data[item.accessor])
}

const formatImageDataContent = (wb, data, config, ws, index) => {
    return config.map((item) => { 
        if(item.accessor === 'picture') {
            const imageId = wb.addImage({
                base64: data[item.accessor],
                extension: 'png'
            })

            const { rowPosition } = getRowAndCellPosition(pictureCell + index)
            const itemRow = ws.getRow(rowPosition)
            setRowHeight(itemRow, 200)
           
            const range = pictureCell + index + ':' + pictureCell + index
            ws.addImage(imageId, range)
        }
        else {
            return data[item.accessor]
        }
        return ''
    })
}

const clearStyle = (obj) => {
    const { font, fill, border, alignment, height } = CLEAR_STYLE
    setObjectStyle(obj, font, fill, border, alignment, height)
}

const addStyleToRowCells = (
    row,
    font,
    fill,
    border,
    alignment,
    height,
    wrapText
) => {
    row.eachCell((cell, colNumber) => {
        setObjectStyle(cell, font, fill, border, alignment, height, wrapText)
    })
}

const addStyleToImageRowCells = (
    row,
    font,
    fill,
    border,
    alignment,
    height,
    wrapText
) => {
    row.eachCell((cell, colNumber) => {
        setObjectStyle(cell, font, fill, border, alignment, 500, wrapText)
    })
}

const addNumFmtToRowCells = (row, fmt) => {
    row.eachCell((cell, colNum) => {
        setNumFmt(cell, fmt)
    })
}

const addDataContent = (ws, initialRow, data, config, styleConfig) => {
    // const firstDataContentRow = ws.getRow(initialRow)
    const { font, fill, border, alignment, height, numFmt } = styleConfig
    // setObjectStyle(firstDataContentRow, font, fill, border, alignment, height)

    data.forEach((item, index) => {
        const rowStyle = "o"
        const dataContent = formatDataContent(item, config)
        const rowIndexToInsert = initialRow + index
        const insertedRow = ws.insertRow(
            rowIndexToInsert,
            dataContent,
            rowStyle
        )

        addStyleToRowCells(insertedRow, font, fill, border, alignment, height)
        addNumFmtToRowCells(insertedRow, numFmt)
    })

    // remaining row with data content style
    const lastRowIndex = initialRow + data.length
    const rowToClear = ws.getRow(lastRowIndex)
    clearStyle(rowToClear)
    return lastRowIndex
}

const addImageDataContent = (ws, initialRow, data, config, styleConfig, wb) => {
    const { font, fill, border, alignment, height } = styleConfig
   
    data.forEach((item, index) => {
        const rowStyle = "o"
        const dataContent = formatImageDataContent(wb, item, config, ws, index+2)
        const rowIndexToInsert = initialRow + index
        const insertedRow = ws.insertRow(
            rowIndexToInsert,
            dataContent,
            rowStyle
        )

        addStyleToImageRowCells(insertedRow, font, fill, border, alignment, height)
    })

    // remaining row with data content style
    const lastRowIndex = initialRow + data.length
    const rowToClear = ws.getRow(lastRowIndex)
    clearStyle(rowToClear)
    return lastRowIndex
}

const addSignatureField = (ws, row, config) => {
    config.forEach((item, index) => {
        const actualRow = row + index
        const mergeRange = getRangeMergeRange(actualRow, item.mergeColumns)
        mergeItemCells(ws, mergeRange)

        const cell = ws.getCell(item.mergeColumns[0] + actualRow)
        setCellValue(cell, item.label)

        const { font, fill, border, alignment, height } = item.style
        setObjectStyle(cell, font, fill, border, alignment, height)

        const rowObj = ws.getRow(actualRow)
        setRowHeight(rowObj, item.style.height)
    })
}

const addLogoToFile = (wb, ws, position, logoBase64) => {
    const imageId2 = wb.addImage({
        base64: logoBase64,
        extension: 'png',
    })
    mergeItemCells(ws, position)
    ws.addImage(imageId2, position)
}

// TODO: review
// const addStyleToDataLabelRow = (row, styleConfig) => {
//     const { font, fill, border, alignment, height } = styleConfig
//     addStyleToRowCells(row, font, fill, border, alignment, height)
// }

export const generateSpreadsheet = async (name, reportData, reportType, logoBase64, reportImagesContent) => {
    try {
        const workbook = createAndConfigureWorkbook()
        const worksheet = createWorksheet(workbook, workSheetNames[reportType], getZoomScale(reportType))

        const title = reportType === 'PIPE_AGE' ? reportData.data.header.extract_desc : getReportTitle(reportData.config.type)
        const reportReference = reportData?.data?.header?.report_reference
        const {
            headerLabelConfig,
            headerContentConfig,
        } = getReportHeaderConfig(reportData.config.type)
        const {
            dataLabelConfig,
            dataContentConfig,
            dataContentRow,
            dataLogoRow
        } = getReportDataConfig(reportData.config.type)

        if(hasImageHeader(reportData.config.type)){
            const worksheetImage = createWorksheet(workbook, 'Pictures')
            const { headerImageSheetConfig } = getReportHeaderConfig(reportData.config.type)
            // format columns sizes
            setColumnsWidth(worksheetImage, getImageSheetColumnsWidth(reportData.config.type))
            // add header labels
            const headerLabels = headerImageSheetConfig.map((config) => config.label)
           
            addHeaders(
                worksheetImage,
                headerLabels,
                headerImageSheetConfig,
                HEADER_STYLES.IMAGE_CONTENT
            )
            
            const {
                dataImageContentRow, 
                dataImageContentConfig
            } = getReportDataConfig(reportData.config.type)
            const { imageContentStyle } = getDataStyle(reportData.config.type)
            // add images data content
            addImageDataContent(
                worksheetImage,
                dataImageContentRow,
                reportImagesContent,
                dataImageContentConfig,
                imageContentStyle, 
                workbook
            )
        }

        if(dataLogoRow && logoBase64){
            addLogoToFile(workbook, worksheet, dataLogoRow, logoBase64)
        }
       
        // format columns sizes
        setColumnsWidth(worksheet, getColumnsWidth(reportData.config.type))
        // title
        addSingleItem(worksheet, title, getTitleConfig(reportData.config.type))
        // Report Reference
        addSingleItem(worksheet, reportReference, getReportReferenceConfig(reportData.config.type))
        //subtitle
        addSingleItem(
            worksheet,
            HEADER_CONFIG.DEFAULT_SUBTITLE.content,
            HEADER_CONFIG.DEFAULT_SUBTITLE
        )

        // add header labels
        const headerLabels = headerLabelConfig.map((config) => config.label)
        addHeaders(
            worksheet,
            headerLabels,
            headerLabelConfig,
            HEADER_STYLES.DEFAULT_LABEL
        )

        // add header content
        const headerContents = getHeaderContentFromData(
            headerContentConfig,
            reportData.data.header
        )
        addHeaders(
            worksheet,
            headerContents,
            headerContentConfig,
            HEADER_STYLES.DEFAULT_CONTENT
        )

        if (hasSecondHeader(reportData.config.type)) {
            const {
                secondHeaderLabelConfig,
                secondHeaderContentConfig,
                secondHeaderContentStyle,
                secondHeaderLabelStyle
            } = getReportSecondHeaderConfig(reportData.config.type)

            // add header labels
            const secondHeaderLabels = secondHeaderLabelConfig.map((config) => config.label)

            addSecondHeaders(
                worksheet,
                secondHeaderLabels,
                secondHeaderLabelConfig,
                secondHeaderLabelStyle
            )

            // add header content
            const secondHeaderContents = getHeaderContentFromData(
                secondHeaderContentConfig,
                reportData.data.secondHeader
            )
           
            addSecondHeaders(
                worksheet,
                secondHeaderContents,
                secondHeaderContentConfig,
                secondHeaderContentStyle
            )
        }
       
        // add data labels
        const { labelStyle, contentStyle } = getDataStyle(
            reportData.config.type
        )
      
        const dataLabels = dataLabelConfig.map((config) => config.label)
        addHeaders(worksheet, dataLabels, dataLabelConfig, labelStyle)

        if(reportType === templateType.MATERIAL_REQUISITION){
            if(reportData.config.type === REPORTS.CONFIG_TYPE.MR_PHYSICAL_TRANSFER ){
                const objct = worksheet.getCell('O14')
                setCellFill(objct, ALT_YELLOW_FILL)
            } 
            else {
                const objct = worksheet.getCell('O16')
                setCellFill(objct, ALT_YELLOW_FILL)
            }
        }

        if(reportType === templateType.RIG_RETURN_INSP){
            const objct = worksheet.getCell('P14')
            setCellFill(objct, ALT_YELLOW_FILL)
        } else if (reportType === templateType.RECEIVING_INSPECTION) {
            const objct = worksheet.getCell('K16')
            setCellFill(objct, ALT_YELLOW_FILL)
        } else if (reportType === templateType.MODIFIED_PRODUCTS) {
            const objct = worksheet.getCell('K12')
            setCellFill(objct, ALT_YELLOW_FILL)
        } else if (reportType === templateType.PERIODICAL_INSPECTION) {
            const objct = worksheet.getCell('K16')
            setCellFill(objct, ALT_YELLOW_FILL)

            const matDescription = worksheet.getCell('C12')
            setCellAlignment(matDescription, MIDDLE_CENTER_ALIGNMENT)
        }
        

         // SPECIAL CASE FOR END USER  
         if(reportType === templateType.PIPE_AGE){
            const endUser = reportData?.data?.header?.end_user
            addSingleItem(worksheet, endUser, {
                cellPosition: 'G2',
                height: 35,
                mergedCells: "G2:G2",
                font: {
                    name: FONT_NAME_NORMAL,
                    size: 30,
                    bold: true
                }
            })
        }   

        // add data content
        const lastContentRow = addDataContent(
            worksheet,
            dataContentRow,
            reportData.data.rows,
            dataContentConfig,
            contentStyle
        )

        if (hasSignature(reportData.config.type)) {
            const signatureConfig = getReportSignatureConfig(
                reportData.config.type
            )
            //add signature column for shipper
            addSignatureField(
                worksheet,
                lastContentRow + signatureConfig.signatureGap,
                signatureConfig.signatureShipper
            )
            //add signature column for receiver
            addSignatureField(
                worksheet,
                lastContentRow + signatureConfig.signatureGap,
                signatureConfig.signatureReceiver
            )
        }

        const buffer = await workbook.xlsx.writeBuffer()
        saveAs(new Blob([buffer]), name + getFileExtension("xlsx"))
    } catch (error) {
        console.log(error)
        throw new Error(error)
    }
}
