import {
    THIN_FULL_BORDER,
    FONT_NAME_BOLD,
    BOTTOM_LEFT_ALIGNMENT,
    MIDDLE_CENTER_ALIGNMENT,
    FONT_NAME_NORMAL,
    GREY_FILL,
    DEFAULT_NUM_FMT,
    YELLOW_FILL,
    WHITE_FILL,
    GREEN_FONT_COLOR,
    RED_FONT_COLOR,
    YELLOW_FONT_COLOR,
    INT_NUM_FMT,
} from "../styles"

const DEFAULT_HEADER_LABEL_STYLE = {
    alignment: BOTTOM_LEFT_ALIGNMENT,
    border: THIN_FULL_BORDER,
    fill: GREY_FILL,
    font: {
        name: FONT_NAME_BOLD,
        size: 12,
        bold: true,
    },
}

const DEFAULT_IMAGE_HEADER_LABEL_STYLE = {
    alignment: MIDDLE_CENTER_ALIGNMENT,
    border: THIN_FULL_BORDER,
    fill: GREY_FILL,
    font: {
        name: FONT_NAME_BOLD,
        size: 12,
        bold: true,
    },
}

const MR_HEADER_LABEL_STYLE = {
    ...DEFAULT_HEADER_LABEL_STYLE,
    alignment: MIDDLE_CENTER_ALIGNMENT,
}

const MR_SECOND_HEADER_LABELS_STYLE = [
    { style: MR_HEADER_LABEL_STYLE },
    { style: MR_HEADER_LABEL_STYLE },
    {
        style: {
            ...MR_HEADER_LABEL_STYLE,
            font: { ...MR_HEADER_LABEL_STYLE.font, color: GREEN_FONT_COLOR },
        }
    },
    {
        style: {
            ...MR_HEADER_LABEL_STYLE,
            font: { ...MR_HEADER_LABEL_STYLE.font, color: RED_FONT_COLOR },
        },
    },
    {
        style: {
            ...MR_HEADER_LABEL_STYLE,
            font: { ...MR_HEADER_LABEL_STYLE.font, color: YELLOW_FONT_COLOR },
        },
    },
    { style: MR_HEADER_LABEL_STYLE },
    { style: MR_HEADER_LABEL_STYLE },
    { style: MR_HEADER_LABEL_STYLE },
]

const RIG_RETURN_SECOND_HEADER_LABELS_STYLE = [
    { style: MR_HEADER_LABEL_STYLE },
    { style: MR_HEADER_LABEL_STYLE },
    { style: MR_HEADER_LABEL_STYLE },
    {
        style: {
            ...MR_HEADER_LABEL_STYLE,
            font: { ...MR_HEADER_LABEL_STYLE.font, color: GREEN_FONT_COLOR },
        },
    },
    {
        style: {
            ...MR_HEADER_LABEL_STYLE,
            font: { ...MR_HEADER_LABEL_STYLE.font, color: RED_FONT_COLOR },
        },
    },
    {
        style: {
            ...MR_HEADER_LABEL_STYLE,
            font: { ...MR_HEADER_LABEL_STYLE.font, color: YELLOW_FONT_COLOR },
        },
    }
]

const RECEIVING_INSPECTION_SECOND_HEADER_LABELS_STYLE = [
    {style: MR_HEADER_LABEL_STYLE},
    {style: MR_HEADER_LABEL_STYLE},
    {
        style: {
            ...MR_HEADER_LABEL_STYLE,
            font: {...MR_HEADER_LABEL_STYLE.font, color: GREEN_FONT_COLOR}
        }
    },
    {
        style: {
            ...MR_HEADER_LABEL_STYLE,
            font: { ...MR_HEADER_LABEL_STYLE.font, color: RED_FONT_COLOR },
        },
    },
    {
        style: {
            ...MR_HEADER_LABEL_STYLE,
            font: { ...MR_HEADER_LABEL_STYLE.font, color: YELLOW_FONT_COLOR },
        },
    }
]

const PERIODICAL_INSPECTION_SECOND_HEADER_LABELS_STYLE = [
    {style: MR_HEADER_LABEL_STYLE},
    {
        style: {
            ...MR_HEADER_LABEL_STYLE,
            font: {...MR_HEADER_LABEL_STYLE.font, color: GREEN_FONT_COLOR}
        }
    },
    {
        style: {
            ...MR_HEADER_LABEL_STYLE,
            font: { ...MR_HEADER_LABEL_STYLE.font, color: RED_FONT_COLOR },
        },
    },
    {
        style: {
            ...MR_HEADER_LABEL_STYLE,
            font: { ...MR_HEADER_LABEL_STYLE.font, color: YELLOW_FONT_COLOR },
        },
    }
]

const MODIFIED_PRODUCTS_SECOND_HEADER_LABELS_STYLE = [
    {style: MR_HEADER_LABEL_STYLE},
    {style: MR_HEADER_LABEL_STYLE},
    {
        style: {
            ...MR_HEADER_LABEL_STYLE,
            font: {...MR_HEADER_LABEL_STYLE.font, color: GREEN_FONT_COLOR}
        }
    },
    {
        style: {
            ...MR_HEADER_LABEL_STYLE,
            font: { ...MR_HEADER_LABEL_STYLE.font, color: RED_FONT_COLOR },
        },
    },
    {
        style: {
            ...MR_HEADER_LABEL_STYLE,
            font: { ...MR_HEADER_LABEL_STYLE.font, color: YELLOW_FONT_COLOR },
        },
    },
    {style: MR_HEADER_LABEL_STYLE},
    {style: MR_HEADER_LABEL_STYLE},
    {style: MR_HEADER_LABEL_STYLE}
]


const DEFAULT_HEADER_CONTENT_STYLE = {
    numFmt: DEFAULT_NUM_FMT,
    alignment: BOTTOM_LEFT_ALIGNMENT,
    border: THIN_FULL_BORDER,
    fill: YELLOW_FILL,
    font: {
        name: FONT_NAME_NORMAL,
        size: 12,
        bold: false,
    },
}

const MR_HEADER_CONTENT_STYLE = {
    ...DEFAULT_HEADER_CONTENT_STYLE,
    alignment: MIDDLE_CENTER_ALIGNMENT,
    fill: WHITE_FILL,
}

const MR_SECOND_HEADER_CONTENTS_STYLE = [
    { style: { ...MR_HEADER_CONTENT_STYLE, numFmt: INT_NUM_FMT } },
    {
        style: {
            ...MR_HEADER_CONTENT_STYLE,
            numFmt: INT_NUM_FMT,
            font: { ...MR_HEADER_LABEL_STYLE.font, color: GREEN_FONT_COLOR },
        },
    },
    {
        style: {
            ...MR_HEADER_CONTENT_STYLE,
            numFmt: INT_NUM_FMT,
            font: { ...MR_HEADER_LABEL_STYLE.font, color: RED_FONT_COLOR },
        },
    },
    {
        style: {
            ...MR_HEADER_CONTENT_STYLE,
            numFmt: INT_NUM_FMT,
            font: { ...MR_HEADER_LABEL_STYLE.font, color: YELLOW_FONT_COLOR },
        },
    },
    { style: { ...MR_HEADER_CONTENT_STYLE, numFmt: INT_NUM_FMT } },
    { style: MR_HEADER_CONTENT_STYLE },
]

const RIG_RETURN_SECOND_HEADER_CONTENTS_STYLE = [
    { style: { ...MR_HEADER_CONTENT_STYLE, numFmt: INT_NUM_FMT } },
    { style: { ...MR_HEADER_CONTENT_STYLE, numFmt: INT_NUM_FMT } },
    {
        style: {
            ...MR_HEADER_CONTENT_STYLE,
            numFmt: INT_NUM_FMT,
            font: { ...MR_HEADER_LABEL_STYLE.font, color: GREEN_FONT_COLOR },
        },
    },
    {
        style: {
            ...MR_HEADER_CONTENT_STYLE,
            numFmt: INT_NUM_FMT,
            font: { ...MR_HEADER_LABEL_STYLE.font, color: RED_FONT_COLOR },
        },
    },
    {
        style: {
            ...MR_HEADER_CONTENT_STYLE,
            numFmt: INT_NUM_FMT,
            font: { ...MR_HEADER_LABEL_STYLE.font, color: YELLOW_FONT_COLOR },
        },
    }
]

const RECEIVING_INSPECTION_SECOND_HEADER_CONTENTS_STYLE = [
    { style: { ...MR_HEADER_CONTENT_STYLE, numFmt: INT_NUM_FMT } },
    {
        style: {
            ...MR_HEADER_CONTENT_STYLE,
            numFmt: INT_NUM_FMT,
            font: { ...MR_HEADER_LABEL_STYLE.font, color: GREEN_FONT_COLOR },
        },
    },
    {
        style: {
            ...MR_HEADER_CONTENT_STYLE,
            numFmt: INT_NUM_FMT,
            font: { ...MR_HEADER_LABEL_STYLE.font, color: RED_FONT_COLOR },
        },
    },
    {
        style: {
            ...MR_HEADER_CONTENT_STYLE,
            numFmt: INT_NUM_FMT,
            font: { ...MR_HEADER_LABEL_STYLE.font, color: YELLOW_FONT_COLOR },
        },
    }
]

const PERIODICAL_INSPECTION_SECOND_HEADER_CONTENTS_STYLE = [
    { style: { ...MR_HEADER_CONTENT_STYLE, numFmt: INT_NUM_FMT } },
    {
        style: {
            ...MR_HEADER_CONTENT_STYLE,
            numFmt: INT_NUM_FMT,
            font: { ...MR_HEADER_LABEL_STYLE.font, color: GREEN_FONT_COLOR },
        },
    },
    {
        style: {
            ...MR_HEADER_CONTENT_STYLE,
            numFmt: INT_NUM_FMT,
            font: { ...MR_HEADER_LABEL_STYLE.font, color: RED_FONT_COLOR },
        },
    },
    {
        style: {
            ...MR_HEADER_CONTENT_STYLE,
            numFmt: INT_NUM_FMT,
            font: { ...MR_HEADER_LABEL_STYLE.font, color: YELLOW_FONT_COLOR },
        },
    }
]

const MODIFIED_PRODUCTS_SECOND_HEADER_CONTENTS_STYLE = [
    { style: { ...MR_HEADER_CONTENT_STYLE, numFmt: INT_NUM_FMT } },
    {
        style: {
            ...MR_HEADER_CONTENT_STYLE,
            numFmt: INT_NUM_FMT,
            font: { ...MR_HEADER_LABEL_STYLE.font, color: GREEN_FONT_COLOR },
        },
    },
    {
        style: {
            ...MR_HEADER_CONTENT_STYLE,
            numFmt: INT_NUM_FMT,
            font: { ...MR_HEADER_LABEL_STYLE.font, color: RED_FONT_COLOR },
        },
    },
    {
        style: {
            ...MR_HEADER_CONTENT_STYLE,
            numFmt: INT_NUM_FMT,
            font: { ...MR_HEADER_LABEL_STYLE.font, color: YELLOW_FONT_COLOR },
        },
    },
    { style: { ...MR_HEADER_CONTENT_STYLE, numFmt: INT_NUM_FMT } },
    { style: { ...MR_HEADER_CONTENT_STYLE, numFmt: INT_NUM_FMT } }
]


export const HEADER_STYLES = {
    DEFAULT_LABEL: DEFAULT_HEADER_LABEL_STYLE,
    DEFAULT_CONTENT: DEFAULT_HEADER_CONTENT_STYLE,
    MR_LABEL: MR_HEADER_LABEL_STYLE,
    MR_CONTENT: MR_HEADER_CONTENT_STYLE,
    IMAGE_CONTENT: DEFAULT_IMAGE_HEADER_LABEL_STYLE
}

export const SECOND_HEADER_STYLES = {
    MR_LABEL: MR_SECOND_HEADER_LABELS_STYLE,
    MR_CONTENT: MR_SECOND_HEADER_CONTENTS_STYLE,
}

export const RIG_RETURN_SECOND_HEADER_STYLES = {
    RR_LABEL: RIG_RETURN_SECOND_HEADER_LABELS_STYLE,
    RR_CONTENT: RIG_RETURN_SECOND_HEADER_CONTENTS_STYLE,
}

export const RECEIVING_INSPECTION_HEADER_STYLE = {
    RI_LABEL: RECEIVING_INSPECTION_SECOND_HEADER_LABELS_STYLE,
    RI_CONTENT: RECEIVING_INSPECTION_SECOND_HEADER_CONTENTS_STYLE
}

export const PERIODICAL_INSPECTION_HEADER_STYLE = {
    PI_LABEL: PERIODICAL_INSPECTION_SECOND_HEADER_LABELS_STYLE,
    PI_CONTENT: PERIODICAL_INSPECTION_SECOND_HEADER_CONTENTS_STYLE
}

export const MODIFIED_PRODUCTS_HEADER_STYLE = {
    MP_LABEL: MODIFIED_PRODUCTS_SECOND_HEADER_LABELS_STYLE,
    MP_CONTENT: MODIFIED_PRODUCTS_SECOND_HEADER_CONTENTS_STYLE
}

const DEFAULT_HEADER_LABEL_CONFIG = {
    cellPosition: "A5",
    height: 30,
    mergedCells: "A5:D5",
    font: {
        name: FONT_NAME_NORMAL,
        size: 14,
        bold: false,
    },
}

const DEFAULT_HEADER_SUBTITLE_CONFIG = {
    content: "Details",
    cellPosition: "A4",
    height: 18,
    mergedCells: "A4:D4",
    font: {
        name: FONT_NAME_BOLD,
        size: 14,
        bold: true,
    },
}

export const HEADER_CONFIG = {
    DEFAULT_LABEL: DEFAULT_HEADER_LABEL_CONFIG,
    DEFAULT_SUBTITLE: DEFAULT_HEADER_SUBTITLE_CONFIG,
}