import theme from './theme'

export const dispatchTypeId = {
  Delivery3rdParty: 1,
  MaterialModification: 2,
  RigPreparation: 3, 
  MaterialPhysicalTransfer: 4
}

export const kpi_uri = {
    UP_INSERT_KPI : "upinsert-kpi",
    GET_CURRENT_KPIS : "get-currents-kpis",
    GET_ADMIN_KPIS: "get-admin-kpis", 
    GET_KPI_HISTORY: "get-kpi-history", 
    GET_KPI_FROM_DATE: "get-kpis-from-date",
    UP_INSERT_YEAR_AVERAGE: "update-kpi-year-average"
} 

export const reports_uri = {
  GET_RECEIVING_INSPECTION:'reports-management/receiving-inspection-reports',
  GET_DELIVERY_NOTES: 'reports-management/dispatch-notes',
  GET_RIG_RETURN: 'reports-management/rig-return-inspection-reports',
  GET_MODIFIED_PRODUCTS: 'reports-management/modified-products-inspection-reports',
  GET_MATERIAL_REQUISITION: 'reports-management/material-requisition-inspection-reports',
  GET_PERIODICAL_INSPECTION:'reports-management/periodical-inspection-reports',
  GET_RECEIPT_NOTES:'receipt-notes/get-receipt-notes-list',
  GET_SPOT_INSPECTION:'reports-management/spot-inspection-reports',
  GET_PIPE_AGE:'service/pipe-age',
  GET_PIPE_AGE_REPORTS: 'pipe-age/get-list',
  GET_RECEIPT_INTERNAL_REPORTS: 'reports-management/receipt-internal-reports', 
  GET_CONSUMPTION_INTERNAL_REPORTS: 'reports-management/consumption-internal-reports', 
  GET_STOCK_INTERNAL_REPORTS: 'reports-management/stock-internal-reports', 
  GET_HIDDEN_REPORTS_HIST: 'reports-management/hidden-reports/get-hidden-reports-hist', 
  HIDDEN_REPORTS_ARCHIVE: 'reports-management/hidden-reports/archive-report',
  HIDDEN_REPORTS_REVEAL: 'reports-management/hidden-reports/reveal-report', 
  GET_AVAILABLE_REPORTS: 'reports-management/get-available-reports', 
  GET_REPORT_INFO: 'reports-management/get-report-info'
}

export const userGuide =  'USER_GUIDE'

export const mrStatus_uri = {
  GET_MR_STATUS:'service/material-requisition-status',
  GET_MR_FILE: 'document-management/get-mr-file'
}

export const unit = {
    PERCENTAGE: "PERCENTAGE", 
    ABSOLUTE: "ABSOLUTE"
}

export const inputUnit = {
  PERCENT: "%", 
  NUMBER_OF_CHANGES: "Number of Changes",
  NUMBER_OF_TIMES: "Number of Times",
  DAYS: 'Days',
  WEEKS: 'Weeks', 
  HOURS: 'Hours'
}

export const peridiocity = {
        FIXED: "FIXED",
        MONTHLY : "MONTHLY", 
        YEARLY: "YEARLY", 
        BIANNUAL: "BIANNUAL", 
        QUARTERLY: "QUARTERLY"
}

export const type = {
    KPI: "KPI", 
    RECIPROCAL: "RECIPROCAL_KPI"
}
export const years = [
    { value: new Date().getFullYear(), label: new Date().getFullYear().toString() },
    { value: new Date().getFullYear()-1, label: ((new Date().getFullYear()) - 1).toString() },
    { value: new Date().getFullYear()-2, label: ((new Date().getFullYear()) - 2).toString() },
    { value: new Date().getFullYear()-3, label: ((new Date().getFullYear()) - 3).toString() },
    { value: new Date().getFullYear()-4, label: ((new Date().getFullYear()) - 4).toString() }
]

export const months = [
    { value: 1, label: 'January' },
    { value: 2, label: "February" },
    { value: 3, label: "March" },
    { value: 4, label: "April" },
    { value: 5, label: "May" },
    { value: 6, label: "June" },
    { value: 7, label: "July" },
    { value: 8, label: "August" },
    { value: 9, label: "September" },
    { value: 10, label: "October" },
    { value: 11, label: "November" },
    { value: 12, label: "December" }
]

export const callComplianceOptions = [
    {
      value: 0,
      label: "Deliver to the site without any delay",
    },
    {
      value: 1,
      label: "1 time over 10 working days",
    },
    {
      value: 2,
      label: "2 times over within 10 working days",
    },
    {
      value: 3,
      label: "2 or more times over within 10 working days"
    }
  ]

export const semesters = [
    { value: 1, label: "First Semester" },
    { value: 2, label: "Second Semester" }
  ]
 
export const paymentAfterAccurateInvoiceOptions = [
  {
    value: 0,
    label: "As per payment terms in T&C",
  },
  {
    value: 3,
    label: "Zero to five days late",
  },
  {
    value: 8,
    label: "Six to ten days late",
  },
  {
    value: 13,
    label: "Eleven to fifteen days late",
  },
  {
    value: 18,
    label: "Sixteen to twenty days late",
  },
  {
    value: 25,
    label: "More than twenty days late"
  }
] 

export const quarters = [
  { value: 1, label: "First Quarter" },
  { value: 2, label: "Second Quarter" },
  { value: 3, label: "Third Quarter" },
  { value: 4, label: "Fourth Quarter" }
]

export const colorStatusMap = {
  'RED': theme.colors.error,
  'ORANGE': theme.colors.warning,
  'GREEN': theme.colors.success,
  'DEFAULT': '#000000'
}

export const adnoc = {
  'ONSHORE': 1,
  'OFFSHORE': 2
}

export const notTotalFactor =  0.001
export const hundred =  100
export const getPercent = (kpi) => {
  return (kpi.score*hundred)/(kpi.maxScore+notTotalFactor)
}

export const getValuePercent = (kpi) => {
  return kpi.value-notTotalFactor
}

export const storageType = {
  closedEnd: "Closed End",
  openEnd: "Open End",
  global: "Global", 
  na: "NA"
}

export const conditions = {
  lessThanSixMonths: { 
    id: 1,
    condition : (time) => time < 6
  },
  betweenSixAndEightMonths: { 
    id: 2, 
    condition : (time) => time >= 6 && time < 8
  },
  betweenEightAndTwelveMonths: {
    id: 3,
    condition : (time) => time >= 8 && time < 12
  },  
  betweenTwelveAndEighteenMonths: { 
    id: 4,
    condition : (time) => time >= 12 && time < 18
  },
  moreThanEighteen: { 
    id: 5,
    condition : (time) => time >= 18
  }
}

export const optionsConsumption = [
  { value: "RP", label: "Rig Prep" },
  { value: "DT3P", label: "Delivery to 3rd Party" },
  { value: "MM", label: "Material Modification" },
  { value: "MPT", label: "Material Physical Transfer" }
]

export const internalReportsType = {
    CONSUMPTION: "CONSUMPTION", 
    STOCK_LEVEL: "STOCK_LEVEL", 
    RECEIPT: "RECEIPT", 
}

export const filenames = {
  RECEIPT_NOTES: 'RN_0000000',
  RECEIVING_INSPECTION: 'R-IR_0000000',
  INSPECTION_REPORT: 'IR_0000000', 
  RIG_PREP: 'RP-IR_0000000',
  DELIVERY_NOTES: 'DN_0000000',
  DISPATCH_REPORT: 'DR_0000000',
  RIG_RETURN: 'RR-IR_0000000',
  MODIFIED_PRODUCTS: 'MP-IR_0000000',
  SPOT_INSPECTION: 'SI-IR_0000000',
  PERIODICAL_INSPECTION: 'PI-IR_0000000', 
  PIPE_AGE: 'PAM_0000000',
  MATERIAL_REQUISITION_DELIVERY_TO_THIRD_PARTY: 'MR-DT3P-IR_0000000',
  MATERIAL_REQUISITION_MATERIAL_PHYSICAL_TRANSFER: 'MR-MPT-IR_0000000',
  MATERIAL_REQUISITION_MATERIAL_MODIFICATION: 'MR-MM-IR_0000000',
  MATERIAL_REQUISITION_RIG_PREP: 'MR-RP-IR_0000000',
  DELIVERY_NOTES_DELIVERY_TO_THIRD_PARTY: 'DN-DT3P-IR_0000000',
  DELIVERY_NOTES_MATERIAL_PHYSICAL_TRANSFER: 'DN-MPT-IR_0000000',
  DELIVERY_NOTES_MATERIAL_MODIFICATION: 'DN-MM-IR_0000000',
  DELIVERY_NOTES_RIG_PREP: 'DN-RP-IR_0000000',
  INVENTORY_BACKUP: 'IB_0000000',
  STOCK_LEVEL_INTERNAL_REPORT: 'SL-IR-',
  CONSUMPTION: 'CSPT-IR-',
  BACKLOAD_MANIFEST: 'RR_0000000'
}

export const reportTypes = [
  { value: 'MILL_RECEIPT', label: 'Receipt Notes' } ,
  { value: 'RECEIVING_INSP', label: 'Receiving Inspection' } ,
  { value: 'RIG_RETURN_INSP', label: 'Rig Return' },
  { value: 'MODIFIED_PRODUCTS', label: 'Modified Products' },
  { value: 'PERIODICAL_INSP', label: 'Periodical Inspection'} ,
  { value: 'MATERIAL_REQUISITION', label: 'Material Requisition' },
  { value: 'DELIVERY_NOTES', label: 'Delivery Notes'}, 
  { value: 'BACKLOAD_MANIFEST', label: 'Backload Manifest'}, 
  { value: 'PIPE_AGE_REPORTS', label: 'Pipe Age Reports'}, 
  // { value: 'MR_STATUS', label: 'MR Status'}
]

export const filenamesPrefix = {
  development: 'dev-',
  qas: 'qas-',
  staging: '',
  production: '',
}

export const hiddenReportsActions = {
  'ARCHIVE': 1,
  'REVEAL': 2
}

export const hiddenReportsStatus = {
  'VISIBLE': 'VISIBLE',
  'ARCHIVED': 'ARCHIVED'
}

export const REPORTS = {
  CONFIG_TYPE: {
    MR_THIRD_PARTY: 'MATERIAL_REQUISITION_DELIVERY_THIRD_PARTY', 
    MR_PHYSICAL_TRANSFER: 'MATERIAL_REQUISITION_MAT_PHYSICAL_TRANSFER',
    MR_MAT_MODIFICATION: 'MATERIAL_REQUISITION_MAT_MODIFICATION',
    MR_RIG_PREP: 'MATERIAL_REQUISITION_RIG_PREP'
  }
}
 
 export const UNIQUE_UPLOAD_PROPERTIES = [
   "erp_ref"
 ]
  
export const REFERENCE_SCREEN_SIZE = 1040

export const STOCK_ROUTES = {
    SERVICE_STOCK_ADIPEC: 'service-stock-adipec', 
    SERVICE_STOCK_BHP: 'service-stock-chevron',
    SERVICE_STOCK_CHEVRON: 'service-stock-chevron',
    SERVICE_STOCK_TOTAL_ANGOLA: 'service-stock-total-angola',
    SERVICE_STOCK_TOTAL_MYANMAR: 'service-stock-ptct',
    SERVICE_STOCK_ENI_MYANMAR: 'service-stock-ptct',
}

export const S3_TYPE_SUBFOLDER = {
  RECEIVING_INSPECTION: 'recinsp',
  MODIFIED_PRODUCTS: 'modifprod', 
  PERIODICAL_INSPECTION: 'perinsp', 
  RIG_RETURN: 'rigreturn'
  
}

export const PIPE_DATA_SLUGS = {
  GET_PIPE_LIST: '/data/get-pipe-data-from-filters',
  GET_NOMINAL_DATA: '/material/get-all-from-filters', 
  GET_QUALITY_CERTIFICATE: '/documents-management/get-quality-certificate', 
  GET_ACTUAL_DATA_COUNT_FROM_FILTERS: '/data/get-actual-data-count-from-filters'  
}
