import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import _ from 'lodash'
import moment from 'moment'
import styled from 'styled-components'

import { Card, FlexView, Icon, LoadingOverlay } from 'components/common'
import FilterableTable from 'components/common/FilterableTable'

import UnitSelect from 'containers/common/UnitSelect'
import StockTabs from 'containers/layout/StockTabs'
import ChartsCard from 'containers/pipe-age/ChartsCard'
import FilterDisplay from 'containers/pipe-age/FilterDisplay'
import FiltersModal from 'containers/pipe-age/FiltersModal'

import { AppContext } from 'stores/AppStore'
import { ExportContext } from 'stores/ExportStore'
import { PipeAgeContext } from 'stores/PipeAgeStore'
import { WarehouseContext } from 'stores/WarehouseStore'
import Cookies from 'js-cookie'

const PipeAgeIcon = styled(Icon)`
    width: 28px; 
    height: 28px; 
    margin: 0px 8px 0px 0px; 
`

const PipeAgePage = ({ stockKey }) => {
  const { t } = useTranslation()
  const { setPageSubtitle } = useContext(AppContext)
  const { lengthUnit, weightUnit, 
          convertToCurrentLengthUnit, 
          convertToCurrentWeightUnit, 
          formatNumberByCulture 
        } = useContext(AppContext)
  const { downloadBackup } = useContext(ExportContext)
  const { filteredPipeAgeTable: {filteredTableData}, setLoading, loading, chartData, updateFilteredData } = useContext(PipeAgeContext)
  const { exportToExcel, exportToPdf, setStock } = useContext(PipeAgeContext)
  const { warehouses } = useContext(WarehouseContext)
  
  const [showModal, setShowModal] = useState(false)

  const stock = useMemo(() => _.find(warehouses, { key: stockKey }), [stockKey, warehouses])
  
  useEffect(() => {
    if (stock) {
      Cookies.set('_currentWarehouse', stockKey)
      setStock(stock)
      setPageSubtitle(stock?.title + ' - ' + t('Pipe Age View'))
    }
  }, [stock, setPageSubtitle, t, setStock, stockKey])

  const filterOptions = [
    {
      accessor: 'end_user',
      label: t('End User')
    },
    {
      accessor: 'co_purchase_order',
      label:  t('CUSTOMER PO#')
    },
    {
        accessor: 'co_purchase_item',
        label:  t('CUSTOMER ITEM#')
    },
    {
      accessor: 'num_sales_order',
      label: t('SALES PO#')
    },
    {
        accessor: 'num_sales_item',
        label:  t('SALES ITEM#')
    },
    {
      accessor: 'dsc_material',
      label: t('Material Description')
    },
    {
      accessor: 'storage_type',
      label: t('Storage Type')
    },
    {
        accessor: 'entrance_date',
        label: t('Entrance Date'), 
        formatter: entrance_date => entrance_date ? moment(entrance_date).format('MMM D, YYYY, h:mm:ss A') : ''
    },
    {
        accessor: 'months_in_stock',
        label:  t('Months in Stock')
    },
    {
      accessor: 'qtd_pc',
      label: t('Pieces'),
      formatter: qtdPC => Number(qtdPC)
    },
    {
      accessor: 'total_footage_ft',
      label: t('Total Length')  + ' [ft]',
      formatter: totalFootage => Number(totalFootage).toFixed(3)
    },
    {
      accessor: 'total_footage_m',
      label: t('Total Length') + ' [m]',
      formatter: totalFootage => Number(totalFootage).toFixed(3)
    },
    {
        accessor: 'total_weight',
        label: `${t('Weight')} (${weightUnit})`, 
        formatter: total_weight => convertToCurrentWeightUnit(total_weight, 'kg')
    }
  ]

  const [isBackupDownloading, setIsBackupDownloading] = useState(false)

  const toggleModal = () => setShowModal(currentState => !currentState)

  const columns = useMemo(() => [
    {
      accessor: 'end_user',
      Header: t('End User'), 
      customCellProps: {
        style: {
            textAlign: 'center'
        }
      }
    },
    {
      Header: t('CUSTOMER PO#'),
      accessor: 'co_purchase_order',
      customCellProps: {
          style: {
              textAlign: 'center'
          }
      }
    },
    {
        Header: t('CUSTOMER ITEM#'),
        accessor: 'co_purchase_item',
        customHeaderProps: {
            style: {
                minWidth: '100px'
            }
        },
        customCellProps: {
            style: {
                textAlign: 'center'
            }
        }
    },
    {
      Header: t('SALES PO#'),
      accessor: 'num_sales_order',
      customHeaderProps: {
          style: {
              minWidth: '100px'
          }
      },
      customCellProps: {
          style: {
              textAlign: 'center'
          }
      }
    },
    {
        Header: t('SALES ITEM#'),
        accessor: 'num_sales_item',
        customCellProps: {
            style: {
                textAlign: 'center'
            }
        }
    },
    {
        Header: t('Material Description'),
        accessor: 'dsc_material',
        customHeaderProps: {
            style: {
                minWidth: '300px'
            }
        },
    },
    {
      Header: t('Storage Type'),
      accessor: 'storage_type',
    },
    {
        Header: t('Entrance Date'),
        accessor: ({ entrance_date }) => entrance_date ? moment(entrance_date).format('MMM D, YYYY, h:mm:ss A') : '',
        sortType: 'date',
        customHeaderProps: {
            style: {
                minWidth: '120px'
            }
        }
    },
    {
        Header: t('Months in Stock'),
        accessor: ({ months_in_stock }) => `${formatNumberByCulture(months_in_stock, 0)}`,
        // accessor: 'months_in_stock',
        customCellProps: {
          style: {
              textAlign: 'center'
          }
      }
    },
    {
        Header: `${t('Pieces')}`,
        accessor: 'qtd_pc',
        customHeaderProps: {
            style: {
                minWidth: '40px'
            }
        },
        customCellProps: {
            style: {
                textAlign: 'center'
            }
        }
    },
    {
        Header: `${t('Length')} (${lengthUnit})`,
        id: 'total_footage',
        accessor: ({ total_footage_ft, total_footage_m }) => `${convertToCurrentLengthUnit(total_footage_m, total_footage_ft, lengthUnit)}`,
        customCellProps: {
            style: {
                textAlign: 'center'
            }
        }
    },
    {
        Header: `${t('Weight')}  (${weightUnit})`,
        id: 'total_weight',
        accessor: ({ total_weight }) => `${convertToCurrentWeightUnit(total_weight, 'kg')}`,
        customCellProps: {
            style: {
                textAlign: 'center'
            }
        }
    }
  ], [t, weightUnit, lengthUnit, convertToCurrentLengthUnit, convertToCurrentWeightUnit, formatNumberByCulture])

  useEffect(() => {
    if (isBackupDownloading) {
      setLoading(true)
      downloadBackup(stock).catch(e => {
        console.log(e)
      }).finally(() => {
        setLoading(false)
        setIsBackupDownloading(false)
      })
    }
  }, [isBackupDownloading, downloadBackup, stock, setLoading])

  const onExcelExportClick = () => {
    exportToExcel(filterOptions, stock.key)
  }

  const onPdfExportClick = () => {
    exportToPdf(filterOptions, stock.key)
  }

  return <FlexView flex="1" position="relative" alignSelf="stretch">
    <StockTabs stock={stock} />
    <FlexView margin="16px 16px 8px" flexDirection="row" justifyContent="space-between" alignSelf="stretch">
      <FilterDisplay options={filterOptions} onTagClick={toggleModal} />
      <FlexView flexDirection="row" alignItems="center" justifyContent="flex-end" flex="1">
        <UnitSelect />
        <PipeAgeIcon name="filter" tooltip={t('Filter')} onClick={toggleModal} />
        <PipeAgeIcon name="xls" tooltip={t('Export to Excel')} onClick={onExcelExportClick} />
        <PipeAgeIcon name="pdf"tooltip={t('Export to PDF')} onClick={onPdfExportClick} />
      </FlexView>
    </FlexView>
    <ChartsCard chartData={chartData} />
    <Card alignSelf="stretch" padding="0px" margin="16px">
        <FilterableTable columns={columns} data={filteredTableData} onRowsChange={updateFilteredData} />
    </Card>
    <FiltersModal isOpen={showModal} onOutsideClick={toggleModal} options={filterOptions} />
    <LoadingOverlay visible={loading} />
  </FlexView>
}

export default PipeAgePage