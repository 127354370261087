import axios from 'axios'
import { addLogger } from 'utils/logger'
import { getDefaultHeader, manageError, manageResponse } from './api-util'

const SHOULD_DECOMPRESS = false

const instance = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}`,
    headers: {
        'Content-Type': 'application/json', 
        'VLR-Authorization': btoa(JSON.stringify({ "id": process.env.REACT_APP_ID }))
    }
})

addLogger(instance, false)

export default {
    getReceiptNotesList: async (params, idToken) => {
        try {
            const response = await instance.post('/receipt-notes/get-receipt-notes-list', params, getDefaultHeader())
            return manageResponse(response, SHOULD_DECOMPRESS)
        } catch (error) {
            manageError(error)
        }
    },
    getReceiptNotesData: async (params, idToken) => {
        try {
            const response = await instance.post('/receipt-notes/get-receipt-notes-data', params, getDefaultHeader())
            return manageResponse(response, SHOULD_DECOMPRESS)
        } catch (error) {
            manageError(error)
        }
    }
}