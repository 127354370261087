import { PERIODICAL_INSPECTION_HEADER_STYLE } from './header'

const PERIODICAL_INSPECTION_HEADER_CONFIG = [
    { id: 'id', label: 'ID', cellPosition: 'A5', mergedCells: 'A5:B5' },
    { id: 'date', label: 'DATE', cellPosition: 'A6', mergedCells: 'A6:B6' },
    { id: 'co_purchase_order', label: 'CUSTOMER PO#', cellPosition: 'A7', mergedCells: 'A7:B7' },
    { id: 'co_purchase_item', label: 'CUSTOMER ITEM#', cellPosition: 'A8', mergedCells: 'A8:B8' },
    { id: 'num_sales_order', label: 'SALES PO#', cellPosition: 'A9', mergedCells: 'A9:B9' },
    { id: 'num_sales_item', label: 'SALES ITEM#', cellPosition: 'A10', mergedCells: 'A10:B10' },
    { id: 'erp_ref', label: 'ERP REFERENCE', cellPosition: 'A11', mergedCells: 'A11:B11' },
    { id: 'dsc_material', label: 'MATERIAL', cellPosition: 'A12', mergedCells: 'A12:B12' },
    { id: 'end_user', label: 'END USER', cellPosition: 'A13', mergedCells: 'A13:B13' }
]

const PERIODICAL_INSPECTION_HEADER_CONTENT_CONFIG = [
    { id: 'id', accessor: 'id', cellPosition: 'C5', mergedCells: 'C5:G5' },
    { id: 'date', accessor: 'date', cellPosition: 'C6', mergedCells: 'C6:G6' },
    { id: 'co_purchase_order', accessor: 'co_purchase_order', cellPosition: 'C7', mergedCells: 'C7:G7' },
    { id: 'co_purchase_item', accessor: 'co_purchase_item', cellPosition: 'C8', mergedCells: 'C8:G8' },
    { id: 'num_sales_order', accessor: 'num_sales_order', cellPosition: 'C9', mergedCells: 'C9:G9' },
    { id: 'num_sales_item', accessor: 'num_sales_item', cellPosition: 'C10', mergedCells: 'C10:G10' },
    { id: 'erp_ref', accessor: 'erp_ref', cellPosition: 'C11', mergedCells: 'C11:G11' },
    { id: 'dsc_material', accessor: 'dsc_material', cellPosition: 'C12', mergedCells: 'C12:G12' },
    { id: 'end_user', accessor: 'end_user', cellPosition: 'C13', mergedCells: 'C13:G13' }
]

const PERIODICAL_INSPECTION_SECOND_HEADER_CONFIG = [
    { id: 'total_inspected', label: 'Inspected', cellPosition: 'I12', mergedCells: 'I12:I12' },
    { id: 'total_accepted', label: 'Accepted', cellPosition: 'J12', mergedCells: 'J12:J12' },
    { id: 'total_rejected', label: 'Scrap', cellPosition: 'K12', mergedCells: 'K12:K12' },
    { id: 'total_repair', label: 'To Repair', cellPosition: 'L12', mergedCells: 'L12:L12' }
]

const PERIODICAL_INSPECTION_SECOND_HEADER_CONTENT_CONFIG = [
    { id: 'total_inspected', accessor: 'total_inspected', cellPosition: 'I13', mergedCells: 'I13:I13' },
    { id: 'total_accepted', accessor: 'total_accepted', cellPosition: 'J13', mergedCells: 'J13:J13' },
    { id: 'total_rejected', accessor: 'total_rejected', cellPosition: 'K13', mergedCells: 'K13:K13' },
    { id: 'total_repair', accessor: 'total_repair', cellPosition: 'L13', mergedCells: 'L13:L13' }
]

const PERIODICAL_INSPECTION_DATA_HEADER_CONFIG = [
    { id: 'item_no', label: 'NO', cellPosition: 'A15', mergedCells: 'A15:A16' },
    { id: 'val_id', label: 'VALID', cellPosition: 'B15', mergedCells: 'B15:B16' },
    { id: 'mark_legibility', label: 'MARKING LEGIBILITY', cellPosition: 'C15', mergedCells: 'C15:C16' },
    { id: 'coating_condition', label: 'COATING/VARNISH CONDITION', cellPosition: 'D15', mergedCells: 'D15:D16' },
    { id: 'thread_protector', label: 'THREAD PROTECTORS', cellPosition: 'E15', mergedCells: 'E15:E16' },
    { id: 'thread', label: 'THREAD', cellPosition: 'F15', mergedCells: 'F15:G15' },
    { id: 'thread_pin', label: 'PIN', cellPosition: 'F16', mergedCells: 'F16:F16' },
    { id: 'thread_box', label: 'BOX', cellPosition: 'G16', mergedCells: 'G16:G16' },
    { id: 'pipe', label: 'PIPE BODY', cellPosition: 'H15', mergedCells: 'H15:I15' },
    { id: 'pipe_int', label: 'INTERNAL', cellPosition: 'H16', mergedCells: 'H16:H16' },
    { id: 'pipe_ext', label: 'EXTERNAL', cellPosition: 'I16', mergedCells: 'I16:I16' },
    { id: 'drift', label: 'DRIFT', cellPosition: 'J15', mergedCells: 'J15:J16' },
    { id: 'final_report', label: 'FINAL STATUS', cellPosition: 'K15', mergedCells: 'K15:K16' },
    { id: 'root_cause', label: 'ROOT CAUSE', cellPosition: 'L15', mergedCells: 'L15:L16' },
    { id: 'pic_ref', label: 'PICTURE REFERENCE', cellPosition: 'M15', mergedCells: 'M15:M16' },
    { id: 'inspector', label: 'INSPECTOR', cellPosition: 'N15', mergedCells: 'N15:N16' },
    { id: 'equipment', label: 'EQUIPMENT', cellPosition: 'O15', mergedCells: 'O15:O16' },
    { id: 'remarks', label: 'REMARKS', cellPosition: 'P15', mergedCells: 'P15:P16' }
]

const PERIODICAL_INSPECTION_DATA_CONTENT_CONFIG = [
    { id: 'item_no', accessor: 'item_no', cellPosition: 'A17', mergedCells: 'A17:A17' },
    { id: 'val_id', accessor: 'val_id', cellPosition: 'B17', mergedCells: 'B17:B17' },
    { id: 'mark_legibility', accessor: 'mark_legibility', cellPosition: 'C17', mergedCells: 'C17:C17' },
    { id: 'coating_condition', accessor: 'coating_condition', cellPosition: 'D17', mergedCells: 'D17:D17' },
    { id: 'thread_protector', accessor: 'thread_protector', cellPosition: 'E17', mergedCells: 'E17:E17' },

    { id: 'thread_pin', accessor: 'thread_pin', cellPosition: 'F17', mergedCells: 'F17:F17' },
    { id: 'thread_box', accessor: 'thread_box', cellPosition: 'G17', mergedCells: 'G17:G17' },

    { id: 'pipe_int', accessor: 'pipe_int', cellPosition: 'H17', mergedCells: 'H17:H17' },
    { id: 'pipe_ext', accessor: 'pipe_ext', cellPosition: 'I17', mergedCells: 'I17:I17' },
    { id: 'drift', accessor: 'drift', cellPosition: 'J17', mergedCells: 'J17:J17' },
    { id: 'final_report', accessor: 'final_report', cellPosition: 'K17', mergedCells: 'K17:K17' },
    { id: 'root_cause', accessor: 'root_cause', cellPosition: 'L17', mergedCells: 'L17:L17' },
    { id: 'pic_ref', accessor: 'pic_ref', cellPosition: 'M17', mergedCells: 'M17:M17' },
    { id: 'inspector', accessor: 'inspector', cellPosition: 'N17', mergedCells: 'N17:N17' },
    { id: 'equipment', accessor: 'equipment', cellPosition: 'O17', mergedCells: 'O17:O17' },
    { id: 'remarks', accessor: 'remarks', cellPosition: 'P17', mergedCells: 'P17:P17' }
]

const PERIODICAL_INSPECTION_IMAGE_SHEET_LABEL_CONFIG = [
    {
        id: "valid",
        label: "VALID",
        cellPosition: "A1",
        mergedCells: "A1:A1",
    },
    {
        id: "picture_reference",
        label: "PICTURE REFERENCE",
        cellPosition: "B1",
        mergedCells: "B1:B1",
    },
    {
        id: "picture",
        label: "PICTURE",
        cellPosition: "C1",
        mergedCells: "C1:C1",
    },
]

const PERIODICAL_INSPECTION_IMAGE_SHEET_CONTENT_CONFIG = [
    { id: "valid", accessor: "valid" },
    { id: "picture_reference", accessor: "picture_reference" },
    { id: "picture", accessor: "picture" }
]

const PERIODICAL_INSPECTION_IMAGE_DATA_CONTENT_ROW = 2

const PERIODICAL_INSPECTION_LOGO_POSITION = 'L2:P8'
const PERIODICAL_INSPECTION_DATA_CONTENT_ROW = 17

export const PERIODICAL_INSPECTION_CONFIG = {
    PERIODICAL_INSPECTION: {
        headerLabelConfig: PERIODICAL_INSPECTION_HEADER_CONFIG,
        headerContentConfig: PERIODICAL_INSPECTION_HEADER_CONTENT_CONFIG,
        secondHeaderLabelConfig: PERIODICAL_INSPECTION_SECOND_HEADER_CONFIG,
        secondHeaderContentConfig: PERIODICAL_INSPECTION_SECOND_HEADER_CONTENT_CONFIG,
        secondHeaderLabelStyle: PERIODICAL_INSPECTION_HEADER_STYLE.PI_LABEL,
        secondHeaderContentStyle: PERIODICAL_INSPECTION_HEADER_STYLE.PI_CONTENT,
        dataLabelConfig: PERIODICAL_INSPECTION_DATA_HEADER_CONFIG,
        dataContentConfig: PERIODICAL_INSPECTION_DATA_CONTENT_CONFIG,
        dataContentRow: PERIODICAL_INSPECTION_DATA_CONTENT_ROW,
        dataLogoRow: PERIODICAL_INSPECTION_LOGO_POSITION,
        headerImageSheetConfig: PERIODICAL_INSPECTION_IMAGE_SHEET_LABEL_CONFIG,
        dataImageContentConfig: PERIODICAL_INSPECTION_IMAGE_SHEET_CONTENT_CONFIG,
        dataImageContentRow: PERIODICAL_INSPECTION_IMAGE_DATA_CONTENT_ROW
    }
}