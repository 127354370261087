import {
    MIDDLE_CENTER_ALIGNMENT,
    THIN_FULL_BORDER,
    FONT_NAME_BOLD,
    BOTTOM_LEFT_ALIGNMENT,
    FONT_NAME_NORMAL,
} from "../styles"

const DELIVERY_NOTES_SIGNATURE_HEADER_STYLE = {
    alignment: MIDDLE_CENTER_ALIGNMENT,
    border: THIN_FULL_BORDER,
    fill: null,
    height: 30,
    font: {
        name: FONT_NAME_BOLD,
        size: 13,
        bold: true,
    },
}

const DELIVERY_NOTES_SIGNATURE_CONTENT_STYLE = {
    alignment: BOTTOM_LEFT_ALIGNMENT,
    border: THIN_FULL_BORDER,
    fill: null,
    height: 85,
    font: {
        name: FONT_NAME_NORMAL,
        size: 10,
        bold: false,
    },
}

export const DELIVERY_NOTES_STYLES = {
    SIGNATURE_HEADER: DELIVERY_NOTES_SIGNATURE_HEADER_STYLE,
    SIGNATURE_CONTENT: DELIVERY_NOTES_SIGNATURE_CONTENT_STYLE,
}

const DELIVERY_NOTES_SIGNATURE_GAP = 5

// RIG PREP
const DELIVERY_NOTES_RIG_PREP_HEADER_LABEL_CONFIG = [
    { id: "id", label: "ID List", cellPosition: "A5", mergedCells: "A5:B5" },
    {
        id: "date",
        label: "Rig Prep Date",
        cellPosition: "A6",
        mergedCells: "A6:B6",
    },
    {
        id: "mr_ref",
        label: "MR Number",
        cellPosition: "A7",
        mergedCells: "A7:B7",
    },
    {
        id: "field",
        label: "Field Name",
        cellPosition: "A8",
        mergedCells: "A8:B8",
    },
    { id: "rig", label: "Rig Name", cellPosition: "A9", mergedCells: "A9:B9" },
    {
        id: "well",
        label: "Well Name",
        cellPosition: "A10",
        mergedCells: "A10:B10",
    },
    {
        id: "ltpa",
        label: "LTPA Number",
        cellPosition: "A11",
        mergedCells: "A11:B11",
    },
    {
        id: "owner_name",
        label: "MATERIAL OWNERSHIP",
        cellPosition: "A12",
        mergedCells: "A12:B12",
    },
    {
        id: "end_user",
        label: "END USER",
        cellPosition: "A13",
        mergedCells: "A13:B13",
    },
    {
        id: "total_weight",
        label: "OVERALL WEIGHT",
        cellPosition: "A14",
        mergedCells: "A14:B14",
    },
    { 
        id: "co_purchase_order", 
        label: "CUSTOMER PO#", 
        cellPosition: "A15",
        mergedCells: "A15:B15",
    }
]

const DELIVERY_NOTES_RIG_PREP_HEADER_CONTENT_CONFIG = [
    { id: "id", accessor: "id", cellPosition: "C5", mergedCells: "C5:D5" },
    { id: "date", accessor: "date", cellPosition: "C6", mergedCells: "C6:D6" },
    {
        id: "mr_ref",
        accessor: "mr_ref",
        cellPosition: "C7",
        mergedCells: "C7:D7",
    },
    {
        id: "field",
        accessor: "field",
        cellPosition: "C8",
        mergedCells: "C8:D8",
    },
    { id: "rig", accessor: "rig", cellPosition: "C9", mergedCells: "C9:D9" },
    {
        id: "well",
        accessor: "well",
        cellPosition: "C10",
        mergedCells: "C10:D10",
    },
    {
        id: "ltpa",
        accessor: "ltpa",
        cellPosition: "C11",
        mergedCells: "C11:D11",
    },
    {
        id: "owner_name",
        accessor: "owner_name",
        cellPosition: "C12",
        mergedCells: "C12:D12",
    },
    {
        id: "end_user",
        accessor: "end_user",
        cellPosition: "C13",
        mergedCells: "C13:D13",
    },
    {
        id: "total_weight",
        accessor: "total_weight",
        cellPosition: "C14",
        mergedCells: "C14:D14",
    },
    { 
        id: "co_purchase_order", 
        accessor: "co_purchase_order", 
        cellPosition: "C15",
        mergedCells: "C15:D15",
    }
]

//  PHYSICAL TRANSFER
const DELIVERY_NOTES_MAT_PHYSICAL_TRANSFER_HEADER_LABEL_CONFIG = [
    { id: "id", label: "ID List", cellPosition: "A5", mergedCells: "A5:B5" },
    { id: "date", label: "Date", cellPosition: "A6", mergedCells: "A6:B6" },
    {
        id: "mr_ref",
        label: "MR Number",
        cellPosition: "A7",
        mergedCells: "A7:B7",
    },
    {
        id: "field",
        label: "Field Name",
        cellPosition: "A8",
        mergedCells: "A8:B8",
    },
    { id: "rig", label: "Rig Name", cellPosition: "A9", mergedCells: "A9:B9" },
    {
        id: "well",
        label: "Well Name",
        cellPosition: "A10",
        mergedCells: "A10:B10",
    },
    {
        id: "ltpa",
        label: "LTPA Number",
        cellPosition: "A11",
        mergedCells: "A11:B11",
    },
    {
        id: "location",
        label: "Delivery Location",
        cellPosition: "A12",
        mergedCells: "A12:B12",
    },
    {
        id: "owner_name",
        label: "MATERIAL OWNERSHIP",
        cellPosition: "A13",
        mergedCells: "A13:B13",
    },
    {
        id: "end_user",
        label: "END USER",
        cellPosition: "A14",
        mergedCells: "A14:B14",
    },
    {
        id: "total_weight",
        label: "OVERALL WEIGHT",
        cellPosition: "A15",
        mergedCells: "A15:B15",
    },
    { 
        id: "co_purchase_order", 
        label: "CUSTOMER PO#", 
        cellPosition: "A16",
        mergedCells: "A16:B16",
    }
]

const DELIVERY_NOTES_MAT_PHYSICAL_TRANSFER_HEADER_CONTENT_CONFIG = [
    { id: "id", accessor: "id", cellPosition: "C5", mergedCells: "C5:D5" },
    { id: "date", accessor: "date", cellPosition: "C6", mergedCells: "C6:D6" },
    {
        id: "mr_ref",
        accessor: "mr_ref",
        cellPosition: "C7",
        mergedCells: "C7:D7",
    },
    {
        id: "field",
        accessor: "field",
        cellPosition: "C8",
        mergedCells: "C8:D8",
    },
    { id: "rig", accessor: "rig", cellPosition: "C9", mergedCells: "C9:D9" },
    {
        id: "well",
        accessor: "well",
        cellPosition: "C10",
        mergedCells: "C10:D10",
    },
    { id: "ltpa", accessor: "ltpa", cellPosition: "C11", mergedCells: "C11:D11" },
    {
        id: "location",
        accessor: "location",
        cellPosition: "C12",
        mergedCells: "C12:D12",
    },
    {
        id: "owner_name",
        accessor: "owner_name",
        cellPosition: "C13",
        mergedCells: "C13:D13",
    },
    {
        id: "end_user",
        accessor: "end_user",
        cellPosition: "C14",
        mergedCells: "C14:D14",
    },
    {
        id: "total_weight",
        accessor: "total_weight",
        cellPosition: "C15",
        mergedCells: "C15:D15",
    },
    { 
        id: "co_purchase_order", 
        accessor: "co_purchase_order", 
        cellPosition: "C16",
        mergedCells: "C16:D16",
    }
]

// MAT MODIFICATION

const DELIVERY_NOTES_MAT_MODIFICATION_HEADER_LABEL_CONFIG = [
    { id: "id", label: "ID List", cellPosition: "A5", mergedCells: "A5:B5" },
    {
        id: "date",
        label: "Material Preparation Date",
        cellPosition: "A6",
        mergedCells: "A6:B6",
    },
    {
        id: "mr_ref",
        label: "MR Number",
        cellPosition: "A7",
        mergedCells: "A7:B7",
    },
    {
        id: "field",
        label: "Field Name",
        cellPosition: "A8",
        mergedCells: "A8:B8",
    },
    { id: "rig", label: "Rig Name", cellPosition: "A9", mergedCells: "A9:B9" },
    {
        id: "well",
        label: "Well Name",
        cellPosition: "A10",
        mergedCells: "A10:B10",
    },
    {
        id: "tparty_name",
        label: "Third Party Workshop Name",
        cellPosition: "A11",
        mergedCells: "A11:B11",
    },
    {
        id: "tparty_loc",
        label: "Third Party Workshop Location",
        cellPosition: "A12",
        mergedCells: "A12:B12",
    },
    {
        id: "ltpa",
        label: "LTPA Number",
        cellPosition: "A13",
        mergedCells: "A13:B13",
    },
    {
        id: "owner_name",
        label: "MATERIAL OWNERSHIP",
        cellPosition: "A14",
        mergedCells: "A14:B14",
    },
    {
        id: "end_user",
        label: "END USER",
        cellPosition: "A15",
        mergedCells: "A15:B15",
    },
    {
        id: "total_weight",
        label: "OVERALL WEIGHT",
        cellPosition: "A16",
        mergedCells: "A16:B16",
    },
    { 
        id: "co_purchase_order", 
        label: "CUSTOMER PO#", 
        cellPosition: "A17",
        mergedCells: "A17:B17",
    }
]

const DELIVERY_NOTES_MAT_MODIFICATION_HEADER_CONTENT_CONFIG = [
    { id: "id", accessor: "id", cellPosition: "C5", mergedCells: "C5:D5" },
    { id: "date", accessor: "date", cellPosition: "C6", mergedCells: "C6:D6" },
    {
        id: "mr_ref",
        accessor: "mr_ref",
        cellPosition: "C7",
        mergedCells: "C7:D7",
    },
    {
        id: "field",
        accessor: "field",
        cellPosition: "C8",
        mergedCells: "C8:D8",
    },
    { id: "rig", accessor: "rig", cellPosition: "C9", mergedCells: "C9:D9" },
    {
        id: "well",
        accessor: "well",
        cellPosition: "C10",
        mergedCells: "C10:D10",
    },
    {
        id: "tparty_name",
        accessor: "tparty_name",
        cellPosition: "C11",
        mergedCells: "C11:D11",
    },
    {
        id: "locattparty_locion",
        accessor: "tparty_loc",
        cellPosition: "C12",
        mergedCells: "C12:D12",
    },
    {
        id: "ltpa",
        accessor: "ltpa",
        cellPosition: "C13",
        mergedCells: "C13:D13",
    },
    {
        id: "owner_name",
        accessor: "owner_name",
        cellPosition: "C14",
        mergedCells: "C14:D14",
    },
    {
        id: "end_user",
        accessor: "end_user",
        cellPosition: "C15",
        mergedCells: "C15:D15",
    },
    {
        id: "total_weight",
        accessor: "total_weight",
        cellPosition: "C16",
        mergedCells: "C16:D16",
    },
    { 
        id: "co_purchase_order", 
        accessor: "co_purchase_order", 
        cellPosition: "C17",
        mergedCells: "C17:D17",
    }
]

// DELIVERY TO THIRD PARTY
const DELIVERY_NOTES_DELIVERY_THIRD_PARTY_HEADER_LABEL_CONFIG = [
    { id: "id", label: "ID List", cellPosition: "A5", mergedCells: "A5:B5" },
    {
        id: "date",
        label: "Material Preparation Date",
        cellPosition: "A6",
        mergedCells: "A6:B6",
    },
    {
        id: "mr_ref",
        label: "MR Number",
        cellPosition: "A7",
        mergedCells: "A7:B7",
    },
    {
        id: "field",
        label: "Field Name",
        cellPosition: "A8",
        mergedCells: "A8:B8",
    },
    { id: "rig", label: "Rig Name", cellPosition: "A9", mergedCells: "A9:B9" },
    {
        id: "well",
        label: "Well Name",
        cellPosition: "A10",
        mergedCells: "A10:B10",
    },
    {
        id: "tparty_name",
        label: "Third Party Workshop Name",
        cellPosition: "A11",
        mergedCells: "A11:B11",
    },
    {
        id: "tparty_loc",
        label: "Third Party Workshop Location",
        cellPosition: "A12",
        mergedCells: "A12:B12",
    },
    {
        id: "ltpa",
        label: "LTPA Number",
        cellPosition: "A13",
        mergedCells: "A13:B13",
    },
    {
        id: "owner_name",
        label: "MATERIAL OWNERSHIP",
        cellPosition: "A14",
        mergedCells: "A14:B14",
    },
    {
        id: "end_user",
        label: "END USER",
        cellPosition: "A15",
        mergedCells: "A15:B15",
    },
    {
        id: "total_weight",
        label: "OVERALL WEIGHT",
        cellPosition: "A16",
        mergedCells: "A16:B16",
    },
    { 
        id: "co_purchase_order", 
        label: "CUSTOMER PO#", 
        cellPosition: "A17",
        mergedCells: "A17:B17",
    }
]

const DELIVERY_NOTES_DELIVERY_THIRD_PARTY_HEADER_CONTENT_CONFIG = [
    { id: "id", accessor: "id", cellPosition: "C5", mergedCells: "C5:D5" },
    { id: "date", accessor: "date", cellPosition: "C6", mergedCells: "C6:D6" },
    {
        id: "mr_ref",
        accessor: "mr_ref",
        cellPosition: "C7",
        mergedCells: "C7:D7",
    },
    {
        id: "field",
        accessor: "field",
        cellPosition: "C8",
        mergedCells: "C8:D8",
    },
    { id: "rig", accessor: "rig", cellPosition: "C9", mergedCells: "C9:D9" },
    {
        id: "well",
        accessor: "well",
        cellPosition: "C10",
        mergedCells: "C10:D10",
    },
    {
        id: "tparty_name",
        accessor: "tparty_name",
        cellPosition: "C11",
        mergedCells: "C11:D11",
    },
    {
        id: "locattparty_locion",
        accessor: "tparty_loc",
        cellPosition: "C12",
        mergedCells: "C12:D12",
    },
    {
        id: "ltpa",
        accessor: "ltpa",
        cellPosition: "C13",
        mergedCells: "C13:D13",
    },
    {
        id: "owner_name",
        accessor: "owner_name",
        cellPosition: "C14",
        mergedCells: "C14:D14",
    },
    {
        id: "end_user",
        accessor: "end_user",
        cellPosition: "C15",
        mergedCells: "C15:D15",
    },
    {
        id: "total_weight",
        accessor: "total_weight",
        cellPosition: "C16",
        mergedCells: "C16:D16",
    },
    { 
        id: "co_purchase_order", 
        accessor: "co_purchase_order", 
        cellPosition: "C17",
        mergedCells: "C17:D17",
    }
]

const DELIVERY_NOTES_DATA_LABEL_CONFIG = [
    { id: "item_no", label: "ITEM NO", cellPosition: "A19", mergedCells: "A19:A19" },
    { id: "co_purchase_item", label: "CUSTOMER ITEM#", cellPosition: "B19", mergedCells: "B19:B19" },
    { id: "num_sales_order", label: "SALES PO#", cellPosition: "C19", mergedCells: "C19:C19" },
    { id: "num_sales_item", label: "SALES ITEM#", cellPosition: "D19", mergedCells: "D19:D19" },
    { id: "cat_id", label: "ERP REFERENCE", cellPosition: "E19", mergedCells: "E19:E19" },
    { id: "dsc_material", label: "MATERIAL", cellPosition: "F19", mergedCells: "F19:F19" },
    { id: "quantity_prep", label: "TOTAL JOINTS", cellPosition: "G19", mergedCells: "G19:G19" },
    { id: "total_length", label: "TOTAL MEASURED LENGTH [ft]", cellPosition: "H19", mergedCells: "H19:H19" },
    { id: "total_weight", label: "TOTAL WEIGHT [kg]", cellPosition: "I19", mergedCells: "I19:I19" },
]

const DELIVERY_NOTES_DATA_CONTENT_CONFIG = [
    { id: "item_no", accessor: "item_no" },
    { id: "co_purchase_item", accessor: "co_purchase_item" },
    { id: "num_sales_order", accessor: "num_sales_order" },
    { id: "num_sales_item", accessor: "num_sales_item" },
    { id: "cat_id", accessor: "cat_id" },
    { id: "dsc_material", accessor: "dsc_material" },
    { id: "quantity_prep", accessor: "quantity_prep" },
    { id: "total_length", accessor: "total_length" },
    { id: "total_weight", accessor: "total_weight" },
]

const DELIVERY_NOTES_RIG_PREP_DATA_LABEL_CONFIG = [
    { id: "item_no", label: "ITEM NO", cellPosition: "A17", mergedCells: "A17:A17" },
    { id: "co_purchase_item", label: "CUSTOMER ITEM#", cellPosition: "B17", mergedCells: "B17:B17" },
    { id: "num_sales_order", label: "SALES PO#", cellPosition: "C17", mergedCells: "C17:C17" },
    { id: "num_sales_item", label: "SALES ITEM#", cellPosition: "D17", mergedCells: "D17:D17" },
    { id: "cat_id", label: "ERP REFERENCE", cellPosition: "E17", mergedCells: "E17:E17" },
    { id: "dsc_material", label: "MATERIAL", cellPosition: "F17", mergedCells: "F17:F17" },
    { id: "quantity_prep", label: "TOTAL JOINTS", cellPosition: "G17", mergedCells: "G17:G17" },
    { id: "total_length", label: "TOTAL MEASURED LENGTH [ft]", cellPosition: "H17", mergedCells: "H17:H17" },
    { id: "total_weight", label: "TOTAL WEIGHT [kg]", cellPosition: "I17", mergedCells: "I17:I17" },
]

const DELIVERY_NOTES_SIGNATURE_SHIPPER_CONFIG = [
    {
        id: "shipper",
        label: "SHIPPER",
        mergeColumns: ["C", "F"],
        style: DELIVERY_NOTES_SIGNATURE_HEADER_STYLE,
    },
    {
        id: "name",
        label: "Name:",
        mergeColumns: ["C", "F"],
        style: DELIVERY_NOTES_SIGNATURE_CONTENT_STYLE,
    },
    {
        id: "signature",
        label: "Signature:",
        mergeColumns: ["C", "F"],
        style: DELIVERY_NOTES_SIGNATURE_CONTENT_STYLE,
    },
    {
        id: "date",
        label: "Date:",
        mergeColumns: ["C", "F"],
        style: DELIVERY_NOTES_SIGNATURE_CONTENT_STYLE,
    },
    {
        id: "stamp",
        label: "Stamp:",
        mergeColumns: ["C", "F"],
        style: DELIVERY_NOTES_SIGNATURE_CONTENT_STYLE,
    },
]

const DELIVERY_NOTES_SIGNATURE_RECEIVER_CONFIG = [
    {
        id: "receiver",
        label: "RECEIVER",
        mergeColumns: ["G", "H"],
        style: DELIVERY_NOTES_SIGNATURE_HEADER_STYLE,
    },
    {
        id: "name",
        label: "Name:",
        mergeColumns: ["G", "H"],
        style: DELIVERY_NOTES_SIGNATURE_CONTENT_STYLE,
    },
    {
        id: "signature",
        label: "Signature:",
        mergeColumns: ["G", "H"],
        style: DELIVERY_NOTES_SIGNATURE_CONTENT_STYLE,
    },
    {
        id: "date",
        label: "Date:",
        mergeColumns: ["G", "H"],
        style: DELIVERY_NOTES_SIGNATURE_CONTENT_STYLE,
    },
    {
        id: "stamp",
        label: "Stamp:",
        mergeColumns: ["G", "H"],
        style: DELIVERY_NOTES_SIGNATURE_CONTENT_STYLE,
    },
]

const DELIVERY_NOTES_DATA_CONTENT_ROW = 20
const DELIVERY_NOTES_RIG_PREP_DATA_CONTENT_ROW = 18

export const DELIVERY_NOTES_CONFIG = {
    DELIVERY_NOTES_DELIVERY_THIRD_PARTY: {
        headerLabelConfig: DELIVERY_NOTES_DELIVERY_THIRD_PARTY_HEADER_LABEL_CONFIG,
        headerContentConfig: DELIVERY_NOTES_DELIVERY_THIRD_PARTY_HEADER_CONTENT_CONFIG,
        dataLabelConfig: DELIVERY_NOTES_DATA_LABEL_CONFIG,
        dataContentConfig: DELIVERY_NOTES_DATA_CONTENT_CONFIG,
        dataContentRow: DELIVERY_NOTES_DATA_CONTENT_ROW,
        signatureShipper: DELIVERY_NOTES_SIGNATURE_SHIPPER_CONFIG,
        signatureReceiver: DELIVERY_NOTES_SIGNATURE_RECEIVER_CONFIG,
        signatureGap: DELIVERY_NOTES_SIGNATURE_GAP,
    },
    DELIVERY_NOTES_MAT_MODIFICATION: {
        headerLabelConfig: DELIVERY_NOTES_MAT_MODIFICATION_HEADER_LABEL_CONFIG,
        headerContentConfig: DELIVERY_NOTES_MAT_MODIFICATION_HEADER_CONTENT_CONFIG,
        dataLabelConfig: DELIVERY_NOTES_DATA_LABEL_CONFIG,
        dataContentConfig: DELIVERY_NOTES_DATA_CONTENT_CONFIG,
        dataContentRow: DELIVERY_NOTES_DATA_CONTENT_ROW,
        signatureShipper: DELIVERY_NOTES_SIGNATURE_SHIPPER_CONFIG,
        signatureReceiver: DELIVERY_NOTES_SIGNATURE_RECEIVER_CONFIG,
        signatureGap: DELIVERY_NOTES_SIGNATURE_GAP,
    },
    DELIVERY_NOTES_MAT_PHYSICAL_TRANSFER: {
        headerLabelConfig: DELIVERY_NOTES_MAT_PHYSICAL_TRANSFER_HEADER_LABEL_CONFIG,
        headerContentConfig: DELIVERY_NOTES_MAT_PHYSICAL_TRANSFER_HEADER_CONTENT_CONFIG,
        dataLabelConfig: DELIVERY_NOTES_DATA_LABEL_CONFIG,
        dataContentConfig: DELIVERY_NOTES_DATA_CONTENT_CONFIG,
        dataContentRow: DELIVERY_NOTES_DATA_CONTENT_ROW,
        signatureShipper: DELIVERY_NOTES_SIGNATURE_SHIPPER_CONFIG,
        signatureReceiver: DELIVERY_NOTES_SIGNATURE_RECEIVER_CONFIG,
        signatureGap: DELIVERY_NOTES_SIGNATURE_GAP,
    },
    DELIVERY_NOTES_RIG_PREP: {
        headerLabelConfig: DELIVERY_NOTES_RIG_PREP_HEADER_LABEL_CONFIG,
        headerContentConfig: DELIVERY_NOTES_RIG_PREP_HEADER_CONTENT_CONFIG,
        dataLabelConfig: DELIVERY_NOTES_RIG_PREP_DATA_LABEL_CONFIG,
        dataContentConfig: DELIVERY_NOTES_DATA_CONTENT_CONFIG,
        dataContentRow: DELIVERY_NOTES_RIG_PREP_DATA_CONTENT_ROW,
        signatureShipper: DELIVERY_NOTES_SIGNATURE_SHIPPER_CONFIG,
        signatureReceiver: DELIVERY_NOTES_SIGNATURE_RECEIVER_CONFIG,
        signatureGap: DELIVERY_NOTES_SIGNATURE_GAP,
    },
}
