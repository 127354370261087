const BACKLOAD_MANIFEST_HEADER_CONFIG = [
    { id: 'date', label: 'RIG RETURN DATE', cellPosition: 'A5', mergedCells: 'A5:B5' },
    { id: 'field_name', label: 'FIELD NAME', cellPosition: 'A6', mergedCells: 'A6:B6' },
    { id: 'rig_name', label: 'RIG NAME', cellPosition: 'A7', mergedCells: 'A7:B7' },
    { id: 'well_name', label: 'WELL NAME', cellPosition: 'A8', mergedCells: 'A8:B8' },
    { id: 'mr_number', label: 'RR NUMBER', cellPosition: 'A9', mergedCells: 'A9:B9' },

    { id: 'nt_quantity', label: 'Non Traceable Pipe', cellPosition: 'G9', mergedCells: 'G9:G9' }
]

const BACKLOAD_MANIFEST_HEADER_CONTENT_CONFIG = [
    { id: 'date', accessor: 'date', cellPosition: 'C5', mergedCells: 'C5:D5' },
    { id: 'field_name', accessor: 'field_name', cellPosition: 'C6', mergedCells: 'C6:D6' },
    { id: 'rig_name', accessor: 'rig_name', cellPosition: 'C7', mergedCells: 'C7:D7' },
    { id: 'well_name', accessor: 'well_name', cellPosition: 'C8', mergedCells: 'C8:D8' },
    { id: 'mr_number', accessor: 'mr_number', cellPosition: 'C9', mergedCells: 'C9:D9' },

    { id: 'nt_quantity', accessor: 'nt_quantity', cellPosition: 'H9', mergedCells: 'H9:H9' }
]

const BACKLOAD_MANIFEST_DATA_HEADER_CONFIG = [
    { id: 'item_no', label: 'ITEM NO', cellPosition: 'A11', mergedCells: 'A11:A12' },
    { id: 'co_purchase_order', label: 'CUSTOMER ORDER#', cellPosition: 'B11', mergedCells: 'B11:B12' },
    { id: 'co_purchase_item', label: 'CUSTOMER ITEM#', cellPosition: 'C11', mergedCells: 'C11:C12' },
    { id: 'num_sales_order', label: 'SALES ORDER', cellPosition: 'D11', mergedCells: 'D11:D12' },
    { id: 'num_sales_item', label: 'SALES ITEM', cellPosition: 'E11', mergedCells: 'E11:E12' },
    { id: 'dsc_material', label: 'MATERIAL', cellPosition: 'F11', mergedCells: 'F11:F12' },
    { id: 'total_joints', label: 'TOTAL JOINTS', cellPosition: 'G11', mergedCells: 'G11:G12' },
    { id: 'total_length', label: 'TOTAL LENGTH [ft]', cellPosition: 'H11', mergedCells: 'H11:H12' },
    { id: 'total_weight', label: 'TOTAL WEIGHT [kg]', cellPosition: 'I11', mergedCells: 'I11:I12' }
]

const BACKLOAD_MANIFEST_DATA_CONTENT_CONFIG = [
    { id: 'item_no', accessor: 'item_no', cellPosition: 'A13' },
    { id: 'co_purchase_order', accessor: 'co_purchase_order', cellPosition: 'B13' },
    { id: 'co_purchase_item', accessor: 'co_purchase_item', cellPosition: 'C13' },
    { id: 'num_sales_order', accessor: 'num_sales_order', cellPosition: 'D13' },
    { id: 'num_sales_item', accessor: 'num_sales_item', cellPosition: 'E13' },
    { id: 'dsc_material', accessor: 'dsc_material', cellPosition: 'F13' },
    { id: 'total_joints', accessor: 'total_joints', cellPosition: 'G13' },
    { id: 'total_length', accessor: 'total_length', cellPosition: 'H13' },
    { id: 'total_weight', accessor: 'total_weight', cellPosition: 'I13' }
]

const BACKLOAD_MANIFEST_LOGO_ROW = 'F3:G8'
const BACKLOAD_MANIFEST_CONTENT_ROW = 13

export const BACKLOAD_MANIFEST_CONFIG = {
    BACKLOAD_MANIFEST: {
        headerLabelConfig: BACKLOAD_MANIFEST_HEADER_CONFIG,
        headerContentConfig: BACKLOAD_MANIFEST_HEADER_CONTENT_CONFIG,
        dataLabelConfig: BACKLOAD_MANIFEST_DATA_HEADER_CONFIG,
        dataContentConfig: BACKLOAD_MANIFEST_DATA_CONTENT_CONFIG,
        dataContentRow: BACKLOAD_MANIFEST_CONTENT_ROW,
        dataLogoRow: BACKLOAD_MANIFEST_LOGO_ROW
    }
}