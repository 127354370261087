import React, { useContext, useMemo, useEffect, useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import _ from 'lodash'
import moment from 'moment'

import receivingInspection from 'api/receiving-inspection'
import documentManagement from 'api/document-management'

import { LoadingOverlay, FlexView, Icon, Card } from 'components/common'
import FilterableTable from 'components/common/FilterableTable'

import StockTabs from 'containers/layout/StockTabs'

import { AppContext } from 'stores/AppStore'
import { UserContext } from 'stores/UserStore'
import { WarehouseContext } from 'stores/WarehouseStore'

import { templateType } from 'provider/types'
import { generateSpreadsheet } from 'provider/excelGeneration'

import { filenames, S3_TYPE_SUBFOLDER } from 'utils/constants'
import { checkIfCanSearchImages, getFileName } from 'utils/helpers'

import apiDataReport from 'api/report-data'

import FilterDisplay from 'containers/common/FilterDisplay'
import FiltersModal from 'containers/common/FiltersModal'
import { FilterContext } from 'stores/FilterStore'

const formatReports = reports => _.chain(reports)
  .orderBy('inspection_date', 'desc')
  .value()

const ReceivingInspectionReports = ({ stockKey }) => {
  const { t } = useTranslation()
  const { setPageSubtitle, datasets, formatNumberByCulture } = useContext(AppContext)
  const { endUsers, idToken } = useContext(UserContext)
  const { warehouses } = useContext(WarehouseContext)

  const stock = useMemo(() => _.find(warehouses, { key: stockKey }), [warehouses, stockKey])
  
  const [loading, setLoading] = useState(true)
  const [showModal, setShowModal] = useState(false)

  const { filteredData: {filteredTableData }, data, setData } = useContext(FilterContext)

  const MAX_IMAGES_PER_CALL = 30 

  useEffect(() => {
    if (stock) {
      setPageSubtitle(stock?.title + ' - ' + t('Receiving Inspection Reports'))
    }
  }, [stock, setPageSubtitle, t])

  const executeManyCallsToGetInspectionImages = useCallback( async (imageRows, type) => {
    const numberOfExecs = Math.ceil(imageRows?.length/MAX_IMAGES_PER_CALL)
    const promises = []
    let contOffset = 0
    _.range(1, numberOfExecs+1, 1).forEach(execNo =>{
        const limit = MAX_IMAGES_PER_CALL*execNo
        const partialImageRows = imageRows.slice(contOffset, limit)
        promises.push(apiDataReport.getReportImages({ 
          imageRows: partialImageRows, type: type 
        }, idToken))
          contOffset = contOffset+30
    })
    const imageData = []
    const results = await Promise.all(promises)
    if(results){
      results.forEach(item => { imageData.push(...item) })
    }       
    return imageData
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const downloadReport = useCallback(async report => {
    setLoading(true)
    try {
      const reportData = await receivingInspection.getReceivingInspectionData({reportId: report.rlid}, idToken)
      const filename = getFileName(filenames['RECEIVING_INSPECTION'], report.rlid)
      let reportImagesContent = []
      if(checkIfCanSearchImages(reportData)){
        reportImagesContent = await executeManyCallsToGetInspectionImages(reportData.data.imageRows, S3_TYPE_SUBFOLDER.RECEIVING_INSPECTION)
      }
      const logoBase64 = await documentManagement.getStockLogo({ wid: stock.wid }, idToken)
      generateSpreadsheet(filename, reportData, templateType.RECEIVING_INSPECTION,  logoBase64, reportImagesContent)
      toast.info(t('documents.popupInfo'))
    }
    catch (error) {
      console.log(error)
      toast.error(error.message)
    }
    finally {
      setLoading(false)
    }
  }, [executeManyCallsToGetInspectionImages, idToken, stock, t])

  const onDownloadClick = useCallback(report => () => {
    downloadReport(report)
  }, [downloadReport])

  useEffect(() => {
    async function fetchData() {
      setLoading(true)
      try {
        const data = await receivingInspection.getReceivingInspectionList({ wid: stock.wid }, idToken)
        setData(formatReports(data))
      } catch (error) {
        setLoading(false)
        toast.error(error.message || error)
      } finally {
        setLoading(false)
      }
    }
    fetchData()
  }, [endUsers, datasets, idToken, stock, setData])

  const columns = useMemo(() => [
    {
      accessor: 'rlid',
      Header: t('ID'),
      label: t('ID'),
      customHeaderProps: {
        style: {
          minWidth: '40px'
        }
      }
    },
    {
      Header: t('CUSTOMER PO#'),
      accessor: 'customer_po',
      label: t('CUSTOMER PO#'),
      customHeaderProps: {
        style: {
          minWidth: '80px'
        }
      },
      customCellProps: {
        style: {
          textAlign: 'center'
        }
      }
    },
    {
      Header: t('CUSTOMER ITEM#'),
      accessor: 'customer_item',
      label: t('CUSTOMER ITEM#'),
      customHeaderProps: {
        style: {
          minWidth: '60px'
        }
      },
      customCellProps: {
        style: {
          textAlign: 'center'
        }
      }
    },
    {
      Header: t('SALES PO#'),
      accessor: 'num_sales_order',
      label: t('SALES PO#'),
      customHeaderProps: {
        style: {
          minWidth: '80px'
        }
      },
      customCellProps: {
        style: {
          textAlign: 'center'
        }
      }
    },
    {
      Header: t('SALES ITEM#'),
      accessor: 'num_sales_item',
      label: t('SALES ITEM#'),
      customHeaderProps: {
        style: {
          minWidth: '60px'
        }
      },
      customCellProps: {
        style: {
          textAlign: 'center'
        }
      }
    },
    {
      id: 'inspection_date',
      Header: t('Inspection Date'),
      label: t('Inspection Date'),
      accessor: 'inspection_date',
      formatter: (inspection_date) => inspection_date ? moment(inspection_date).format('MMM D, YYYY, h:mm:ss A') : '',
      customHeaderProps: {
        style: {
          minWidth: '120px'
        }
      }
    },
    {
      Header: t('ERP REFERENCE'),
      label: t('ERP REFERENCE'),
      accessor: 'cat_id',
      customHeaderProps: {
        style: {
          minWidth: '80px'
        }
      },
    },
    {
      Header: t('Material Description'),
      label: t('Material Description'),
      accessor: 'dsc_material',
      customHeaderProps: {
        style: {
          minWidth: '300px'
        }
      },
    },
    {
      Header: `${t('Inspected')}`,
      label: t('Inspected'),
      accessor: 'inspected_pipe_count', 
      formatter: (inspected_pipe_count) => `${formatNumberByCulture(inspected_pipe_count, 0)}`,
      customCellProps: {
        style: {
          textAlign: 'center',
          minWidth: '20px'
        }
      }
    },
    {
      Header: `${t('Accepted')}`,
      label: t('Accepted'),
      formatter: (accept_count) => `${formatNumberByCulture(accept_count, 0)}`,
      accessor: 'accept_count',
      customCellProps: {
        style: {
          textAlign: 'center',
          minWidth: '20px'
        }
      }
    },
    {
      Header: `${t('Rejected')}`,
      label: t('Rejected'),
      formatter: (reject_count) => `${formatNumberByCulture(reject_count, 0)}`,
      accessor: 'reject_count',
      customCellProps: {
        style: {
          textAlign: 'center',
          minWidth: '20px'
        }
      }
    },

    {
      Header: `${t('To Repair')}`,
      label: t('To Repair'),
      formatter: (repair_count) => `${formatNumberByCulture(repair_count, 0)}`,
      accessor: 'repair_count',
      customHeaderProps: {
        style: {
          minWidth: '20px'
        }
      },
      customCellProps: {
        style: {
          textAlign: 'center'
        }
      }
    },
    {
      id: 'download',
      Header: t('Download'),
      disableFilters: true,
      customHeaderProps: {
        style: {
          minWidth: '32px'
        }
      },
      Cell: ({ cell: { row } }) => <FlexView alignItems="center" justifyContent="center" width="100%">
        <Icon name="download" width="24px" height="24px" onClick={onDownloadClick(row.original)} />
      </FlexView>
    }
  ], [t, onDownloadClick, formatNumberByCulture])

  const toggleModal = () => setShowModal(currentState => !currentState)

  return <FlexView flex="1" position="relative" alignSelf="stretch">
    <StockTabs stock={stock} />
    <FlexView margin="16px 16px 8px" flexDirection="row" justifyContent="space-between" alignSelf="stretch">
      <FilterDisplay options={columns} onTagClick={toggleModal} />
      <FlexView flexDirection="row" alignItems="center" justifyContent="flex-end" flex="1">
        <Icon name="filter" width="28px" height="28px" margin="0px 8px 0px 0px" tooltip={t('Filter')} onClick={toggleModal}/>
      </FlexView>
    </FlexView>
    <Card alignSelf="stretch" padding="0px" margin="16px">
      <FilterableTable columns={columns} data={filteredTableData} />
    </Card>
    <FiltersModal isOpen={showModal} onOutsideClick={toggleModal} options={columns} data={data}/>
    <LoadingOverlay visible={loading} />
  </FlexView>
}

export default ReceivingInspectionReports