import { functionalities as func } from "./warehouse-config/functionalities"

export const stockTabsLayout = [
  {
    id: func.SERVICE_STOCK.id,
    label: func.SERVICE_STOCK.label,
    subItems: []
  },
  {
    id: func.TRACKING_OF_GOODS.id,
    label: func.TRACKING_OF_GOODS.label,
    subItems: []
  },
  {
    id: 'Inspection Report Menu',
    label: 'Inspection Reports',
    isCategory: true,
    subItems: [
      {
        id: func.RECEIVING_INSPECTION.id,
        label: func.RECEIVING_INSPECTION.label
      },
      {
        id: func.RIG_RETURN_INSPECTION.id,
        label: func.RIG_RETURN_INSPECTION.label,
      },
      {
        id: func.MODIFIED_PRODUCT_INSPECTION.id,
        label: func.MODIFIED_PRODUCT_INSPECTION.label,
      },
      {
        id: func.MATERIAL_REQUISITION_INSPECTION.id,
        label: func.MATERIAL_REQUISITION_INSPECTION.label
      },
      {
        id: func.PERIODICAL_INSPECTION.id,
        label: func.PERIODICAL_INSPECTION.label
      },
      {
        id: func.ARCHIVED_REPORTS.id,
        label: func.ARCHIVED_REPORTS.label
      }
    ]
  },
  {
    id: func.BACKLOAD_MANIFEST.id,
    label: func.BACKLOAD_MANIFEST.label,
    subItems: []
  },
  {
    id: func.RECEIPT_NOTES.id,
    label: func.RECEIPT_NOTES.label,
    subItems: []  
  },
  {
    id: func.DELIVERY_NOTES.id,
    label: func.DELIVERY_NOTES.label,
    subItems: []
  },
  {
    id: func.MATERIAL_REQUISITION_STATUS.id,
    label: func.MATERIAL_REQUISITION_STATUS.label,
    subItems: [],
  },
  {
    id: func.COLLABORATIVE_PLANNING.id,
    label: func.COLLABORATIVE_PLANNING.label,
    subItems: []
  },
  {
    id: 'Pipe Age',
    label: 'Pipe Age Management',
    isCategory: true,
    subItems: [
      {
        id: func.PIPE_AGE_VIEW.id,
        label: func.PIPE_AGE_VIEW.label
      },
      {
        id: func.PIPE_AGE_REPORTS.id,
        label: func.PIPE_AGE_REPORTS.label
      }
    ]
  },
  {
    id: func.KPIS.id,
    label: func.KPIS.label,
    subItems: []
  },
  {
    id: func.BLOCKED_STOCK.id,
    label: func.BLOCKED_STOCK.label,
    subItems: []
  },
  {
    id: func.WAREHOUSE_CONFIG.id,
    label: func.WAREHOUSE_CONFIG.label,
    subItems: []
  }
]