import { textToSlug } from 'utils/helpers'
import { functionalities as func} from 'utils/warehouse-config/functionalities'

export const getFunctionalityRoute = (warehouse, functionality) => {
    return `/${textToSlug(warehouse?.name)}/${textToSlug(functionality?.name)}`
}

export const getWarehouseRoute = (warehouse) => ['/' + textToSlug(warehouse?.name)]

export const formatWarehouses = (warehouseConfig) => 
warehouseConfig.map(warehouse => ({
  ...warehouse,
  wid: warehouse.id,
  title: warehouse.name,
  key: warehouse.id,
  baseUrl: textToSlug(warehouse.name),
  hasExcelBackup: warehouse.isLegacy ? false : true,
  functionalities: warehouse.functionalities.map(item => ({...item, icon: iconsByFunctionality[item.id]})),
  endpoints: warehouse.functionalities.map(fun => textToSlug(fun.name))
}))

const icons = {
  'BOX': 'box',
  'FOLDER': 'folder',
  'SEARCH': 'search',
  'DOCUMENT': 'document',
  'MESSAGE-OPEN': 'message-open',
  'SHIP': 'ship',
  'MR-STATUS': 'mr-status',
  'PIPE-AGE': 'pipe-age',
  'CHART': 'chart',
  'GEAR3': 'gear-3', 
  'TIME': 'time'
}

const iconsByFunctionality = {
  [func.ARCHIVED_REPORTS.id]: icons.SHIP,
  [func.BACKLOAD_MANIFEST.id]: icons.DOCUMENT,
  [func.BLOCKED_STOCK.id]: icons['MESSAGE-OPEN'],
  [func.COLLABORATIVE_PLANNING.id]: icons.FOLDER,
  [func.DELIVERY_NOTES.id]: icons['MESSAGE-OPEN'],
  [func.KPIS.id]: icons.CHART,
  [func.MATERIAL_REQUISITION_INSPECTION.id]: icons.SHIP,
  [func.MATERIAL_REQUISITION_STATUS.id]: icons['MR-STATUS'],
  [func.MODIFIED_PRODUCT_INSPECTION.id]: icons.SHIP,
  [func.PERIODICAL_INSPECTION.id]: icons.SEARCH,
  [func.PIPE_AGE_REPORTS.id]: icons.TIME,
  [func.PIPE_AGE_VIEW.id]: icons.TIME,
  [func.PIPE_DATA.id]: icons.BOX,
  [func.RECEIPT_NOTES.id]: icons.DOCUMENT,
  [func.RECEIVING_INSPECTION.id]: icons.SEARCH,
  [func.RIG_RETURN_INSPECTION.id]: icons.SHIP,
  [func.SERVICE_STOCK.id]: icons.BOX,
  [func.TRACKING_OF_GOODS.id]: icons.FOLDER,
  [func.WAREHOUSE_CONFIG.id]: icons.GEAR3
}