import axios from 'axios'
import { decompressResponse } from '../utils/decompress'
import { addLogger } from 'utils/logger'
import Cookies from 'js-cookie'

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  headers: {
    'Content-Type': 'application/json', 
    'VLR-Authorization': btoa(JSON.stringify({ "id": process.env.REACT_APP_ID }))
  }
})

addLogger(instance, true)

// const formatAuthorization = idToken => idToken + '.' + btoa(JSON.stringify({ 'id': process.env.REACT_APP_ID }))

export default {

  getMaterialRequisitionList: (uri, params = {}, idToken = '') => new Promise((resolve, reject) => {
    instance.post(
      `/${uri}`,
      params,
      {
        headers: {
          Authorization: Cookies.get("_ssoToken")
        }
      }
    ).then(response => {
      if (response.status === 200) {
        resolve(decompressResponse(response.data))
      }
      else {
        reject(new Error('Couldn\'t retrieve data'))
      }
    })
      .catch(error => {
        reject(error)
      })
  }),
  getRigReturnInspectionList: (uri, params = {}, idToken = '') => new Promise((resolve, reject) => {
    instance.post(
      `/${uri}`,
      params,
      {
        headers: {
          Authorization: Cookies.get("_ssoToken")
        }
      }
    ).then(response => {
      if (response.status === 200) {
        resolve(decompressResponse(response.data))
      }
      else {
        reject(new Error('Couldn\'t retrieve data'))
      }
    })
      .catch(error => {
        reject(error)
      })
  }),
  getModifiedProductsInspectionList: (uri, params = {}, idToken = '') => new Promise((resolve, reject) => {
    instance.post(
      `/${uri}`,
      params,
      {
        headers: {
          Authorization: Cookies.get("_ssoToken")
        }
      }
    ).then(response => {
      if (response.status === 200) {
        resolve(decompressResponse(response.data))
      }
      else {
        reject(new Error('Couldn\'t retrieve data'))
      }
    })
      .catch(error => {
        reject(error)
      })
  }),
  getDeliveryNotesList: (uri, params = {}, idToken = '') => new Promise((resolve, reject) => {
    instance.post(
      `/${uri}`,
      params,
      {
        headers: {
          Authorization: Cookies.get("_ssoToken")
        }
      }
    ).then(response => {
      if (response.status === 200) {
        resolve(decompressResponse(response.data))
      }
      else {
        reject(new Error('Couldn\'t retrieve data'))
      }
    })
      .catch(error => {
        reject(error)
      })
  }),
  getReceivingInspectionList: (uri, params = {}, idToken = '') => new Promise((resolve, reject) => {
    instance.post(
      `/${uri}`,
      params,
      {
        headers: {
          Authorization: Cookies.get("_ssoToken")
        }
      }
    ).then(response => {
      if (response.status === 200) {
        resolve(decompressResponse(response.data))
      }
      else {
        reject(new Error('Couldn\'t retrieve data'))
      }
    })
      .catch(error => {
        reject(error)
      })
  }),
  getPeriodicalInspectionList: (uri, params = {}, idToken = '') => new Promise((resolve, reject) => {
    instance.post(
      `/${uri}`,
      params,
      {
        headers: {
          Authorization: Cookies.get("_ssoToken")
        }
      }
    ).then(response => {
      if (response.status === 200) {
        resolve(decompressResponse(response.data))
      }
      else {
        reject(new Error('Couldn\'t retrieve data'))
      }
    })
      .catch(error => {
        reject(error)
      })
  }),
  getSpotnspectionReportList: (uri, params = {}, idToken = '') => new Promise((resolve, reject) => {
    instance.post(
      `/${uri}`,
      params,
      {
        headers: {
          Authorization: Cookies.get("_ssoToken")
        }
      }
    ).then(response => {
      if (response.status === 200) {
        resolve(decompressResponse(response.data))
      }
      else {
        reject(new Error('Couldn\'t retrieve data'))
      }
    })
      .catch(error => {
        reject(error)
      })
  }),
  getSlowMovingPipesList: (uri, params = {}, idToken = '') => new Promise((resolve, reject) => {
    instance.post(
      `/${uri}`,
      params,
      {
        headers: {
          Authorization: Cookies.get("_ssoToken")
        }
      }
    ).then(response => {
      if (response.status === 200) {
        resolve(decompressResponse(response.data))
      }
      else {
        reject(new Error('Couldn\'t retrieve data'))
      }
    })
      .catch(error => {
        reject(error)
      })
  }),
  getStockLevelInternalReportsData: (uri, params = {}, idToken = '') => new Promise((resolve, reject) => {
    instance.post(
      `/${uri}`,
      params,
      {
        headers: {
          Authorization: Cookies.get("_ssoToken")
        }
      }
    ).then(response => {
      if (response.status === 200) {
        resolve(decompressResponse(response.data))
      }
      else {
        reject(new Error('Couldn\'t retrieve data'))
      }
    })
      .catch(error => {
        reject(error)
      })
  }), 
  getConsumptionInternalReportsData: (uri, params = {}, idToken = '') => new Promise((resolve, reject) => {
    instance.post(
      `/${uri}`,
      params,
      {
        headers: {
          Authorization: Cookies.get("_ssoToken")
        }
      }
    ).then(response => {
      if (response.status === 200) {
        resolve(decompressResponse(response.data))
      }
      else {
        reject(new Error('Couldn\'t retrieve data'))
      }
    })
      .catch(error => {
        reject(error)
      })
  }), 
  getReceiptInternalReportsData: (uri, params = {}, idToken = '') => new Promise((resolve, reject) => {
    instance.post(
      `/${uri}`,
      params,
      {
        headers: {
          Authorization: Cookies.get("_ssoToken")
        }
      }
    ).then(response => {
      if (response.status === 200) {
        resolve(decompressResponse(response.data))
      }
      else {
        reject(new Error('Couldn\'t retrieve data'))
      }
    })
      .catch(error => {
        reject(error)
      })
  }), 
  getHiddenReportsHist: (uri, params = {}, idToken = '') => new Promise((resolve, reject) => {
    instance.post(
      `/${uri}`,
      params,
      {
        headers: {
          Authorization: Cookies.get("_ssoToken")
        }
      }
    ).then(response => {
      if (response.status === 200) {
        resolve(decompressResponse(response.data))
      }
      else {
        reject(new Error('Couldn\'t retrieve data'))
      }
    })
      .catch(error => {
        reject(error)
      })
  }), 
  revealReports: (uri, params = {}, idToken = '') => new Promise((resolve, reject) => {
    instance.post(
      `/${uri}`,
      params,
      {
        headers: {
          Authorization: Cookies.get("_ssoToken")
        }
      }
    ).then(response => {
      if (response.status === 200) {
        resolve(decompressResponse(response.data))
      }
      else {
        reject(new Error('Couldn\'t retrieve data'))
      }
    })
      .catch(error => {
        reject(error)
      })
  }), 
  archiveReports: (uri, params = {}, idToken = '') => new Promise((resolve, reject) => {
    instance.post(
      `/${uri}`,
      params,
      {
        headers: {
          Authorization: Cookies.get("_ssoToken")
        }
      }
    ).then(response => {
      if (response.status === 200) {
        resolve(decompressResponse(response.data))
      }
      else {
        reject(new Error('Couldn\'t retrieve data'))
      }
    })
      .catch(error => {
        reject(error)
      })
  }), 
  getReportIdsByType: (uri, params = {}, idToken = '') => new Promise((resolve, reject) => {
    instance.post(
      `/${uri}`,
      params,
      {
        headers: {
          Authorization: Cookies.get("_ssoToken")
        }
      }
    ).then(response => {
      if (response.status === 200) {
        resolve(decompressResponse(response.data))
      }
      else {
        reject(new Error('Couldn\'t retrieve data'))
      }
    })
      .catch(error => {
        reject(error)
      })
  }), 
  getReportInfo: (uri, params = {}, idToken = '') => new Promise((resolve, reject) => {
    instance.post(
      `/${uri}`,
      params,
      {
        headers: {
          Authorization: Cookies.get("_ssoToken")
        }
      }
    ).then(response => {
      if (response.status === 200) {
        resolve(decompressResponse(response.data))
      }
      else {
        reject(new Error('Couldn\'t retrieve data'))
      }
    })
      .catch(error => {
        reject(error)
      })
  })
}