import { SECOND_HEADER_STYLES } from "./header"

// MATERIAL REQUISITION RIG PREP
const MATERIAL_REQ_RIG_PREP_HEADER_LABEL_CONFIG = [
    { id: "id", label: "ID List", cellPosition: "A5", mergedCells: "A5:B5" },
    { id: "date", label: "Date", cellPosition: "A6", mergedCells: "A6:B6" },
    {
        id: "mr_ref",
        label: "MR Number",
        cellPosition: "A7",
        mergedCells: "A7:B7",
    },
    {
        id: "field_name",
        label: "Field Name",
        cellPosition: "A8",
        mergedCells: "A8:B8",
    },
    {
        id: "rig_name",
        label: "Rig Name",
        cellPosition: "A9",
        mergedCells: "A9:B9",
    },
    {
        id: "well_name",
        label: "Well Name",
        cellPosition: "A10",
        mergedCells: "A10:B10",
    },
    {
        id: "ltpa",
        label: "LTPA Number",
        cellPosition: "A11",
        mergedCells: "A11:B11",
    },
    {
        id: "end_user",
        label: "END USER",
        cellPosition: "A12",
        mergedCells: "A12:B12",
    },
]


const MATERIAL_REQ_MAT_MODIFICATION_HEADER_LABEL_CONFIG = [
    { id: "id", label: "ID List", cellPosition: "A5", mergedCells: "A5:B5" },
    { id: "date", label: "Date", cellPosition: "A6", mergedCells: "A6:B6" },
    {
        id: "mr_ref",
        label: "MR Number",
        cellPosition: "A7",
        mergedCells: "A7:B7",
    },
    {
        id: "field_name",
        label: "Field Name",
        cellPosition: "A8",
        mergedCells: "A8:B8",
    },
    {
        id: "rig_name",
        label: "Rig Name",
        cellPosition: "A9",
        mergedCells: "A9:B9",
    },
    {
        id: "well_name",
        label: "Well Name",
        cellPosition: "A10",
        mergedCells: "A10:B10",
    },
    {
        id: "tparty_name",
        label: "Material Requisition Name",
        cellPosition: "A11",
        mergedCells: "A11:B11",
    },
    {
        id: "location",
        label: "Material Requisition Location",
        cellPosition: "A12",
        mergedCells: "A12:B12",
    },
    {
        id: "ltpa",
        label: "LTPA Number",
        cellPosition: "A13",
        mergedCells: "A13:B13",
    },
    {
        id: "end_user",
        label: "END USER",
        cellPosition: "A14",
        mergedCells: "A14:B14",
    },
]

const MATERIAL_REQ_THIRD_PARTY_HEADER_LABEL_CONFIG = [
    { id: "id", label: "ID List", cellPosition: "A5", mergedCells: "A5:B5" },
    { id: "date", label: "Date", cellPosition: "A6", mergedCells: "A6:B6" },
    {
        id: "mr_ref",
        label: "MR Number",
        cellPosition: "A7",
        mergedCells: "A7:B7",
    },
    {
        id: "field_name",
        label: "Field Name",
        cellPosition: "A8",
        mergedCells: "A8:B8",
    },
    {
        id: "rig_name",
        label: "Rig Name",
        cellPosition: "A9",
        mergedCells: "A9:B9",
    },
    {
        id: "well_name",
        label: "Well Name",
        cellPosition: "A10",
        mergedCells: "A10:B10",
    },
    {
        id: "tparty_name",
        label: "Third Party Name",
        cellPosition: "A11",
        mergedCells: "A11:B11",
    },
    {
        id: "location",
        label: "Third Party Location",
        cellPosition: "A12",
        mergedCells: "A12:B12",
    },
    {
        id: "ltpa",
        label: "LTPA Number",
        cellPosition: "A13",
        mergedCells: "A13:B13",
    },
    {
        id: "end_user",
        label: "END USER",
        cellPosition: "A14",
        mergedCells: "A14:B14",
    },
]

const MATERIAL_REQ_PHYSICAL_TRANSFER_HEADER_LABEL_CONFIG = [
    { id: "id", label: "ID List", cellPosition: "A5", mergedCells: "A5:B5" },
    { id: "date", label: "Date", cellPosition: "A6", mergedCells: "A6:B6" },
    {
        id: "mr_ref",
        label: "MR Number",
        cellPosition: "A7",
        mergedCells: "A7:B7",
    },
    {
        id: "field_name",
        label: "Field Name",
        cellPosition: "A8",
        mergedCells: "A8:B8",
    },
    {
        id: "rig_name",
        label: "Rig Name",
        cellPosition: "A9",
        mergedCells: "A9:B9",
    },
    {
        id: "well_name",
        label: "Well Name",
        cellPosition: "A10",
        mergedCells: "A10:B10",
    },
    {
        id: "ltpa",
        label: "LTPA Number",
        cellPosition: "A11",
        mergedCells: "A11:B11",
    },
    {
        id: "end_user",
        label: "END USER",
        cellPosition: "A12",
        mergedCells: "A12:B12",
    },
]

const MATERIAL_REQ_RIG_PREP_HEADER_CONTENT_CONFIG = [
    { id: "id", accessor: "id", cellPosition: "C5", mergedCells: "C5:D5" },
    { id: "date", accessor: "date", cellPosition: "C6", mergedCells: "C6:D6" },
    {
        id: "mr_ref",
        accessor: "mr_ref",
        cellPosition: "C7",
        mergedCells: "C7:D7",
    },
    {
        id: "field_name",
        accessor: "field",
        cellPosition: "C8",
        mergedCells: "C8:D8",
    },
    {
        id: "rig_name",
        accessor: "rig",
        cellPosition: "C9",
        mergedCells: "C9:D9",
    },
    {
        id: "well_name",
        accessor: "well",
        cellPosition: "C10",
        mergedCells: "C10:D10",
    },
    {
        id: "ltpa",
        accessor: "ltpa",
        cellPosition: "C11",
        mergedCells: "C11:D11",
    },
    {
        id: "end_user",
        accessor: "end_user",
        cellPosition: "C12",
        mergedCells: "C12:D12",
    },
]

const MATERIAL_REQ_MAT_MODIFICATION_HEADER_CONTENT_CONFIG = [
    { id: "id", accessor: "id", cellPosition: "C5", mergedCells: "C5:D5" },
    { id: "date", accessor: "date", cellPosition: "C6", mergedCells: "C6:D6" },
    {
        id: "mr_ref",
        accessor: "mr_ref",
        cellPosition: "C7",
        mergedCells: "C7:D7",
    },
    {
        id: "field_name",
        accessor: "field",
        cellPosition: "C8",
        mergedCells: "C8:D8",
    },
    {
        id: "rig_name",
        accessor: "rig",
        cellPosition: "C9",
        mergedCells: "C9:D9",
    },
    {
        id: "well_name",
        accessor: "well",
        cellPosition: "C10",
        mergedCells: "C10:D10",
    },
    {
        id: "tparty_name",
        accessor: "tparty_name",
        cellPosition: "C11",
        mergedCells: "C11:D11",
       
    },
    {
        id: "location",
        accessor: "location",
        cellPosition: "C12",
        mergedCells: "C12:D12",
    },
    {
        id: "ltpa",
        accessor: "ltpa",
        cellPosition: "C13",
        mergedCells: "C13:D13",
    },
    {
        id: "end_user",
        accessor: "end_user",
        cellPosition: "C14",
        mergedCells: "C14:D14",
    },
]

const MATERIAL_REQ_PHYSICAL_TRANSFER_HEADER_CONTENT_CONFIG = [
    { id: "id", accessor: "id", cellPosition: "C5", mergedCells: "C5:D5" },
    { id: "date", accessor: "date", cellPosition: "C6", mergedCells: "C6:D6" },
    {
        id: "mr_ref",
        accessor: "mr_ref",
        cellPosition: "C7",
        mergedCells: "C7:D7",
    },
    {
        id: "field_name",
        accessor: "field",
        cellPosition: "C8",
        mergedCells: "C8:D8",
    },
    {
        id: "rig_name",
        accessor: "rig",
        cellPosition: "C9",
        mergedCells: "C9:D9",
    },
    {
        id: "well_name",
        accessor: "well",
        cellPosition: "C10",
        mergedCells: "C10:D10",
    },
    {
        id: "ltpa",
        accessor: "ltpa",
        cellPosition: "C11",
        mergedCells: "C11:D11",
    },
    {
        id: "end_user",
        accessor: "end_user",
        cellPosition: "C12",
        mergedCells: "C12:D12",
    },
]

const MATERIAL_REQ_SECOND_HEADER_LABEL_CONFIG = [
    {
        id: "qnt_inspected",
        label: "Qty Inspected",
        cellPosition: "I10",
        mergedCells: "I10:L10",
    },
    {
        id: "inspected",
        label: "Inspected",
        cellPosition: "I11",
        mergedCells: "I11:I11",
    },
    {
        id: "accepted",
        label: "Accepted",
        cellPosition: "J11",
        mergedCells: "J11:J11",
    },
    {
        id: "rejected",
        label: "Rejected",
        cellPosition: "K11",
        mergedCells: "K11:K11",
    },
    {
        id: "to_repair",
        label: "To repair",
        cellPosition: "L11",
        mergedCells: "L11:L11",
    },
    {
        id: "qnt_measured",
        label: "Qty Measured",
        cellPosition: "M10",
        mergedCells: "M10:N10",
    },
    {
        id: "measured",
        label: "Measured",
        cellPosition: "M11",
        mergedCells: "M11:M11",
    },
    {
        id: "total_length",
        label: "Effective Length [ft]",
        cellPosition: "N11",
        mergedCells: "N11:N11",
    },
]

const MATERIAL_REQ_SECOND_HEADER_MAT_MODIFICATION_LABEL_CONFIG = [
    {
        id: "qnt_inspected",
        label: "Qty Inspected",
        cellPosition: "I12",
        mergedCells: "I12:L12",
    },
    {
        id: "inspected",
        label: "Inspected",
        cellPosition: "I13",
        mergedCells: "I13:I13",
    },
    {
        id: "accepted",
        label: "Accepted",
        cellPosition: "J13",
        mergedCells: "J13:J13",
    },
    {
        id: "rejected",
        label: "Rejected",
        cellPosition: "K13",
        mergedCells: "K13:K13",
    },
    {
        id: "to_repair",
        label: "To repair",
        cellPosition: "L13",
        mergedCells: "L13:L13",
    },
    {
        id: "qnt_measured",
        label: "Qty Measured",
        cellPosition: "M12",
        mergedCells: "M12:N12",
    },
    {
        id: "measured",
        label: "Measured",
        cellPosition: "M13",
        mergedCells: "M13:M13",
    },
    {
        id: "total_length",
        label: "Effective Length [ft]",
        cellPosition: "N13",
        mergedCells: "N13:N13",
    },
]

const MATERIAL_REQ_SECOND_HEADER_PHYSICAL_TRANSFER_LABEL_CONFIG = [
    {
        id: "qnt_inspected",
        label: "Qty Inspected",
        cellPosition: "I10",
        mergedCells: "I10:L10",
    },
    {
        id: "inspected",
        label: "Inspected",
        cellPosition: "I11",
        mergedCells: "I11:I11",
    },
    {
        id: "accepted",
        label: "Accepted",
        cellPosition: "J11",
        mergedCells: "J11:J11",
    },
    {
        id: "rejected",
        label: "Rejected",
        cellPosition: "K11",
        mergedCells: "K11:K11",
    },
    {
        id: "to_repair",
        label: "To repair",
        cellPosition: "L11",
        mergedCells: "L11:L11",
    },
    {
        id: "qnt_measured",
        label: "Qty Measured",
        cellPosition: "M10",
        mergedCells: "M10:N10",
    },
    {
        id: "measured",
        label: "Measured",
        cellPosition: "M11",
        mergedCells: "M11:M11",
    },
    {
        id: "total_length",
        label: "Effective Length [ft]",
        cellPosition: "N11",
        mergedCells: "N11:N11",
    },
]

const MATERIAL_REQ_SECOND_HEADER_THIRD_PARTY_LABEL_CONFIG = [
    {
        id: "qnt_inspected",
        label: "Qty Inspected",
        cellPosition: "I12",
        mergedCells: "I12:L12",
    },
    {
        id: "inspected",
        label: "Inspected",
        cellPosition: "I13",
        mergedCells: "I13:I13",
    },
    {
        id: "accepted",
        label: "Accepted",
        cellPosition: "J13",
        mergedCells: "J13:J13",
    },
    {
        id: "rejected",
        label: "Rejected",
        cellPosition: "K13",
        mergedCells: "K13:K13",
    },
    {
        id: "to_repair",
        label: "To repair",
        cellPosition: "L13",
        mergedCells: "L13:L13",
    },
    {
        id: "qnt_measured",
        label: "Qty Measured",
        cellPosition: "M12",
        mergedCells: "M12:N12",
    },
    {
        id: "measured",
        label: "Measured",
        cellPosition: "M13",
        mergedCells: "M13:M13",
    },
    {
        id: "total_length",
        label: "Effective Length [ft]",
        cellPosition: "N13",
        mergedCells: "N13:N13",
    },
]

const MATERIAL_REQ_SECOND_HEADER_CONTENT_CONFIG = [
    {
        id: "inspected",
        accessor: "total_inspected",
        cellPosition: "I12",
        mergedCells: "I12:I12",
    },
    {
        id: "accepted",
        accessor: "total_accepted",
        cellPosition: "J12",
        mergedCells: "J12:J12",
    },
    {
        id: "rejected",
        accessor: "total_rejected",
        cellPosition: "K12",
        mergedCells: "K12:K12",
    },
    {
        id: "to_repair",
        accessor: "total_repair",
        cellPosition: "L12",
        mergedCells: "L12:L12",
    },
    {
        id: "measured",
        accessor: "total_measured",
        cellPosition: "M12",
        mergedCells: "M12:M12",
    },
    {
        id: "total_length",
        accessor: "total_effective_length",
        cellPosition: "N12",
        mergedCells: "N12:N12",
    },
]

const MATERIAL_REQ_SECOND_HEADER_MAT_MODIFICATION_CONTENT_CONFIG = [
    {
        id: "inspected",
        accessor: "total_inspected",
        cellPosition: "I14",
        mergedCells: "I14:I14",
    },
    {
        id: "accepted",
        accessor: "total_accepted",
        cellPosition: "J14",
        mergedCells: "J14:J14",
    },
    {
        id: "rejected",
        accessor: "total_rejected",
        cellPosition: "K14",
        mergedCells: "K14:K14",
    },
    {
        id: "to_repair",
        accessor: "total_repair",
        cellPosition: "L14",
        mergedCells: "L14:L14",
    },
    {
        id: "measured",
        accessor: "total_measured",
        cellPosition: "M14",
        mergedCells: "M14:M14",
    },
    {
        id: "total_length",
        accessor: "total_effective_length",
        cellPosition: "N14",
        mergedCells: "N14:N14",
    },
]

const MATERIAL_REQ_SECOND_HEADER_THIRD_PARTY_CONTENT_CONFIG = [
    {
        id: "inspected",
        accessor: "total_inspected",
        cellPosition: "I14",
        mergedCells: "I14:I14",
    },
    {
        id: "accepted",
        accessor: "total_accepted",
        cellPosition: "J14",
        mergedCells: "J14:J14",
    },
    {
        id: "rejected",
        accessor: "total_rejected",
        cellPosition: "K14",
        mergedCells: "K14:K14",
    },
    {
        id: "to_repair",
        accessor: "total_repair",
        cellPosition: "L14",
        mergedCells: "L14:L14",
    },
    {
        id: "measured",
        accessor: "total_measured",
        cellPosition: "M14",
        mergedCells: "M14:M14",
    },
    {
        id: "total_length",
        accessor: "total_effective_length",
        cellPosition: "N14",
        mergedCells: "N14:N14",
    },
]

const MATERIAL_REQ_SECOND_HEADER_PHYSICAL_TRANSFER_CONTENT_CONFIG = [
    {
        id: "inspected",
        accessor: "total_inspected",
        cellPosition: "I12",
        mergedCells: "I12:I12",
    },
    {
        id: "accepted",
        accessor: "total_accepted",
        cellPosition: "J12",
        mergedCells: "J12:J12",
    },
    {
        id: "rejected",
        accessor: "total_rejected",
        cellPosition: "K12",
        mergedCells: "K12:K12",
    },
    {
        id: "to_repair",
        accessor: "total_repair",
        cellPosition: "L12",
        mergedCells: "L12:L12",
    },
    {
        id: "measured",
        accessor: "total_measured",
        cellPosition: "M12",
        mergedCells: "M12:M12",
    },
    {
        id: "total_length",
        accessor: "total_effective_length",
        cellPosition: "N12",
        mergedCells: "N12:N12",
    },
]

const MATERIAL_REQ_RIG_PREP_DATA_LABEL_CONFIG = [
    {
        id: "item_no",
        label: "ITEM NO",
        cellPosition: "A14",
        mergedCells: "A14:A15",
    },
    {
        id: "co_purchase_order",
        label: "CUSTOMER ORDER#",
        cellPosition: "B14",
        mergedCells: "B14:B15",
    },
    {
        id: "co_purchase_item",
        label: "CUSTOMER ITEM#",
        cellPosition: "C14",
        mergedCells: "C14:C15",
    },
    {
        id: "num_sales_order",
        label: "SALES PO#",
        cellPosition: "D14",
        mergedCells: "D14:D15",
    },
    {
        id: "num_sales_item",
        label: "SALES ITEM#",
        cellPosition: "E14",
        mergedCells: "E14:E15",
    },
    {
        id: "cat_id",
        label: "ERP REFERENCE",
        cellPosition: "F14",
        mergedCells: "F14:F15",
    },
    {
        id: "dsc_material",
        label: "MATERIAL",
        cellPosition: "G14",
        mergedCells: "G14:G15",
    },
    {
        id: "valid",
        label: "VALID",
        cellPosition: "H14",
        mergedCells: "H14:H15",
    },
    {
        id: "ippn",
        label: "IPPN#",
        cellPosition: "I14",
        mergedCells: "I14:I15",
    },
    {
        id: "marking",
        label: "MARKING LEGIBILITY",
        cellPosition: "J14",
        mergedCells: "J14:J15",
    },
    {
        id: "thread",
        label: "THREAD",
        cellPosition: "K14",
        mergedCells: "K14:L14",
    },
    {
        id: "thread_pin",
        label: "PIN",
        cellPosition: "K15",
        mergedCells: "K15:K15",
    },
    {
        id: "thread_box",
        label: "BOX",
        cellPosition: "L15",
        mergedCells: "L15:L15",
    },
    {
        id: "pipe_body",
        label: "PIPE BODY",
        cellPosition: "M14",
        mergedCells: "M14:N14",
    },
    {
        id: "body_int",
        label: "INTERNAL",
        cellPosition: "M15",
        mergedCells: "M15:M15",
    },
    {
        id: "body_ext",
        label: "EXTERNAL",
        cellPosition: "N15",
        mergedCells: "N15:N15",
    },
    {
        id: "drift",
        label: "DRIFT",
        cellPosition: "O14",
        mergedCells: "O14:O15",
    },
    {
        id: "final_report",
        label: "ACCEPT / REJECT",
        cellPosition: "P14",
        mergedCells: "P14:P15",
    },
    {
        id: "root_cause",
        label: "ROOT CAUSE",
        cellPosition: "Q14",
        mergedCells: "Q14:Q15",
    },
    {
        id: "pic_ref",
        label: "PICTURE REFERENCE",
        cellPosition: "R14",
        mergedCells: "R14:R15",
    },
    {
        id: "insp_responsible",
        label: "INSPECTOR",
        cellPosition: "S14",
        mergedCells: "S14:S15",
    },
    {
        id: "length_measurement",
        label: "LENGTH MEASUREMENT",
        cellPosition: "T14",
        mergedCells: "T14:Z14",
    },
    {
        id: "length_measurement_status",
        label: "STATUS",
        cellPosition: "T15",
        mergedCells: "T15:T15",
    },
    {
        id: "length_measured",
        label: "LENGTH MEASURED [ft]",
        cellPosition: "U15",
        mergedCells: "U15:U15",
    },
    {
        id: "length_makeup_loss",
        label: "MAKEUP LOSS [ft]",
        cellPosition: "V15",
        mergedCells: "V15:V15",
    },
    {
        id: "length_effective",
        label: "EFFECTIVE LENGTH [ft]",
        cellPosition: "W15",
        mergedCells: "W15:W15",
    },
    {
        id: "length_int_diameter",
        label: "INTERNAL DIAMETER [ID]",
        cellPosition: "X15",
        mergedCells: "X15:X15",
    },
    {
        id: "length_out_diameter",
        label: "OUTSIDE DIAMETER [OD]",
        cellPosition: "Y15",
        mergedCells: "Y15:Y15",
    },
    {
        id: "measure_responsible",
        label: "RESPONSIBLE",
        cellPosition: "Z15",
        mergedCells: "Z15:Z15",
    },
    {
        id: "equipment",
        label: "EQUIPMENT",
        cellPosition: "AA14",
        mergedCells: "AA14:AA15",
    },
    {
        id: "remarks",
        label: "REMARKS",
        cellPosition: "AB14",
        mergedCells: "AB14:AB15",
    },
]

const MATERIAL_REQ_THIRD_PARTY_DATA_LABEL_CONFIG = [
    {
        id: "item_no",
        label: "ITEM NO",
        cellPosition: "A16",
        mergedCells: "A16:A17",
    },
    {
        id: "co_purchase_order",
        label: "CUSTOMER ORDER#",
        cellPosition: "B16",
        mergedCells: "B16:B17",
    },
    {
        id: "co_purchase_item",
        label: "CUSTOMER ITEM#",
        cellPosition: "C16",
        mergedCells: "C16:C17",
    },
    {
        id: "num_sales_order",
        label: "SALES PO#",
        cellPosition: "D16",
        mergedCells: "D16:D17",
    },
    {
        id: "num_sales_item",
        label: "SALES ITEM#",
        cellPosition: "E16",
        mergedCells: "E16:E17",
    },
    {
        id: "cat_id",
        label: "ERP REFERENCE",
        cellPosition: "F16",
        mergedCells: "F16:F17",
    },
    {
        id: "dsc_material",
        label: "MATERIAL",
        cellPosition: "G16",
        mergedCells: "G16:G17",
    },
    {
        id: "valid",
        label: "VALID",
        cellPosition: "H16",
        mergedCells: "H16:H17",
    },
    {
        id: "ippn",
        label: "IPPN#",
        cellPosition: "I16",
        mergedCells: "I16:I17",
    },
    {
        id: "marking",
        label: "MARKING LEGIBILITY",
        cellPosition: "J16",
        mergedCells: "J16:J17",
    },
    {
        id: "thread",
        label: "THREAD",
        cellPosition: "K16",
        mergedCells: "K16:L16",
    },
    {
        id: "thread_pin",
        label: "PIN",
        cellPosition: "K17",
        mergedCells: "K17:K17",
    },
    {
        id: "thread_box",
        label: "BOX",
        cellPosition: "L17",
        mergedCells: "L17:L17",
    },
    {
        id: "pipe_body",
        label: "PIPE BODY",
        cellPosition: "M16",
        mergedCells: "M16:N16",
    },
    {
        id: "body_int",
        label: "INTERNAL",
        cellPosition: "M17",
        mergedCells: "M17:M17",
    },
    {
        id: "body_ext",
        label: "EXTERNAL",
        cellPosition: "N17",
        mergedCells: "N17:N17",
    },
    {
        id: "drift",
        label: "DRIFT",
        cellPosition: "O16",
        mergedCells: "O16:O17",
    },
    {
        id: "final_report",
        label: "ACCEPT / REJECT",
        cellPosition: "P16",
        mergedCells: "P16:P17",
    },
    {
        id: "root_cause",
        label: "ROOT CAUSE",
        cellPosition: "Q16",
        mergedCells: "Q16:Q17",
    },
    {
        id: "pic_ref",
        label: "PICTURE REFERENCE",
        cellPosition: "R16",
        mergedCells: "R16:R17",
    },
    {
        id: "insp_responsible",
        label: "INSPECTOR",
        cellPosition: "S16",
        mergedCells: "S16:S17",
    },
    {
        id: "length_measurement",
        label: "LENGTH MEASUREMENT",
        cellPosition: "T16",
        mergedCells: "T16:Z16",
    },
    {
        id: "length_measurement_status",
        label: "STATUS",
        cellPosition: "T17",
        mergedCells: "T17:T17",
    },
    {
        id: "length_measured",
        label: "LENGTH MEASURED [ft]",
        cellPosition: "U17",
        mergedCells: "U17:U17",
    },
    {
        id: "length_makeup_loss",
        label: "MAKEUP LOSS [ft]",
        cellPosition: "V17",
        mergedCells: "V17:V17",
    },
    {
        id: "length_effective",
        label: "EFFECTIVE LENGTH [ft]",
        cellPosition: "W17",
        mergedCells: "W17:W17",
    },
    {
        id: "length_int_diameter",
        label: "INTERNAL DIAMETER [ID]",
        cellPosition: "X17",
        mergedCells: "X17:X17",
    },
    {
        id: "length_out_diameter",
        label: "OUTSIDE DIAMETER [OD]",
        cellPosition: "Y17",
        mergedCells: "Y17:Y17",
    },
    {
        id: "measure_responsible",
        label: "RESPONSIBLE",
        cellPosition: "Z17",
        mergedCells: "Z17:Z17",
    },
    {
        id: "equipment",
        label: "EQUIPMENT",
        cellPosition: "AA16",
        mergedCells: "AA16:AA17",
    },
    {
        id: "remarks",
        label: "REMARKS",
        cellPosition: "AB16",
        mergedCells: "AB16:AB17",
    },
]

const MATERIAL_REQ_MAT_MODIFICATION_DATA_LABEL_CONFIG = [
    {
        id: "item_no",
        label: "ITEM NO",
        cellPosition: "A16",
        mergedCells: "A16:A17",
    },
    {
        id: "co_purchase_order",
        label: "CUSTOMER ORDER#",
        cellPosition: "B16",
        mergedCells: "B16:B17",
    },
    {
        id: "co_purchase_item",
        label: "CUSTOMER ITEM#",
        cellPosition: "C16",
        mergedCells: "C16:C17",
    },
    {
        id: "num_sales_order",
        label: "SALES PO#",
        cellPosition: "D16",
        mergedCells: "D16:D17",
    },
    {
        id: "num_sales_item",
        label: "SALES ITEM#",
        cellPosition: "E16",
        mergedCells: "E16:E17",
    },
    {
        id: "cat_id",
        label: "ERP REFERENCE",
        cellPosition: "F16",
        mergedCells: "F16:F17",
    },
    {
        id: "dsc_material",
        label: "MATERIAL",
        cellPosition: "G16",
        mergedCells: "G16:G17",
    },
    {
        id: "valid",
        label: "VALID",
        cellPosition: "H16",
        mergedCells: "H16:H17",
    },
    {
        id: "ippn",
        label: "IPPN#",
        cellPosition: "I16",
        mergedCells: "I16:I17",
    },
    {
        id: "marking",
        label: "MARKING LEGIBILITY",
        cellPosition: "J16",
        mergedCells: "J16:J17",
    },
    {
        id: "thread",
        label: "THREAD",
        cellPosition: "K16",
        mergedCells: "K16:L16",
    },
    {
        id: "thread_pin",
        label: "PIN",
        cellPosition: "K17",
        mergedCells: "K17:K17",
    },
    {
        id: "thread_box",
        label: "BOX",
        cellPosition: "L17",
        mergedCells: "L17:L17",
    },
    {
        id: "pipe_body",
        label: "PIPE BODY",
        cellPosition: "M16",
        mergedCells: "M16:N16",
    },
    {
        id: "body_int",
        label: "INTERNAL",
        cellPosition: "M17",
        mergedCells: "M17:M17",
    },
    {
        id: "body_ext",
        label: "EXTERNAL",
        cellPosition: "N17",
        mergedCells: "N17:N17",
    },
    {
        id: "drift",
        label: "DRIFT",
        cellPosition: "O16",
        mergedCells: "O16:O17",
    },
    {
        id: "final_report",
        label: "ACCEPT / REJECT",
        cellPosition: "P16",
        mergedCells: "P16:P17",
    },
    {
        id: "root_cause",
        label: "ROOT CAUSE",
        cellPosition: "Q16",
        mergedCells: "Q16:Q17",
    },
    {
        id: "pic_ref",
        label: "PICTURE REFERENCE",
        cellPosition: "R16",
        mergedCells: "R16:R17",
    },
    {
        id: "insp_responsible",
        label: "INSPECTOR",
        cellPosition: "S16",
        mergedCells: "S16:S17",
    },
    {
        id: "length_measurement",
        label: "LENGTH MEASUREMENT",
        cellPosition: "T16",
        mergedCells: "T16:Z16",
    },
    {
        id: "length_measurement_status",
        label: "STATUS",
        cellPosition: "T17",
        mergedCells: "T17:T17",
    },
    {
        id: "length_measured",
        label: "LENGTH MEASURED [ft]",
        cellPosition: "U17",
        mergedCells: "U17:U17",
    },
    {
        id: "length_makeup_loss",
        label: "MAKEUP LOSS [ft]",
        cellPosition: "V17",
        mergedCells: "V17:V17",
    },
    {
        id: "length_effective",
        label: "EFFECTIVE LENGTH [ft]",
        cellPosition: "W17",
        mergedCells: "W17:W17",
    },
    {
        id: "length_int_diameter",
        label: "INTERNAL DIAMETER [ID]",
        cellPosition: "X17",
        mergedCells: "X17:X17",
    },
    {
        id: "length_out_diameter",
        label: "OUTSIDE DIAMETER [OD]",
        cellPosition: "Y17",
        mergedCells: "Y17:Y17",
    },
    {
        id: "measure_responsible",
        label: "RESPONSIBLE",
        cellPosition: "Z17",
        mergedCells: "Z17:Z17",
    },
    {
        id: "equipment",
        label: "EQUIPMENT",
        cellPosition: "AA16",
        mergedCells: "AA16:AA17",
    },
    {
        id: "remarks",
        label: "REMARKS",
        cellPosition: "AB16",
        mergedCells: "AB16:AB17",
    },
]

const MATERIAL_REQ_PHYSICAL_TRANSFER_DATA_LABEL_CONFIG = [
    {
        id: "item_no",
        label: "ITEM NO",
        cellPosition: "A14",
        mergedCells: "A14:A15",
    },
    {
        id: "co_purchase_order",
        label: "CUSTOMER ORDER#",
        cellPosition: "B14",
        mergedCells: "B14:B15",
    },
    {
        id: "co_purchase_item",
        label: "CUSTOMER ITEM#",
        cellPosition: "C14",
        mergedCells: "C14:C15",
    },
    {
        id: "num_sales_order",
        label: "SALES PO#",
        cellPosition: "D14",
        mergedCells: "D14:D15",
    },
    {
        id: "num_sales_item",
        label: "SALES ITEM#",
        cellPosition: "E14",
        mergedCells: "E14:E15",
    },
    {
        id: "cat_id",
        label: "ERP REFERENCE",
        cellPosition: "F14",
        mergedCells: "F14:F15",
    },
    {
        id: "dsc_material",
        label: "MATERIAL",
        cellPosition: "G14",
        mergedCells: "G14:G15",
    },
    {
        id: "valid",
        label: "VALID",
        cellPosition: "H14",
        mergedCells: "H14:H15",
    },
    {
        id: "ippn",
        label: "IPPN#",
        cellPosition: "I14",
        mergedCells: "I14:I15",
    },
    {
        id: "marking",
        label: "MARKING LEGIBILITY",
        cellPosition: "J14",
        mergedCells: "J14:J15",
    },
    {
        id: "thread",
        label: "THREAD",
        cellPosition: "K14",
        mergedCells: "K14:L14",
    },
    {
        id: "thread_pin",
        label: "PIN",
        cellPosition: "K15",
        mergedCells: "K15:K15",
    },
    {
        id: "thread_box",
        label: "BOX",
        cellPosition: "L15",
        mergedCells: "L15:L15",
    },
    {
        id: "pipe_body",
        label: "PIPE BODY",
        cellPosition: "M14",
        mergedCells: "M14:N14",
    },
    {
        id: "body_int",
        label: "INTERNAL",
        cellPosition: "M15",
        mergedCells: "M15:M15",
    },
    {
        id: "body_ext",
        label: "EXTERNAL",
        cellPosition: "N15",
        mergedCells: "N15:N15",
    },
    {
        id: "drift",
        label: "DRIFT",
        cellPosition: "O14",
        mergedCells: "O14:O15",
    },
    {
        id: "final_report",
        label: "ACCEPT / REJECT",
        cellPosition: "P14",
        mergedCells: "P14:P15",
    },
    {
        id: "root_cause",
        label: "ROOT CAUSE",
        cellPosition: "Q14",
        mergedCells: "Q14:Q15",
    },
    {
        id: "pic_ref",
        label: "PICTURE REFERENCE",
        cellPosition: "R14",
        mergedCells: "R14:R15",
    },
    {
        id: "insp_responsible",
        label: "INSPECTOR",
        cellPosition: "S14",
        mergedCells: "S14:S15",
    },
    {
        id: "length_measurement",
        label: "LENGTH MEASUREMENT",
        cellPosition: "T14",
        mergedCells: "T14:Z14",
    },
    {
        id: "length_measurement_status",
        label: "STATUS",
        cellPosition: "T15",
        mergedCells: "T15:T15",
    },
    {
        id: "length_measured",
        label: "LENGTH MEASURED [ft]",
        cellPosition: "U15",
        mergedCells: "U15:U15",
    },
    {
        id: "length_makeup_loss",
        label: "MAKEUP LOSS [ft]",
        cellPosition: "V15",
        mergedCells: "V15:V15",
    },
    {
        id: "length_effective",
        label: "EFFECTIVE LENGTH [ft]",
        cellPosition: "W15",
        mergedCells: "W15:W15",
    },
    {
        id: "length_int_diameter",
        label: "INTERNAL DIAMETER [ID]",
        cellPosition: "X15",
        mergedCells: "X15:X15",
    },
    {
        id: "length_out_diameter",
        label: "OUTSIDE DIAMETER [OD]",
        cellPosition: "Y15",
        mergedCells: "Y15:Y15",
    },
    {
        id: "measure_responsible",
        label: "RESPONSIBLE",
        cellPosition: "Z15",
        mergedCells: "Z15:Z15",
    },
    {
        id: "equipment",
        label: "EQUIPMENT",
        cellPosition: "AA14",
        mergedCells: "AA14:AA15",
    },
    {
        id: "remarks",
        label: "REMARKS",
        cellPosition: "AB14",
        mergedCells: "AB14:AB15",
    }
]

const MATERIAL_REQ_RIG_PREP_IMAGE_SHEET_LABEL_CONFIG = [
    {
        id: "valid",
        label: "VALID",
        cellPosition: "A1",
        mergedCells: "A1:A1",
    },
    {
        id: "picture_reference",
        label: "PICTURE REFERENCE",
        cellPosition: "B1",
        mergedCells: "B1:B1",
    },
    {
        id: "picture",
        label: "PICTURE",
        cellPosition: "C1",
        mergedCells: "C1:C1",
    },
]

const MATERIAL_REQ_RIG_PREP_DATA_CONTENT_CONFIG = [
    { id: "item_no", accessor: "item_no" },
    { id: "co_purchase_order", accessor: "co_purchase_order" },
    { id: "co_purchase_item", accessor: "co_purchase_item" },
    { id: "num_sales_order", accessor: "num_sales_order" },
    { id: "num_sales_item", accessor: "num_sales_item" },
    { id: "cat_id", accessor: "cat_id" },
    { id: "dsc_material", accessor: "dsc_material" },
    { id: "valid", accessor: "valid" },
    { id: "IPPN#", accessor: "ippn"},
    { id: "marking", accessor: "mark_legibility_report" },
    { id: "thread_pin", accessor: "pin_report" },
    { id: "thread_box", accessor: "box_report" },
    { id: "body_int", accessor: "int_body_report" },
    { id: "body_ext", accessor: "ext_body_report" },
    { id: "drift", accessor: "drift_report" },
    { id: "final_report", accessor: "final_report" },
    { id: "root_cause", accessor: "root_cause" },
    { id: "pic_ref", accessor: "pic_ref" },
    { id: "insp_responsible", accessor: "insp_responsible" },
    { id: "length_measurement_status", accessor: "measure_status" },
    { id: "length_measured", accessor: "measured_lenght" },
    { id: "length_makeup_loss", accessor: "makeup_loss" },
    { id: "length_effective", accessor: "effective_lenght" },
    { id: "length_int_diameter", accessor: "int_diameter" },
    { id: "length_out_diameter", accessor: "out_diameter" },
    { id: "measure_responsible", accessor: "measure_responsible" },
    { id: "equipment", accessor: "instruments" },
    { id: "remarks", accessor: "remarks" }
]

const MATERIAL_REQ_RIG_PREP_IMAGE_SHEET_CONTENT_CONFIG = [
    { id: "valid", accessor: "valid" }, 
    { id: "picture_reference", accessor: "picture_reference" }, 
    { id: "picture", accessor: "picture" }
]

const MATERIAL_REQ_RIG_PREP_DATA_CONTENT_ROW = 16
const MATERIAL_REQ_PHYSICAL_TRANSFER_DATA_CONTENT_ROW = 16
const MATERIAL_REQ_MAT_MODIFICATION_DATA_CONTENT_ROW = 18
const MATERIAL_REQ_THIRD_PARTY_DATA_CONTENT_ROW = 18

const MATERIAL_REQ_IMAGE_DATA_CONTENT_ROW = 2

const MATERIAL_REQ_RIG_PREP_LOGO_POSITION = 'I1:N8'
const MATERIAL_REQ_THIRD_PARTY_LOGO_POSITION = 'I1:N10'
const MATERIAL_REQ_PHYSICAL_TRANSFER_LOGO_POSITION = 'I1:N8'
const MATERIAL_MAT_MODIFICATION_LOGO_POSITION = 'I1:N10'

export const MATERIAL_REQUISITION_CONFIG = {
    MATERIAL_REQUISITION_RIG_PREP: {
        headerLabelConfig: MATERIAL_REQ_RIG_PREP_HEADER_LABEL_CONFIG,
        headerContentConfig: MATERIAL_REQ_RIG_PREP_HEADER_CONTENT_CONFIG,
        headerImageSheetConfig: MATERIAL_REQ_RIG_PREP_IMAGE_SHEET_LABEL_CONFIG,
        dataImageContentConfig: MATERIAL_REQ_RIG_PREP_IMAGE_SHEET_CONTENT_CONFIG,
        dataImageContentRow: MATERIAL_REQ_IMAGE_DATA_CONTENT_ROW,
        dataLabelConfig: MATERIAL_REQ_RIG_PREP_DATA_LABEL_CONFIG,
        dataContentConfig: MATERIAL_REQ_RIG_PREP_DATA_CONTENT_CONFIG,
        dataContentRow: MATERIAL_REQ_RIG_PREP_DATA_CONTENT_ROW,
        secondHeaderLabelConfig: MATERIAL_REQ_SECOND_HEADER_LABEL_CONFIG,
        secondHeaderContentConfig: MATERIAL_REQ_SECOND_HEADER_CONTENT_CONFIG,
        dataLogoRow: MATERIAL_REQ_RIG_PREP_LOGO_POSITION, 
        secondHeaderLabelStyle: SECOND_HEADER_STYLES.MR_LABEL, 
        secondHeaderContentStyle: SECOND_HEADER_STYLES.MR_CONTENT
    },
    MATERIAL_REQUISITION_MAT_MODIFICATION: {
        headerLabelConfig: MATERIAL_REQ_MAT_MODIFICATION_HEADER_LABEL_CONFIG,
        headerContentConfig: MATERIAL_REQ_MAT_MODIFICATION_HEADER_CONTENT_CONFIG,
        headerImageSheetConfig: MATERIAL_REQ_RIG_PREP_IMAGE_SHEET_LABEL_CONFIG,
        dataImageContentConfig: MATERIAL_REQ_RIG_PREP_IMAGE_SHEET_CONTENT_CONFIG,
        dataImageContentRow: MATERIAL_REQ_IMAGE_DATA_CONTENT_ROW,
        dataLabelConfig: MATERIAL_REQ_MAT_MODIFICATION_DATA_LABEL_CONFIG,
        dataContentConfig: MATERIAL_REQ_RIG_PREP_DATA_CONTENT_CONFIG,
        dataContentRow: MATERIAL_REQ_MAT_MODIFICATION_DATA_CONTENT_ROW,
        secondHeaderLabelConfig: MATERIAL_REQ_SECOND_HEADER_MAT_MODIFICATION_LABEL_CONFIG,
        secondHeaderContentConfig: MATERIAL_REQ_SECOND_HEADER_MAT_MODIFICATION_CONTENT_CONFIG,
        dataLogoRow: MATERIAL_MAT_MODIFICATION_LOGO_POSITION, 
        secondHeaderLabelStyle: SECOND_HEADER_STYLES.MR_LABEL, 
        secondHeaderContentStyle: SECOND_HEADER_STYLES.MR_CONTENT
    },
    MATERIAL_REQUISITION_DELIVERY_THIRD_PARTY: { 
        headerLabelConfig: MATERIAL_REQ_THIRD_PARTY_HEADER_LABEL_CONFIG,
        headerContentConfig: MATERIAL_REQ_MAT_MODIFICATION_HEADER_CONTENT_CONFIG,
        headerImageSheetConfig: MATERIAL_REQ_RIG_PREP_IMAGE_SHEET_LABEL_CONFIG,
        dataImageContentConfig: MATERIAL_REQ_RIG_PREP_IMAGE_SHEET_CONTENT_CONFIG,
        dataImageContentRow: MATERIAL_REQ_IMAGE_DATA_CONTENT_ROW,
        dataLabelConfig: MATERIAL_REQ_THIRD_PARTY_DATA_LABEL_CONFIG,
        dataContentConfig: MATERIAL_REQ_RIG_PREP_DATA_CONTENT_CONFIG,
        dataContentRow: MATERIAL_REQ_THIRD_PARTY_DATA_CONTENT_ROW,
        secondHeaderLabelConfig: MATERIAL_REQ_SECOND_HEADER_THIRD_PARTY_LABEL_CONFIG,
        secondHeaderContentConfig: MATERIAL_REQ_SECOND_HEADER_THIRD_PARTY_CONTENT_CONFIG,
        dataLogoRow: MATERIAL_REQ_THIRD_PARTY_LOGO_POSITION, 
        secondHeaderLabelStyle: SECOND_HEADER_STYLES.MR_LABEL, 
        secondHeaderContentStyle: SECOND_HEADER_STYLES.MR_CONTENT
    },
    MATERIAL_REQUISITION_MAT_PHYSICAL_TRANSFER: {
        headerLabelConfig: MATERIAL_REQ_PHYSICAL_TRANSFER_HEADER_LABEL_CONFIG,
        headerContentConfig: MATERIAL_REQ_PHYSICAL_TRANSFER_HEADER_CONTENT_CONFIG,
        headerImageSheetConfig: MATERIAL_REQ_RIG_PREP_IMAGE_SHEET_LABEL_CONFIG,
        dataImageContentConfig: MATERIAL_REQ_RIG_PREP_IMAGE_SHEET_CONTENT_CONFIG,
        dataImageContentRow: MATERIAL_REQ_IMAGE_DATA_CONTENT_ROW,
        dataLabelConfig: MATERIAL_REQ_PHYSICAL_TRANSFER_DATA_LABEL_CONFIG,
        dataContentConfig: MATERIAL_REQ_RIG_PREP_DATA_CONTENT_CONFIG,
        dataContentRow: MATERIAL_REQ_PHYSICAL_TRANSFER_DATA_CONTENT_ROW,
        secondHeaderLabelConfig: MATERIAL_REQ_SECOND_HEADER_PHYSICAL_TRANSFER_LABEL_CONFIG,
        secondHeaderContentConfig: MATERIAL_REQ_SECOND_HEADER_PHYSICAL_TRANSFER_CONTENT_CONFIG,
        dataLogoRow: MATERIAL_REQ_PHYSICAL_TRANSFER_LOGO_POSITION, 
        secondHeaderLabelStyle: SECOND_HEADER_STYLES.MR_LABEL, 
        secondHeaderContentStyle: SECOND_HEADER_STYLES.MR_CONTENT
    },
}

