export const getFileName = (filename, reportId) => filename + reportId

export const getRejectReason = (defects) => {
    let s = ''
    defects.forEach(d => validateRejectReason(d) && (s += `${d}; `))
    return s
}

const validateRejectReason = (rejectReason) => rejectReason !== null && rejectReason !== undefined && rejectReason !== '' 

export const getPictureRef = (mark_legibility_photo, pin_photo, box_photo, int_body_photo, ext_body_photo, drift_photo) => {
    let pictureRef = []
    let cont = 1
    if(mark_legibility_photo){
        pictureRef.push('\n MARK LEGIBILITY: ' + cont)
        cont++
    }
    if(pin_photo){
        pictureRef.push('\n THREAD PIN: ' + cont)
        cont++
    }
    if(box_photo){
       pictureRef.push('\n THREAD BOX: ' + cont)
       cont++
    }
    if(int_body_photo){
        pictureRef.push('\n PIPE BODY INTERNAL: ' + cont)
        cont++
    }
    if(ext_body_photo){
        pictureRef.push('\n PIPE BODY EXTERNAL: ' + cont)
        cont++
    }
    if(drift_photo){
        pictureRef.push('\n DRIFT: ' + cont)
        cont++
    }
    pictureRef.push('\n')
    return pictureRef.join(' ')
}

export const truncateField = (values, numOfValuesToShow = 1) => {
    if (values && values.length === 1) {
        return values[0]
    }

    if (values && values[0]) {
        const valuesToShow = values.slice(0, numOfValuesToShow)
        const remainingValuesQtdy = values.length - numOfValuesToShow
        const valuesToShowText = valuesToShow.join(', ')
        return `${valuesToShowText} and ${remainingValuesQtdy} more...`
    }

    return ''
}

export const checkIfCanSearchImages = (reportData) => reportData && reportData.data && reportData.data.imageRows && reportData.data.imageRows.length > 0

export const textToSlug = (text) => text && text.toLowerCase()
    .replace(/[^\w ]+/g,'')
    .replace(/ +/g,'-')

export const loadFile = url => {
    const link = document.createElement('a')
    link.href = url
    link.target = '_blank'
    link.click()
}