import axios from 'axios'
import { addLogger } from 'utils/logger'
import { getDefaultHeader, manageResponse, manageError } from './api-util'

const SHOULD_DECOMPRESS = false

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  headers: {
    'Content-Type': 'application/json', 
    'VLR-Authorization': btoa(JSON.stringify({ "id": process.env.REACT_APP_ID }))
  }
})

addLogger(instance, false)

export default {
    getStockLogo: async (params, idToken) => {
      try {
          const result = await instance.post('/document-management/get-stock-logo', params, getDefaultHeader())
          return manageResponse(result, SHOULD_DECOMPRESS)
      } catch (error) {
        console.log(error)
        return ''
      }
    }, 
    getPreSignedUrl: async (params, idToken) => {
      try {
          const result = await instance.post('/document-management/get-presigned-url', params, getDefaultHeader())
          return manageResponse(result, SHOULD_DECOMPRESS)
      } catch (error) {
        manageError(error)
      }
    }, 
    updateDynamo: async (params, idToken) => {
      try {
          const result = await instance.post('/dynamo/update', params, getDefaultHeader())
          return manageResponse(result, SHOULD_DECOMPRESS)
      } catch (error) {
        manageError(error)
      }
    }, 
    deleteDocumentItem: async (params, idToken) => {
      try {
          const result = await instance.post('dynamo/delete-item', params, getDefaultHeader())
          return manageResponse(result, SHOULD_DECOMPRESS)
      } catch (error) {
        manageError(error)
      }
    }, 
    deleteFile: async (params, idToken) => {
      try {
          const result = await instance.post('document-management/delete-file', params, getDefaultHeader())
          return manageResponse(result, SHOULD_DECOMPRESS)
      } catch (error) {
        manageError(error)
      }
    }, 
    getDynamoItems: async (params, idToken) => {
      try {
          const result = await instance.post('dynamo/get-items', params, getDefaultHeader())
          return manageResponse(result, SHOULD_DECOMPRESS)
      } catch (error) {
        manageError(error)
      }
    }
    
}