import { filenames, filenamesPrefix } from './constants'

const filenamePrefix = filenamesPrefix[process.env.REACT_APP_DATASETS_ENV]

/**
 *  File to keep all the template generator queries to avoid duplicates and make easier the visualization
 */


export const getMillReceiptDownloadQuery = (jobId, datasets, templates, mrid=0) => {
  return {
    download: {
      id: templates.mill_receipt,
      description: '',
      datasetId: datasets.mill_receipt,
      filters: [],
      columns: [],
      select: [
        'beid',
        'co_purchase_item',
        'co_purchase_order',
        'vtsa_order_item',
        'vtsa_sales_order',
        'cat_id',
        'owner',
        'entrance_date',
        'dsc_material',
        'pipe_count',
        'pipe_length',
        'pipe_weight',
        'val_id',
        'report_reference',
      ],
      sort: [],
      jobID: jobId,
      totalRows: 11000,
      extension: 'xlsx',
      token: process.env.REACT_APP_DATAPORT_API_KEY,
      picture_cols: [],
      picture_tab_info: [],
      filename: filenamePrefix + filenames.MILL_RECEIPT + mrid
    }
  }
}


export const getMatRequisitionDeliveryThirdPartyReportDownloadQuery = (jobId, datasets, templates, dt3pid=0) => {
  return {
    download: {
      id: templates.material_requisition_delivery_third_party_report,
      description: '',
      datasetId: datasets.material_requisition_delivery_third_party_data,
      columns: [],
      select: ["id",
        "wid",
        "twl_name",
        "twlc_id",
        "tparty_name",
        "tparty_loc",
        "mr_ref",
        "ltpa",
        "closure_date",
        "quantity",
        "status",
        "co_purchase_order",
        "co_purchase_item",
        "num_sales_order",
        "num_sales_item",
        "cat_id",
        "valid",
        "it_status",
        "dsc_material",
        "insp_status",
        "mark_legibility_report",
        "pin_report",
        "box_report",
        "int_body_report",
        "ext_body_report",
        "drift_report",
        "final_report",
        "insp_responsible",
        "measure_status",
        "measured_lenght",
        "makeup_loss",
        "effective_lenght",
        "measure_responsible",
        "mark_defect",
        "pin_defect",
        "box_defect",
        "int_body_defect",
        "ext_body_defect",
        "drift_defect",
        "remarks", 
        'int_diameter',
        'out_diameter', 
        "instruments", 
        "end_user"
      ],
      sort: [],
      jobID: jobId,
      totalRows: 11000,
      extension: 'xlsx',
      token: process.env.REACT_APP_DATAPORT_API_KEY,
      picture_cols: [], 
      filename: filenamePrefix + filenames.MATERIAL_REQUISITION_DELIVERY_TO_THIRD_PARTY + dt3pid
    }
  }
}
export const getMatRequisitionMatModificationReportDownloadQuery = (jobId, datasets, templates, mmid=0) => {
  return {
    download: {
      id: templates.material_requisition_mat_modification_report,
      description: '',
      datasetId: datasets.material_requisition_mat_modification_data,
      columns: [],
      select: ["id",
        "wid",
        "twl_name",
        "twlc_id",
        "tparty_name",
        "tparty_loc",
        "mr_ref",
        "ltpa",
        "closure_date",
        "quantity",
        "status",
        "co_purchase_order",
        "co_purchase_item",
        "num_sales_order",
        "num_sales_item",
        "cat_id",
        "valid",
        "it_status",
        "dsc_material",
        "insp_status",
        "mark_legibility_report",
        "pin_report",
        "box_report",
        "int_body_report",
        "ext_body_report",
        "drift_report",
        "final_report",
        "insp_responsible",
        "measure_status",
        "measured_lenght",
        "makeup_loss",
        "effective_lenght",
        "measure_responsible",
        "mark_defect",
        "pin_defect",
        "box_defect",
        "int_body_defect",
        "ext_body_defect",
        "drift_defect",
        "remarks",
        "int_diameter", 
        "out_diamenter", 
        "instruments", 
        "end_user"
      ],
      sort: [],
      jobID: jobId,
      totalRows: 11000,
      extension: 'xlsx',
      token: process.env.REACT_APP_DATAPORT_API_KEY,
      picture_cols: [], 
      filename: filenamePrefix + filenames.MATERIAL_REQUISITION_MATERIAL_MODIFICATION + mmid
    }
  }
}

export const getMaterialRequisitionReportDownloadQuery = (jobId, datasets, templates, rpid=0) => {
  return {
    download: {
      id: templates.material_requisition_report,
      description: '',
      datasetId: datasets.material_requisition_report_data,
      columns: [],
      select: ["id",
        "wid",
        "rpl_name",
        "rplc_id",
        "field",
        "rig",
        "well",
        "mr_ref",
        "ltpa",
        "expected_date",
        "quantity",
        "status",
        "valid",
        "it_status",
        "dsc_material",
        "insp_status",
        "mark_legibility_report",
        "pin_report",
        "box_report",
        "int_body_report",
        "ext_body_report",
        "drift_report",
        "final_report",
        "insp_responsible",
        "measure_status",
        "measured_lenght",
        "makeup_loss",
        "effective_lenght",
        "measure_responsible",
        "co_purchase_order",
        "co_purchase_item",
        "num_sales_order",
        "num_sales_item",
        "cat_id",
        "mark_defect",
        "pin_defect",
        "box_defect",
        "int_body_defect",
        "ext_body_defect",
        "drift_defect",
        "remarks",
        "mark_legibility_photo",
        "pin_photo",
        "box_photo",
        "int_body_photo",
        "ext_body_photo",
        "drift_photo", 
        "int_diameter", 
        "out_diameter", 
        "instruments", 
        "end_user"
      ],
      sort: [],
      jobID: jobId,
      totalRows: 11000,
      extension: 'xlsx',
      token: process.env.REACT_APP_DATAPORT_API_KEY,
      picture_cols: [], 
      filename: filenamePrefix + filenames.MATERIAL_REQUISITION_RIG_PREP + rpid
    }
  }
}

export const getMaterialRequisitionMatPhysicalTransferReportDownloadQuery = (jobId, datasets, templates, mptid=0) => {
  return {
    download: {
      id: templates.material_requisition_mat_physical_transfer_report,
      description: '',
      datasetId: datasets.material_requisition_mat_physical_transfer_data,
      columns: [],
      select: [
        'id',
        'wid',
        'ptl_name',
        'ptlc_id',
        'tparty_name',
        'tparty_loc',
        'mr_ref',
        'ltpa',
        'closure_date',
        'quantity',
        'status',
        'co_purchase_order',
        'co_purchase_item',
        'num_sales_order',
        'num_sales_item',
        'cat_id', 'valid',
        'it_status',
        'dsc_material',
        'insp_status',
        'mark_legibility_report',
        'pin_report', 
        'box_report',
        'int_body_report',
        'ext_body_report',
        'drift_report',
        'final_report',
        'insp_responsible',
        'measure_status',
        'measured_lenght', 
        'makeup_loss',
        'effective_lenght',
        'measure_responsible',
        'mark_defect',
        'pin_defect',
        'box_defect',
        'int_body_defect',
        'ext_body_defect',
        'drift_defect',
        'remarks',
        'mark_legibility_photo',
        'pin_photo',
        'box_photo',
        'int_body_photo',
        'ext_body_photo',
        'drift_photo',
        'revision', 
        'int_diameter',
        'out_diameter', 
        'instruments', 
        "end_user"
      ],
      sort: [],
      jobID: jobId,
      totalRows: 5000,
      extension: 'xlsx',
      token: process.env.REACT_APP_DATAPORT_API_KEY,
      picture_cols: [
        'mark_legibility_photo',
        'pin_photo',
        'box_photo',
        'int_body_photo',
        'ext_body_photo',
        'drift_photo'
      ],
      picture_tab_info: [
        'valid',
      ], 
      filename: filenamePrefix + filenames.MATERIAL_REQUISITION_MATERIAL_PHYSICAL_TRANSFER + mptid
    }
  }
}


export const getReceiveInspectionReportDownloadQuery = (jobId, datasets, templates, riid=0) => {
  return {
    download: {
      id: templates.receive_inspection_data,
      description: '',
      datasetId: datasets.receive_inspection_data,
      columns: [],
      select: [ 'id', 'closure_date', 'customer_po', 'customer_item', 'num_sales_order', 'num_sales_item', 
      'dsc_material', 'valid', 'mark_legibility_report', 'coating_varnish_report', 'thread_prot_report',
      'pin_report', 'box_report','int_body_report', 'ext_body_report', 'drift_report', 'final_report', 
      'responsible', 'mark_defect', 'coat_varn_defect', 'prot_defect', 'pin_defect', 'box_defect', 
      'int_body_defect', 'ext_body_defect', 'drift_defect', 'remarks', 'mark_legibility_photo', 
      'coating_varnish_photo', 'thread_prot_photo', 'pin_photo', 'box_photo', 'int_body_photo', 
      'ext_body_photo', 'drift_photo', 'cat_id', 'instruments', 'end_user', 'report_reference',
      ],
      sort: [],
      jobID: jobId,
      totalRows: 1100,
      extension: 'xlsx',
      token: process.env.REACT_APP_DATAPORT_API_KEY,
      picture_cols: [
        'mark_legibility_photo', 'coating_varnish_photo', 'thread_prot_photo', 'pin_photo', 
        'box_photo', 'int_body_photo', 'ext_body_photo', 'drift_photo'
      ],
      picture_tab_info: [
        'valid',
      ], 
      filename: filenamePrefix + filenames.RECEIVING_INSPECTION + riid
    }
  }
}

export const getPeriodicalInspectionReportDownloadQuery = (jobId, datasets, templates, piid=0) => {
  return {
    download: {
      id: templates.periodical_inspection_data,
      description: '',
      datasetId: datasets.periodical_inspection_data,
      columns: [],
      select: [
        'id',
        'closure_date',
        'customer_po',
        'customer_item',
        'sales_order',
        'sales_item',
        'dsc_material',
        'valid',
        'mark_legibility_report',
        'coating_varnish_report',
        'thread_prot_report',
        'pin_report',
        'box_report',
        'int_body_report',
        'ext_body_report',
        'drift_report',
        'final_report',
        'responsible',
        'mark_defect',
        'coat_varn_defect',
        'prot_defect',
        'pin_defect',
        'box_defect',
        'int_body_defect',
        'ext_body_defect',
        'drift_defect',
        'remarks',
        'mark_legibility_photo',
        'coating_varnish_photo',
        'thread_prot_photo',
        'pin_photo',
        'box_photo',
        'int_body_photo',
        'ext_body_photo',
        'drift_photo',
        'cat_id',
        'entrance_id', 
        'instruments', 
        'end_user',
        'report_reference',
      ],
      sort: [],
      jobID: jobId,
      totalRows: 11000,
      extension: 'xlsx',
      token: process.env.REACT_APP_DATAPORT_API_KEY,
      picture_cols: [
        'mark_legibility_photo',
        'coating_varnish_photo',
        'thread_prot_photo',
        'pin_photo',
        'box_photo',
        'int_body_photo',
        'ext_body_photo',
        'drift_photo'
      ],
      picture_tab_info: [
        'valid',
      ], 
      filename: filenamePrefix + filenames.PERIODICAL_INSPECTION + piid
    }
  }
}

export const getRigReturnReportDownloadQuery = (jobId, datasets, templates, rrid=0) => {
  return {
    download: {
      id: templates.rig_return_report_data,
      description: '',
      datasetId: datasets.rig_return_report_data,
      columns: [],
      select: [
        'closure_date',
        'field',
        'rig',
        'well',
        'rr_ref',
        'end_user',
        'nt_quantity',
        'co_purchase_order',
        'co_purchase_item',
        'num_sales_order',
        'num_sales_item',
        'dsc_material',
        'valid',
        'mark_legibility_report',
        'pin_report',
        'box_report',
        'int_body_report',
        'ext_body_report',
        'drift_report',
        'final_report',
        'responsible',
        'id',
        'cat_id',
        'mark_defect',
        'coat_varn_defect',
        'prot_defect',
        'pin_defect',
        'box_defect',
        'int_body_defect',
        'ext_body_defect',
        'drift_defect',
        'remarks',
        'mark_legibility_photo',
        'coating_varnish_photo',
        'thread_prot_photo',
        'pin_photo',
        'box_photo',
        'int_body_photo',
        'ext_body_photo',
        'drift_photo',
        'instruments',
        'report_reference',
        'ippn',
      ],
      sort: [],
      jobID: jobId,
      totalRows: 11000,
      extension: 'xlsx',
      token: process.env.REACT_APP_DATAPORT_API_KEY,
      picture_cols: [
        'mark_legibility_photo',
        'coating_varnish_photo',
        'thread_prot_photo',
        'pin_photo',
        'box_photo',
        'int_body_photo',
        'ext_body_photo',
        'drift_photo'
      ],
      picture_tab_info: [
        'valid',
      ], 
      filename: filenamePrefix + filenames.RIG_RETURN + rrid
    }
  }
}

export const getModifiedProductsReportDownloadQuery = (jobId, datasets, templates, mpid=0) => {
  return {
    download: {
      id: templates.modified_products_report_data,
      description: '',
      datasetId: datasets.modified_products_report_data,
      columns: [],
      select: [
        'id',
        'wid',
        'mpl_name',
        'mplc_id',
        'er_ref',
        'closure_date',
        'status',
        'cat_id',
        'valid',
        'it_status',
        'dsc_material',
        'insp_status', 
        'mark_legibility_report',
        'pin_report',
        'box_report',
        'int_body_report',
        'ext_body_report',
        'drift_report',
        'final_report',
        'insp_responsible',
        'measure_status', 
        'measured_lenght', 
        'makeup_loss',
        'effective_lenght',
        'measure_responsible',
        'mark_defect',
        'pin_defect',
        'box_defect',
        'int_body_defect',
        'ext_body_defect',
        'drift_defect',
        'mark_legibility_photo',
        'coating_varnish_photo',
        'thread_prot_photo',
        'pin_photo',
        'box_photo',
        'int_body_photo',
        'ext_body_photo',
        'drift_photo',
        'mark_defect',
        'remarks', 
        'int_diameter', 
        'out_diameter',
        'instruments',
        'owner',
        'report_reference',
      ],
      sort: [],
      jobID: jobId,
      totalRows: 5000,
      extension: 'xlsx',
      token: process.env.REACT_APP_DATAPORT_API_KEY,
      picture_cols: [
        'mark_legibility_photo',
        'coating_varnish_photo',
        'thread_prot_photo',
        'pin_photo',
        'box_photo',
        'int_body_photo',
        'ext_body_photo',
        'drift_photo'
      ],
      picture_tab_info: [
        'valid',
      ], 
      filename: filenamePrefix + filenames.MODIFIED_PRODUCTS + mpid
    }
  }
}

export const getSlowMovingInspectionDownloadQuery = (jobId, datasets, templates, smid=0) => {
  return {
    download: {
      id: templates.slow_moving_report_data,
      description: '',
      datasetId: datasets.slow_moving_pipes_report_data,
      filters: [],
      columns: [],
      select: ['id', 'trigger_date', 'ltpa', 'co_purchase_order', 'co_purchase_item', 'num_sales_item', 'num_sales_order', 'cat_id', 'dsc_material',
        'ownership', 'entrance_date', 'months_in_stock', 'pipe_count', 'pipes_length', 'pipes_weight', 'storage_type', 'end_user', 'report_reference',
      ],
      sort: [],
      jobID: jobId,
      totalRows: 5000,
      extension: 'xlsx',
      token: process.env.REACT_APP_DATAPORT_API_KEY, 
      filename: filenamePrefix + filenames.PAM_SLOW_MOVING_PIPES + smid
    }
  }
}

export const getTitleTransferInspectionDownloadQuery = (jobId, datasets, templates, ttid=0) => {
  return {
    download: {
      id: templates.title_transfer_report_data,
      description: '',
      datasetId: datasets.title_transfer_report_data,
      filters: [],
      columns: [],
      select: ['id', 'trigger_date', 'ltpa', 'co_purchase_order', 'co_purchase_item', 'num_sales_item', 'num_sales_order', 'cat_id', 'dsc_material',
        'ownership', 'entrance_date', 'months_in_stock', 'pipe_count', 'pipes_length', 'pipes_weight', 'storage_type', 'end_user', 'report_reference',
      ],
      sort: [],
      jobID: jobId,
      totalRows: 5000,
      extension: 'xlsx',
      token: process.env.REACT_APP_DATAPORT_API_KEY, 
      filename: filenamePrefix + filenames.PAM_TITLE_TRANSFER + ttid
    }
  }
}

export const getMaterialPhysicalTransferInspectionDownloadQuery = (jobId, datasets, templates, mptid=0) => {
  return {
    download: {
      id: templates.material_physical_transfer_report_data,
      description: '',
      datasetId: datasets.material_physical_transfer_report_data,
      filters: [],
      columns: [],
      select: ['id', 'trigger_date', 'ltpa', 'co_purchase_order', 'co_purchase_item', 'num_sales_item', 'num_sales_order', 'cat_id', 'dsc_material',
        'ownership', 'entrance_date', 'months_in_stock', 'pipe_count', 'pipes_length', 'pipes_weight', 'storage_type', 'end_user', 'report_reference',
      ],
      sort: [],
      jobID: jobId,
      totalRows: 5000,
      extension: 'xlsx',
      token: process.env.REACT_APP_DATAPORT_API_KEY, 
      filename: filenamePrefix + filenames.PAM_MATERIAL_PHYSICAL_TRANSFER + mptid
    }
  }
}