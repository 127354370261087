import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import _ from 'lodash'
import moment from 'moment'

import mrStatusApi from 'api/mr-status'

import { Card, FlexView, Icon, LoadingOverlay } from 'components/common'
import FilterableTable from 'components/common/FilterableTable'

import FilterDisplay from 'containers/common/FilterDisplay'
import FiltersModal from 'containers/common/FiltersModal'
import StockTabs from 'containers/layout/StockTabs'
import MaterialsModal from 'containers/mr-status/MaterialsModal'

import { AppContext } from 'stores/AppStore'
import { FilterContext } from 'stores/FilterStore'
import { UserContext } from 'stores/UserStore'
import { WarehouseContext } from 'stores/WarehouseStore'
import { mrStatus_uri } from 'utils/constants'
import { truncateField } from 'utils/helpers'
import { exportAsTablePdfFile } from 'utils/pdf'
import { exportAsExcelFile } from '../utils/excel'

// TODO: ADD provider or store calls like this
const downloadMrFile = async (mrFileName, idToken) => {
    try {
        const { url } = await mrStatusApi.getMrFile(mrStatus_uri.GET_MR_FILE, { mrFileName }, idToken)
        if (url) {
            const link = document.createElement('a')
            link.href = url
            link.target = '_blank'
            link.click()
        } else {
            throw new Error('Could not get MR File')
        }
    } catch (error) {
        throw new Error(error)
    }
}

const cellWithModalStyle = {
    fontSize: '13px',
    textDecoration: 'underline',
    cursor: 'pointer'
}

const cellWithoutModalStyle = {
    fontSize: '13px',
}

const getCellStyle = (value) => {
    return (value && value.length > 1) ? cellWithModalStyle : cellWithoutModalStyle
}

const formatModalsMaterials = (row) => {
    const materialData = []
    if (row && row.erp_refs && row.dsc_materials) {
        row.erp_refs.forEach((value, index) => {
            materialData.push({ erp_ref: value, dsc_material: row.dsc_materials[index] })
        })
    }
    return materialData
}

const MaterialRequisitionStatusPage = ({ stockKey }) => {
    
    const { t } = useTranslation()

    const { setPageSubtitle } = useContext(AppContext)
    const { warehouses } = useContext(WarehouseContext)
    const { idToken } = useContext(UserContext)

    const stock = useMemo(() => _.find(warehouses, { key: stockKey }), [warehouses, stockKey])

    const [showModal, setShowModal] = useState(false)

    const { filteredData: {filteredTableData }, data, setData } = useContext(FilterContext)

    const [loading, setLoading] = useState(true)
   
    const [exportableData, setExportableData] = useState([])
    const [showMaterialsModal, setShowMaterialsModal] = useState(false)
    const [materialModalData, setMaterialModalData] = useState([])
    // const { exportToExcel, exportToPdf } = useContext(StockContext)

    const toggleMaterialsModal = useCallback(() => setShowMaterialsModal(currentState => !currentState), [])

    const onExcelExportClick = () => {
        exportToExcel(filterOptions, stock.key)
    }

    const onPdfExportClick = () => {
        exportToPdf(filterOptions, stock.key)
    }


    const handleCellClick = useCallback((row) => {
        toggleMaterialsModal()
        const formatedMaterials = formatModalsMaterials(row)
        setMaterialModalData(formatedMaterials)
    }, [toggleMaterialsModal])


    const onDownloadClick = useCallback(mrFileName => async () => {
        try {
            if (mrFileName) {
                await downloadMrFile(mrFileName, idToken)
            }
        }
        catch (e) {
            toast.error(e.data || e.message)
        }
    }, [idToken])

    const updateExportableData = useCallback((data) => {
        setExportableData(data)
    }, [])

    const getMrStatus = useCallback(async () => {
        try {
            setLoading(true)
            const response = await mrStatusApi.getMrStatusList(mrStatus_uri.GET_MR_STATUS, { wid: stock.wid}, idToken)
            const sortedResponse = _.orderBy(response, ['expected_date', 'closure_date'], 'desc')
            setData(sortedResponse)
            setExportableData(sortedResponse)
        } catch (error) {
            setLoading(false)
            toast.error(error.message || error)
        }
        finally {
            setLoading(false)
        }
    }, [idToken, setData, stock.wid])


    const filterOptions = [
        {
            accessor: 'end_user',
            label: t('End User')
        },
        {
            accessor: 'mr_ref',
            label: t('MR Reference')
        },
        {
            accessor: 'mr_status',
            label: t('MR Status')
        },
        {
            accessor: 'rig',
            label: t('Rig Name')
        },
        {
            accessor: 'well',
            label: t('Well Name')
        },
        {
            accessor: 'closure_date',
            label: t('MR Completion Date'),
            Cell: (props) => {
                const { closure_date } = props.row.original
                return closure_date ? moment(closure_date).format('MMM D, YYYY, h:mm:ss A') : ''
            },
        },
        {
            accessor: 'expected_date',
            label: t('MR Expected Date'),
            Cell: (props) => {
                const { expected_date } = props.row.original
                return expected_date ? moment(expected_date).format('MMM D, YYYY, h:mm:ss A') : ''
            },
        },
        {
            accessor: 'erp_refs',
            label: t('SAP Reference'),
            Cell: (props) => {
                const { erp_refs } = props.row.original
                return erp_refs ? erp_refs.join(', ') : ''
            },
        },
        {
            accessor: 'dsc_materials',
            label: t('Material Description'),
            Cell: (props) => {
                const { dsc_materials } = props.row.original
                return dsc_materials ? dsc_materials.join(', ') : ''
            },
        },
        {
            accessor: 'pipe_count',
            label: t('Pipe Count')
        },
        {
            accessor: 'type',
            label: t('Type')
        }
    ]

    const exportToExcel = async (columnsConfig, stockKey) => {
        const excelData = _.map(exportableData, row => (
            _.reduce(columnsConfig, (acc, { accessor, label, formatter }) => {
                const value = _.get(row, accessor, '')
                return {
                    ...acc,
                    [label]: formatter ? formatter(value) : value
                }
            }, {})
        ))
        exportAsExcelFile(excelData, `${t('MR_STATUS_')} ${moment().format('YYYY-MM-DD HH-mm-ss')}`)
    }

    const exportToPdf = async (columnsConfig, stockKey) => {
        const header = {
            title: `${t('MR_STATUS_')} ${moment().format('YYYY-MM-DD HH-mm-ss')}`,
            subTitle: ' ',
            logo: 'Smart'
        }
        const headers = _.map(columnsConfig, 'label')
        const body = _.map(exportableData, row => (
            _.reduce(columnsConfig, (acc, { accessor, formatter }) => {
                const value = _.get(row, accessor)
                return [
                    ...acc,
                    value ? (formatter ? formatter(value) : value) : '-'
                ]
            }, [])
        ))
        const reportData = [headers, ...body]
        const options = {
            pageOrientation: 'landscape',
            pageSize: 'A3',
            watermark: false,
            zebraStyle: true
        }
        exportAsTablePdfFile(header, reportData, options)
    }

    const columns = useMemo(() => [
        {
            accessor: 'end_user',
            Header: t('End User'),
            label: t('End User'),
            customHeaderProps: {
                style: {
                    minWidth: '40px',
                }
            },
            customCellProps: {
                style: {
                    textAlign: 'center'
                }
            }
        },
        {
            accessor: 'mr_ref',
            Header: t('MR Reference'),
            label: t('MR Reference'),
            customHeaderProps: {
                style: {
                    minWidth: '40px',
                }
            },
            customCellProps: {
                style: {
                    textAlign: 'center'
                }
            }
        },
        {
            Header: t('Download MR File'),
            accessor: 'mrFileName',
            Cell: ({ cell: { value } }) => <FlexView width="100%" alignItems="center" justifyContent="center">
                <Icon name="download" width="20px" height="20px" onClick={onDownloadClick(value)} />
            </FlexView>,
            disableFilters: true
        },
        {
            Header: t('Rig Name'),
            label: t('Rig Name'),
            accessor: 'rig',
            customHeaderProps: {
                style: {
                    minWidth: '80px'
                }
            },
            customCellProps: {
                style: {
                    textAlign: 'center'
                }
            }
        },
        {
            Header: t('Well Name'),
            label: t('Well Name'),
            accessor: 'well',
            customHeaderProps: {
                style: {
                    minWidth: '80px'
                }
            },
            customCellProps: {
                style: {
                    textAlign: 'center'
                }
            }
        },
        {
            Header: t('MR Expected Date'),
            label: t('MR Expected Date'),
            accessor: 'expected_date',
            Cell: ({ cell: { value } }) => value ? moment(value).format('DD/MM/YYYY') : '',
            sortType: 'date',
            customHeaderProps: {
                style: {
                    minWidth: '60px'
                }
            },
            customCellProps: {
                style: {
                    textAlign: 'center'
                }
            }
        },
        {
            Header: t('MR Status'),
            label: t('MR Status'),
            accessor: 'mr_status',
            cell: ({ mr_status }) => t(mr_status),
            customHeaderProps: {
                style: {
                    minWidth: '80px'
                }
            },
            customCellProps: {
                style: {
                    textAlign: 'center'
                }
            }
        },
        {
            Header: t('MR Completion Date'),
            label: t('MR Completion Date'),
            accessor: 'closure_date',
            Cell: ({ cell: { value } }) => value ? moment(value).format('DD/MM/YYYY') : '',
            sortType: 'date',
            customHeaderProps: {
                style: {
                    minWidth: '80px'
                }
            },
            customCellProps: {
                style: {
                    textAlign: 'center'
                }
            }
        },
        {
            accessor: 'erp_refs',
            Header: t('Sap Reference'),
            label: t('Sap Reference'),
            customHeaderProps: {
                style: {
                    minWidth: '40px',
                }
            },
            customCellProps: {
                style: {
                    textAlign: 'center'
                }
            },
            Cell: ({ cell: { value, row } }) => <FlexView width="100%" alignItems="center" justifyContent="center">
                <span
                    style={getCellStyle(row.original.erp_refs)}
                    onClick={() => handleCellClick(row.original)}>
                    {value ? truncateField(value) : ''}
                </span>
            </FlexView>,
        },
        {
            accessor: 'dsc_materials',
            Header: t('Material Description'),
            label: t('Material Description'),
            customHeaderProps: {
                style: {
                    minWidth: '300px',
                }
            },
            customCellProps: {
                style: {
                    textAlign: 'center'
                }
            },
            Cell: ({ cell: { value, row } }) => <FlexView width="100%" alignItems="center" justifyContent="center">
                <span
                    style={getCellStyle(row.original.dsc_materials)}
                    onClick={() => handleCellClick(row.original)}>
                    {value ? truncateField(value) : ''}
                </span>
            </FlexView>,
        },
        {
            accessor: 'pipe_count',
            Header: t('Pipe Count'),
            label: t('Pipe Count'),
            customHeaderProps: {
                style: {
                    minWidth: '10px',
                }
            },
            customCellProps: {
                style: {
                    textAlign: 'center'
                }
            }
        },
        {
            accessor: 'type',
            Header: t('Type'), 
            label: t('Type')
        },
    ], [t, onDownloadClick, handleCellClick])

    useEffect(() => {
        if (stock) {
            setPageSubtitle(stock?.title + ' - ' + t('Material Requisition Status'))
        }
    }, [stock, setPageSubtitle, t])

    useEffect(() => {
        getMrStatus()
    }, [getMrStatus])

    const toggleModal = () => setShowModal(currentState => !currentState)

    return <FlexView flex="1" position="relative" alignSelf="stretch">
        <StockTabs stock={stock} />
        <FlexView alignSelf="stretch" flexDirection="row" justifyContent="space-between" alignItems="center" margin="16px 16px 0px">
            <FilterDisplay options={columns} onTagClick={toggleModal} />
            <FlexView flexDirection="row" alignItems="center" justifyContent="flex-end" flex="1">
                <Icon name="filter" width="28px" height="28px" margin="0px 8px 0px 0px" tooltip={t('Filter')} onClick={toggleModal}/>
                <Icon name="xls" width="28px" height="28px" margin="0px 8px 0px 0px" tooltip={t('Export to Excel')} onClick={onExcelExportClick} />
                <Icon name="pdf" width="28px" height="28px" margin="0px 0px 0px 0px" tooltip={t('Export to PDF')} onClick={onPdfExportClick} />
            </FlexView>
        </FlexView>
        <Card alignSelf="stretch" padding="0px" margin="16px">
            <FilterableTable columns={columns} data={filteredTableData} onRowsChange={updateExportableData} />
        </Card>
        <FiltersModal isOpen={showModal} onOutsideClick={toggleModal} options={columns} data={data}/>
        <LoadingOverlay visible={loading} />
        <MaterialsModal isOpen={showMaterialsModal} onOutsideClick={toggleMaterialsModal} data={materialModalData}></MaterialsModal>
    </FlexView>
}

export default MaterialRequisitionStatusPage